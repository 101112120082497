import {
  Avatar,
  Button,
} from '@Infowijs-eng/component-library/components'
import {
  Pencil,
  PersonCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  getUserId,
  makeHasPermission,
} from '@Infowijs-eng/component-library/modules/authentication'
import { PropTypes } from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import getFormattedFullName from '../../modules/getFormattedFullName'
import ListItem from '../ListItem'

function AdministratorListItem({ administrator }) {
  const hasPermission = makeHasPermission()
  const userCanEdit = useSelector((state) => hasPermission(state, {
    permissions: 'administrators.edit',
  }))

  const userId = useSelector(getUserId)
  const isMe = userId === administrator.id

  return (
    <ListItem
      key={administrator.id}
      row
      leadingAddon={(
        <Avatar
          alt={getFormattedFullName(administrator)}
          id={administrator.id}
          placeholder={administrator.avatar}
        />
      )}
      trailingAddon={(
        <div className="space-x-4 flex items-center">
          {isMe && (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <button
              type="button"
              onClick={() => {
                // eslint-disable-next-line no-alert
                alert('It\'s a Me, Mario!')
              }}
            >
              <PersonCircle
                className="w-5 h-5 text-theme-700"
              />
            </button>
          )}
          <Button
            to={`${administrator.id}/edit`}
            // eslint-disable-next-line max-len
            neutral
            small
            className="outline-none text-gray-500 hover:bg-gray-100 group-hover:text-black"
            leadingAddon={<Pencil />}
          />
        </div>
      )}
      to={userCanEdit && `${administrator.id}`}
    >
      <span className="flex flex-col items-start mt-1 sm:mt-0 sm:w-full sm:justify-center">
        <span className="w-full flex flex-wrap justify-start items-center text-black font-medium font-display">
          {getFormattedFullName(administrator)}
        </span>
        <span className="w-full flex flex-wrap justify-start items-center text-gray-500">
          {administrator.email}
        </span>
      </span>
    </ListItem>
  )
}

AdministratorListItem.propTypes = {
  administrator: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
  }),
}

AdministratorListItem.defaultProps = {
  administrator: null,
}

export default AdministratorListItem

import React from 'react'
import { XMark } from '@Infowijs-eng/component-library/icons'
import { Clickable } from '@Infowijs-eng/component-library/components'
import { cn, getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import { ImporterUser } from '../../../../modules/importerSwitch/types'
import getFullName from '../../../../modules/getFullName'

interface ImporterMigrateMatchProps {
  customerId: string
  user: ImporterUser
  onRemoveUser?: () => void
}

export default function ImporterMigrateMatch({
  customerId, user, onRemoveUser = undefined,
}: ImporterMigrateMatchProps) {
  return (
    <div className={cn(
      'flex flex-row h-full w-full border border-green-300 bg-green-50',
      'p-4 gap-2 rounded-lg group items-center',
    )}
    >
      <Clickable
        className={`invisible ${onRemoveUser ? 'group-hover:visible' : ''}`}
        onClick={onRemoveUser}
      >
        <XMark />
      </Clickable>
      <div className="text-xs">{getLocalisedLabelString(user.userRole.labelI18n, 1)}</div>
      <div className="flex flex-col gap-1 ml-2">
        <div className="font-medium">{user.email}</div>
        <a
          className="hover:underline text-sm"
          href={`/customers/${customerId}/users/${user.id}`}
          target="_blank"
          rel="noreferrer"
        >
          {getFullName(user)}
          {user.archivedAt || user.deletedAt ? ' (Archived)' : null}
        </a>
      </div>

    </div>
  )
}

import {
  Alert,
  Button,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLoop,
  FloppyDisk,
} from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { parse } from 'qs'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import {
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import createUserRelation from '../../actions/user/createUserRelation'
import fetchUsers from '../../actions/users/fetchUsers'
import filters from '../../modules/filterBarFilters'
import userRelationMetadataStructure from '../../modules/userRelationMetadataStructure'
import makeGetUserRelationsGroupedByUserId from '../../selectors/user/makeGetUserRelationsGroupedByUserId'
import getUsers from '../../selectors/users/getUsers'
import FilterBar from '../FilterBar'
import List from '../List'
import MetadataEditor from '../MetadataEditor/MetadataEditor'
import Modal from '../Modal'
import UserRelationUserListItem from './UserRelationUserListItem'
import UserRelationUserListSkeleton from './UserRelationUserListSkeleton'

const getUserRelationsGroupedByUserId = makeGetUserRelationsGroupedByUserId()

function UserRelationsCreateModal() {
  const promisifyDispatch = usePromisifyDispatch()
  const navigate = useNavigate()
  const [metadata, setMetadata] = useState({})
  const [submitting, setSubmitting] = useState(false)
  const [errors, setErrors] = useState(null)
  const {
    customerId,
    userId,
  } = useParams()
  const [loading, setLoading] = useState(false)
  const [selectedUserRelation, setSelectedUserRelation] = useState(null)
  const [searchParams] = useSearchParams()
  const {
    sort,
    page,
    ...filter
  } = parse(searchParams.toString(), { ignoreQueryPrefix: true })
  const users = useSelector(getUsers)
  const relations = useSelector((state) => getUserRelationsGroupedByUserId(state, { userId }))

  useEffect(() => {
    if (customerId) {
      setLoading(true)
      promisifyDispatch(fetchUsers({
        page: Number(page),
        sort,
        limit: 25,
        filter: {
          id: `ne:${userId}`,
          customerId: `eq:${customerId}`,
          ...filter,
        },
      })).finally(() => {
        setLoading(false)
      })
    }
  }, [fetchUsers, searchParams])

  return (
    <Modal
      className="p-0 bg-white sm:max-h-[80vh] sm:min-w-[50vw]"
      onClose={() => (
        navigate(`/customers/${customerId}/users/${userId}/relations`)
      )}
    >
      <div className="px-8 pt-8">
        <Heading fontSize={Heading.size.H3}>
          New User Relation
        </Heading>
        {errors && <Alert errorMessages={errors} title="Something went wrong" />}
        <FilterBar
          disabled={!!selectedUserRelation}
          filters={[
            filters.fullName,
            filters.email,
          ]}
          sort={[
            {
              key: 'lastUpdatedAt',
              label: 'Last updated',
              defaultOrder: 'desc',
            },
            {
              key: 'lastName',
              label: 'Last name',
            },
            {
              key: 'importerLockedUntil',
              label: 'Importer locking',
            },
          ]}
          limits={[25]}
        />
      </div>
      <div className="overflow-y-scroll max-w-full px-8">
        {!!selectedUserRelation && (
        <List>
          <UserRelationUserListItem
            key={selectedUserRelation.id}
            user={selectedUserRelation}
            selected
            onSelect={() => setSelectedUserRelation(null)}
          />
        </List>
        )}
        <List>
          {!!loading && (!users || !users.length) && (
            <UserRelationUserListSkeleton />
          )}
          {!selectedUserRelation && users.map((user) => {
            const hasRelation = relations && Object.keys(relations).includes(user.id)
            return (
              <UserRelationUserListItem
                key={user.id}
                hasRelation={hasRelation}
                user={user}
                selected={selectedUserRelation && selectedUserRelation.id === user.id}
                onSelect={(selected) => setSelectedUserRelation(selected ? user : null)}
              />
            )
          })}
        </List>
      </div>
      <div className="p-8 pt-4">
        {selectedUserRelation && (
        <MetadataEditor
          structure={userRelationMetadataStructure}
          metadata={metadata}
          onChange={(newMetadata) => {
            setMetadata(newMetadata)
          }}
        />
        )}
        <div className="flex justify-end items-center">
          <Button
            primary
            disabled={submitting || !selectedUserRelation}
            leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
            onClick={() => {
              setSubmitting(true)
              setErrors(null)
              promisifyDispatch(createUserRelation(userId, selectedUserRelation.id, metadata, metadata))
                .then(() => {
                  setSubmitting(false)
                  navigate(`/customers/${customerId}/users/${userId}/relations`)
                })
                .catch((e) => {
                  setSubmitting(false)
                  setErrors(e)
                })
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default UserRelationsCreateModal

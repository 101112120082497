export interface UserRole {
  id: string
  legacyRole: string
  labelI18n: unknown
  metadata: unknown
  parentUserRoleId?: string | null
}

export interface ImporterUser {
  id: string
  firstName?: string | null
  initials?: string | null
  lastName: string
  lastNamePrefix?: string | null
  email?: string | null
  importerSourceId: string
  userRole: UserRole
  createdAt: string
  archivedAt?: string | null
  deletedAt?: string | null
}

export enum MatchStatus {
  ImplicitUnmatch,
  ExplicitUnmatch,
  ImplicitMatch,
  ExplicitMatch,
}

export interface PossibleMatchUser {
  user: ImporterUser
  distanceScoreEmail: number
  distanceScoreName: number
}

export interface UserStatus {
  status: MatchStatus
  from?: ImporterUser
  to?: ImporterUser
  possibleMatches: PossibleMatchUser[]
}

import { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Heading,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import { Pencil } from '@Infowijs-eng/component-library/icons'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import Layout from '../components/Layout'
import fetchCustomer from '../actions/customers/fetchCustomer'
import getCustomerTitle from '../selectors/customers/getCustomerTitle'
import PageHeader from '../components/PageHeader'

function CustomerContextScreen({
  children,
  customerId,
  secondaryActions,
  loading,
}) {
  const dispatch = useDispatch()
  const customerTitle = useSelector((state) => getCustomerTitle(state, { id: customerId }))
  useEffect(() => {
    if (customerId) {
      dispatch(fetchCustomer(customerId))
    }
  }, [customerId])

  return (
    <Layout>
      <PageHeader
        loading={loading}
        breadcrumbs={[
          {
            to: '/customers',
            label: 'Customers',
          },
        ]}
        actions={[
          {
            primary: true,
            children: 'Edit Customer',
            leadingAddon: <Pencil />,
            to: `/customers/${customerId}/edit`,
            permissions: ['customers.edit'],
            key: 'customer.edit',
          },
          ...(secondaryActions && secondaryActions.map((action) => ({
            ...action,
            primary: false,
          }))),
        ]}
        subNavigation={[
          {
            children: 'Overview',
            end: true,
            to: `/customers/${customerId}`,
            permissions: ['customers.view'],
            key: 'customer.detail',
          },
          {
            children: 'Users',
            to: `/customers/${customerId}/users`,
            permissions: ['users.list'],
            key: 'customer.users',
          },
          {
            children: 'Roles',
            to: `/customers/${customerId}/roles`,
            permissions: ['users.list'],
            key: 'customer.roles',
          },
        ]}
      >
        {<Heading fontSize={Heading.size.H3}>{customerTitle}</Heading> || <Skeleton xl className="w-48" />}
      </PageHeader>
      {children}
    </Layout>
  )
}

CustomerContextScreen.propTypes = {
  children: PropTypes.node.isRequired,
  customerId: PropTypes.string,
  secondaryActions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    children: PropTypes.string,
    trailingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    onClick: PropTypes.func,
    to: PropTypes.string,
    neutral: PropTypes.bool,
  })),
  loading: PropTypes.bool,
}

CustomerContextScreen.defaultProps = {
  customerId: null,
  secondaryActions: [],
  loading: false,
}

export default CustomerContextScreen

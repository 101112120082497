import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  useDefaultPageLimit,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { parse } from 'qs'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import fetchCustomers from '../actions/customers/fetchCustomers'
import CustomerListItem from '../components/Customer/CustomerListItem'
import CustomerListSkeleton from '../components/Customer/CustomerListSkeleton'
import FilterBar from '../components/FilterBar'
import Layout from '../components/Layout'
import List from '../components/List'
import ListItem from '../components/ListItem'
import PageHeader from '../components/PageHeader'
import Pagination from '../components/Pagination'
import filterBarFilters from '../modules/filterBarFilters'
import getDefaultPageLimits from '../modules/getDefaultPageLimits'
import usePagination from '../modules/usePagination'
import getCustomersData from '../selectors/customers/getCustomersData'
import getCustomersMeta from '../selectors/customers/getCustomersMeta'

function CustomersScreen() {
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [searchParams] = useSearchParams()

  const customers = useSelector(getCustomersData)

  const meta = useSelector(getCustomersMeta)
  const [page, pages, total] = usePagination(meta)
  const limits = getDefaultPageLimits()
  const defaultPageLimit = limits && useDefaultPageLimit(limits)
  const {
    sort,
    limit,
    ...filter
  } = parse(searchParams.toString(), { ignoreQueryPrefix: true })

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchCustomers({
      page,
      sort,
      limit: limit || defaultPageLimit,
      filter,
    }))
      .then(() => setLoading(false))
      .catch(setErrorMessages)
      .finally(() => setLoading(false))
  }, [searchParams, fetchCustomers])

  return (
    <Layout>
      <PageHeader
        actions={[{
          primary: true,
          children: 'New Customer',
          leadingAddon: <PlusCircle />,
          to: '/customers/new',
          permissions: ['customers.create'],
          key: 'create',
        }]}
        loading={loading}
      >
        <Heading fontSize={Heading.size.H3}>Customers</Heading>
      </PageHeader>
      <FilterBar
        filters={[
          filterBarFilters.title,
          filterBarFilters.customerProductTypes,
          filterBarFilters.suspended,
        ]}
        sort={[
          {
            key: 'title',
            label: 'Name',
          },
          {
            key: 'createdAt',
            label: 'Date',
            defaultOrder: 'desc',
          },
        ]}
        limits={limits}
      />
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List>
        {loading && (!customers || !customers.length) && <CustomerListSkeleton />}
        {!loading && !customers.length && <ListItem className="italic gray-500">No results</ListItem>}
        {!!customers.length && customers.map((customer) => (
          <CustomerListItem
            key={customer.id}
            customer={customer}
          />
        ))}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && (<Pagination current={page || 1} total={pages} />)}
    </Layout>
  )
}

export default CustomersScreen

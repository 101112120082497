import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import setUserRelations from '../../actions/user/setUserRelations'
import fetchUserRelations from '../../actions/user/fetchUserRelations'

const onFetchUserRelations = function* onFetchUserRelations() {
  yield takeLatest(actionTypes.USER.RELATIONS_FETCH, function* onFetchUserRelationsSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    let response
    const { payload: { id } } = action

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/${id}/relations`,
        params: {
          include: 'userRole',
        },
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchUser() {
          yield put(fetchUserRelations(id))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setUserRelations(id, response.data.data || [], response.data.included || []))

    acceptAction(action, response.data.data)
  })
}

export default onFetchUserRelations

import actionTypes from '../action-types'

const initialState = {
  data: {},
  meta: {
    limit: 50,
    pages: 1,
  },
}

function mapCustomerProductIncludes(customerProductRefs, customerProductIncludes) {
  return customerProductRefs.flatMap((ref) => (
    (customerProductIncludes[ref.id] && [customerProductIncludes[ref.id]]) || []
  ))
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CUSTOMERS.SET_ALL:
      return {
        ...state,
        data: action.payload.reduce((acc, customer) => {
          acc[customer.id] = customer
          acc[customer.id].createdBy = {
            ...action.included.administrator[customer.createdBy.id],
          }
          acc[customer.id].customerProducts = mapCustomerProductIncludes(
            acc[customer.id].customerProducts,
            action.included.customerProduct,
          )
          return acc
        }, {}),
        meta: {
          ...state.meta,
          ...action.meta,
          pages: Math.ceil(((action.meta && action.meta.total) || 0) / ((action.meta && action.meta.limit) || 50)),
        },
      }
    case actionTypes.CUSTOMERS.SET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
            createdBy: {
              ...(action.included && action.included.administrator
                ? action.included.administrator[action.payload.createdBy.id]
                : {}),
            },
            customerProducts: (action.payload
              && action.payload.customerProducts
              && mapCustomerProductIncludes(
                action.payload.customerProducts,
                (action.included && action.included.customerProduct) || {},
              )) || null,
          },
        },
      }
    default:
      return state
  }
}

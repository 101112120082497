import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  BackgroundBubble,
  Button,
  Infowijs,
  TextInput,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowRight,
  ArrowsLoop,
  LockOpen,
} from '@Infowijs-eng/component-library/icons'
import { useNavigate } from 'react-router-dom'
import {
  getSessionRequest,
  isAuthenticated,
  signOut,
} from '@Infowijs-eng/component-library/modules/authentication'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import signIn from '../actions/authentication/signIn'
import startWebAuthNLogin from '../actions/webAuthN/startWebAuthNLogin'
import isWebAuthNSupported from '../selectors/webAuthN/isWebAuthNSupported'

function SignInScreen() {
  const navigate = useNavigate()
  const [username, setUsername] = useState('')
  const [errorMessages, setErrorMessages] = useState([])
  const [submitting, setSubmitting] = useState(false)

  const sessionRequest = useSelector(getSessionRequest)
  const isUserAuthenticated = useSelector(isAuthenticated)

  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()

  const supportsWebAuthn = useSelector(isWebAuthNSupported)

  useEffect(() => {
    if (isUserAuthenticated) {
      navigate('/')
    }
  }, [isUserAuthenticated])

  useEffect(() => {
    if (sessionRequest) {
      setUsername(sessionRequest.emailAddress)
      setSubmitting(false)
    }
  }, [sessionRequest, setSubmitting])

  return (
    <div>
      <BackgroundBubble />
      <div className="min-h-screen flex flex-col px-4 items-center justify-center">

        <div className="mb-8">
          <Infowijs />
        </div>

        <form
          className="w-full max-w-sm"
          onSubmit={async (ev) => {
            ev.preventDefault()
            try {
              await promisifyDispatch(signIn(username))
              setErrorMessages(null)
              setSubmitting(true)
            } catch (errors) {
              setErrorMessages(errors)
              setSubmitting(false)
            }
          }}
        >

          {errorMessages && errorMessages.length > 0 && <Alert errorMessages={errorMessages} />}

          <div className="space-y-4 text-center">

            <span className="text-base font-bold">Get Wise Owl Access</span>

            <label htmlFor="email-address" className="sr-only">Email address</label>
            <TextInput
              id="email-address"
              name="email"
              type="email"
              autoComplete="off"
              disabled={!!sessionRequest}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
              placeholder="Email address"
              data-lpignore="true"
              aria-autocomplete="none"
              className="shadow"
            />

            <div className="w-full flex flex-col justify-center space-y-4">
              <Button
                type="submit"
                primary
                small
                disabled={!!sessionRequest}
                trailingAddon={[
                  (!sessionRequest && !submitting) && <ArrowRight key="ArrowForward" />,
                  (!!sessionRequest || !!submitting) && <ArrowsLoop key="Loop" className="animate-spin" />,
                ]}
              >
                {sessionRequest ? 'Check your mail!' : 'Sign in'}
              </Button>
              {!!sessionRequest && (
                <Button
                  neutral
                  small
                  onClick={() => {
                    setSubmitting(false)
                    dispatch(signOut())
                  }}
                  className="mr-2"
                >
                  Cancel
                </Button>
              )}
              {!!supportsWebAuthn && (
                <>
                  <div className="flex items-center">
                    <hr className="flex-1 border-t border-gray-400" />
                    <span className="px-3 text-gray-400">or</span>
                    <div className="flex-1 border-t border-gray-400" />
                  </div>
                  <Button
                    small
                    disabled={!!sessionRequest}
                    trailingAddon={[
                      <LockOpen key="LockOpen" />,
                    ]}
                    onClick={() => dispatch(startWebAuthNLogin())}
                  >
                    Sign in with passkey
                  </Button>
                </>
              )}
            </div>
          </div>

        </form>
      </div>
    </div>
  )
}

export default SignInScreen

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import setSessions from '../../actions/sessions/setSessions'
import setSessionsFailed from '../../actions/sessions/setSessionsFailed'
import fetchSessions from '../../actions/sessions/fetchSessions'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchSessions = function* onFetchSessions() {
  yield takeLatest(actionTypes.SESSIONS.FETCH_SESSIONS, function* onfetchSessions({
    meta: {
      page,
      filter,
    },
  }) {
    const accessToken = yield call(awaitAccessToken)

    let response

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.sessions}`,
        params: {
          page,
          ...filter,
          limit: 20,
        },
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchSessions() {
          yield put(fetchSessions(page, filter))
        })
      } else {
        yield put(setSessionsFailed(e.response.data.errors))
      }
    }
    if (response && response.status === 204) {
      yield put(setSessions(null, null))
      return
    }

    if (response && response.data && response.data.data) {
      yield put(setSessions(response.data.data, response.data.meta))
      return
    }

    if (response && response.data && response.data.errors) {
      yield put(setSessionsFailed(response.data.errors))
    }
  })
}

export default onFetchSessions

import { Button } from '@Infowijs-eng/component-library/components'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import actionsPropTypes from '@Infowijs-eng/component-library/prop-types/actionsPropTypes'

function SectionActions({ actions }) {
  return actions && (
    <div className="mt-3 flex flex-row-reverse sm:mt-0 sm:ml-4 space-x-2 space-x-reverse">
      {actions.map(({
        permissions, permissionsWithRegex, key, ...action
      }) => (
        <Visibility key={key} permissions={permissions} permissionsWithRegex={permissionsWithRegex}>
          <Button
            small
            neutral={!action.primary}
            {...action /* eslint-disable-line react/jsx-props-no-spreading */}
          />
        </Visibility>
      ))}
    </div>
  )
}

SectionActions.propTypes = {
  actions: actionsPropTypes,
}

SectionActions.defaultProps = {
  actions: null,
}

export default SectionActions

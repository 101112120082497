import React from 'react'
import ImporterMigrateMatch from './ImporterMigrateMatch'
import ImporterMigratePossibleMatchesSelect from './ImporterMigratePossibleMatchesSelect'
import { ImporterUser, MatchStatus, PossibleMatchUser } from '../../../../modules/importerSwitch/types'
import ImporterMigrateExplicitNoMatch from './ImporterMigrateExplicitNoMatch'
import ImporterMigrateNoMatch from './ImporterMigrateNoMatch'

interface ImporterMigrateUserProps {
  customerId: string
  status: MatchStatus
  user?: ImporterUser
  possibleMatches: PossibleMatchUser[]
  onRemoveUser?: () => void
  onAddUser?: (user: ImporterUser) => void
  onUnmatchedExplicit?: () => void
  onSearch?: () => void
}

export default function ImporterMigrateUser({
  customerId,
  status,
  user = undefined,
  possibleMatches,
  onRemoveUser = undefined,
  onAddUser = undefined,
  onUnmatchedExplicit = undefined,
  onSearch = undefined,
}: ImporterMigrateUserProps) {
  if (user) {
    return (
      <ImporterMigrateMatch
        user={user}
        customerId={customerId}
        onRemoveUser={onRemoveUser}
      />
    )
  }

  if (possibleMatches.length > 0) {
    return (
      <ImporterMigratePossibleMatchesSelect
        customerId={customerId}
        possibleMatches={possibleMatches}
        onSelectUser={(u: ImporterUser) => {
          onAddUser(u)
        }}
        onRemoveUser={onUnmatchedExplicit}
        onSearch={onSearch}
      />
    )
  }

  if (status === MatchStatus.ImplicitUnmatch) {
    return (
      <ImporterMigrateNoMatch
        onSearch={onSearch}
        onRemove={onUnmatchedExplicit}
      />
    )
  }

  return (
    <ImporterMigrateExplicitNoMatch
      onSearch={onSearch}
    />
  )
}

import actionTypes from '../action-types'

const initialState = {
  data: {},
  meta: {
    page: 1,
    limit: 50,
    pages: 1,
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ADMINISTRATORS.SET_ALL:
      return {
        ...state,
        data: action.payload.sort((a, b) => a.lastName.localeCompare(b.lastName)).reduce((acc, administrator) => {
          acc[administrator.id] = administrator
          return acc
        }, {}),
        meta: {
          ...action.meta,
          pages: Math.ceil(((action.meta && action.meta.total) || 0) / ((action.meta && action.meta.limit) || 50)),
        },
      }
    case actionTypes.ADMINISTRATORS.SET:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      }
    default:
      return state
  }
}

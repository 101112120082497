import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { useSelector } from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import UserForm from '../components/User/UserForm'
import getFullName from '../modules/getFullName'
import getCustomerTitle from '../selectors/customers/getCustomerTitle'
import getUser from '../selectors/user/getUser'
import fetchCustomer from '../actions/customers/fetchCustomer'
import updateUser from '../actions/user/updateUser'
import fetchUser from '../actions/user/fetchUser'
import PageHeader from '../components/PageHeader'

function UserEditScreen() {
  const { customerId, userId } = useParams()
  const customerTitle = useSelector((state) => getCustomerTitle(state, { id: customerId }))
  const user = useSelector(getUser)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const promisifyDispatch = usePromisifyDispatch()

  useEffect(() => {
    if (!customerTitle) {
      promisifyDispatch(fetchCustomer(customerId))
        .catch((e) => {
          setErrorMessages(e)
        })
    }
  }, [customerId])

  useEffect(() => {
    setLoading(true)
    promisifyDispatch(fetchUser(userId))
      .catch((e) => (
        setErrorMessages(e)
      ))
      .finally(() => (
        setLoading(false)
      ))
  }, [userId])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[{
          to: '/customers',
          label: 'Customers',
        }, {
          to: `/customers/${customerId}`,
          label: customerTitle,
        }, {
          to: `/customers/${customerId}/users`,
          label: 'Users',
        }, {
          to: `/customers/${customerId}/users/${userId}`,
          label: getFullName(user),
        }]}
      >
        <Heading fontSize={Heading.size.H3}>Edit a User</Heading>
      </PageHeader>

      {!!errorMessages.length && (
        <div className="sticky top-0 z-50">
          <Alert title="You made a booboo" errorMessages={errorMessages} />
        </div>
      )}

      <UserForm
        user={user}
        loading={loading}
        submitting={submitting}
        onError={(e) => setErrorMessages(e)}
        onSubmit={({
          firstName,
          initials,
          lastNamePrefix,
          lastName,
          email,
          phone,
          userRoleId,
          metadata,
          importerLockedUntil,
        }) => {
          setSubmitting(true)
          promisifyDispatch(updateUser({
            id: userId,
            firstName: firstName || null,
            initials: initials || null,
            lastNamePrefix: lastNamePrefix || null,
            lastName,
            email,
            phone: phone || null,
            userRoleId,
            metadata: metadata || null,
            importerLockedUntil: user.importerType !== 'manual' ? importerLockedUntil : undefined,
          }))
            .then(() => {
              navigate(`/customers/${customerId}/users/${userId}`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        }}
      />
    </Layout>
  )
}

export default UserEditScreen

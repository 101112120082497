import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import fetchCustomerProducts from '../../actions/customerProducts/fetchCustomerProducts'
import setCustomerProducts from '../../actions/customerProducts/setCustomerProducts'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchCustomerProducts = function* onFetchCustomerProducts() {
  yield takeLatest(actionTypes.CUSTOMER_PRODUCTS.FETCH_ALL, function* onFetchCustomerProductsSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    let response
    const { payload } = action

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/${payload}/products`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchProducts() {
          yield put(fetchCustomerProducts(payload))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    if (response.status !== 204) {
      yield put(setCustomerProducts(response.data.data))
    }

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchCustomerProducts

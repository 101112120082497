import {
  ArrowRight,
  ArrowsLoop,
  MagnifyingGlass,
} from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import createImporter from '../../actions/importers/createImporter'
import useSomtodayInstituteSearch from '../../modules/useSomtodayInstituteSearch'
import ModalWithSteps from '../ModalWithSteps'
import TextInputWithAutoComplete from '../TextInputWithAutoComplete'

function ImporterEditSomtodayInstitute({ totalSteps, currentStep }) {
  const { customerId } = useParams()
  const promisifyDispatch = usePromisifyDispatch()
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [loading, results, onSearch] = useSomtodayInstituteSearch()
  const [selectedInstitute, setSelectedInstitute] = useState()

  return (
    <ModalWithSteps
      title="Add Importer"
      onClose={() => navigate(`/customers/${customerId}`)}
      totalSteps={totalSteps}
      currentStep={currentStep}
      nextButtonProps={{
        disabled: submitting || !selectedInstitute,
        trailingAddon: submitting ? <ArrowsLoop className="animate-spin" /> : <ArrowRight />,
        onClick: () => {
          setSubmitting(true)
          setErrorMessages(null)
          promisifyDispatch(createImporter({
            customerId,
            type: 'somtoday',
            metadata: { config: { somtodayApi: { organisationId: selectedInstitute.key } } },
          }))
            .then((data) => {
              setSubmitting(false)
              navigate(`/customers/${customerId}/importers/create/somtoday/${data.id}`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        },
      }}
      previousButtonProps={{
        to: `/customers/${customerId}/importers/create`,
      }}
      alert={errorMessages ? {
        title: 'Something went wrong while creating the importer',
        errorMessages,
      } : null}
    >
      <div className="h-full flex flex-col justify-between">
        <div className="font-bold pb-2">Choose a school</div>
        <TextInputWithAutoComplete
          name="search institute"
          placeholder="Search for school name or BRIN"
          value={selectedInstitute ? selectedInstitute.label : undefined}
          disabled={!!selectedInstitute}
          onChange={(e) => !selectedInstitute && onSearch(e.target.value)}
          autoCompleteResults={(!loading && results.length > 0) ? results : null}
          onAutoCompleteSelect={(uuid) => {
            setSelectedInstitute(results.find((result) => result.key === uuid))
          }}
          onReset={() => setSelectedInstitute(undefined)}
          trailingAddon={<MagnifyingGlass className="text-[1.25rem] px-1" />}
        />
      </div>
    </ModalWithSteps>
  )
}

ImporterEditSomtodayInstitute.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditSomtodayInstitute

import { createSelector } from 'reselect'
import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import getUserRolesData from './getUserRoles'

export default createSelector(
  getUserRolesData,
  (userRoles) => (userRoles || []).map((userRole) => (
    { key: userRole.id, label: getLocalisedLabelString(userRole.labelI18n, 1) }
  )),
)

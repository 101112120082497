import {
  Badge,
} from '@Infowijs-eng/component-library/components'
import {
  cn,
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  Link,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import fetchUserGroupBadges from '../actions/userGroups/fetchUserGroupBadges'
import fetchUserGroups from '../actions/userGroups/fetchUserGroups'
import UserGroups from '../components/UserGroup/UserGroups'
import createSearchParams from '../modules/createSearchParams'
import getUserGroupBadges from '../selectors/userGroups/getUserGroupBadges'
import getUserGroupsTree from '../selectors/userGroups/getUserGroupsTree'
import CustomerProductContextScreen from './CustomerProductContextScreen'

function CustomerProductUserGroupsScreen() {
  const { customerProductId } = useParams()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)

  const userGroupBadgeIds = (searchParams.get('userGroupBadgeIds') || '').split(',').filter(Boolean)
  const userGroupBadges = useSelector((state) => getUserGroupBadges(state, { customerProductId }))
  const userGroups = useSelector((state) => getUserGroupsTree(state, { customerProductId }))

  const [loadingUserGroupBadgeIds, setLoadingUserGroupBadgeIds] = useState([])

  useEffect(() => {
    dispatch(fetchUserGroupBadges(customerProductId))
  }, [])

  useEffect(() => {
    userGroupBadgeIds.forEach((badgeId) => {
      if (loadingUserGroupBadgeIds.indexOf(badgeId) === -1) {
        setLoadingUserGroupBadgeIds([...loadingUserGroupBadgeIds, badgeId])
        dispatch(fetchUserGroups(customerProductId, badgeId))
      }
    })
  }, [searchParams])

  return (
    <CustomerProductContextScreen
      onLoad={(state) => setLoading(state)}
    >
      <div className="pt-4 space-y-3">
        {loading && (
        <div className="text-sm text-gray-400">Loading User Groups...</div>
        )}
        {(!loading && userGroupBadges.length === 0) && (
        <div className="text-sm text-gray-400">No User Groups found for this Customer Product</div>
        )}
        {!loading
            && userGroupBadges
            && userGroupBadges.length > 0
            && userGroupBadges.sort((a, b) => a.order - b.order).map((badge, index) => {
              const isSelected = userGroupBadgeIds.indexOf(badge.id) > -1

              // eslint-disable-next-line react/jsx-props-no-spreading
              let search = createSearchParams(
                new URLSearchParams(searchParams),
                'userGroupBadgeIds',
                !isSelected && badge.id,
                isSelected && new Set([badge.id]),
              )

              if (isSelected && userGroups && userGroups[badge.id]) {
                let childrenIds = new Set()

                userGroups[badge.id].forEach((userGroup) => {
                  childrenIds = new Set([...childrenIds, ...userGroup.childrenIds])
                })

                search = createSearchParams(
                  new URLSearchParams(search),
                  'userGroupIds',
                  null,
                  childrenIds,
                )
              }

              return (
                <div key={badge.id} className="space-y-1">
                  <div
                    className={cn(
                      'hover:bg-gray-50 p-1.5 pr-2.5 rounded flex flex-initial items-center',
                      isSelected && 'bg-gray-50',
                    )}
                  >
                    <Link
                      to={{
                        search: search.toString(),
                      }}
                      className="w-full flex"
                    >
                      <Badge
                        className={cn(
                          'text-sm p-2',
                          index === 0 && 'bg-green-500',
                          index === 1 && 'bg-orange-500',
                          index === 2 && 'bg-aqua-500',
                        )}
                      >
                        {getLocalisedLabelString(badge.labelI18n)}
                      </Badge>
                    </Link>
                    <div className="text-right">
                      <Badge>
                        {`${badge.activeUserCount} users`}
                      </Badge>
                    </div>
                  </div>
                  {isSelected && userGroups && userGroups[badge.id] && (
                    <UserGroups userGroups={userGroups[badge.id]} />
                  )}
                </div>
              )
            })}
      </div>
    </CustomerProductContextScreen>
  )
}

export default CustomerProductUserGroupsScreen

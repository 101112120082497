import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { PlusCircle } from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { getUserId } from '@Infowijs-eng/component-library/modules/authentication'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import fetchAdministrators from '../actions/administrators/fetchAdministrators'
import AdministratorListItem from '../components/Administrator/AdministratorListItem'
import AdministratorListSkeleton from '../components/Administrator/AdministratorListSkeleton'
import Layout from '../components/Layout'
import List from '../components/List'
import ListSectionHeader from '../components/ListSectionHeader'
import PageHeader from '../components/PageHeader'
import getAdministratorsData from '../selectors/administrators/getAdministratorsData'

export default function AdministratorsScreen() {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const promisifyDispatch = usePromisifyDispatch()

  const administrators = useSelector(getAdministratorsData)
  const userId = useSelector(getUserId)

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)

    promisifyDispatch(fetchAdministrators(1))
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setErrorMessages(e)
      })
  }, [])

  return (
    <Layout>
      <PageHeader
        loading={loading}
        actions={[{
          primary: true,
          children: 'Invite Administrator',
          leadingAddon: <PlusCircle />,
          to: '/administrators/invite',
          permissions: ['administrators.invite'],
          key: 'invite',
        }]}
      >
        <Heading fontSize={Heading.size.H3}>Administrators</Heading>
      </PageHeader>
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List loading={loading}>
        {loading && (!administrators || !Object.values(administrators).length) && <AdministratorListSkeleton />}
        <ListSectionHeader>This is you</ListSectionHeader>
        {!!administrators[userId] && (
          <AdministratorListItem
            administrator={administrators[userId]}
          />
        )}
        <ListSectionHeader>Your colleagues</ListSectionHeader>
        {!!administrators && Object.values(administrators)
          .map((administrator) => administrator.id !== userId && (
          <AdministratorListItem
            key={administrator.id}
            administrator={administrator}
          />
          ))}
      </List>
    </Layout>
  )
}

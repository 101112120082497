import { createSelector } from 'reselect'
import getAdministratorsData from './getAdministratorsData'
import getUser from '../user/getUser'

export default function makeGetAdministratorByUserImporterSourceId() {
  return createSelector(
    getUser,
    getAdministratorsData,
    (user, administrators) => {
      if (
        !user
        || !administrators
        || administrators.length === 0
        || user.importerType !== 'manual'
        || user.importerSourceId.indexOf('admin:') === -1
      ) {
        return null
      }

      const administratorSourceId = user.importerSourceId.split(':')[1]

      return Object.values(administrators).find((administrator) => administrator.id === administratorSourceId)
    },
  )
}

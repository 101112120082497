import {
  TextInput,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowCounterClockwise,
  CheckCircle,
  ExclamationTriangle,
  Pencil,
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useState,
} from 'react'
import MetadataEditorButton from '../MetadataEditor/MetadataEditorButton.tsx'

// eslint-disable-next-line max-len
const confirmMessage = 'The name of this location has already been customized, changing it might have an impact on deployed customer products. \n\nAre you sure you want to continue?'

function ImporterLocationEntry({
  id,
  name,
  defaultName,
  showDefaultName,
  brins,
  selected,
  onToggle,
  onSubmit,
  onPreventSubmit,
  keyAlreadyExists,
  nameAlreadyExists,
}) {
  const [dirty, setDirty] = useState(false)
  const [newName, setNewName] = useState('')

  useEffect(() => {
    onPreventSubmit(dirty)
  }, [dirty])

  let warning = null
  if (!!keyAlreadyExists && !nameAlreadyExists) {
    warning = 'The name of this location has already been customized before by possibly another importer configuration'
  }
  if (!!nameAlreadyExists && !keyAlreadyExists) {
    warning = 'There\'s already a location with this name'
  }

  return (
    <div className="h-12 flex items-center">
      <WithAddon
        className="flex justify-between w-full pl-0"
        wrapperClassName="space-x-0"
        leadingAddon={warning && (
          <div
            title={warning}
            className="p-2 box-border bg-orange-50 rounded-full flex justify-center items-center text-white"
          >
            <ExclamationTriangle className="text-orange-500" />
          </div>
        )}
        trailingAddon={(
          <button
            type="button"
            disabled={dirty}
            onClick={() => {
              setNewName('')
              onToggle({ id, name })
            }}
            className={cn(
              'px-1 text-gray-700',
              dirty && 'pointer-events-none opacity-50',
            )}
          >
            {!selected && <PlusCircle />}
            {!!selected && <CheckCircle className="text-green-500" />}
          </button>
        )}
      >
        {!dirty && (
          <div className="flex items-center">
            <MetadataEditorButton
              disabled={!selected}
              onClick={() => {
                setDirty(true)
                setNewName(name !== defaultName ? name : '')
              }}
              className="mx-1"
            >
              {name}
              {selected && (<Pencil className="w-4 h-4 ml-2" />)}
            </MetadataEditorButton>
            {showDefaultName && (
              <div
                className="text-xs font-medium text-gray-400"
                title="This is the original name we received from the importer"
              >
                {`(Original: ${defaultName})`}
              </div>
            )}
          </div>
        )}
        {dirty && (
          <div className="w-full">
            <TextInput
              autoFocus
              value={newName}
              placeholder="Write new location name (press esc to cancel, enter to submit)"
              name="importer location name"
              className="bg-transparent rounded-none focus:ring-0 focus:border-0 outline-none px-3 py-2 text-sm"
              onChange={(e) => setNewName(e.target.value)}
              trailingAddon={
                showDefaultName && (
                  <span className="pointer-events-auto">
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <button
                      type="button"
                      title="Restore location name to original location name"
                      onClick={() => {
                        // eslint-disable-next-line no-alert
                        if (!keyAlreadyExists || (keyAlreadyExists && window.confirm(confirmMessage))) {
                          setDirty(false)
                          setNewName('')
                          onSubmit({ id, name: defaultName })
                        }
                      }}
                      className="px-0 mx-1 text-gray-700"
                    >
                      <ArrowCounterClockwise />
                    </button>
                  </span>
                )
              }
              onKeyUp={(e) => {
                if (e.keyCode === 27) {
                  setNewName('')
                  setDirty(false)
                }
                if (e.keyCode === 13) {
                  setNewName('')
                  setDirty(false)
                  if (!newName.length) {
                    onSubmit({ id, name: defaultName })
                  }
                  if (!!newName.length
                    && (!keyAlreadyExists
                      // eslint-disable-next-line no-alert
                      || (keyAlreadyExists && window.confirm(confirmMessage)))
                  ) {
                    onSubmit({ id, name: newName })
                  }
                }
              }}
            />
          </div>
        )}
        {brins && Array.isArray(brins) && <span className="text-xs text-gray-400">{brins.join(', ')}</span>}
      </WithAddon>
    </div>
  )
}

ImporterLocationEntry.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultName: PropTypes.string,
  showDefaultName: PropTypes.bool,
  brins: PropTypes.arrayOf(PropTypes.string),
  selected: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onPreventSubmit: PropTypes.func,
  keyAlreadyExists: PropTypes.bool,
  nameAlreadyExists: PropTypes.bool,
}

ImporterLocationEntry.defaultProps = {
  defaultName: null,
  showDefaultName: false,
  brins: null,
  selected: false,
  onPreventSubmit: null,
  keyAlreadyExists: null,
  nameAlreadyExists: null,
}

export default ImporterLocationEntry

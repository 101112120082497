import PropTypes from 'prop-types'

import {
  Link,
  useNavigate,
  useParams,
} from 'react-router-dom'
import ModalWithSteps from '../ModalWithSteps'
import MagisterLogo from './MagisterLogo'
import SomtodayLogo from './SomtodayLogo'

function ImporterEditType({ totalSteps, currentStep }) {
  const { customerId } = useParams()
  const navigate = useNavigate()

  return (
    <ModalWithSteps
      title="Add Importer"
      onClose={() => navigate(`/customers/${customerId}`)}
      totalSteps={totalSteps}
      currentStep={currentStep}
    >
      <div className="font-bold">Choose importer type</div>
      <div className="flex flex-col md:flex-row gap-3">
        <Link
          to={`/customers/${customerId}/importers/create/somtoday`}
          className="border-[1px] border-gray-100 rounded-lg w-full h-20 px-4 flex justify-center items-center"
        >
          <SomtodayLogo />
        </Link>
        <Link
          to={`/customers/${customerId}/importers/create/magister`}
          className="border-[1px] border-gray-100 rounded-lg w-full h-20 px-4 flex justify-center items-center"
        >
          <MagisterLogo />
        </Link>
      </div>
    </ModalWithSteps>
  )
}

ImporterEditType.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditType

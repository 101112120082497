import PropTypes from 'prop-types'
import MetadataEditorEntries from './MetadataEditorEntries'

function MetadataEditor({
  metadata,
  structure,
  onChange,
}) {
  return (
    <MetadataEditorEntries
      structure={structure}
      metadata={metadata}
      firstLevel
      onChange={onChange}
    />
  )
}

MetadataEditor.propTypes = {
  metadata: PropTypes.shape().isRequired,
  structure: PropTypes.shape().isRequired,
  onChange: PropTypes.func,
}

MetadataEditor.defaultProps = {
  onChange: null,
}

export default MetadataEditor

import { DropdownItem } from '@Infowijs-eng/component-library/components'
import { XMark } from '@Infowijs-eng/component-library/icons'
import React, { useMemo } from 'react'
import MetadataEditorButton from '../MetadataEditorButton'
import MetadataEditorDropdown from '../MetadataEditorDropdown'

export interface SelectFieldOption {
  value: string
  label: string
}

type SelectFieldProps = {
  value: string
  options?: SelectFieldOption[]
  onChange?: (change: string) => void
  required?: boolean
  readOnly?: boolean
  defaultValue?: string
}

function SelectField({
  value: currentValue,
  options = [],
  onChange = null,
  readOnly = false,
  required = false,
  defaultValue = null,
}: SelectFieldProps) {
  const selectedOption = useMemo(
    () => (
      options.find(({ value }) => (currentValue ? value === currentValue : value === defaultValue))
    ),
    [currentValue, options, defaultValue],
  )

  if (!onChange) {
    return (
      <MetadataEditorButton disabled>
        {selectedOption && selectedOption.label}
      </MetadataEditorButton>
    )
  }
  return (
    <div className="flex flex-1 justify-between">
      <MetadataEditorDropdown label={(selectedOption && selectedOption.label) || 'Select an option'}>
        {options.map(({ value, label }) => (
          <DropdownItem
            key={value}
            onClick={() => onChange && onChange(value)}
            className={value === currentValue ? 'font-extrabold' : undefined}
          >
            {label}
          </DropdownItem>
        ))}
      </MetadataEditorDropdown>
      {!!onChange && !required && !readOnly && (
        <MetadataEditorButton
          className="group-hover:block hidden"
          onClick={() => onChange(null)}
        >
          <XMark />
        </MetadataEditorButton>
      )}
    </div>
  )
}

export default SelectField

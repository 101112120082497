import { cn } from '@Infowijs-eng/component-library/modules'

import PropTypes from 'prop-types'

function DetailCardFieldGroup({
  children,
  className,
}) {
  return (children) && (
  <div className={cn('grid grid-cols-2 gap-x-4 sm:grid-cols-3 break-words pb-2 last:pb-0', className)}>
    {children}
  </div>
  )
}

DetailCardFieldGroup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  className: cn.propType,
}

DetailCardFieldGroup.defaultProps = {
  children: null,
  className: null,
}

export default DetailCardFieldGroup

import React, { useEffect, useState } from 'react'
import { Clickable, Dropdown, DropdownItem } from '@Infowijs-eng/component-library/components'
import {
  Check, ExclamationTriangle, MagnifyingGlass, XMark,
} from '@Infowijs-eng/component-library/icons'
import { cn, getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import { ImporterUser, PossibleMatchUser } from '../../../../modules/importerSwitch/types'
import getFullName from '../../../../modules/getFullName'

interface ImporterMigratePossibleMatchesSelectProps {
  customerId: string
  possibleMatches: PossibleMatchUser[]
  onSelectUser: (user: ImporterUser) => void
  onRemoveUser: (user: ImporterUser) => void
  onSearch: () => void
}

export default function ImporterMigratePossibleMatchesSelect({
  customerId,
  possibleMatches,
  onSelectUser,
  onRemoveUser,
  onSearch,
}: ImporterMigratePossibleMatchesSelectProps) {
  const [selectedUser, setSelectedUser] = useState<ImporterUser>(possibleMatches[0].user)

  useEffect(() => {
    if (!possibleMatches.some((match) => match.user.id === selectedUser.id)) {
      setSelectedUser(possibleMatches[0].user)
    }
  }, [possibleMatches, selectedUser])

  const onSelectMatch = (match: ImporterUser) => {
    setSelectedUser(match)
  }

  const onConfirm = () => {
    onSelectUser(selectedUser)
  }

  const onRemove = () => {
    onRemoveUser(selectedUser)
  }

  return (
    <div
      className={cn(
        'flex flex-row h-full w-full border border-orange-300 bg-orange-50',
        'p-4 gap-2 rounded-lg items-center justify-between group',
      )}
    >
      <div className="flex flex-row gap-2 items-center">
        <Clickable
          className="invisible group-hover:visible"
          onClick={onRemove}
        >
          <XMark />
        </Clickable>
        <div className="text-xs">{getLocalisedLabelString(selectedUser.userRole.labelI18n, 1)}</div>
        <div className="flex flex-row gap-2 items-center">
          <div className="flex flex-col gap-1 ml-2">
            <div className="font-medium">{selectedUser.email}</div>
            <a
              className="hover:underline text-sm"
              href={`/customers/${customerId}/users/${selectedUser.id}`}
              target="_blank"
              rel="noreferrer"
            >
              {getFullName(selectedUser)}
            </a>
          </div>
          {selectedUser && !selectedUser.email && (
            <div className="flex flex-row items-center">
              <ExclamationTriangle className="peer fill-orange-500" />
              <div className="hidden peer-hover:block bg-gray-600 p-2 rounded-lg text-white whitespace-nowrap text-xs">
                No Email
              </div>
            </div>
          )}
        </div>
        {possibleMatches.length > 1
          && (
            <Dropdown buttonProps={{
              width: 32,
              height: 32,
              small: true,
              neutral: true,
            }}
            >
              {possibleMatches
                .map(((possibleMatch) => (
                  <DropdownItem
                    key={possibleMatch.user.id}
                    onClick={() => {
                      onSelectMatch(possibleMatch.user)
                    }}
                  >
                    <div className="flex flex-col items-start">
                      <div>{getLocalisedLabelString(possibleMatch.user.userRole.labelI18n, 1)}</div>
                      <div className="font-medium">{possibleMatch.user?.email}</div>
                      <div>{getFullName(possibleMatch.user)}</div>
                    </div>
                  </DropdownItem>
                )))}
            </Dropdown>
          )}
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Clickable
          className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 h-fit"
          onClick={onSearch}
        >
          <MagnifyingGlass />
        </Clickable>
        <Clickable
          className="p-2 rounded-full bg-orange-100 hover:bg-orange-300"
          onClick={onConfirm}
        >
          <Check className="fill-orange-700" />
        </Clickable>
      </div>
    </div>
  )
}

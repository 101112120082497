import React, { useMemo } from 'react'
import getLanguage, { defaultLanguage, languages } from '../../../modules/getLanguage'
import SelectField, { SelectFieldOption } from './SelectField'

type LocaleStringFieldProps = {
  value: string
  onChange?: (change: string) => void
  required?: boolean
  readOnly?: boolean
}

function LocaleStringField({
  value,
  onChange = null,
  required = false,
  readOnly = false,
}:LocaleStringFieldProps) {
  const options = useMemo(() => Object.keys(languages).map((currentValue) => ({
    value: currentValue,
    label: getLanguage(currentValue),
  })) as SelectFieldOption[], [languages])

  return (
    <SelectField
      value={value}
      options={options}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
      defaultValue={defaultLanguage}
    />
  )
}

export default LocaleStringField

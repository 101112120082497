import PropTypes from 'prop-types'
import React from 'react'

function ListSectionHeader({ children }) {
  return (
    <div className="uppercase text-sm font-medium text-gray-400 py-4">{children}</div>
  )
}

ListSectionHeader.propTypes = {
  children: PropTypes.node.isRequired,
}

export default ListSectionHeader

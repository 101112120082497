import actionTypes from '../action-types'

export default (state = [], action = {}) => {
  switch (action.type) {
    case actionTypes.ADMINISTRATOR_PERMISSIONS.SET:
      return [
        ...action.payload,
      ]
    default:
      return state
  }
}

import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import { createSelector } from 'reselect'
import getUserRoles from './getUserRoles'

export default function makeGetFilteredUserRolesForAutoComplete() {
  return createSelector(
    getUserRoles,
    (_, props) => props.userRoleSearchValue,
    (userRoles, userRoleSearchValue) => (userRoles || [])
      .reduce((acc, curr) => {
        if (userRoleSearchValue.length < 1) {
          return userRoles.map((role) => ({
            key: role.id,
            label: getLocalisedLabelString(role.labelI18n, 1),
          }))
        }

        const label = getLocalisedLabelString(curr.labelI18n, 1)
        if (label.toLowerCase()
          .indexOf(userRoleSearchValue) > -1) {
          acc.push({
            key: curr.id,
            label,
          })
        }
        return acc
      }, []),
  )
}

import { parse } from 'qs'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'

export default function usePagination(meta) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { page: rawPage } = parse(searchParams.toString(), { ignoreQueryPrefix: true })
  const page = Number(rawPage)

  const {
    pages,
    total,
  } = meta

  useEffect(() => {
    if ((page || 1) > pages) {
      if (searchParams.has('page')) {
        searchParams.delete('page')
        setSearchParams(searchParams)
      }
    }
  }, [pages, page])

  return [page, pages, total]
}

import { getHoyEndpoint, isDevelopment } from '@Infowijs-eng/component-library/modules'
import isStaging from '@Infowijs-eng/component-library/modules/isStaging'

export default function getCustomerProductPreviewLink(productType: 'hoy' | 'schoolwiki' | 'brugger', slug: string) {
  switch (productType) {
    case 'schoolwiki':
      return `https://${slug}.schoolwiki.nl`
    case 'brugger': {
      if (isDevelopment && process.env.INFOWIJS_CLIENT_BRUGGER) {
        return process.env.INFOWIJS_CLIENT_BRUGGER.toLowerCase().replace('[subdomain]', slug)
      }

      const tld = process.env.INFOWIJS_TLD || (isDevelopment && 'test') || 'nl'
      const clientUriPart = isStaging ? '.staging' : ''

      return `https://${slug}${clientUriPart}.brugger.${tld}`
    }
    default:
      return getHoyEndpoint(slug)
  }
}

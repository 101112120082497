import {
  useEffect,
  useState,
} from 'react'
import { Alert } from '@Infowijs-eng/component-library/components'
import {
  ArrowDownArchive,
  ArrowUpArchive,
} from '@Infowijs-eng/component-library/icons'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import MetadataEditor from '../components/MetadataEditor/MetadataEditor'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import UserHeader from '../components/User/UserHeader'
import fetchCustomerProducts from '../actions/customerProducts/fetchCustomerProducts'
import fetchAdministrator from '../actions/administrators/fetchAdministrator'
import archiveUser from '../actions/user/archiveUser'
import UserTimelineSection from '../components/User/UserTimelineSection'
import getUser from '../selectors/user/getUser'
import fetchUser from '../actions/user/fetchUser'
import makeGetCustomerProductsByCustomerId from '../selectors/customerProducts/makeGetCustomerProductsByCustomerId'
import UserInformationSection from '../components/User/UserInformationSection'
import UserCustomerProductKeyAccessSection from '../components/User/UserCustomerProductKeyAccessSection'
import UserImporterDetails from '../components/User/UserImporterDetails'

const getCustomerProductsByCustomerId = makeGetCustomerProductsByCustomerId()

function UserDetailScreen() {
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()

  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  const {
    customerId,
    userId,
  } = useParams()

  const user = useSelector(getUser)

  const customerProducts = customerId && useSelector(
    (state) => getCustomerProductsByCustomerId(state, { customerId }),
  )

  const loadUser = () => {
    promisifyDispatch(fetchUser(userId, ['userCustomerProductKey']))
      .then((result) => {
        if (result.importerSourceId.indexOf('admin:') === -1) {
          setLoading(false)
          return
        }

        promisifyDispatch(fetchAdministrator(result.importerSourceId.split(':')[1]))
          .finally(() => {
            setLoading(false)
          })
      })
      .catch((e) => (
        setErrorMessages(e)
      ))
  }

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)

    dispatch(fetchCustomerProducts(customerId))
    loadUser()
  }, [customerId])

  return (
    <Layout>
      <UserHeader
        breadcrumbs={[
          {
            to: '/customers',
            label: 'Customers',
          },
          {
            to: `/customers/${customerId}`,
            label: user && user.customer && user.customer.title,
          },
          {
            to: `/customers/${customerId}/users`,
            label: 'Users',
          },
        ]}
        customerId={customerId}
        user={{
          id: userId,
          ...user,
        }}
        loading={loading}
        actions={user && [
          user.archivedAt && dayjs(user.archivedAt).isBefore(dayjs())
            ? {
              key: 'userUnarchive',
              permissions: ['users.archive'],
              children: 'Unarchive this user',
              leadingAddon: <ArrowUpArchive />,
              onClick: () => {
                dispatch(archiveUser(false, userId))
              },
            } : {
              key: 'userArchive',
              permissions: ['users.archive'],
              children: 'Archive this user',
              leadingAddon: <ArrowDownArchive />,
              onClick: () => {
                dispatch(archiveUser(true, userId))
              },
              className: 'text-red-700 hover:text-red-800 active:text-red-800',
            },
        ]}
      />
      {(errorMessages && errorMessages.length > 0) && <Alert errorMessages={errorMessages} />}
      <div className="pt-4 space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-12">
        <div className="space-y-4 xl:col-span-7">
          <UserInformationSection user={user} loading={loading} />
          {(!user || customerProducts.length > 0) && (
            <UserCustomerProductKeyAccessSection loadUser={loadUser} />
          )}
          <UserImporterDetails user={user} loading={loading} />
          {(!loading
              && !!user
              && !!user.metadata
              && !!Object.keys(user.metadata).length
              && !!user.userRole
              && !!user.userRole.userMetadataStructure) && (
              <section className="xl:col-span-5">
                <SectionTitle>Metadata</SectionTitle>
                <SectionBody>
                  <MetadataEditor
                    metadata={user.metadata}
                    structure={user.userRole.userMetadataStructure}
                  />
                </SectionBody>
              </section>
          )}
        </div>
        <div className="xl:col-span-5">
          <UserTimelineSection />
        </div>
      </div>
    </Layout>
  )
}

export default UserDetailScreen

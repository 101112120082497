import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { Skeleton } from '@Infowijs-eng/component-library/components'

function TimelineEvent({
  Icon, label, date, eventLineOff,
}) {
  return (
    <li>
      <div className="relative pb-8">
        {!eventLineOff
        && <span className="absolute top-8 left-4 -ml-px mt-2 h-4 w-0.5 bg-gray-200" aria-hidden="true" />}
        <div className="relative flex space-x-3">
          <div className="h-8 w-8 rounded-full flex justify-center items-center text-white bg-gray-600">
            {Icon ? <Icon /> : <Skeleton icon />}
          </div>
          <div className="min-w-0 flex-1 flex justify-between items-center space-x-4 max-h-8">
            <div className="flex flex-row items-center">
              {(label && <p className="text-sm text-gray-500">{label}</p>) || <Skeleton />}
            </div>
            <div className="text-right text-sm whitespace-nowrap self-start text-gray-500">
              {date ? (
                <time dateTime={date}>
                  {dayjs(date).format('D MMM YYYY')}
                  <br />
                  {dayjs(date).format('HH:mm')}
                </time>
              ) : (
                <div className="flex flex-col items-end">
                  <Skeleton sm />
                  <Skeleton sm className="mt-0.5 w-12" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  )
}

TimelineEvent.propTypes = {
  Icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  label: PropTypes.string,
  date: PropTypes.string,
  eventLineOff: PropTypes.bool,
}

TimelineEvent.defaultProps = {
  Icon: null,
  label: null,
  date: null,
  eventLineOff: false,
}

export default TimelineEvent

import {
  useEffect,
  useState,
} from 'react'
import { useParams } from 'react-router-dom'
import {
  Button,
  FormCard,
  FormField,
} from '@Infowijs-eng/component-library/components'
import { validateMetadataStructure } from '@Infowijs-eng/component-library/modules'
import {
  ArrowsLoop,
  FloppyDisk,
} from '@Infowijs-eng/component-library/icons'
import PropTypes from 'prop-types'
import SelectItem from '@Infowijs-eng/component-library/components/SelectItem'
import MetadataEditor from '../MetadataEditor/MetadataEditor'
import filterMetadata from '../../modules/filterMetadata'
import getCustomerProductMetadataStructure from '../../modules/getCustomerProductMetadataStructure'
import customerProductDesignStructure from '../../modules/customerProductDesignStructure'
import getCustomerProductTypeLabel, { customerProductTypes } from '../../modules/getCustomerProductTypeLabel'
import { defaultLanguage } from '../../modules/getLanguage'

function CustomerProductForm({
  customerProduct,
  onSubmit,
  onError,
  loading,
  submitting,
}) {
  const { customerProductId } = useParams()
  const [name, setName] = useState('')
  const [title, setTitle] = useState('')
  const [type, setType] = useState('')
  const [metadata, setMetadata] = useState({})
  const [design, setDesign] = useState({})

  useEffect(() => {
    if (!customerProduct) {
      return
    }

    if (customerProduct.name) {
      setName(customerProduct.name)
    }
    if (customerProduct.title) {
      setTitle(customerProduct.title)
    }
    if (customerProduct.type) {
      setType(customerProduct.type)
    }
    if (customerProduct.metadata) {
      setMetadata(customerProduct.metadata)
    }
    if (customerProduct.design) {
      setDesign(customerProduct.design)
    }
  }, [customerProduct])

  return (
    <form
      className="space-y-6"
      onSubmit={(e) => {
        e.preventDefault()

        const errors = []
        if (!name || name.length < 1) {
          errors.push({ title: 'You need to provide an alias' })
        }

        if (!title || title.length < 1) {
          errors.push({ title: 'You need to provide a title' })
        }

        if (!customerProductId && (!type || type.length < 1)) {
          errors.push({ title: 'You need to provide a customer product type' })
        }

        if (type || (customerProduct && customerProduct.type)) {
          errors.push(...validateMetadataStructure(
            (getCustomerProductMetadataStructure(type || (customerProduct && customerProduct.type))),
            metadata,
          ))
        }

        onError(errors)
        if (errors.length === 0) {
          onSubmit({
            id: customerProductId || undefined,
            name: customerProductId ? undefined : name,
            title,
            type: customerProductId ? undefined : type,
            metadata: filterMetadata(
              getCustomerProductMetadataStructure(type || (customerProduct && customerProduct.type)),
              metadata,
            ),
            design: filterMetadata(
              customerProductDesignStructure,
              design,
            ),
          })
        }
      }}
    >
      <FormCard
        label="General Information"
        hint="We need to know all of these things to deploy a new product"
      >
        {!customerProductId && (
          <FormField
            label="Alias"
            name="alias"
            value={name}
            onChange={(event) => {
              setName(event.target.value.replace(/[^a-zA-Z0-9]+/g, '')
                .toLowerCase())
            }}
            disabled={loading}
            inputProps={{
              placeholder: 'e.g. the subdomain',
            }}
          />
        )}
        <FormField
          label="Title"
          name="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          disabled={loading}
          inputProps={{ placeholder: 'e.g. School name' }}
        />
        {!customerProductId && (
          <FormField
            label="Type"
            name="type"
            type="select"
            disabled={loading}
            inputProps={{
              placeholder: getCustomerProductTypeLabel(type) || ' - Select product type - ',
              onChange: (value) => {
                if (value === 'hoy') {
                  setMetadata({
                    ...metadata,
                    language: defaultLanguage,
                  })
                } else {
                  delete metadata.language
                  setMetadata({ ...metadata })
                }
                setType(value)
              },
            }}
          >
            {Object
              .entries(customerProductTypes).map(([value, label]) => (
                <SelectItem
                  key={value}
                  label={label}
                  value={value}
                />
              ))}
          </FormField>
        )}
      </FormCard>

      {(type || (customerProduct && customerProduct.type)) && (
        <>
          <FormCard
            label="Design 🎨"
            hint="Make it look splendid!"
          >
            <div className="col-span-6">
              <MetadataEditor
                structure={customerProductDesignStructure}
                metadata={design}
                onChange={(newDesign) => setDesign(newDesign)}
              />
            </div>
          </FormCard>

          <FormCard
            label="Metadata"
            hint="Fields with * are required"
          >
            {(type || (customerProduct && customerProduct.type)) && (
              <div className="col-span-6">
                <MetadataEditor
                  structure={getCustomerProductMetadataStructure(type || (customerProduct && customerProduct.type))}
                  metadata={metadata}
                  onChange={(newMetadata) => setMetadata(newMetadata)}
                />
              </div>
            )}
          </FormCard>
        </>
      )}

      <div className="flex justify-end space-x-3">
        <Button
          type="submit"
          primary
          disabled={submitting || loading}
          trailingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
        >
          Save this Customer Product
        </Button>
      </div>
    </form>
  )
}

CustomerProductForm.propTypes = {
  customerProduct: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  submitting: PropTypes.bool,
}

CustomerProductForm.defaultProps = {
  customerProduct: null,
  loading: false,
  submitting: false,
}

export default CustomerProductForm

import { currentSchoolyear } from './schoolYearOptions'

export default {
  type: 'object',
  optional: {
    schoolyears: {
      type: 'list',
      labelI18n: {
        other: 'School Years',
        locale: 'en_US',
      },
      items: {
        type: 'string',
        format: 'yearRange',
        defaultValue: currentSchoolyear,
      },
    },
  },
}

import { createSelector } from 'reselect'
import getImportersData from './getImportersData'

export default function makeGetImporterById() {
  return createSelector(
    getImportersData,
    (_, props) => props.id,
    (importers, importerId) => importers && importers[importerId],
  )
}

import actionTypes from '../action-types'

const initialState = {
  isSupported: false,
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.WEB_AUTH_N.SET_SUPPORT:
      return {
        ...state,
        isSupported: action.payload,
      }
    default:
      return state
  }
}

import {
  Badge,
} from '@Infowijs-eng/component-library/components'
import { TwoVerticalLines } from '@Infowijs-eng/component-library/icons'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import { PropTypes } from 'prop-types'
import ImpersonateAsAdminButton from '../ImpersonateAsAdminButton'
import ListItem from '../ListItem'

function CustomerProductListItem({
  to,
  customerProduct,
  customerId,
}) {
  return (
    <ListItem
      className="space-y-1"
      to={to}
      trailingAddon={
        (
          customerProduct.type === 'hoy' || customerProduct.type === 'brugger'
        ) && customerProduct.deployedAt !== null && (
          <Visibility permissions="users.customerProductKey.impersonate">
            <ImpersonateAsAdminButton
              customerId={customerId}
              customerProduct={customerProduct}
            />
          </Visibility>
        )
      }
    >
      <div className="text-black font-bold font-display">
        {customerProduct.title}
      </div>
      <div className="flex space-x-1">
        {!customerProduct.deployedAt && (
          <Badge className="bg-red-500" leadingAddon={<TwoVerticalLines />} />
        )}
        <Badge
          className={[
            customerProduct.type === 'hoy' && 'bg-green-500',
            customerProduct.type === 'schoolwiki' && 'bg-blue-500',
            customerProduct.type === 'brugger' && 'bg-red-500',
          ]}
        >
          {customerProduct.type}
        </Badge>
        {customerProduct.premium && (
          <Badge>
            Premium
          </Badge>
        )}
      </div>
    </ListItem>
  )
}

CustomerProductListItem.propTypes = {
  to: PropTypes.string,
  customerProduct: PropTypes.shape({
    type: PropTypes.string,
    deletedAt: PropTypes.string,
    deployedAt: PropTypes.string,
    title: PropTypes.string,
    premium: PropTypes.bool,
  }),
  customerId: PropTypes.string,
}

CustomerProductListItem.defaultProps = {
  to: null,
  customerProduct: null,
  customerId: null,
}

export default CustomerProductListItem

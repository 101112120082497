export default {
  FETCH_ALL: 'CUSTOMER_PRODUCTS/FETCH_ALL',
  SET_ALL: 'CUSTOMER_PRODUCTS/SET_ALL',
  FETCH: 'CUSTOMER_PRODUCTS/FETCH',
  SET: 'CUSTOMER_PRODUCTS/SET',
  PATCH: 'CUSTOMER_PRODUCTS/PATCH',
  CREATE: 'CUSTOMER_PRODUCTS/CREATE',
  UPDATE: 'CUSTOMER_PRODUCTS/UPDATE',
  DEPLOY: 'CUSTOMER_PRODUCTS/DEPLOY',
}

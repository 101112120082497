import actionTypes from '../../action-types'

export default (fromUserId, toUserId, fromMetadata, toMetadata) => ({
  type: actionTypes.USER.RELATIONS_CREATE,
  payload: {
    fromUserId,
    toUserId,
    fromMetadata,
    toMetadata,
  },
})

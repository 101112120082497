import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

import isMetadataStructureType, {
  STRUCTURE_BOOLEAN,
  STRUCTURE_DATETIME,
  STRUCTURE_ENUM,
  STRUCTURE_INTEGER,
  STRUCTURE_LOCALE_STRING,
  STRUCTURE_YEAR_RANGE_STRING,
} from '../../modules/isMetadataStructureType'
import DateTimeField from './entryfields/DateTimeField.tsx'
import LocaleStringField from './entryfields/LocaleStringField.tsx'
import NumberField from './entryfields/NumberField.tsx'
import SelectField from './entryfields/SelectField.tsx'
import TextInputField from './entryfields/TextInputField.tsx'
import ToggleField from './entryfields/ToggleField.tsx'
import YearRangeField from './entryfields/YearRangeField.tsx'

function MetadataEditorEntryField({
  textInputPlaceholder,
  buttonPlaceholder,
  onChange,
  structure,
  value,
  required,
}) {
  if (isMetadataStructureType(STRUCTURE_LOCALE_STRING, structure)) {
    return (
      <LocaleStringField
        value={value}
        onChange={onChange}
        required={required}
        readOnly={structure && structure.readOnly}
      />
    )
  }

  if (isMetadataStructureType(STRUCTURE_YEAR_RANGE_STRING, structure)) {
    return (
      <YearRangeField
        value={value}
        onChange={onChange}
        required={required}
        readOnly={structure && structure.readOnly}
      />
    )
  }

  if (isMetadataStructureType(STRUCTURE_ENUM, structure)) {
    return (
      <SelectField
        value={value}
        onChange={onChange}
        required={required}
        readOnly={structure && structure.readOnly}
        options={structure.values.map((key) => ({
          value: key,
          label: structure.getValueLabel(key),
        }))}
      />
    )
  }

  if (isMetadataStructureType(STRUCTURE_BOOLEAN, structure)) {
    const label = structure.labelI18n ? getLocalisedLabelString(structure.labelI18n) : structure.label
    return (
      <ToggleField
        label={label}
        onChange={onChange}
        value={value}
        readOnly={structure && structure.readOnly}
      />
    )
  }

  if (isMetadataStructureType(STRUCTURE_INTEGER, structure)) {
    return (
      <NumberField
        value={value}
        textInputPlaceholder={textInputPlaceholder}
        buttonPlaceholder={buttonPlaceholder}
        onChange={onChange}
        required={required}
        readOnly={structure && structure.readOnly}
      />
    )
  }

  if (isMetadataStructureType(STRUCTURE_DATETIME, structure)) {
    return (
      <DateTimeField
        value={value}
        textInputPlaceholder={textInputPlaceholder}
        buttonPlaceholder={buttonPlaceholder}
        onChange={onChange}
        required={required}
        readOnly={structure && structure.readOnly}
      />
    )
  }

  return (
    <TextInputField
      value={value}
      textInputPlaceholder={textInputPlaceholder}
      buttonPlaceholder={buttonPlaceholder}
      onChange={onChange}
      required={required}
      readOnly={structure && structure.readOnly}
    />
  )
}

MetadataEditorEntryField.propTypes = {
  textInputPlaceholder: PropTypes.string,
  buttonPlaceholder: PropTypes.string,
  onChange: PropTypes.func,
  structure: PropTypes.shape({
    labelI18n: PropTypes.shape({
      other: PropTypes.string,
      locale: PropTypes.string,
    }),
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.string),
    getValueLabel: PropTypes.func,
  }),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  required: PropTypes.bool,
}

MetadataEditorEntryField.defaultProps = {
  textInputPlaceholder: 'This field is required',
  buttonPlaceholder: 'Click to add value',
  onChange: null,
  structure: null,
  value: null,
  required: false,
}

export default MetadataEditorEntryField

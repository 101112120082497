import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import fetchCustomerProduct from '../../actions/customerProducts/fetchCustomerProduct'
import setCustomerProduct from '../../actions/customerProducts/setCustomerProduct'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchCustomerProduct = function* onFetchCustomerProduct() {
  yield takeLatest(actionTypes.CUSTOMER_PRODUCTS.FETCH, function* onFetchCustomerProductSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    let response
    const { payload } = action

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/products/${payload}`,
        params: {
          include: 'customer,administrator',
        },
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchCustomerProduct() {
          yield put(fetchCustomerProduct(payload))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    if (response.status !== 204) {
      yield put(setCustomerProduct(response.data.data, response.data.included))
    }

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchCustomerProduct

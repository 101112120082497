import {
  useEffect,
  useState,
} from 'react'
import {
  useSelector,
} from 'react-redux'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Button,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { FloppyDisk } from '@Infowijs-eng/component-library/icons'
import {
  getLocalisedLabelString,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import List from '../List'
import ListItem from '../ListItem'
import Modal from '../Modal'
import getUserGroupBadges from '../../selectors/userGroups/getUserGroupBadges'
import fetchUserGroupBadges from '../../actions/userGroups/fetchUserGroupBadges'
import fetchUserGroups from '../../actions/userGroups/fetchUserGroups'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'
import UserGroups from '../UserGroupRelation/UserGroups'
import UserGroupBadges from '../UserGroupRelation/UserGroupBadges'
import getUserGroups from '../../selectors/userGroups/getUserGroups'
import createUserGroupRelation from '../../actions/user/createUserGroupRelation'
import Breadcrumbs from '../Breadcrumbs'
import createUserGroupPath from '../../modules/createUserGroupPath'
import fetchUserGroupRelations from '../../actions/user/fetchUserGroupRelations'

function UserGroupRelationsCreateModal() {
  const promisifyDispatch = usePromisifyDispatch()
  const navigate = useNavigate()

  const { customerId, userId } = useParams()

  const [loading, setLoading] = useState(false)
  const [selectedUserGroupBadgeId, setSelectedUserGroupBadgeId] = useState(null)
  const [selectedUserGroupParentId, setSelectedUserGroupParentId] = useState(null)
  const [selectedUserGroupId, setSelectedUserGroupId] = useState(null)
  const [bidirectional, setBidirectional] = useState(false)

  const userGroupBadges = useSelector((state) => getUserGroupBadges(state, { customerId }))
  const userGroups = useSelector((state) => getUserGroups(state, { customerId }))

  let path = []
  if (selectedUserGroupParentId) {
    path = createUserGroupPath(selectedUserGroupParentId, userGroups, userGroupBadges)
  }

  const breadcrumbs = [
    selectedUserGroupBadgeId && {
      label: 'All',
      onClick: !selectedUserGroupId ? () => {
        setSelectedUserGroupBadgeId(null)
        setSelectedUserGroupParentId(null)
      } : null,
    },
    ...path.map((step) => ({
      onClick: !selectedUserGroupId ? () => {
        const isBadge = userGroupBadges.find((badge) => badge.id === step.id)
        if (isBadge) {
          setSelectedUserGroupBadgeId(step.id)
          setSelectedUserGroupParentId(null)
          return
        }

        setSelectedUserGroupParentId(step.id)
      } : null,
      label: getLocalisedLabelString(step.labelI18n),
    })),
  ]

  let title = 'All'
  if (selectedUserGroupBadgeId && !selectedUserGroupParentId) {
    title = getLocalisedLabelString(userGroupBadges.find((badge) => badge.id === selectedUserGroupBadgeId).labelI18n)
  }
  if (selectedUserGroupParentId) {
    title = getLocalisedLabelString(userGroups[selectedUserGroupParentId].labelI18n)
  }

  useEffect(() => {
    setLoading(true)
    promisifyDispatch(fetchUserGroupBadges(customerId))
      .then(() => setLoading(false))
  }, [])

  useEffect(() => {
    if (userGroupBadges && userGroupBadges.length > 0) {
      userGroupBadges.forEach((badge) => promisifyDispatch(fetchUserGroups(customerId, badge.id)))
    }
  }, [userGroupBadges])

  return (
    <Modal
      onClose={() => (
        navigate(`/customers/${customerId}/users/${userId}/user-groups`)
      )}
    >
      <Heading fontSize={Heading.size.H3}>
        Add relation between user and user group
      </Heading>
      <Breadcrumbs items={breadcrumbs} />
      <div className="pt-4 flex-grow md:(overflow-hidden)">
        <div className="md:(overflow-y-auto max-h-full)">
          <SectionTitle>{title}</SectionTitle>
          <SectionBody>
            <List>
              {!!loading && <ListItem className="italic">loading...</ListItem>}
              {!selectedUserGroupBadgeId && (
                <UserGroupBadges
                  onClick={(badge) => {
                    setSelectedUserGroupBadgeId(badge.id)
                  }}
                />
              )}
              {selectedUserGroupBadgeId && (
                <UserGroups
                  badgeId={selectedUserGroupBadgeId}
                  parentId={selectedUserGroupParentId}
                  setSelectedUserGroupId={setSelectedUserGroupId}
                  selectedUserGroupId={selectedUserGroupId}
                  setBidirectional={setBidirectional}
                  bidirectional={bidirectional}
                  onClick={(group) => {
                    setSelectedUserGroupParentId(group.id)
                  }}
                />
              )}
            </List>
          </SectionBody>
        </div>
      </div>
      <div className="pt-4 flex justify-end items-center">
        <Button
          primary
          disabled={!selectedUserGroupId}
          leadingAddon={<FloppyDisk />}
          onClick={() => {
            promisifyDispatch(createUserGroupRelation(userId, selectedUserGroupId, bidirectional))
              .then(() => {
                promisifyDispatch(fetchUserGroupRelations(userId))
                  .then(navigate(`/customers/${customerId}/users/${userId}/user-groups`))
              })
          }}
        >
          Save
        </Button>
      </div>
    </Modal>
  )
}

export default UserGroupRelationsCreateModal

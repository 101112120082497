import {
  Avatar,
  Button,
  Skeleton,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import {
  Envelope,
  Person,
} from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'

import ListItem from '../ListItem'

function SkeletonListItem({ className }) {
  return (
    <ListItem
      className={className}
      leadingAddon={<Avatar className={cn('animate-none', className)} />}
      trailingAddon={(
        <div key="actions" className="flex flex-row items-center mt-2 sm:mt-0 sm:justify-start">
          <Button
            small
            neutral
            disabled
            trailingAddon={<Skeleton xs icon className={cn('animate-none', className)} />}
          />
        </div>
    )}
      row
    >
      <div className="flex flex-col flex-grow items-start mt-1 sm:mt-0 sm:w-full sm:justify-center">
        <div
          className="flex flex-col justify-start space-y-1 sm:space-y-0 sm:flex-row sm:justify-between not-last:mb-1"
        >
          <div className="w-full flex flex-wrap justify-start items-center text-black font-bold font-display">
            <Skeleton className="animate-none w-48" />
          </div>
        </div>
        <div
          className={cn(
            'flex flex-col justify-start space-y-1 ',
            'sm:space-x-2 sm:space-y-0 sm:flex-row sm:justify-between not-last:mb-1',
          )}
        >
          <div className="w-full flex flex-wrap justify-start items-center">
            <WithAddon leadingAddon={<Person />}>
              <Skeleton className="animate-none w-12" />
            </WithAddon>
          </div>
          <div className="w-full flex flex-wrap justify-start items-center">
            <WithAddon leadingAddon={<Envelope />}>
              <Skeleton className="animate-none w-12" />
            </WithAddon>
          </div>
        </div>
      </div>
    </ListItem>
  )
}

SkeletonListItem.propTypes = {
  className: cn.propType,
}

SkeletonListItem.defaultProps = {
  className: null,
}

function UserRelationUserListSkeleton({ className }) {
  return (
    <div>
      <SkeletonListItem className={cn('animate-skeleton-1', className)} />
      <SkeletonListItem className={cn('animate-skeleton-2', className)} />
      <SkeletonListItem className={cn('animate-skeleton-3', className)} />
    </div>
  )
}

UserRelationUserListSkeleton.propTypes = {
  className: cn.propType,
}

UserRelationUserListSkeleton.defaultProps = {
  className: null,
}

export default UserRelationUserListSkeleton

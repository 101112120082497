import { createSelector } from 'reselect'
import getUser from './getUser'

export default function makeGetUserRelationByToUserId() {
  return createSelector(
    getUser,
    (_, props) => props.toUserId,
    (user, toUserId) => {
      if (!user || !user.relations || user.relations.length === 0) {
        return null
      }

      const relation = user.relations.find((r) => r && r.user && r.user.id === toUserId)
      if (!relation) {
        return null
      }

      return relation
    },
  )
}

const filters = {
  title: {
    key: 'title',
    label: 'Title',
  },
  fullName: {
    key: 'fullName',
    label: 'Full Name',
  },
  email: {
    key: 'email',
    label: 'Email Address',
  },
  customerId: {
    key: 'customerId',
    label: 'Customer',
  },
  customerProductTypes: {
    key: 'customerProductTypes',
    options: {
      hoy: 'Hoy',
      schoolwiki: 'Schoolwiki',
    },
  },
  suspended: {
    key: 'suspended',
    options: {
      1: 'Only Suspended',
      0: 'Hide Suspended',
    },
  },
  userRoleId: {
    key: 'userRoleId',
    label: 'User Role',
  },
  importerType: {
    key: 'type',
    options: {
      somtoday: 'Somtoday',
      magister: 'Magister',
    },
  },
  importerScheduled: {
    key: 'active',
    options: {
      false: 'Not scheduled',
      true: 'Scheduled',
    },
  },
}

export default filters

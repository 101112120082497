import {
  call,
  takeLatest,
} from 'redux-saga/effects'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onInviteUser = function* onInviteUser() {
  yield takeLatest(actionTypes.USER.ACCESS_INVITE, function* onInviteUserSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const {
      payload: { customerProductId },
      meta: { userId },
    } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/${userId}/customer-product-keys`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          customerProductId,
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data.errors)
      return
    }

    acceptAction(action, response.data.data)
  })
}

export default onInviteUser

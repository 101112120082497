import PropTypes from 'prop-types'
import { cn } from '@Infowijs-eng/component-library/modules'
import SectionBody from './SectionBody'
import SectionTitle from './SectionTitle'

function TimelineSection({ children, className }) {
  return (
    <section
      aria-labelledby="timeline"
      className={className}
    >
      <SectionTitle>
        Timeline
      </SectionTitle>
      <SectionBody className="pt-4">
        <ul className="-mb-8">
          {children}
        </ul>
      </SectionBody>
    </section>
  )
}

TimelineSection.propTypes = {
  children: PropTypes.node.isRequired,
  className: cn.propType,
}

TimelineSection.defaultProps = {
  className: null,
}

export default TimelineSection

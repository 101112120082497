import {
  Badge,
  Button,
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import {
  MagnifyingGlass,
  XMark,
} from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { parse } from 'qs'
import {
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import getActiveFilterLabel from '../modules/getActiveFilterLabel'
import FilterDropdown from './FilterDropdown'
import LimitDropdown from './LimitDropdown'
import SortingDropdown from './SortingDropdown'
import TextInput from './TextInput'
import TextInputWithAutoComplete from './TextInputWithAutoComplete'

function FilterBar({
  disabled,
  filters,
  sort,
  limits,
  textInputClassName,
}) {
  const [searchParams, setSearchParams] = useSearchParams()

  const {
    sort: _,
    ...parsedSearchParams
  } = parse(searchParams.toString(), { ignoreQueryPrefix: true })

  const activeFilterKeys = Object.keys(parsedSearchParams)
  const searchFilters = (filters || []).filter((filter) => filter && !!filter.label && !filter.options)
    .reduce((acc, filter) => {
      acc[filter.key] = filter
      return acc
    }, {})
  const dropdownFilters = (filters || []).filter((filter) => filter && !!filter.options)
  const activeFilters = (filters || []).filter((filter) => filter && activeFilterKeys.includes(filter.key))
  const activeSearchFilters = Object.values(searchFilters)
    .filter((searchFilter) => activeFilterKeys.includes(searchFilter.key))
  const inactiveSearchFilters = Object.values(searchFilters)
    .filter((searchFilter) => !activeFilterKeys.includes(searchFilter.key))

  const [currentSearchOptionKey, setCurrentSearchOptionKey] = useState(
    (inactiveSearchFilters[0] && inactiveSearchFilters[0].key)
    || (activeSearchFilters.length === 1 && activeSearchFilters[0].key),
  )

  const [debounceTimer, setDebounceTimer] = useState(null)

  const inputRef = useRef(null)

  useEffect(() => () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }
  }, [])

  let inputOptions = inactiveSearchFilters && inactiveSearchFilters[0] && (
    <Button small disabled className="whitespace-nowrap">
      {inactiveSearchFilters[0].label}
    </Button>
  )

  if (inactiveSearchFilters.length > 1) {
    inputOptions = (
      <Dropdown
        label={searchFilters[currentSearchOptionKey].label}
        buttonProps={{
          small: true,
        }}
      >
        {inactiveSearchFilters.map((option) => !option.disabled && (
          <DropdownItem
            small
            key={option.key}
            className="whitespace-nowrap"
            onClick={() => {
              setCurrentSearchOptionKey(option.key)
            }}
          >
            {option.label}
          </DropdownItem>
        ))}
      </Dropdown>
    )
  }

  const inputProps = {
    name: 'search',
    autoComplete: 'off',
    small: Object.values(searchFilters).length === 1,
    placeholder: 'Search',
    inputOptions: Object.values(searchFilters).length > 1 && inactiveSearchFilters.length > 0 && inputOptions,
    trailingAddon: <MagnifyingGlass className="text-[1.25rem]" />,
    className: cn('focus:shadow', textInputClassName),
    disabled: Object.keys(searchFilters).length > 1 && inactiveSearchFilters.length === 0,
  }

  const currentFilter = searchFilters && currentSearchOptionKey && searchFilters[currentSearchOptionKey]

  const activeFilterBadges = activeFilters.map((activeFilter) => {
    const {
      key,
      label,
      options,
      value,
      dependencies,
    } = activeFilter

    return (
      <div key={key} className="pt-2">
        <Badge
          className="bg-theme-500 hover:bg-theme-700"
          trailingAddon={<XMark />}
          onClick={() => {
            searchParams.delete(key)

            if (dependencies) {
              dependencies.forEach((dependencyFilter) => {
                searchParams.delete(dependencyFilter)
              })
            }

            setSearchParams(searchParams)

            if (
              inputRef
              && inputRef.current
              && inputRef.current.value
              && inputRef.current.value.length > 0
              && Object.values(searchFilters).length === 1
            ) {
              inputRef.current.value = ''
              return
            }

            setCurrentSearchOptionKey(key)

            const removingFilter = activeFilters.find((filter) => (filter.key === key))
            if (removingFilter && removingFilter.onChange) {
              removingFilter.onChange({ target: { value: '' } })
            }
          }}
        >
          {getActiveFilterLabel({
            label,
            options,
            value: value || parsedSearchParams[key],
          })}
        </Badge>
      </div>
    )
  })

  return (
    <div className={cn('py-4', disabled && 'opacity-40 pointer-events-none')}>
      <div className="flex flex-col md:flex-row md:items-center justify-between space-y-2 md:space-y-0">
        {searchFilters && (
          <div className="md:w-1/2 flex items-center space-x-2">
            {currentFilter && currentFilter.onChange && (
              <TextInputWithAutoComplete
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                onChange={(...args) => {
                  if (!currentFilter.debounce) {
                    currentFilter.onChange(...args)
                    return
                  }

                  if (debounceTimer) {
                    clearTimeout(debounceTimer)
                    setDebounceTimer(null)
                  }

                  setDebounceTimer(setTimeout(() => {
                    currentFilter.onChange(...args)
                  }, currentFilter.debounce || 500))
                }}
                autoCompleteResults={currentFilter.autoCompleteResults}
                onAutoCompleteSelect={(id) => {
                  searchParams.set(currentSearchOptionKey, `eq:${id}`)

                  const nextSearchOption = inactiveSearchFilters.find((filter) => filter.key !== currentSearchOptionKey)

                  if (nextSearchOption) {
                    setCurrentSearchOptionKey(nextSearchOption.key)
                  } else {
                    setCurrentSearchOptionKey(null)
                  }

                  setSearchParams(searchParams)
                }}
              />
            )}
            {!currentFilter && (
              <TextInput
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                disabled
              />
            )}
            {currentFilter && !currentFilter.onChange && (
              <TextInput
                ref={inputRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...inputProps}
                defaultValue={(parsedSearchParams
                  && parsedSearchParams[currentSearchOptionKey]
                  && parsedSearchParams[currentSearchOptionKey].split(':')[1]) || ''}
                onKeyUp={(e) => {
                  const value = e.target.value.trim()

                  if (searchParams.has(currentSearchOptionKey)) {
                    searchParams.delete(currentSearchOptionKey)
                  }

                  if (value.length > 0) {
                    searchParams.set(currentSearchOptionKey, `contains:${value}`)
                  }

                  if ((e.key === 'Enter' || e.keyCode === 13)) {
                    setSearchParams(searchParams)

                    // eslint-disable-next-line max-len
                    const nextSearchOption = inactiveSearchFilters.find((filter) => filter.key !== currentSearchOptionKey)
                    if (nextSearchOption) {
                      setCurrentSearchOptionKey(nextSearchOption.key)
                    } else if (Object.values(searchFilters).length > 1) {
                      setCurrentSearchOptionKey(null)
                    }

                    if (Object.values(searchFilters).length > 1) {
                      e.target.value = ''
                    }
                  }
                }}
              />
            )}
            {dropdownFilters.length > 0 && (
              <FilterDropdown
                options={dropdownFilters}
              />
            )}
          </div>
        )}

        {filters && (
          <div className="flex md:hidden flex-row flex-wrap gap-1">
            {activeFilterBadges}
          </div>
        )}

        {(sort || limits) && (
          <div className="md:w-1/2 flex flex-wrap flex-row-reverse md:ml-auto space-x-2">
            {sort.length > 1 && <SortingDropdown options={sort} />}
            {limits.length > 1 && <LimitDropdown options={limits} />}
          </div>
        )}
      </div>

      {activeFilterBadges.length > 0 && (
        <div className="hidden md:flex flex-row flex-wrap gap-1">
          {activeFilterBadges}
        </div>
      )}
    </div>
  )
}

FilterBar.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.shape(),
    onChange: PropTypes.func,
    onAutoCompleteSelect: PropTypes.func,
    autoCompleteResults: PropTypes.arrayOf(PropTypes.shape()),
    dependencies: PropTypes.arrayOf(PropTypes.string),
  })),
  sort: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    defaultOrder: PropTypes.oneOf(['asc', 'desc']),
  })),
  limits: PropTypes.arrayOf(PropTypes.number),
  textInputClassName: cn.propType,
  disabled: PropTypes.bool,
}

FilterBar.defaultProps = {
  filters: [],
  limits: null,
  sort: [],
  textInputClassName: null,
  disabled: false,
}

export default FilterBar

import usePromisifyDispatch from '@Infowijs-eng/component-library/modules/usePromisifyDispatch'
import {
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  useSelector,
} from 'react-redux'
import { useParams } from 'react-router-dom'
import dayjs from 'dayjs'
import {
  Alert,
  Badge,
  EditableAvatar,
  Heading,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import {
  Pencil,
  TwoVerticalLines,
} from '@Infowijs-eng/component-library/icons'
import { TYPE_CUSTOMER_PRODUCT } from '@Infowijs-eng/component-library/constants/avatar'
import AvatarUploadModal from '@Infowijs-eng/component-library/components/AvatarUploadModal'
import fetchCustomerProduct from '../actions/customerProducts/fetchCustomerProduct'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import getCustomerProduct from '../selectors/customerProducts/getCustomerProduct'

function CustomerProductContextScreen({
  children,
  secondaryActions,
}) {
  const { customerProductId } = useParams()
  const promisifyDispatch = usePromisifyDispatch()
  const [errorMessages, setErrorMessages] = useState(null)

  const customerProduct = useSelector((state) => (
    getCustomerProduct(state, { customerProductId })
  ))

  const [avatarVersion, setAvatarVersion] = useState(0)
  const [isAvatarModalVisible, setAvatarModalVisible] = useState(false)

  useEffect(() => {
    promisifyDispatch(fetchCustomerProduct(customerProductId))
      .catch((e) => (
        setErrorMessages(e)
      ))
  }, [customerProductId])

  return (
    <>
      {isAvatarModalVisible && (
        <AvatarUploadModal
          confirmText="Save Logo"
          title="Upload Logo"
          onClose={() => setAvatarModalVisible(false)}
          onSuccess={() => {
            setAvatarModalVisible(false)
            setAvatarVersion(avatarVersion + 1)
          }}
          id={customerProductId}
          type={TYPE_CUSTOMER_PRODUCT}
        />
      )}
      <Layout>
        <PageHeader
          actions={[
            {
              primary: true,
              children: 'Edit Customer Product',
              leadingAddon: <Pencil />,
              to: `/customers/products/${customerProductId}/edit`,
              permissions: ['customers.customerProducts.edit'],
              key: 'customerProduct.edit',
            },
            ...secondaryActions,
          ]}
          subNavigation={[
            {
              children: 'Overview',
              end: true,
              to: `/customers/products/${customerProductId}`,
              permissions: ['customers.view'],
              key: 'customerProduct.detail',
            },
            {
              children: 'User Groups',
              to: `/customers/products/${customerProductId}/user-groups`,
              permissions: ['customers.view'],
              key: 'customerProduct.userGroups',
            },
          ]}
          breadcrumbs={[{
            to: '/customers',
            label: 'Customers',
          }, {
            to: (customerProduct && customerProduct.customerId && `/customers/${customerProduct.customerId}`) || '',
            label: customerProduct && customerProduct.customerTitle,
          }, {
            to: `/customers/${customerProduct && customerProduct.customerId}`,
            label: 'Overview',
          }]}
        >
          {customerProduct && (
            <div className="flex flex-col space-y-4">
              <div className="flex flex-row items-center">
                <EditableAvatar
                  avatarType={TYPE_CUSTOMER_PRODUCT}
                  avatarId={customerProductId}
                  onClick={() => setAvatarModalVisible(true)}
                  cacheBust={avatarVersion}
                />
                <Heading fontSize={Heading.size.H3}>{customerProduct.title}</Heading>
              </div>
              <div className="flex space-x-2">
                {!(customerProduct
                    && customerProduct.deployedAt
                    && dayjs(customerProduct.deployedAt)
                      .isBefore(dayjs()))
                  && <Badge className="bg-red-500" leadingAddon={<TwoVerticalLines />} />}
                <Badge
                  className={[
                    customerProduct.type === 'hoy' && 'bg-green-500',
                    customerProduct.type === 'schoolwiki' && 'bg-blue-500',
                    customerProduct.type === 'brugger' && 'bg-red-500',
                  ]}
                >
                  {customerProduct.type}
                </Badge>
                {customerProduct.premium && <Badge>Premium</Badge>}
              </div>
            </div>
          )}
          {!customerProduct && <Skeleton xl className="w-48" />}
        </PageHeader>
        {(errorMessages && errorMessages.length > 0) && <Alert errorMessages={errorMessages} />}
        {children}
      </Layout>
    </>
  )
}

CustomerProductContextScreen.propTypes = {
  children: PropTypes.node.isRequired,
  secondaryActions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
    children: PropTypes.string,
    trailingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
    onClick: PropTypes.func,
    to: PropTypes.string,
    neutral: PropTypes.bool,
  })),
}

CustomerProductContextScreen.defaultProps = {
  secondaryActions: [],
}

export default CustomerProductContextScreen

import { Skeleton } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function DetailField({
  loading,
  label,
  hint,
  children,
  className,
  labelClassName,
  title,
}) {
  return (loading || children) && (
    <div className="grid grid-cols-6 border-b last:border-0 pt-4 pb-2">
      <div className="col-span-2 space-y-1.5">
        <div className={cn('font-medium text-gray-500', labelClassName)}>
          {label}
        </div>
        {hint && (
          <div className="text-sm text-gray-300">{hint}</div>
        )}
      </div>
      {loading && <Skeleton sm />}
      {(!loading && children) && (
        <div
          className={cn('text-gray-900 col-span-4', className)}
          title={title}
        >
          {children}
        </div>
      )}
    </div>
  )
}

DetailField.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string.isRequired,
  hint: PropTypes.node,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  labelClassName: cn.propType,
  className: cn.propType,
  title: PropTypes.string,
}

DetailField.defaultProps = {
  loading: false,
  children: null,
  hint: null,
  labelClassName: null,
  className: null,
  title: null,
}

export default DetailField

import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  BackNavigation,
  Breadcrumb,
} from '@Infowijs-eng/component-library/components'

function Breadcrumbs({
  items,
  children,
  className,
}) {
  if (items) {
    const lastItem = [...items].splice(-1, 1)[0]
    const breadcrumbs = [...items]
    breadcrumbs.pop()
    return (
      <div className={className}>
        {breadcrumbs.length > 0 && (
          <div className="flex space-x-1">
            {breadcrumbs.map(({ to, onClick, label }) => (
              <Breadcrumb
                key={`breadcrumb${to}${label}`}
                to={to}
                onClick={onClick}
              >
                {label}
              </Breadcrumb>
            ))}
          </div>
        )}
        {lastItem && (
          <BackNavigation label={lastItem.label} onClick={lastItem.onClick} to={lastItem.to} />
        )}
      </div>
    )
  }

  if (children) {
    return (
      <div className="flex space-x-1">
        {children}
      </div>
    )
  }

  return null
}

Breadcrumbs.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  })),
  className: cn.propType,
}

Breadcrumbs.defaultProps = {
  children: null,
  items: [],
  className: null,
}

export default Breadcrumbs

import {
  useEffect,
  useState,
} from 'react'
import * as PropTypes from 'prop-types'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

function Duration({ since, until, onUpdate }) {
  const [diff, setDiff] = useState(dayjs.duration((until ? dayjs(until) : dayjs()).diff(dayjs(since))))

  useEffect(() => {
    if (until || (!until && Math.round(diff.asSeconds()) < 0)) {
      return () => {
      }
    }

    const interval = setInterval(() => {
      const newDiff = dayjs.duration(dayjs().diff(dayjs(since)))
      if (onUpdate) {
        onUpdate(newDiff)
      }
      setDiff(newDiff)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  if (!since) {
    return null
  }

  const diffFormat = []
  if (diff.seconds() > 1 || Math.round(diff.asSeconds()) === 0) {
    diffFormat.unshift('s [seconds]')
  }
  if (diff.seconds() === 1) {
    diffFormat.unshift('s [second]')
  }
  if (diff.minutes() > 1) {
    diffFormat.unshift('m [minutes]')
  }
  if (diff.minutes() === 1) {
    diffFormat.unshift('m [minute]')
  }
  if (diff.hours() > 1) {
    diffFormat.unshift('H [hours]')
  }
  if (diff.hours() === 1) {
    diffFormat.unshift('H [hour]')
  }
  if (diff.days() > 1) {
    diffFormat.unshift('D [days]')
  }
  if (diff.days() === 1) {
    diffFormat.unshift('D [day]')
  }
  if (diffFormat.length === 0) {
    diffFormat.push('D [days], M [months], Y [years], H [hours], m [minutes], s [seconds]')
  }

  if (Math.ceil(diff.asSeconds()) < 0) {
    return 'Not yet started...'
  }

  return (
    <span
      className={[
        !until && 'animate-pulse',
        diff.asMinutes() > 2 && 'text-orange-600',
        diff.asMinutes() > 5 && 'text-red-600',
      ]}
    >
      {diff.format(diffFormat.join(', '))}
    </span>
  )
}

Duration.propTypes = {
  since: PropTypes.string.isRequired,
  until: PropTypes.string,
  onUpdate: PropTypes.func,
}

Duration.defaultProps = {
  until: null,
  onUpdate: null,
}

export default Duration

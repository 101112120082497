import { cn } from '@Infowijs-eng/component-library/modules'

import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import { useSearchParams } from 'react-router-dom'
import {
  Dropdown,
  DropdownDivider,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { Sliders } from '@Infowijs-eng/component-library/icons'

function FilterDropdown({ options, onAddFilter, onRemoveFilter }) {
  const [searchParams, setSearchParams] = useSearchParams()

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <Dropdown
      alignRight={isMobile}
      icon={<Sliders />}
      buttonProps={{
        small: true,
        neutral: true,
      }}
    >
      {options && options.map(({
        key,
        options: filterOptions,
      }, i) => (
        <div key={key}>
          {i > 0 && <DropdownDivider />}
          <fieldset>
            {filterOptions && Object.entries(filterOptions).map(([value, label]) => (
              <label key={value}>
                <DropdownItem
                  small
                  className="whitespace-nowrap"
                  leadingAddon={(
                    <input
                      name={key}
                      type="checkbox"
                      onChange={() => {
                        const filterIsActive = searchParams.get(key) !== `eq:${value}`
                        if (searchParams.has(key)) {
                          searchParams.delete(key)
                          onRemoveFilter(key)
                        }
                        if (filterIsActive) {
                          searchParams.set(key, `eq:${value}`)
                          onAddFilter(key)
                        }
                        setSearchParams(searchParams)
                      }}
                      checked={searchParams.has(key)
                        && searchParams.get(key).split(':')[1] === value}
                      className={cn(
                        'h-4 w-4 mr-2 rounded-full bg-transparent text-theme-500 border-gray-300',
                        'focus:ring-transparent focus:ring-offset-transparent',
                      )}
                    />
                  )}
                  addonProps={{
                    wrapperClassName: 'space-x-0.5',
                  }}
                >
                  {label}
                </DropdownItem>
              </label>
            ))}
          </fieldset>
        </div>
      ))}
    </Dropdown>
  )
}

FilterDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onAddFilter: PropTypes.func,
  onRemoveFilter: PropTypes.func,
}

FilterDropdown.defaultProps = {
  onAddFilter: () => {
  },
  onRemoveFilter: () => {
  },
}

export default FilterDropdown

import { DropdownItem } from '@Infowijs-eng/component-library/components'
import { XMark } from '@Infowijs-eng/component-library/icons'
import {
  cn,
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import isMetadataStructureType, {
  STRUCTURE_DYNAMIC_OBJECT,
  STRUCTURE_FORMATTED_KEY,
  STRUCTURE_LIST,
  STRUCTURE_OBJECT,
  STRUCTURE_YEAR_RANGE,
} from '../../modules/isMetadataStructureType'
import MetadataEditorButton from './MetadataEditorButton.tsx'
import MetadataEditorDropdown from './MetadataEditorDropdown'
import MetadataEditorEntryField from './MetadataEditorEntryField'

const hasStructureChildren = (structure) => isMetadataStructureType([
  STRUCTURE_OBJECT,
  STRUCTURE_LIST,
  STRUCTURE_YEAR_RANGE,
  STRUCTURE_FORMATTED_KEY,
  STRUCTURE_DYNAMIC_OBJECT,
], structure)

function getMetadataDefaultFieldValue(fieldStructure) {
  let fieldValue = ''
  if (fieldStructure.type === 'object' || fieldStructure.type === 'yearRangeObject') {
    fieldValue = {}
  } else if (fieldStructure.type === 'list') {
    fieldValue = []
  } else if (fieldStructure.type === 'boolean') {
    fieldValue = false
  }

  if ('default' in fieldStructure) {
    fieldValue = fieldStructure.default
  }

  if (hasStructureChildren(fieldStructure)) {
    if (fieldStructure.required) {
      fieldValue = Object.keys(fieldStructure.required).reduce((acc, requiredKey) => {
        acc[requiredKey] = getMetadataDefaultFieldValue(fieldStructure.required[requiredKey])
        return acc
      }, {})
    }
  }
  return fieldValue
}

function MetadataEditorEntry({
  fallbackLabel,
  structure,
  value,
  required,
  onChange,
  onReplacePath,
  editableKey,
  isDirectChildInList,
  onlyOptionalChild,
}) {
  let label = fallbackLabel
  if (structure) {
    if (structure.labelI18n) {
      label = getLocalisedLabelString(
        structure.labelI18n,
        !isMetadataStructureType(STRUCTURE_LIST, structure) ? 1 : null,
      )
    } else if (structure.label) {
      label = structure.label
    }
  }
  return structure && (
    <div
      className={cn(
        'flex flex-row flex-1 border-b last:border-b-0',
        hasStructureChildren(structure) && 'flex-col',
        !hasStructureChildren(structure) && 'group items-center',
      )}
    >
      <div className="group flex-1 flex justify-between">
        {editableKey && (
          <MetadataEditorEntryField
            textInputPlaceholder="This key is required"
            buttonPlaceholder="Click to add field key"
            value={label}
            onChange={onChange && onReplacePath}
            required
          />
        )}
        {!editableKey && (
          <div className={cn('h-10 flex items-center pl-4 text-sm text-gray-500', !!onChange && 'text-gray-700')}>
            {isDirectChildInList && Number(label) >= 0 ? '↳' : label}
            {!!onChange && required && <span className="mx-1 text-red-300">*</span>}
          </div>
        )}
        {!!onChange && hasStructureChildren(structure) && !required && (
          <MetadataEditorButton
            onClick={() => onChange && onChange(null)}
            className="group-hover:block hidden"
          >
            <XMark />
          </MetadataEditorButton>
        )}
      </div>
      <div className="flex-1">
        {hasStructureChildren(structure) && (
          <MetadataEditorEntries
            structure={structure}
            metadata={value}
            onChange={onChange}
          />
        )}
        {!hasStructureChildren(structure) && (
          <MetadataEditorEntryField
            textInputPlaceholder="This value is required"
            buttonPlaceholder="Click to add field value"
            structure={structure}
            value={value}
            onChange={onChange}
            required={required || onlyOptionalChild}
          />
        )}
      </div>
    </div>
  )
}

MetadataEditorEntry.propTypes = {
  fallbackLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
    PropTypes.number,
    PropTypes.shape(),
    PropTypes.arrayOf(PropTypes.shape()),
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.bool),
  ]),
  structure: PropTypes.shape(),
  onChange: PropTypes.func,
  onReplacePath: PropTypes.func,
  required: PropTypes.bool,
  editableKey: PropTypes.bool,
  isDirectChildInList: PropTypes.bool,
  onlyOptionalChild: PropTypes.bool,
}

MetadataEditorEntry.defaultProps = {
  fallbackLabel: null,
  value: null,
  structure: null,
  onChange: null,
  onReplacePath: () => {
  },
  required: false,
  editableKey: false,
  isDirectChildInList: false,
  onlyOptionalChild: false,
}

function MetadataEditorEntries({
  firstLevel,
  metadata,
  structure,
  onChange,
}) {
  if (!hasStructureChildren(structure)) {
    return null
  }

  let keysOnThisLayer = new Set(
    Object.entries(metadata || {}).flatMap(([key, value]) => (value === null ? [] : [key])),
  )

  let possibleKeysOnThisLayer = [
    ...(structure.optional ? Object.keys(structure.optional) : []),
    ...(structure.required ? Object.keys(structure.required) : []),
  ]

  if (structure.required) {
    keysOnThisLayer = new Set([
      ...keysOnThisLayer,
      ...Object.keys(structure.required),
    ])
  }

  if (!structure.required && structure.optional && Object.keys(structure.optional).length === 1) {
    keysOnThisLayer.add(Object.keys(structure.optional)[0])
  }

  if (isMetadataStructureType(STRUCTURE_YEAR_RANGE, structure)) {
    if (keysOnThisLayer.size > 0) {
      keysOnThisLayer.forEach((key) => {
        const yearRange = key.split('-')
        possibleKeysOnThisLayer = [`${Number(yearRange[0]) + 1}-${Number(yearRange[1]) + 1}`]
      })
    } else {
      possibleKeysOnThisLayer = [`${dayjs().year()}-${dayjs().add(1, 'year').year()}`]
    }
  }

  // Strip away the keys we've already rendered
  possibleKeysOnThisLayer = possibleKeysOnThisLayer.filter((key) => !Array.from(keysOnThisLayer).includes(key))

  if (!onChange) {
    possibleKeysOnThisLayer = []
  }

  return (
    <div className={cn(!firstLevel && 'border-t pl-4')}>
      {Array.from(keysOnThisLayer).map((key) => (onChange || (metadata && metadata[key] !== null)) && (
        <MetadataEditorEntry
          key={key}
          fallbackLabel={key}
          structure={
            (isMetadataStructureType(STRUCTURE_LIST, structure) && structure.items)
            || (isMetadataStructureType(STRUCTURE_FORMATTED_KEY, structure) && structure.items)
            || (isMetadataStructureType(STRUCTURE_YEAR_RANGE, structure) && structure.data)
            || (isMetadataStructureType(STRUCTURE_DYNAMIC_OBJECT, structure) && structure.data)
            || (structure.required && structure.required[key])
            || (structure.optional && structure.optional[key])
          }
          // This avoids random keys like "2021" to be shown as List group
          isDirectChildInList={isMetadataStructureType(STRUCTURE_LIST, structure)}
          required={!!(structure.required && structure.required[key])}
          // When there is only 1 optional child, since then you need to remove the parent instead
          onlyOptionalChild={!!(structure.optional && Object.keys(structure.optional).length === 1)}
          value={metadata && metadata[key]}
          onChange={!onChange ? null : (newData) => {
            if (isMetadataStructureType(STRUCTURE_LIST, structure)) {
              const index = Number(key)
              const newArray = [...metadata]
              if (newData === null) {
                // Remove entry
                newArray.splice(index, 1)
              } else {
                newArray[index] = newData
              }
              onChange(newArray)
            } else if (key === '' && newData === null) {
              const newObj = {
                ...metadata,
              }
              delete newObj[key]
              onChange(newObj)
            } else {
              onChange({
                ...metadata,
                [key]: newData,
              })
            }
          }}
          onReplacePath={!onChange ? null : (newKey) => {
            const newMetadata = { ...metadata }
            newMetadata[newKey] = newMetadata[key]
            if (key === '') {
              delete newMetadata[key]
            } else {
              newMetadata[key] = null
            }
            onChange(newMetadata)
          }}
          editableKey={
            isMetadataStructureType(STRUCTURE_YEAR_RANGE, structure)
            || isMetadataStructureType(STRUCTURE_DYNAMIC_OBJECT, structure)
          }
        />
      ))}
      {isMetadataStructureType(STRUCTURE_LIST, structure) && onChange && (
        <MetadataEditorButton
          onClick={() => {
            if (!onChange) {
              return
            }

            let fieldValue = structure.defaultValue || (structure.items && structure.items.defaultValue) || ''
            if (hasStructureChildren(structure.items)) {
              if (structure.items.required) {
                fieldValue = Object.keys(structure.items.required).reduce((acc, requiredKey) => {
                  acc[requiredKey] = hasStructureChildren(structure.items.required[requiredKey]) ? {} : ''
                  return acc
                }, {})
              }
            }

            onChange([...(metadata || []), fieldValue])
          }}
        >
          Add entry
        </MetadataEditorButton>
      )}
      {possibleKeysOnThisLayer.length > 0 && onChange && (
        <MetadataEditorDropdown label="Add field">
          {possibleKeysOnThisLayer.map((key) => {
            let label = null
            let labelI18n = null
            let pluralCount = 1

            let fieldValue = ''
            if ((structure.required && structure.required[key])
              || (structure.optional && structure.optional[key])
            ) {
              const fieldStructure = ((structure.required && structure.required[key])
                || (structure.optional && structure.optional[key]))

              if (fieldStructure.readOnly) {
                return null
              }

              fieldValue = getMetadataDefaultFieldValue(fieldStructure)

              if (fieldStructure.type === 'list') {
                pluralCount = null
              }

              labelI18n = fieldStructure.labelI18n
              label = fieldStructure.label
            }

            return (
              <DropdownItem
                key={key}
                onClick={() => onChange && onChange({
                  ...metadata,
                  [key]: fieldValue,
                })}
                className="select-none"
              >
                {(labelI18n && getLocalisedLabelString(labelI18n, pluralCount)) || label}
                {(!label && !labelI18n) && key}
              </DropdownItem>
            )
          })}
        </MetadataEditorDropdown>
      )}
      {isMetadataStructureType(STRUCTURE_DYNAMIC_OBJECT, structure) && (!metadata || !('' in metadata)) && onChange && (
        <MetadataEditorButton
          type="button"
          onClick={() => onChange && onChange({
            ...metadata,
            '': '',
          })}
          className="space-x-0.5 select-none"
        >
          Add field
        </MetadataEditorButton>
      )}
    </div>
  )
}

MetadataEditorEntries.propTypes = {
  metadata: PropTypes.oneOfType([
    PropTypes.shape(),
    PropTypes.array,
  ]),
  structure: PropTypes.shape().isRequired,
  firstLevel: PropTypes.bool,
  onChange: PropTypes.func,
}

MetadataEditorEntries.defaultProps = {
  metadata: null,
  firstLevel: false,
  onChange: null,
}

export default MetadataEditorEntries

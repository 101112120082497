export default (searchParams, queryParamKey, queryParamValue) => {
  if (searchParams.has(queryParamKey)) {
    searchParams.delete(queryParamKey)
  }

  if (queryParamValue) {
    searchParams.append(queryParamKey, queryParamValue)
  }

  return searchParams
}

import {
  Button,
  FormCard,
  FormField,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLoop,
  FloppyDisk,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  getLocalisedLabelString,
  validateDate,
  validateEmail,
  validateMetadataStructure,
  validateTime,
} from '@Infowijs-eng/component-library/modules'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import PropTypes from 'prop-types'
import {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useParams } from 'react-router-dom'
import SelectItem from '@Infowijs-eng/component-library/components/SelectItem'
import fetchUserRoles from '../../actions/userRoles/fetchUserRoles'
import filterMetadata from '../../modules/filterMetadata'
import getUserRolesData from '../../selectors/userRoles/getUserRoles'
import MetadataEditor from '../MetadataEditor/MetadataEditor'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

function UserForm({
  user,
  onSubmit,
  submitting,
  onError,
  loading,
}) {
  const dispatch = useDispatch()
  const {
    customerId,
    userId,
  } = useParams()
  const [firstName, setFirstName] = useState(null)
  const [initials, setInitials] = useState(null)
  const [lastNamePrefix, setLastNamePrefix] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [userRoleId, setUserRoleId] = useState(null)
  const [availableUserRoles, setAvailableUserRoles] = useState([])
  const [metadata, setMetadata] = useState({})
  const [metadataStructure, setMetadataStructure] = useState(null)
  const [importerLockedUntil, setImporterLockedUntil] = useState(null)
  const [selectedImporterlockOption, setSelectedImporterlockOption] = useState(null)
  const [customImporterLockDate, setCustomImporterLockDate] = useState(null)
  const [customImporterLockTime, setCustomImporterLockTime] = useState(null)
  const [clearImporterLockNextRun, setClearImporterLockNextRun] = useState(false)

  const userRoles = useSelector(getUserRolesData)

  useEffect(() => {
    if (!user) {
      return
    }

    setFirstName(user.firstName)
    setInitials(user.initials)
    setLastNamePrefix(user.lastNamePrefix)
    setLastName(user.lastName)
    setEmail(user.email)
    setPhone(user.phone)
    if (user.userRole) {
      setUserRoleId(user.userRole.id)
    }
    setMetadata(user.metadata)
    setImporterLockedUntil(user.importerLockedUntil)
    if (user.importerLockedUntil && dayjs(user.importerLockedUntil)
      .isAfter(dayjs())) {
      setSelectedImporterlockOption('custom')
      setCustomImporterLockDate(dayjs(user.importerLockedUntil)
        .format('DD-MM-YYYY'))
      setCustomImporterLockTime(dayjs(user.importerLockedUntil)
        .format('HH:mm'))
    }
    if (user.importerLockedUntil && dayjs(user.importerLockedUntil)
      .isBefore(dayjs())) {
      setSelectedImporterlockOption('clear')
      setClearImporterLockNextRun(true)
    }
  }, [user])

  useEffect(() => {
    if (!dispatch || !customerId) {
      return
    }

    dispatch(fetchUserRoles(customerId))
  }, [dispatch, customerId])

  useEffect(() => {
    if (!userRoleId || !userRoles || userRoles.length === 0) {
      return
    }

    const newMetadataStructure = userRoles.find(({ id }) => id === userRoleId).userMetadataStructure
    setMetadataStructure(newMetadataStructure)
  }, [userRoleId, userRoles, metadata])

  useEffect(() => {
    if (!userRoles || userRoles.length === 0) {
      return
    }

    if (!user || !user.userRole || (userId && user.importerType === 'manual')) {
      setAvailableUserRoles(userRoles)
      return
    }

    const currentUserRole = user.userRole
      && user.userRole.id
      && userRoles.find((userRole) => userRole.id === user.userRole.id)

    setAvailableUserRoles(userRoles.filter((userRole) => (
      userRole.id === currentUserRole.id
      || (userRole && userRole.parentUserRole && userRole.parentUserRole.id === currentUserRole.id)
      || (userRole && currentUserRole.parentUserRole && currentUserRole.parentUserRole.id === userRole.id)
    )))
  }, [userRoles, user])

  useEffect(() => {
    let lockDate
    switch (selectedImporterlockOption) {
      case 'day': {
        lockDate = dayjs()
          .add(1, 'day')
          .toISOString()
        break
      }
      case 'week': {
        lockDate = dayjs()
          .add(1, 'week')
          .toISOString()
        break
      }
      case 'month': {
        lockDate = dayjs()
          .add(1, 'month')
          .toISOString()
        break
      }
      case 'schoolYear': {
        const currentYearAugust = dayjs(new Date(dayjs()
          .year(), 7))
          .local()
        if (dayjs()
          .isAfter(currentYearAugust)) {
          lockDate = currentYearAugust.add(1, 'year')
            .toISOString()
        } else {
          lockDate = currentYearAugust.toISOString()
        }
        break
      }
      default:
        lockDate = null
        break
    }

    setImporterLockedUntil(lockDate)
  }, [selectedImporterlockOption])

  const importerLockOptions = {
    clear: 'Clear',
    day: 'Lock for 24h',
    week: 'Lock for a week',
    month: 'Lock for a month',
    schoolYear: 'Lock this school year',
    custom: 'Lock until ...',
  }

  return (
    <form
      noValidate
      className="space-y-6"
      onSubmit={(event) => {
        event.preventDefault()

        const errors = []
        if ((!firstName || firstName.length < 1) && (!initials || initials.length < 1)) {
          errors.push({ title: 'Please provide the user\'s first name or initial(s)' })
        }

        if (!lastName || lastName.length < 1) {
          errors.push({ title: 'Please provide the user\'s last name' })
        }

        try {
          validateEmail(email)
        } catch (error) {
          errors.push({ title: error.message })
        }

        if (!userRoleId) {
          errors.push({ title: 'Please provide the user\'s role' })
        }

        let importerLock = importerLockedUntil
        if (selectedImporterlockOption === 'custom') {
          try {
            validateDate(customImporterLockDate)
            validateTime(customImporterLockTime)
            importerLock = dayjs(
              `${customImporterLockDate} ${customImporterLockTime}`,
              'DD-MM-YYYY HH:mm',
            )
              .local()
              .toISOString()
          } catch (error) {
            errors.push({ title: error.message })
          }
        }

        errors.push(...validateMetadataStructure(metadataStructure, metadata))
        onError(errors)

        if (errors.length === 0) {
          onSubmit({
            firstName,
            initials,
            lastNamePrefix,
            lastName,
            email,
            phone,
            userRoleId,
            importerLockedUntil: importerLock,
            metadata: filterMetadata(metadataStructure, metadata, 1),
          })
        }
      }}
    >
      <FormCard
        label="Personal Information"
      >
        <div className="col-span-6 sm:col-span-4 grid grid-cols-6 gap-6">
          <FormField
            label="Initials"
            required
            name="initials"
            className="sm:col-span-2"
            value={initials || ''}
            onChange={(event) => setInitials(event.target.value)}
          />
          <FormField
            label="First Name"
            required
            name="firstName"
            className="sm:col-span-4"
            value={firstName || ''}
            onChange={(event) => setFirstName(event.target.value)}
          />
        </div>
        <div className="col-span-6 sm:col-span-4 grid grid-cols-6 gap-6">
          <FormField
            label="Prefix"
            name="lastNamePrefix"
            className="sm:col-span-2"
            value={lastNamePrefix || ''}
            onChange={(event) => setLastNamePrefix(event.target.value)}
          />
          <FormField
            label="Last name"
            required
            name="lastName"
            className="sm:col-span-4"
            value={lastName || ''}
            onChange={(event) => setLastName(event.target.value)}
          />
        </div>
        <FormField
          label="Email Address"
          required
          name="email"
          type="email"
          value={email || ''}
          onChange={(event) => setEmail(event.target.value)}
        />
        <FormField
          label="Phone Number"
          name="phone"
          type="tel"
          value={phone || ''}
          onChange={(event) => setPhone(event.target.value)}
        />
        <div className="col-span-6 sm:col-span-4">
          <FormField
            name="userRole"
            label="User Role"
            required
            type="select"
            inputProps={{
              placeholder: ' - Select - ',
              label: (userRoles && userRoles.length > 0 && userRoleId)
                && getLocalisedLabelString(userRoles.find(({ id }) => id === userRoleId).labelI18n, 1),
              buttonProps: {
                disabled:
                  !availableUserRoles || availableUserRoles.length < 2,
              },
              onChange: (value) => setUserRoleId(value),
            }}
          >
            {(availableUserRoles && availableUserRoles.length > 0) && availableUserRoles.map((userRole) => (
              <SelectItem
                key={userRole.id}
                value={userRole.id}
                label={getLocalisedLabelString(userRole.labelI18n, 1)}
                className="whitespace-nowrap"
              />
            ))}
          </FormField>
        </div>
      </FormCard>
      {user && user.importerType !== 'manual' && (
        <FormCard label="Importer Lock Settings">
          <div className="col-span-6 sm:col-span-4 xl:col-span-2 self-end">
            <FormField
              name="importerLockOptions"
              type="select"
              label="Importer lock options"
              value={selectedImporterlockOption}
              inputProps={{
                placeholder: ' - Select - ',
                label: selectedImporterlockOption && importerLockOptions[selectedImporterlockOption],
                onChange: (value) => {
                  setSelectedImporterlockOption(value)
                  setClearImporterLockNextRun(false)
                },
              }}
            >
              {Object.entries(importerLockOptions)
                .map(([key, label]) => (
                  <SelectItem
                    key={label}
                    value={key}
                    label={label}
                    className="whitespace-nowrap"
                  />
                ))}
            </FormField>
          </div>
          {selectedImporterlockOption === 'clear' && (
            <div className="col-span-6 sm:col-span-4 xl:px-0">
              <div>
                <input
                  type="radio"
                  name="clearImporterLock"
                  id="nextRun"
                  checked={clearImporterLockNextRun}
                  onChange={() => {
                    setImporterLockedUntil(dayjs()
                      .toISOString())
                    setClearImporterLockNextRun(true)
                  }}
                  className={cn(
                    'h-4 w-4 mr-2 rounded-full bg-transparent text-theme-500 border-gray-300',
                    'focus:ring-transparent focus:ring-offset-transparent',
                  )}
                />
                <label htmlFor="nextRun">Clear and update on next import</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="clearImporterLock"
                  id="nextChange"
                  onChange={() => {
                    setImporterLockedUntil(null)
                    setClearImporterLockNextRun(false)
                  }}
                  className={cn(
                    'h-4 w-4 mr-2 rounded-full bg-transparent text-theme-500 border-gray-300',
                    'focus:ring-transparent focus:ring-offset-transparent',
                  )}
                />
                <label htmlFor="nextChange">
                  {`Clear when user changes in ${user.importerType}`}
                </label>
              </div>
            </div>
          )}
          {selectedImporterlockOption === 'custom' && (
            <>
              <div className="col-span-6 sm:col-span-4 xl:col-span-2 xl:px-0">
                <FormField
                  name="iets"
                  label="Date"
                  inputProps={{ placeholder: 'DD-MM-YYYY' }}
                  value={customImporterLockDate || ''}
                  onChange={(e) => setCustomImporterLockDate(e.target.value)}
                />
              </div>
              <div className="col-span-6 sm:col-span-4 xl:col-span-2 xl:px-0">
                <FormField
                  name="iets"
                  label="Time"
                  inputProps={{ placeholder: 'HH-MM' }}
                  value={customImporterLockTime || ''}
                  onChange={(e) => setCustomImporterLockTime(e.target.value)}
                />
              </div>
            </>
          )}
        </FormCard>
      )}
      {metadataStructure && (
        <FormCard
          label="Metadata"
          hint="Fields with * are required"
        >
          <div className="col-span-6">
            <MetadataEditor
              structure={metadataStructure}
              metadata={metadata}
              onChange={(newMetadata) => setMetadata(newMetadata)}
            />
          </div>
        </FormCard>
      )}
      <div className="flex justify-end space-x-3">
        <Button
          type="submit"
          primary
          disabled={submitting || loading}
          leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
        >
          Save this User
        </Button>
      </div>
    </form>
  )
}

UserForm.propTypes = {
  user: PropTypes.shape(),
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  onError: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

UserForm.defaultProps = {
  user: null,
  submitting: false,
  loading: false,
}

export default UserForm

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  debounceAction,
} from '@Infowijs-eng/component-library/modules/authentication'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import actionTypes from '../../action-types'
import createImporter from '../../actions/importers/createImporter'
import setImporter from '../../actions/importers/setImporter'
import awaitAccessToken from '../../modules/awaitAccessToken'
import fetchImporter from '../../actions/importers/fetchImporter'

const onCreateImporter = function* onCreateImporter() {
  yield takeLatest(actionTypes.IMPORTERS.CREATE, function* onCreateImporterSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const { payload } = action
    let response

    try {
      response = yield call(axios, {
        url: serviceEndpoints.importers,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: payload,
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reCreateImporter() {
          yield put(createImporter(payload))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    if (response.status !== 204) {
      yield put(setImporter(response.data.data))
    }

    if (response.data.data && response.data.data.id) {
      yield put(fetchImporter(response.data.data.id))
    }

    acceptAction(action, response.data.data)
  })
}

export default onCreateImporter

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  setSessionRequest,
  verifySessionRequest,
} from '@Infowijs-eng/component-library/modules/authentication'
import axios from 'axios'
import { Base64 } from 'js-base64'
import {
  call,
  put,
  takeLeading,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import setWebAuthNSupport from '../../actions/webAuthN/setWebAuthNSupport'

const onWebAuthnStartLogin = function* onWebAuthnStartLogin() {
  // eslint-disable-next-line no-undef
  if (!window.PublicKeyCredential
    // eslint-disable-next-line no-undef
    || !PublicKeyCredential.isConditionalMediationAvailable
    // eslint-disable-next-line no-undef
    || !(yield call(PublicKeyCredential.isConditionalMediationAvailable))
  ) {
    return
  }

  yield put(setWebAuthNSupport(true))

  yield takeLeading(actionTypes.WEB_AUTH_N.START_LOGIN, function* onLoginStart() {
    const loginChallengeResponse = yield call(axios, {
      url: `${serviceEndpoints.sessions}/passkey/login`,
      headers: {
        'X-Infowijs-Client': 'nl.infowijs.admin',
        'Content-Type': 'application/vnd.infowijs.v1+json',
        Accept: 'application/vnd.infowijs.v1+json',
      },
    })
    if (!loginChallengeResponse.data || !loginChallengeResponse.data.data) {
      return // We can't do a passkey signin
    }

    const loginChallenge = loginChallengeResponse.data.data
    const {
      credentialOptions,
      challengeId,
    } = loginChallenge

    try {
      // Manually timeout navigator.credentials.get since conditional might stay open indefinite, and backend will
      // forget the challenge in 15 minutes
      const credential = yield navigator.credentials.get({
        publicKey: {
          ...credentialOptions,
          challenge: Base64.toUint8Array(credentialOptions.challenge),
          excludeCredentials: (credentialOptions.excludeCredentials
            && credentialOptions.excludeCredentials.map((excludeCredential) => ({
              ...excludeCredential,
              id: Base64.toUint8Array(excludeCredential.id),
            }))) || [],
        },
      })

      if (!credential) {
        throw Error('User cancelled authentication')
      }

      const loginResponse = yield call(axios, {
        url: `${serviceEndpoints.sessions}/passkey/login`,
        method: 'POST',
        headers: {
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          credentials: {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: credential.id,
            type: credential.type,
            response: {
              authenticatorData: Base64.fromUint8Array(new Uint8Array(credential.response.authenticatorData), true),
              clientDataJSON: Base64.fromUint8Array(new Uint8Array(credential.response.clientDataJSON), true),
              signature: Base64.fromUint8Array(new Uint8Array(credential.response.signature), true),
              userHandle: Base64.fromUint8Array(new Uint8Array(credential.response.userHandle), true),
            },
          },
          challengeId,
        },
      })
      if (!loginResponse.data || !loginResponse.data.data) {
        throw Error('Login failed')
      }

      // Set the prevalidated session request that we got on successful auth
      yield put(setSessionRequest(loginResponse.data.data))
      // And make sure that the auth.infowijs.nl iframe will verify it instantly to retrieve an accesstoken
      yield put(verifySessionRequest())
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }
  })
}

export default onWebAuthnStartLogin

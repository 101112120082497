import actionTypes from '../../action-types'

export default (userId, userGroupId, bidirectional) => ({
  type: actionTypes.USER.USER_GROUP_RELATIONS_CREATE,
  payload: {
    userId,
    userGroupId,
    bidirectional,
  },
})

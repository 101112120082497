import {
  Card,
  Clickable,
} from '@Infowijs-eng/component-library/components'
import {
  CheckCircle,
  Hourglass,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useState,
} from 'react'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import fetchImporter from '../../actions/importers/fetchImporter'
import ModalWithSteps from '../ModalWithSteps'

function ImporterEditMagisterCredentials({ totalSteps, currentStep }) {
  const navigate = useNavigate()
  const { customerId, importerId } = useParams()
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [importer, setImporterData] = useState(null)
  const [copied, setCopied] = useState(false)

  const user = !!importer
    && !!importer.metadata
    && !!importer.metadata.config
    && !!importer.metadata.config.credentialsSetup
    && {
      name: importer.metadata.config.credentialsSetup.name,
      email: importer.metadata.config.credentialsSetup.email,
      securityToken: importer.metadata.config.credentialsSetup.securityToken,
      url: importer.metadata.config.credentialsSetup.url,
    }

  const credentialsConfirmed = !!importer
    && importer.metadata
    && importer.metadata.config
    && importer.metadata.config.magister
    && importer.metadata.config.magister.credentials

  useEffect(() => {
    setLoading(true)
    promisifyDispatch(fetchImporter(importerId))
      .then((data) => setImporterData(data))
      .catch((err) => setErrorMessages(err))
      .finally(() => setLoading(false))
  }, [importerId])

  return (
    <ModalWithSteps
      totalSteps={totalSteps}
      currentStep={currentStep}
      title="Add Importer"
      onClose={() => navigate(`/customers/${customerId}`)}
      nextButtonProps={credentialsConfirmed
        ? {
          children: 'Continue',
          to: 'locations',
        }
        : {
          children: 'Close and continue later',
          primary: false,
          disabled: !user,
          to: `/customers/${customerId}`,
        }}
      previousButtonProps={{
        children: credentialsConfirmed ? 'Ask new credentials' : 'Cancel invite',
        className: 'text-white bg-red-500 hover:bg-red-600',
        onClick: () => {
          // eslint-disable-next-line no-alert
          if (window.confirm(
            credentialsConfirmed
              // eslint-disable-next-line max-len
              ? 'Are you sure you want to return and send a new invite? All previously entered credentials will be deleted.'
              // eslint-disable-next-line max-len
              : `Are you sure you want to cancel this invite? The link that ${user.name} has received will become invalid when the credentials are changed.`,
          )) {
            navigate(`/customers/${customerId}/importers/create/magister`)
          }
        },
      }}
      alert={(errorMessages
          && {
            title: 'Something went wrong while creating the importer',
            errorMessages,
          })
        || (
          (!loading && !user && !credentialsConfirmed) && {
            title: 'Importer not found',
            errorMessages: [{ title: 'Could not load the details for the importer you are configuring' }],
          })
        || null}
    >
      <div className="font-bold">Set up credentials for this school</div>
      {loading && <span>loading...</span>}
      {!loading && user && (
        <Card className="p-6 flex gap-4">
          <div className="p-2 box-border self-start bg-theme-50 rounded-full text-lg">
            <Hourglass className="text-theme-500" />
          </div>
          <div className="leading-relaxed space-y-4">
            <p className="font-bold text-base">Patience, you must have...</p>
            <p>
              {`An email with instructions has been sent to ${user.name} (${user.email}).`}
            </p>
            <p>
              Once the credentials have been filled out, you&apos;ll be able to continue the configuration.
              Come back later!
            </p>
            <div className="space-x-4">
              <Clickable
                className="text-theme-700"
                onClick={() => {
                  if (!window.navigator.clipboard) {
                    window.open(user.url, '_blank')
                    return
                  }
                  window.navigator.clipboard.writeText(user.url)
                    .then(() => {
                      setCopied(true)
                      setTimeout(() => {
                        setCopied(false)
                      }, 2000)
                    })
                    .catch(() => {
                      window.open(user.url, '_blank')
                    })
                }}
              >
                Copy link
              </Clickable>
              <span className={cn('text-gray-500 transition-opacity opacity-0', copied && 'opacity-100')}>
                Copied!
              </span>
            </div>
          </div>
        </Card>
      )}
      {!loading && credentialsConfirmed && (
        <Card className="p-6 flex gap-4">
          <div className="p-2 box-border self-start bg-green-50 rounded-full text-lg">
            <CheckCircle className="text-green-500" />
          </div>
          <div className="leading-relaxed space-y-4">
            <div className="leading-relaxed space-y-4">
              <p className="font-bold text-base">Ready, are you?</p>
              <p>
                Hooray, the credentials are filled in for the Magister domain:
                <br />
                <pre className="text-sm">
                  {importer.metadata.config.magister.credentials
                    ? importer.metadata.config.magister.credentials.portal
                    : '[Unknown portal]'}
                </pre>
              </p>
              <p>You&apos;re ready to complete the configuration.</p>
            </div>
          </div>
        </Card>
      )}
    </ModalWithSteps>
  )
}

ImporterEditMagisterCredentials.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditMagisterCredentials

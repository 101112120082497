import {
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import Qr from './qr'

function QRCode({
  width,
  height,
  padding,
  backgroundColor,
  fillColor,
  content,
  ...props
}) {
  const [qrCode, setQrCode] = useState(null)
  useEffect(() => {
    setQrCode(new Qr({
      ...props,
      content,
      width,
      height,
    }))
  }, [content])

  if (!qrCode) {
    return null
  }

  const { length } = qrCode.qrcode.modules
  const xsize = width / (length + 2 * padding)
  const ysize = height / (length + 2 * padding)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width={width} height={height}>
      <rect
        x="0"
        y="0"
        width={width}
        height={height}
        style={{
          fill: backgroundColor,
          shapeRendering: 'crispEdges',
        }}
      />
      {qrCode.qrcode.modules?.map((y, yIndex) => qrCode.qrcode.modules?.map((_x, xIndex) => {
        const px = ((xIndex * xsize) + (padding * xsize))
        const py = ((yIndex * ysize) + (padding * ysize))

        if (!_x[yIndex]) {
          return null
        }

        return (
          <rect
            // eslint-disable-next-line react/no-array-index-key, prefer-template
            key={yIndex + '' + xIndex}
            x={px.toString()}
            y={py.toString()}
            width={xsize}
            height={ysize}
            style={{
              fill: fillColor,
              shapeRendering: 'crispEdges',
            }}
          />
        )
      }))}
    </svg>
  )
}

QRCode.propTypes = {
  content: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
  fillColor: PropTypes.string,
  padding: PropTypes.number,
}

QRCode.defaultProps = {
  width: 300,
  height: 300,
  backgroundColor: '#FFFFFF',
  fillColor: '#000000',
  padding: 2,
}

export default QRCode

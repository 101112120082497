import PropTypes from 'prop-types'

import TableRows from './TableRows'

function Table({ head, body, foot }) {
  return (
    <table className="w-full">
      {head && (
        <thead>
          {head.map((rows, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index}>
              <TableRows rows={rows} />
            </tr>
          ))}
        </thead>
      )}
      {body && (
        <tbody>
          {body.map((rows, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index} className="border-t border-gray-50">
              <TableRows rows={rows} />
            </tr>
          ))}
        </tbody>
      )}
      {foot && (
        <tfoot>
          {foot.map((rows, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={index} className="border-t border-gray-50">
              <TableRows rows={rows} cellCss="font-extrabold" />
            </tr>
          ))}
        </tfoot>
      )}
    </table>
  )
}

Table.propTypes = {
  head: PropTypes.arrayOf(TableRows.propTypes.rows),
  body: PropTypes.arrayOf(TableRows.propTypes.rows),
  foot: PropTypes.arrayOf(TableRows.propTypes.rows),
}

Table.defaultProps = {
  head: null,
  body: null,
  foot: null,
}

export default Table

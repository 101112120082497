import { UserRole } from './types'

export default function determineTopmostUserRoleId(roles: { [id: string]: UserRole }, roleId: string): string {
  let role = roles[roleId]
  while (role && role.parentUserRoleId) {
    const newRole = roles[role.parentUserRoleId]
    if (!newRole) {
      return role.parentUserRoleId
    }
    role = newRole
  }
  return role.id ?? roleId
}

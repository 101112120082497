import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import DetailCardFieldGroup from '../DetailCardFieldGroup'
import DetailCardField from '../DetailCardField'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'

function UserImporterDetails({ user, loading }) {
  return (
    <section aria-labelledby="user importer details">
      <SectionTitle>Importer details</SectionTitle>
      <SectionBody className="pt-4">
        <DetailCardFieldGroup>
          <DetailCardField
            label="Type"
            loading={loading}
          >
            {user && user.importerType}
          </DetailCardField>
          <DetailCardField
            label="Source ID"
            loading={loading}
            className="truncate"
            title={user && user.importerSourceId}
          >
            {user && user.importerSourceId}
          </DetailCardField>
          {!loading && user && user.importerLockedUntil && (
            <DetailCardField
              label="Automatic import locked until"
              loading={loading}
            >
              {dayjs(user.importerLockedUntil).isBefore(dayjs()) && (
                <span>Next importer run</span>
              )}
              {dayjs(user.importerLockedUntil).isAfter(dayjs()) && (
                <time dateTime={user.importerLockedUntil}>
                  {dayjs(user.importerLockedUntil).format('D MMMM YYYY [at] HH:mm')}
                </time>
              )}
            </DetailCardField>
          )}
        </DetailCardFieldGroup>
      </SectionBody>
    </section>
  )
}

UserImporterDetails.propTypes = {
  user: PropTypes.shape({
    importerLockedUntil: PropTypes.string,
    importerType: PropTypes.string,
    importerSourceId: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

UserImporterDetails.defaultProps = {
  user: null,
  loading: false,
}

export default UserImporterDetails

import { DropdownItem } from '@Infowijs-eng/component-library/components'
import usePromisifyDispatch from '@Infowijs-eng/component-library/modules/usePromisifyDispatch'
import PropTypes from 'prop-types'
import {
  useMemo,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import updateImporter from '../../actions/importers/updateImporter'
import addNotification from '../../actions/notificationCenter/addNotification'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'
import makeGetImporterById from '../../selectors/importers/makeGetImporterById'
import {
  STATUS_ERROR,
  STATUS_SUCCESS,
} from '../StatusIcon'

function AutomaticImportToggleDropdownItem({ importerId }) {
  const getImporterById = useMemo(
    () => makeGetImporterById(),
    [],
  )

  const importer = useSelector((state) => getImporterById(
    state,
    { id: importerId },
  ))

  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)

  return (
    <DropdownItem
      small
      disabled={isLoading}
      onClick={() => {
        setLoading(true)
        promisifyDispatch(
          updateImporter({
            id: importerId,
            active: !importer.active,
          }),
        )
          .then(() => {
            dispatch(addNotification({
              title: `Importer schedule has been ${(importer.active ? 'disabled' : 'enabled')}`,
              status: STATUS_SUCCESS,
            }))
          })
          .catch((e) => dispatch(addNotification({
            title: 'Could not enable importer schedule',
            errorMessages: e,
            status: STATUS_ERROR,
          }, NOTIFICATION_TIMEOUT_LONG)))
          .finally(() => {
            setLoading(false)
          })
      }}
    >
      {importer && importer.active ? 'Disable schedule' : 'Enable schedule'}
    </DropdownItem>
  )
}

AutomaticImportToggleDropdownItem.propTypes = {
  importerId: PropTypes.string.isRequired,
}

export default AutomaticImportToggleDropdownItem

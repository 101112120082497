import React from 'react'
import { Clickable } from '@Infowijs-eng/component-library/components'
import { MagnifyingGlass, TrashBin } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'

interface ImporterMigrateNoMatchProps {
  onSearch: () => void
  onRemove: () => void
}
export default function ImporterMigrateNoMatch({
  onSearch,
  onRemove,
}: ImporterMigrateNoMatchProps) {
  return (
    <div
      className={cn(
        'flex flex-row h-full w-full border border-red-300 bg-red-50',
        'p-4 gap-2 rounded-lg items-center justify-between group',
      )}
    >
      <span className="text-sm ml-6">System could not determine possible match</span>
      <div className="flex flex-row gap-2 items-center">
        <Clickable
          className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 h-fit"
          onClick={onSearch}
        >
          <MagnifyingGlass />
        </Clickable>
        <Clickable
          className="p-2 rounded-full bg-red-100 hover:bg-red-300"
          onClick={onRemove}
        >
          <TrashBin className="fill-red-700" />
        </Clickable>
      </div>
    </div>
  )
}

import actionTypes from '../action-types'

export default (state = { data: {} }, action = {}) => {
  switch (action.type) {
    case actionTypes.CUSTOMER_PRODUCTS.SET_ALL:
      return {
        ...state,
        data: action.payload.reduce((acc, product) => {
          acc[product.id] = product
          return acc
        }, {}),
      }
    case actionTypes.CUSTOMER_PRODUCTS.SET:
      if (action.included) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.id]: {
              ...action.payload,
              customerTitle: action.included.customer[action.payload.customerId].title,
              createdBy: action.included.administrator[action.payload.createdBy.id],
            },
          },
        }
      }

      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: action.payload,
        },
      }
    case actionTypes.CUSTOMER_PRODUCTS.PATCH:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            ...action.payload,
            customerTitle: action.included
              ? action.included.customer[action.payload.customerId].title
              : state.data[action.payload.id].customerTitle || null,
            createdBy: action.included
              ? action.included.administrator[action.payload.createdBy.id]
              : state.data[action.payload.id].createdBy || null,
          },
        },
      }
    default:
      return state
  }
}

import { onAuthentication } from '@Infowijs-eng/component-library/modules/authentication'
import {
  all,
  spawn,
} from 'redux-saga/effects'
import onFetchAdministratorPermissions from './administratorPermissions/onFetchAdministratorPermissions'
import onFetchAdministrator from './administrators/onFetchAdministrator'
import onFetchAdministrators from './administrators/onFetchAdministrators'
import onInviteAdministrator from './administrators/onInviteAdministrator'
import onUpdateAdministrator from './administrators/onUpdateAdministrator'
import onSetSessionRequest from './authentication/onSetSessionRequest'
import onSignIn from './authentication/onSignIn'
import onValidateMagicLink from './authentication/onValidateMagicLink'
import onCreateCustomerProduct from './customerProducts/onCreateCustomerProduct'
import onDeployCustomerProduct from './customerProducts/onDeployCustomerProduct'
import onFetchCustomerProduct from './customerProducts/onFetchCustomerProduct'
import onFetchCustomerProducts from './customerProducts/onFetchCustomerProducts'
import onUpdateCustomerProduct from './customerProducts/onUpdateCustomerProduct'
import onCreateCustomer from './customers/onCreateCustomer'
import onFetchCustomer from './customers/onFetchCustomer'
import onFetchCustomers from './customers/onFetchCustomers'
import onUpdateCustomer from './customers/onUpdateCustomer'
import onUpdateCustomerMetadataLocations from './customers/onUpdateCustomerMetadataLocations'
import onCreateImporter from './importers/onCreateImporter'
import onFetchImporter from './importers/onFetchImporter'
import onFetchImporterDryRun from './importers/onFetchImporterDryRun'
import onFetchImporterLocations from './importers/onFetchImporterLocations'
import onFetchImporters from './importers/onFetchImporters'
import onFetchSomtodayInstitute from './importers/onFetchSomtodayInstitute'
import onManualImport from './importers/onManualImport'
import onUpdateImporter from './importers/onUpdateImporter'
import onAddNotification from './notificationCenter/onAddNotification'
import onFetchSessions from './sessions/onFetchSessions'
import onArchiveUser from './user/onArchiveUser'
import onCreateUser from './user/onCreateUser'
import onCreateUserGroupRelation from './user/onCreateUserGroupRelations'
import onCreateUserRelation from './user/onCreateUserRelation'
import onDeleteUserGroupRelation from './user/onDeleteUserGroupRelation'
import onDeleteUserRelation from './user/onDeleteUserRelation'
import onFetchUser from './user/onFetchUser'
import onFetchUserGroupRelations from './user/onFetchUserGroupRelations'
import onFetchUserRelations from './user/onFetchUserRelations'
import onInitiateImpersonation from './user/onInitiateImpersonation'
import onInviteUser from './user/onInviteUser'
import onRevokeUserAccess from './user/onRevokeUserAccess'
import onUpdateUser from './user/onUpdateUser'
import onFetchUserGroupBadges from './userGroups/onFetchUserGroupBadges'
import onFetchUserGroups from './userGroups/onFetchUserGroups'
import onFetchUserRoles from './userRoles/onFetchUserRoles'
import onFetchUsers from './users/onFetchUsers'
import onWebAuthnStartLogin from './webAuthN/onWebAuthnStartLogin'
import onWebAuthnStartRegister from './webAuthN/onWebAuthnStartRegister'

const rootSaga = function* rootSaga() {
  yield all([
    spawn(onAddNotification),
    spawn(onArchiveUser),
    spawn(onAuthentication),
    spawn(onCreateCustomer),
    spawn(onCreateCustomerProduct),
    spawn(onCreateImporter),
    spawn(onCreateUser),
    spawn(onCreateUserRelation),
    spawn(onDeleteUserRelation),
    spawn(onDeployCustomerProduct),
    spawn(onFetchAdministrator),
    spawn(onFetchAdministratorPermissions),
    spawn(onFetchAdministrators),
    spawn(onFetchCustomer),
    spawn(onFetchCustomerProduct),
    spawn(onFetchCustomerProducts),
    spawn(onFetchCustomers),
    spawn(onFetchImporters),
    spawn(onFetchImporter),
    spawn(onFetchSessions),
    spawn(onFetchUser),
    spawn(onFetchUserGroupBadges),
    spawn(onFetchUserGroupRelations),
    spawn(onCreateUserGroupRelation),
    spawn(onDeleteUserGroupRelation),
    spawn(onFetchUserGroups),
    spawn(onFetchUserRelations),
    spawn(onFetchUserRoles),
    spawn(onFetchUsers),
    spawn(onInviteAdministrator),
    spawn(onInviteUser),
    spawn(onManualImport),
    spawn(onRevokeUserAccess),
    spawn(onSetSessionRequest),
    spawn(onSignIn),
    spawn(onUpdateAdministrator),
    spawn(onUpdateCustomer),
    spawn(onUpdateCustomerProduct),
    spawn(onUpdateImporter),
    spawn(onUpdateUser),
    spawn(onValidateMagicLink),
    spawn(onInitiateImpersonation),
    spawn(onFetchSomtodayInstitute),
    spawn(onFetchImporterLocations),
    spawn(onFetchImporterDryRun),
    spawn(onUpdateCustomerMetadataLocations),
    spawn(onWebAuthnStartLogin),
    spawn(onWebAuthnStartRegister),
  ])
}

export default rootSaga

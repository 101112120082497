import {
  delay,
  put,
  select,
  take,
  takeEvery,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import removeNotification from '../../actions/notificationCenter/removeNotification'
import resetNotificationTimeout from '../../actions/notificationCenter/resetNotificationTimeout'
import { NOTIFICATION_TIMEOUT_SHORT } from '../../modules/notificationCenter'
import isNotificationCenterInFocus from '../../selectors/notificationCenter/isNotificationCenterInFocus'

const onAddNotification = function* onAddNotification() {
  yield takeEvery([
    actionTypes.NOTIFICATION_CENTER.ADD,
    actionTypes.NOTIFICATION_CENTER.RESET_TIMEOUT,
  ], function* addNotification({ payload }) {
    if (payload.timeout) {
      yield delay(payload.timeout || 0)

      const rejectTimeoutDismiss = yield select(isNotificationCenterInFocus)

      if (rejectTimeoutDismiss) {
        yield take(actionTypes.NOTIFICATION_CENTER.HOVERED)
        yield put(resetNotificationTimeout({
          ...payload,
          // By calculating the diff between now and the startime we now
          timeout: NOTIFICATION_TIMEOUT_SHORT,
        }))
        return
      }

      yield put(removeNotification(payload.id))
    }
  })
}

export default onAddNotification

import {
  Route,
  Routes,
} from 'react-router-dom'
import ImporterEditDryRun from './ImporterEditDryRun'
import ImporterEditLocations from './ImporterEditLocations'
import ImporterEditMagisterAdminCredentials from './ImporterEditMagisterAdminCredentials'
import ImporterEditMagisterCredentials from './ImporterEditMagisterCredentials'
import ImporterEditSettings from './ImporterEditSettings'
import ImporterEditSomtodayInstitute from './ImporterEditSomtodayInstitute'
import ImporterEditType from './ImporterEditType'

export default function ImporterCreateModal() {
  const somtodaySteps = 5
  const magisterSteps = 6
  return (
    <Routes>
      <Route
        path="/"
        element={<ImporterEditType totalSteps={2} currentStep={1} />}
      />
      <Route
        path="/somtoday"
        element={<ImporterEditSomtodayInstitute totalSteps={somtodaySteps} currentStep={2} />}
      />
      <Route
        path="/magister"
        element={<ImporterEditMagisterAdminCredentials totalSteps={magisterSteps} currentStep={2} />}
      />
      <Route
        path="/magister/:importerId"
        element={<ImporterEditMagisterCredentials totalSteps={magisterSteps} currentStep={3} />}
      />
      <Route
        path="/magister/:importerId/locations"
        element={<ImporterEditLocations totalSteps={magisterSteps} currentStep={4} />}
      />
      <Route
        path="/somtoday/:importerId"
        element={<ImporterEditLocations totalSteps={somtodaySteps} currentStep={3} />}
      />
      <Route
        path="/magister/:importerId/settings"
        element={<ImporterEditSettings totalSteps={magisterSteps} currentStep={5} />}
      />
      <Route
        path="/somtoday/:importerId/settings"
        element={<ImporterEditSettings totalSteps={somtodaySteps} currentStep={4} />}
      />
      <Route
        path="/magister/:importerId/dry-run"
        element={<ImporterEditDryRun totalSteps={magisterSteps} type="magister" currentStep={6} />}
      />
      <Route
        path="/somtoday/:importerId/dry-run"
        element={<ImporterEditDryRun totalSteps={somtodaySteps} type="somtoday" currentStep={5} />}
      />
    </Routes>
  )
}

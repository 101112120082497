import { Skeleton } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import ListItem from '../ListItem'

function ImporterListItemSkeleton({ className }) {
  return (
    <ListItem className={className}>
      <div className="w-full space-y-2">
        <Skeleton className="block w-3/4" />
        <div className="flex gap-1">
          <Skeleton className="block w-1/6" />
          <Skeleton className="block w-1/6" />
          <Skeleton className="block w-1/6" />
        </div>
        <Skeleton className="block w-2/3 bg-red-300" />
      </div>
    </ListItem>
  )
}

ImporterListItemSkeleton.propTypes = {
  className: cn.propType,
}

ImporterListItemSkeleton.defaultProps = {
  className: null,
}

export default ImporterListItemSkeleton

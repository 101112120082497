import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import CustomerProductForm from '../components/CustomerProduct/CustomerProductForm'
import getCustomer from '../selectors/customers/getCustomer'
import fetchCustomer from '../actions/customers/fetchCustomer'
import createCustomerProduct from '../actions/customerProducts/createCustomerProduct'
import PageHeader from '../components/PageHeader'

function CustomerProductCreateScreen() {
  const { customerId } = useParams()
  const navigate = useNavigate()
  const customer = useSelector((state) => getCustomer(state, { id: customerId }))
  const [loading, setLoading] = useState(!customer)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState('')
  const promisifyDispatch = usePromisifyDispatch()

  useEffect(() => {
    setErrorMessages(null)
    promisifyDispatch(fetchCustomer(customerId))
      .then(() => (
        setLoading(false)
      ))
      .catch((e) => {
        setErrorMessages(e)
      })
  }, [customerId])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/customers',
            label: 'Customers',
          },
          {
            to: `/customers/${customerId}`,
            label: customer && customer.title,
          },
        ]}
      >
        <Heading fontSize={Heading.size.H3}>Create a new Customer Product</Heading>
      </PageHeader>

      <div className="sticky top-0 z-50">
        {errorMessages && !!errorMessages.length && (<Alert title="You made a booboo" errorMessages={errorMessages} />)}
      </div>

      <CustomerProductForm
        loading={loading}
        submitting={submitting}
        onError={(e) => setErrorMessages(e)}
        onSubmit={(payload) => {
          setSubmitting(true)
          promisifyDispatch(createCustomerProduct(
            customerId,
            payload,
          ))
            .then(() => {
              setSubmitting(false)
              navigate(`/customers/${customerId}`)
            })
            .catch((error) => {
              setSubmitting(false)
              setErrorMessages(error)
            })
        }}
      />

    </Layout>
  )
}

export default CustomerProductCreateScreen

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  getAccessToken,
} from '@Infowijs-eng/component-library/modules/authentication'
import { acceptAction, rejectAction } from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import { Base64 } from 'js-base64'
import {
  call,
  select,
  takeLeading,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'

const onWebAuthnStartRegister = function* onWebAuthnStartRegister() {
  yield takeLeading(actionTypes.WEB_AUTH_N.START_REGISTER, function* onRegisterStart(action) {
    try {
      const registerChallengeResponse = yield call(axios, {
        url: `${serviceEndpoints.sessions}/passkey/register`,
        headers: {
          Authorization: `Bearer ${yield select(getAccessToken)}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
      if (!registerChallengeResponse.data || !registerChallengeResponse.data.data) {
        rejectAction(action, 'Could not receive challenge from server')
      }

      const {
        credentialOptions,
        challengeId,
      } = registerChallengeResponse.data.data

      const credential = yield navigator.credentials.create({
        publicKey: {
          ...credentialOptions,
          challenge: Base64.toUint8Array(credentialOptions.challenge),
          user: {
            ...credentialOptions.user,
            id: Base64.toUint8Array(credentialOptions.user.id),
          },
          excludeCredentials: (credentialOptions.excludeCredentials
                  && credentialOptions.excludeCredentials.map((excludeCredential) => ({
                    ...excludeCredential,
                    id: Base64.toUint8Array(excludeCredential.id),
                  }))) || [],
        },
      })

      const registerResponse = yield call(axios, {
        url: `${serviceEndpoints.sessions}/passkey/register`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${yield select(getAccessToken)}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          credentials: {
            authenticatorAttachment: credential.authenticatorAttachment,
            id: credential.id,
            rawId: credential.id,
            type: credential.type,
            response: {
              attestationObject: Base64.fromUint8Array(new Uint8Array(credential.response.attestationObject), true),
              clientDataJSON: Base64.fromUint8Array(new Uint8Array(credential.response.clientDataJSON), true),
            },
          },
          challengeId,
        },
      })
      if (!registerResponse.data || !registerResponse.data.data) {
        rejectAction(action, 'Could not register passKey on server')
      }

      acceptAction(action)
    } catch (e) {
      rejectAction(action, e)
    }
  })
}

export default onWebAuthnStartRegister

export default (a, b) => {
  if (a.type < b.type) {
    return -1
  }

  if (a.type > b.type) {
    return 1
  }

  if (a.title < b.title) {
    return -1
  }

  if (a.title > b.title) {
    return -1
  }

  return 0
}

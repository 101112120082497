export default {
  SET_ALL: 'IMPORTERS/SET_ALL',
  FETCH: 'IMPORTERS/FETCH',
  FETCH_ALL: 'IMPORTERS/FETCH_ALL',
  SET: 'IMPORTERS/SET',
  CREATE: 'IMPORTERS/CREATE',
  MANUAL_IMPORT: ' IMPORTERS/MANUAL_IMPORT',
  UPDATE: 'IMPORTERS/UPDATE',
  FETCH_SOMTODAY_INSTITUTES: 'IMPORTERS/FETCH_SOMTODAY_INSTITUTES',
  FETCH_LOCATIONS: 'IMPORTERS/FETCH_LOCATIONS',
  DRY_RUN: 'IMPORTERS/DRY_RUN',
}

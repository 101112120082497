import { createSelector } from 'reselect'
import getCustomerProductsData from './getCustomerProductsData'

export default function makeGetCustomerProductsByCustomerId() {
  return createSelector(
    getCustomerProductsData,
    (_, props) => props.customerId,
    (customerProducts, customerId) => {
      if (!customerId) {
        return []
      }

      return Object.values(customerProducts).filter((product) => product.customerId === customerId)
    },
  )
}

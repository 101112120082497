import {
  Avatar,
  Badge,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { TYPE_USER } from '@Infowijs-eng/component-library/constants/avatar'
import {
  Badge as BadgeIcon,
  Envelope,
  Lock,
  Person,
  TrashBin,
} from '@Infowijs-eng/component-library/icons'
import {
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import getFormattedFullName from '../../modules/getFormattedFullName'
import ListItem from '../ListItem'
import MetadataBadges from '../MetadataBadges'

export const USER_DETAIL_FIELD_ROLE = 'role'
export const USER_DETAIL_FIELD_EMAIL = 'email'
export const USER_DETAIL_FIELD_SUBTITLE = 'subtitle'

const getUserFieldRecordElement = (user, field) => {
  switch (field) {
    case USER_DETAIL_FIELD_ROLE:
      return (
        <WithAddon
          key={field}
          leadingAddon={<Person />}
          wrapperClassName="space-x-1 grow-0"
        >
          {getLocalisedLabelString(user.userRole.labelI18n, 1)}
        </WithAddon>
      )
    case USER_DETAIL_FIELD_EMAIL:
      return user.email && (
        <WithAddon
          key={field}
          leadingAddon={<Envelope />}
          wrapperClassName="space-x-1 grow-0"
        >
          {user.email}
        </WithAddon>
      )

    case USER_DETAIL_FIELD_SUBTITLE:
      return user.subtitle && (
        <WithAddon
          key={field}
          leadingAddon={<BadgeIcon />}
          wrapperClassName="space-x-1 grow-0"
        >
          {user.subtitle}
        </WithAddon>
      )
    default:
      return null
  }
}

function UserListItem({
  user,
  detailFields,
  trailingAddon,
  metadata,
}) {
  const fullName = getFormattedFullName(user)

  return (
    <ListItem
      leadingAddon={(
        <Avatar
          key="avatar"
          alt={fullName}
          id={user.id}
          type={TYPE_USER}
        />
      )}
      trailingAddon={trailingAddon}
      to={`/customers/${user.customer.id}/users/${user.id}`}
      className="w-full flex flex-col space-y-1"
    >
      <WithAddon
        className="flex flex-col space-x-2"
        wrapperClassName="space-x-2"
        trailingAddon={[
          user.importerLockedUntil && dayjs(user.importerLockedUntil)
            .isAfter(dayjs()) && (
            <span
              key={`user-${user.id}-locked`}
              title={`User locked from importing until ${user.importerLockedUntil}`}
            >
              <Badge leadingAddon={<Lock />}>
                {dayjs(user.importerLockedUntil)
                  .format('MMM DD, YYYY [at] HH:mm')}
              </Badge>
            </span>
          ),
          user.archivedAt && (
            <span
              key={`user-${user.id}-archived`}
              title={`User archived since ${user.archivedAt}`}
            >
              <Badge className="bg-red-500" leadingAddon={<TrashBin />}>
                {dayjs(user.archivedAt)
                  .format('MMM DD, YYYY [at] HH:mm')}
              </Badge>
            </span>
          ),
          <MetadataBadges key="metadata-badges" metadata={metadata} />,
        ]}
      >
        <span className="whitespace-nowrap font-extrabold">
          {fullName}
        </span>
      </WithAddon>
      <WithAddon
        trailingAddon={(
          <span className="flex-nowrap" title={user.lastUpdatedAt}>
            {`Last updated: ${dayjs(user.lastUpdatedAt)
              .format('MMM DD, YYYY [at] HH:mm')}`}
          </span>
        )}
        className="space-x-4 flex-grow justify-start"
      >
        {detailFields.map((field) => getUserFieldRecordElement(user, field))}
      </WithAddon>
    </ListItem>
  )
}

UserListItem.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.string,
    subtitle: PropTypes.string,
    customer: PropTypes.shape(),
    importerLockedUntil: PropTypes.string,
    archivedAt: PropTypes.string,
    email: PropTypes.string,
    lastUpdatedAt: PropTypes.string,
    userRole: PropTypes.shape(),
  }).isRequired,
  detailFields: PropTypes.arrayOf(PropTypes.oneOf([
    USER_DETAIL_FIELD_ROLE,
    USER_DETAIL_FIELD_EMAIL,
    USER_DETAIL_FIELD_SUBTITLE,
  ])),
  metadata: PropTypes.shape(),
  trailingAddon: WithAddon.propTypes.trailingAddon,
}

UserListItem.defaultProps = {
  detailFields: [USER_DETAIL_FIELD_ROLE, USER_DETAIL_FIELD_EMAIL],
  metadata: null,
  trailingAddon: null,
}

export default UserListItem

export default {
  SET_ACCESS_TOKEN: 'AUTHENTICATION/SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'AUTHENTICATION/SET_REFRESH_TOKEN',
  REFRESH_REFRESH_TOKEN: 'AUTHENTICATION/REFRESH_REFRESH_TOKEN',
  REQUEST_ACCESS_TOKEN: 'AUTHENTICATION/REQUEST_ACCESS_TOKEN',
  SIGN_IN: 'AUTHENTICATION/SIGN_IN',
  SIGN_OUT: 'AUTHENTICATION/SIGN_OUT',
  SET_SESSION_REQUEST: 'AUTHENTICATION/SET_SESSION_REQUEST',
  VALIDATE_MAGIC_LINK: 'AUTHENTICATION/VALIDATE_MAGIC_LINK',
}

import {
  Checkbox, Clickable, Modal, TextInput,
} from '@Infowijs-eng/component-library/components'
import React, { useEffect, useState } from 'react'
import * as levenshtein from 'damerau-levenshtein'
import { PersonPlus } from '@Infowijs-eng/component-library/icons'
import { cn, getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import {
  ImporterUser, MatchStatus, UserRole, UserStatus,
} from '../../../modules/importerSwitch/types'
import getFullName from '../../../modules/getFullName'
import determineTopmostUserRoleId from '../../../modules/importerSwitch/determineTopmostUserRoleId'

interface ImporterMigrateUserSearchModalProps {
  customerId: string
  onClose: () => void
  onSelectUser: (user: ImporterUser) => void
  importerUsers: { [key: string]: ImporterUser }
  userStatuses: { [key: string]: UserStatus }
  sourceImporterUser: ImporterUser
  userRoles: {[key: string]: UserRole}
}

export default function ImporterMigrateUserSearchModal(
  {
    customerId, onClose, onSelectUser, importerUsers, userStatuses, sourceImporterUser, userRoles,
  }: ImporterMigrateUserSearchModalProps,
) {
  const [userList, setUserList] = useState<ImporterUser[]>([])
  const [showMatchedUsers, setShowMatchedUsers] = useState(false)
  const [showUsersMatchingRole, setShowUsersMatchingRole] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  const onSearch = (term: string) => {
    setSearchTerm(term)
  }

  useEffect(() => {
    const localTerm = searchTerm?.trim()

    let newUserList: ImporterUser[]
    if (!localTerm || localTerm === '') {
      newUserList = Object.values(importerUsers).sort((a, b) => getFullName(a).localeCompare(getFullName(b)))
    } else {
      const results = Object.values(importerUsers).flatMap((user) => {
        const nameDistance = levenshtein(getFullName(user), localTerm).similarity
        const emailDistance = levenshtein(user?.email ?? '', localTerm).similarity

        return [{
          score: Math.max(emailDistance, nameDistance),
          user,
        }]
      }).sort((a, b) => b.score - a.score)
      newUserList = results.map((res) => res.user)
    }

    const sourceUserTopmostRoleId = determineTopmostUserRoleId(userRoles, sourceImporterUser.userRole.id)

    newUserList = newUserList.filter((user) => {
      const userStatus = userStatuses[user.id]
      if (!showMatchedUsers && (
        !userStatus
        || userStatus.status === MatchStatus.ImplicitMatch
        || userStatus.status === MatchStatus.ExplicitMatch
      )) {
        return false
      }

      if (!showUsersMatchingRole) {
        const targetUserTopmostRoleId = determineTopmostUserRoleId(userRoles, user.userRole.id)
        if (targetUserTopmostRoleId !== sourceUserTopmostRoleId) {
          return false
        }
      }

      return true
    })

    setUserList(newUserList)
  }, [searchTerm, importerUsers, userStatuses, showMatchedUsers, showUsersMatchingRole])

  return (
    <Modal className="max-w-screen-md" onClose={onClose}>
      <div className="flex flex-row gap-4">
        <Checkbox
          checked={showMatchedUsers}
          name="includeMatchedUsers"
          label="Include already matched users"
          onChange={() => {
            setShowMatchedUsers(!showMatchedUsers)
          }}
        />
        <Checkbox
          checked={showUsersMatchingRole}
          name="includeOtherRoles"
          label="Include other roles"
          onChange={() => {
            setShowUsersMatchingRole(!showUsersMatchingRole)
          }}
        />
      </div>

      <TextInput
        placeholder={`Search match for ${getFullName(sourceImporterUser)}`}
        name="search"
        inline
        onChange={(e) => {
          onSearch(e?.target?.value ?? '')
        }}
      />
      {userList.map((user) => (
        <div className={cn(
          'flex flex-row w-full hover:bg-turquoise-50',
          'p-4 gap-2 rounded-lg items-center justify-between group',
        )}
        >
          <div className="flex flex-row gap-2 items-center">
            <div className="text-xs">{getLocalisedLabelString(user.userRole.labelI18n, 1)}</div>
            <div className="flex flex-row gap-2 items-center">
              <div>
                <a
                  className="hover:underline font-medium"
                  href={`/customers/${customerId}/users/${user.id}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {getFullName(user)}
                </a>
                <div className="text-sm">{user.email}</div>
              </div>

            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Clickable
              disabled={userStatuses[user.id].status === MatchStatus.ImplicitMatch
                  || userStatuses[user.id].status === MatchStatus.ExplicitMatch}
              className="p-1 rounded-full bg-turquoise-100 hover:bg-turquoise-200 h-fit disabled:bg-gray-50"
              onClick={() => {
                onSelectUser(user)
              }}
            >
              { (userStatuses[user.id].status === MatchStatus.ImplicitMatch
                  || userStatuses[user.id].status === MatchStatus.ExplicitMatch) ? 'Matched' : <PersonPlus />}
            </Clickable>
          </div>
        </div>
      ))}
    </Modal>
  )
}

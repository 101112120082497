import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  getLocalisedLabelString,
  serviceEndpoints,
} from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import fetchUserGroupRelations from '../../actions/user/fetchUserGroupRelations'
import addNotification from '../../actions/notificationCenter/addNotification'
import getFullName from '../../modules/getFullName'
import createUserGroupRelation from '../../actions/user/createUserGroupRelation'
import getUser from '../../selectors/user/getUser'
import getUserGroupRelation from '../../selectors/user/getUserGroupRelation'

const onDeleteUserGroupRelation = function* onDeleteUserGroupRelation() {
  yield takeLatest(actionTypes.USER.USER_GROUP_RELATION_DELETE, function* onDeleteUserGroupRelationSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const { payload: { userId, id } } = action

    try {
      yield call(axios, {
        url: `${serviceEndpoints.users}/${userId}/user-groups/relations/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(fetchUserGroupRelations(userId))

    const user = yield select(getUser)
    const relation = yield select(getUserGroupRelation, { id })

    yield put(addNotification({
      title: `${getFullName(user)} can no longer reach group ${getLocalisedLabelString(relation.userGroup.label)}`,
      actions: [{
        key: 'undo',
        dispatchOnClick: createUserGroupRelation(userId, relation.userGroup.id, relation.bidirectional),
        children: 'Undo',
      }],
    }))

    acceptAction(action)
  })
}

export default onDeleteUserGroupRelation

import React from 'react'
import ImporterMigrateUser from './ImporterMigrateUser'
import { ImporterUser, UserStatus } from '../../../../modules/importerSwitch/types'

interface ImporterMigrateUserStatus {
  customerId: string
  userStatus: UserStatus
  onAddUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
  onRemoveUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
  onUnmatchedExplicit: (user: ImporterUser) => void
  onSearchUser: () => void
}
export default function ImporterMigrateUserStatus({
  userStatus,
  onRemoveUser,
  onAddUser,
  onUnmatchedExplicit,
  customerId,
  onSearchUser,
}: ImporterMigrateUserStatus) {
  const isLeftEditable = !userStatus.from && !!userStatus.to

  return (
    <div
      key={`${userStatus?.from?.id ?? ''} - ${userStatus?.to?.id ?? ''}`}
      className="grid grid-cols-6 gap-4 justify-between items-center"
    >
      <div className="col-span-3 h-full min-h-[78px]">
        <ImporterMigrateUser
          customerId={customerId}
          user={userStatus.from}
          status={userStatus.status}
          possibleMatches={userStatus.possibleMatches}
          onAddUser={(user: ImporterUser) => onAddUser(user, userStatus.to)}
          onUnmatchedExplicit={() => onUnmatchedExplicit(userStatus.to)}
          onSearch={isLeftEditable ? () => { onSearchUser() } : undefined}
        />
      </div>

      <div className="col-span-3 h-full min-h-[78px]">
        <ImporterMigrateUser
          customerId={customerId}
          user={userStatus.to}
          status={userStatus.status}
          possibleMatches={userStatus.possibleMatches}
          onRemoveUser={!isLeftEditable ? () => {
            onRemoveUser(userStatus.from, userStatus.to)
          } : undefined}
          onAddUser={(user: ImporterUser) => onAddUser(userStatus.from, user)}
          onUnmatchedExplicit={() => onUnmatchedExplicit(userStatus.from)}
          onSearch={!isLeftEditable ? () => { onSearchUser() } : undefined}
        />
      </div>
    </div>
  )
}

import isDevelopment from '@Infowijs-eng/component-library/modules/isDevelopment'
import isStaging from '@Infowijs-eng/component-library/modules/isStaging'

export default () => {
  if (isDevelopment) {
    return 'development'
  }
  if (isStaging) {
    return 'staging'
  }
  return 'production'
}

import { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import PropTypes from 'prop-types'
import {
  FormCard,
  FormField,
} from '@Infowijs-eng/component-library/components'
import fetchAdministratorPermissions from '../../actions/administratorPermissions/fetchAdministratorPermissions'
import getAdministratorPermissionsDisabledDependencies
  from '../../selectors/administratorPermissions/getAdministratorPermissionsDisabledDependencies'
import getRecursiveAdministratorPermissions
  from '../../selectors/administratorPermissions/getRecursiveAdministratorPermissions'

function AdministratorPermissionsFormCard({
  loadingAccessToken,
  onChange,
  value: permissions,
  userId,
  administrator,
}) {
  const dispatch = useDispatch()
  const administratorPermissions = useSelector(getRecursiveAdministratorPermissions)
  const administratorPermissionsDisabledDependencies = useSelector(getAdministratorPermissionsDisabledDependencies)

  useEffect(() => {
    if ((!administratorPermissions || administratorPermissions.length === 0) && !loadingAccessToken) {
      dispatch(fetchAdministratorPermissions())
    }
  }, [administratorPermissions, loadingAccessToken])

  const updatePermission = (permission, state) => {
    if (state) {
      onChange([...new Set([
        permission.id,
        ...permission.permissionDependencies,
        ...permissions,
      ])])
    } else {
      onChange([...new Set([
        ...permissions.filter((item) => ![
          ...(administratorPermissionsDisabledDependencies[permission.id] || []),
          permission.id,
        ].includes(item)),
      ])])
    }
  }

  return (
    <FormCard
      label="Permissions"
      hint="Allow permissions to an Administrator so they will be able to access sensitive data"
    >
      {administratorPermissions && administratorPermissions.map((administratorPermissionGroup) => (
        <fieldset key={administratorPermissionGroup.label} className="col-span-3 space-y-3">
          <legend className="text-base font-medium text-gray-900">{administratorPermissionGroup.label}</legend>
          {administratorPermissionGroup.permissions.map((administratorPermission) => (
            <FormField
              key={administratorPermission.id}
              name={`${administratorPermissionGroup.label}${administratorPermission.label}`}
              type="toggle"
              onChange={(state) => updatePermission(administratorPermission, state)}
              disabled={(
                userId
                && administrator
                && administratorPermission.disableSelfEdit
                && userId === administrator.id
              )}
              inputProps={{
                enabled: permissions.includes(administratorPermission.id),
                label: administratorPermission.label,
              }}
            />
          ))}
        </fieldset>
      ))}
    </FormCard>
  )
}

AdministratorPermissionsFormCard.propTypes = {
  loadingAccessToken: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  userId: PropTypes.string,
  administrator: PropTypes.shape(),
}

AdministratorPermissionsFormCard.defaultProps = {
  loadingAccessToken: false,
  value: [],
  userId: null,
  administrator: null,
}

export default AdministratorPermissionsFormCard

import { connect } from 'react-redux'
import NotificationCenter from './NotificationCenter'
import getNotifications from '../../selectors/notificationCenter/getNotifications'
import setNotificationCenterHovered from '../../actions/notificationCenter/setNotificationCenterHovered'

const stateToProps = (state) => ({
  notifications: getNotifications(state),
})

const dispatchToProps = {
  setNotificationCenterHovered,
}

export default connect(stateToProps, dispatchToProps)(NotificationCenter)

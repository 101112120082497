import dayjs from 'dayjs'
import React from 'react'
import TextInputField from './TextInputField'

type DateTimeFieldProps = {
  value: string
  textInputPlaceholder?: string
  buttonPlaceholder?: string
  onChange?: (change: string) => void
  readOnly?: boolean
  required?: boolean
}

function DateTimeField({
  value,
  textInputPlaceholder = null,
  buttonPlaceholder = null,
  onChange = null,
  readOnly = false,
  required = false,
}: DateTimeFieldProps) {
  const textValue = dayjs(value)
    .format('D MMMM YYYY [at] HH:mm')

  return (
    <TextInputField
      value={textValue}
      textInputPlaceholder={textInputPlaceholder}
      buttonPlaceholder={buttonPlaceholder}
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    />
  )
}

export default DateTimeField

import dayjs from 'dayjs'
import { createSelector } from 'reselect'
import getImporters from './getImporters'

export default function makeGetImportersByCustomerId() {
  return createSelector(
    getImporters,
    (_, props) => props.customerId,
    (importers, customerId) => {
      if (!customerId) {
        return []
      }

      return importers.filter(((importer) => importer.customer.id === customerId))
        .sort((a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix())
    },
  )
}

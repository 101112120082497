import {
  Button,
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { XMarkCircle } from '@Infowijs-eng/component-library/icons'
import {
  getHoyClientForUserPermissions,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import getBruggerAdminClientAddress from '@Infowijs-eng/component-library/modules/getBruggerAdminClientAddress'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useParams } from 'react-router-dom'
import addNotification from '../../actions/notificationCenter/addNotification'
import fetchUser from '../../actions/user/fetchUser'
import initiateImpersonation from '../../actions/user/initiateImpersonation'
import revokeUserAccess from '../../actions/user/revokeUserAccess'
import getCustomerProductTypeLabel from '../../modules/getCustomerProductTypeLabel'
import getFullName from '../../modules/getFullName'
import getCustomerProductsData from '../../selectors/customerProducts/getCustomerProductsData'
import getUser from '../../selectors/user/getUser'
import UserCustomerProductKeyAccessModal from './UserCustomerProductKeyAccessModal'

function UserCustomerProductKeyAccessOptions({
  userCustomerProductKey,
}) {
  const [revokeDisabled, setRevokeDisabled] = useState(false)
  const [transferTokenForModal, setTransferTokenForModal] = useState(null)
  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const user = useSelector(getUser)

  const allCustomerProducts = useSelector(getCustomerProductsData)

  const product = allCustomerProducts
    && allCustomerProducts[userCustomerProductKey.customerProductId]

  const loadUser = () => promisifyDispatch(fetchUser(userId, ['userCustomerProductKey']))

  return (
    <>
      <Visibility permissions="users.customerProductKey.impersonate">
        {transferTokenForModal
          && (
            <UserCustomerProductKeyAccessModal
              transferToken={transferTokenForModal}
              onRefresh={() => {
                promisifyDispatch(initiateImpersonation(userCustomerProductKey.id))
                  .then((response) => {
                    setTransferTokenForModal(response.transferToken)
                  })
                  .catch(() => {
                  })
              }}
              onClose={() => setTransferTokenForModal(null)}
            />
          )}
        <Dropdown
          buttonProps={{
            neutral: true,
            small: true,
            disabled: !product || !!product.deletedAt || !product.deployedAt || product.type !== 'hoy',
            title: !!product.deletedAt || !product.deployedAt
              ? 'Cannot sign user into Customer Product since it\'s either deleted or not deployed.'
              : undefined,
          }}
          label="Login as user"
        >
          {product.type === 'hoy' && (
            <>
              <DropdownItem
                small
                onClick={() => {
                  promisifyDispatch(initiateImpersonation(userCustomerProductKey.id))
                    .then((response) => {
                      const hoyClient = getHoyClientForUserPermissions(product.name, response.permissions ?? [])
                      window.open(`${hoyClient}?token=${response.transferToken}`)
                    })
                    .catch(() => {
                    })
                }}
              >
                Hoy web/admin
              </DropdownItem>
              <DropdownItem
                small
                onClick={() => {
                  promisifyDispatch(initiateImpersonation(userCustomerProductKey.id))
                    .then((response) => {
                      setTransferTokenForModal(response.transferToken)
                    })
                    .catch(() => {
                    })
                }}
              >
                Hoy app
              </DropdownItem>
            </>
          )}
          {product.type === 'brugger' && (
            <DropdownItem
              small
              onClick={() => {
                promisifyDispatch(initiateImpersonation(userCustomerProductKey.id))
                  .then((response) => {
                    const bruggerClient = getBruggerAdminClientAddress(product.name)
                    window.open(`${bruggerClient}?token=${response.transferToken}`)
                  })
                  .catch(() => {
                  })
              }}
            >
              Brugger admin
            </DropdownItem>
          )}
        </Dropdown>
      </Visibility>
      <Visibility permissions="users.invite">
        <Button
          small
          neutral
          className="text-red-700 hover:text-red-800"
          title="Revoke user from Customer Product"
          disabled={revokeDisabled}
          leadingAddon={<XMarkCircle />}
          onClick={() => {
            // eslint-disable-next-line no-alert
            if (window.confirm('This user would have to be invited again to regain access. '
              + 'Are you sure you want to continue?')) {
              setRevokeDisabled(true)
              promisifyDispatch(revokeUserAccess(userId, userCustomerProductKey.id))
                .then(() => {
                  loadUser()
                  setRevokeDisabled(false)
                  dispatch(addNotification({
                  // eslint-disable-next-line max-len
                    title: `${getFullName(user)}'s access has been revoked from ${getCustomerProductTypeLabel(product.type)} (${product.title})`,
                  }))
                })
            }
          }}
        >
          Revoke
        </Button>
      </Visibility>
    </>
  )
}

UserCustomerProductKeyAccessOptions.propTypes = {
  userCustomerProductKey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerProductId: PropTypes.string.isRequired,
  }).isRequired,
}

UserCustomerProductKeyAccessOptions.defaultProps = {}

export default UserCustomerProductKeyAccessOptions

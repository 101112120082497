import {
  Alert,
  Dropdown,
  DropdownDivider,
  DropdownItem,
  Heading,
} from '@Infowijs-eng/component-library/components'
import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import {
  ArrowsLeftAndRight,
  Ellipsis,
  PlusSquare,
} from '@Infowijs-eng/component-library/icons'
import { serviceEndpoints, usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import dayjs from 'dayjs'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Route,
  Routes,
  useParams,
} from 'react-router-dom'
import fetchImporters from '../../actions/importers/fetchImporters'
import addNotification from '../../actions/notificationCenter/addNotification'
import makeGetImportersByCustomerId from '../../selectors/importers/makeGetImportersByCustomerId'
import List from '../List'
import ListItem from '../ListItem'
import Modal from '../Modal'
import SectionActions from '../SectionActions'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'
import { STATUS_SUCCESS } from '../StatusIcon'
import AutomaticImportToggleDropdownItem from './AutomaticImportToggleDropdownItem'
import ImporterCreateModal from './ImporterCreateModal'
import ImporterListItem from './ImporterListItem'
import ImporterListItemSkeleton from './ImporterListItemSkeleton'
import ImporterManualUpload from './ImporterManualUpload'
import ManualImportDropdownItem from './ManualImportDropdownItem'

function ImporterSection() {
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const { customerId } = useParams()
  const [openMagisterImporter, setOpenMagisterImporter] = useState(null)
  const promisifyAction = usePromisifyDispatch()
  const authenticatedRequest = useAuthenticatedRequest()
  const dispatch = useDispatch()
  const [actionsDisabled, setActionsDisabled] = useState(false)

  const getImportersByCustomerId = useMemo(
    () => makeGetImportersByCustomerId(),
    [],
  )
  const importers = useSelector((state) => getImportersByCustomerId(
    state,
    { customerId },
  ))

  const labelCounter = []

  const importerSectionActions = useMemo(() => {
    const actions = [
      {
        neutral: true,
        children: 'Add Importer',
        leadingAddon: <PlusSquare />,
        permissions: ['importers.create'],
        key: 'add.importer',
        to: 'importers/create',
      },
    ]

    if (importers.length > 1) {
      actions.push({
        neutral: true,
        children: 'Migrate Importers',
        leadingAddon: <ArrowsLeftAndRight />,
        permissions: ['importers.migrate'],
        key: 'migrate.importer',
        to: 'importers/migrate',
      })
    }

    return actions
  }, [importers])

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyAction(fetchImporters({ filter: { customerId: `eq:${customerId}` } }))
      .catch(setErrorMessages)
      .finally(() => setLoading(false))
  }, [fetchImporters, customerId])

  return (
    <>
      <Routes>
        <Route
          path="/importers/create/*"
          element={<ImporterCreateModal />}
        />
      </Routes>
      <section>
        <SectionTitle
          trailingAddon={(
            <SectionActions
              actions={importerSectionActions}
            />
          )}
        >
          Importers
        </SectionTitle>
        <SectionBody>
          {errorMessages && <Alert errorMessages={errorMessages} />}
          <List
            isLoading={!importers}
            placeholder="No importers found for this customer"
            loadingMessage="Loading importers"
          >
            {!!loading && <ImporterListItemSkeleton />}
            {!loading && (!importers || !importers.length) && (
              <ListItem className="text-gray-500 italic">No importers found</ListItem>
            )}
            {importers.sort((a, b) => dayjs(a.createdAt)
              .unix() - dayjs(b.createdAt)
              .unix())
              .map((importer) => {
                if (labelCounter[importer.type]) {
                  labelCounter[importer.type] += 1
                } else {
                  labelCounter[importer.type] = 1
                }
                const configured = !!(importer.metadata && importer.metadata.config && importer.metadata.config.done)
                return (
                  <ImporterListItem
                    key={importer.id}
                    id={importer.id}
                    label={
                    `${importer.type}${labelCounter[importer.type] > 1 ? ` ${labelCounter[importer.type]}` : ''}`
                    }
                    active={importer.active}
                    configured={configured}
                    currentlyRunning={importer.currentlyRunning}
                    lastRunStartAt={importer.lastRunStartAt}
                    lastRunEndAt={importer.lastRunEndAt}
                    lastRunError={importer.lastRunError}
                    lastUpdatedBy={importer.lastUpdatedBy}
                    nextRunAt={importer.nextRunAt}
                    customer={{ id: customerId }}
                    onError={(e) => setErrorMessages(e)}
                    trailingAddon={(
                      <Visibility key="importer.options" permissionsWithRegex="importers\.(run\.manual|create)">
                        <Dropdown
                          disabled={actionsDisabled}
                          icon={<Ellipsis />}
                          alignRight
                          buttonProps={{
                            small: true,
                            neutral: true,
                            className: 'hover:bg-gray-100 hover:text-black',
                          }}
                        >
                          {!configured && (
                            <Visibility permissions={['importers.create']}>
                              <DropdownItem small to={`importers/create/${importer.type}/${importer.id}`}>
                                Continue config
                              </DropdownItem>
                              {
                                !importer.lastRunStartAt && (
                                  <>
                                    <DropdownDivider />
                                    <DropdownItem
                                      className="text-red-500"
                                      small
                                      onClick={async () => {
                                        setActionsDisabled(true)
                                        try {
                                          await authenticatedRequest(
                                            `${serviceEndpoints.importers}/${importer.id}`,
                                            {
                                              method: 'delete',
                                            },
                                          )

                                          dispatch(addNotification({
                                            title: 'Successfully deleted importer',
                                            status: STATUS_SUCCESS,
                                          }))
                                        } catch (e) {
                                          // no-op
                                        }

                                        dispatch(fetchImporters(
                                          { filter: { customerId: `eq:${customerId}` } },
                                        ))
                                        setActionsDisabled(false)
                                      }}
                                    >
                                      Delete importer
                                    </DropdownItem>
                                  </>
                                )
                              }
                            </Visibility>
                          )}
                          {configured && (
                            <>
                              <Visibility permissions={['importers.create']}>
                                <AutomaticImportToggleDropdownItem importerId={importer.id} />
                              </Visibility>
                              <Visibility permissions={['importers.run.manual', 'importers.create']}>
                                <DropdownDivider />
                              </Visibility>
                              <Visibility permissions={['importers.run.manual']}>
                                <ManualImportDropdownItem importerId={importer.id} />
                                {importer.type === 'magister' && (
                                  <DropdownItem small onClick={() => setOpenMagisterImporter(importer.id)}>
                                    Import from Magister CSV
                                  </DropdownItem>
                                )}
                              </Visibility>
                              <Visibility permissions={['importers.run.manual', 'importers.create']}>
                                <DropdownDivider />
                              </Visibility>
                            </>
                          )}
                        </Dropdown>
                      </Visibility>
                    )}
                  />
                )
              })}
          </List>
          <Visibility permissions={['importers.run.manual']}>
            {!!openMagisterImporter && (
              <Modal className="w-1/2 h-auto" onClose={() => setOpenMagisterImporter(null)}>
                <Heading fontSize={Heading.size.H3}>Upload Magister CSV</Heading>
                <ImporterManualUpload importerId={openMagisterImporter} />
              </Modal>
            )}
          </Visibility>
        </SectionBody>
      </section>
    </>
  )
}

export default ImporterSection

export default (user) => {
  if (!user) {
    return ''
  }

  const {
    firstName,
    lastName,
    lastNamePrefix,
    initials,
  } = user

  let formattedLastName = `${lastNamePrefix || ''} ${lastName}`.trim()

  if (!lastName && !lastNamePrefix) {
    formattedLastName = ''
  }

  const fullName = []

  if (firstName) {
    fullName.push(firstName)
  }

  if (initials) {
    fullName.push(firstName ? `(${initials})` : initials)
  }

  fullName.push(formattedLastName)

  return fullName.join(' ')
}

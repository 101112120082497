export default (id, userGroups, userGroupBadges = []) => {
  const ids = [id]

  while (userGroups[ids[ids.length - 1]].parentUserGroup) {
    ids.push(userGroups[ids[ids.length - 1]].parentUserGroup.id)
  }

  let { userGroupBadge } = userGroups[ids[ids.length - 1]]
  if (userGroupBadge && userGroupBadge.type) {
    userGroupBadge = userGroupBadges.find((badge) => badge.id === userGroupBadge.id)
  }

  const path = (userGroupBadge)
    ? [userGroupBadge]
    : []

  for (let i = ids.length - 1; i > 0; i -= 1) {
    path.push(userGroups[ids[i]])
  }

  return path
}

import { useState } from 'react'

export default () => {
  const [selectedLocations, setSelectedLocations] = useState({})

  function editLocation({ id, name }) {
    setSelectedLocations((prev) => ({
      ...prev,
      [id]: name,
    }))
  }

  function toggleLocation({ id, name }) {
    setSelectedLocations((prev) => {
      let newState = { ...prev }
      if (prev[id]) {
        delete newState[id]
      } else {
        newState = { ...newState, [id]: name }
      }
      return newState
    })
  }

  return [selectedLocations, editLocation, toggleLocation, setSelectedLocations]
}

import {
  ENUM,
  LIST,
  OBJECT,
  OBJECT_YEAR_RANGE,
} from '@Infowijs-eng/component-library/modules/metadataStructureTypes'

const filterMetadata = (structure, obj, preserveLevels = 0) => {
  switch (structure.type) {
    case OBJECT: {
      const newObj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (structure.optional && key in structure.optional) {
            if (structure.optional[key] && structure.optional[key].readOnly) {
              return []
            }

            return [key, filterMetadata(structure.optional, value, preserveLevels - 1)]
          }
          if (structure.required && key in structure.required) {
            if (structure.required[key] && structure.required[key].readOnly) {
              return []
            }

            return [key, filterMetadata(structure.required, value, preserveLevels - 1)]
          }
          return [key, null]
        }),
      )

      if (preserveLevels > 0 || Object.values(newObj).some((value) => value != null)) {
        return newObj
      }

      return null
    }
    case LIST: {
      return obj.flatMap((entry) => {
        const newEntry = filterMetadata(structure.items, entry, preserveLevels - 1)
        if (!newEntry) {
          return []
        }
        if (Object.keys(newEntry).length) {
          return [newEntry]
        }
        return []
      })
    }
    case OBJECT_YEAR_RANGE: {
      const newObj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => {
          if (!key.match(/^\d{4}-\d{4}$/)) {
            return [key, null]
          }
          return [key, filterMetadata(structure.data, value, preserveLevels - 1)]
        }),
      )

      if (preserveLevels > 0 || Object.values(newObj).some((value) => value != null)) {
        return newObj
      }

      return null
    }
    case ENUM: {
      if (structure.values.includes(obj)) {
        return obj
      }
      return null
    }
    default:
      return obj
  }
}

export default filterMetadata

import StackedTableRecord from '@Infowijs-eng/component-library/components/StackedTableRecord'
import {
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  getLocalisedLabelString,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import getUserGroupBadges from '../../selectors/userGroups/getUserGroupBadges'
import fetchUserGroupBadges from '../../actions/userGroups/fetchUserGroupBadges'

function UserGroupBadges({ onClick }) {
  const promisifyDispatch = usePromisifyDispatch()
  const { customerProductId } = useParams()
  const userGroupBadges = useSelector((state) => getUserGroupBadges(state, { customerProductId }))
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!loading && !userGroupBadges) {
      setLoading(true)
      promisifyDispatch(fetchUserGroupBadges(customerProductId))
        .then(() => setLoading(false))
    }
  }, [customerProductId])

  return userGroupBadges.map((userGroupBadge) => (
    <StackedTableRecord
      key={userGroupBadge.id}
      onClick={() => onClick(userGroupBadge)}
    >
      <div className="h-8 flex justify-center">
        <div className="w-full flex flex-wrap justify-start items-center text-black font-bold font-display select-none">
          {getLocalisedLabelString(userGroupBadge.labelI18n)}
        </div>
      </div>
    </StackedTableRecord>
  ))
}

UserGroupBadges.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default UserGroupBadges

import actionTypes from '../../action-types'

export default (
  customerId,
  meta,
) => ({
  type: actionTypes.USER_ROLES.FETCH_ALL,
  payload: {
    customerId,
  },
  meta,
})

import { DropdownItem } from '@Infowijs-eng/component-library/components'
import usePromisifyDispatch from '@Infowijs-eng/component-library/modules/usePromisifyDispatch'
import PropTypes from 'prop-types'
import {
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import runManualImport from '../../actions/importers/runManualImport'
import makeGetImporterById from '../../selectors/importers/makeGetImporterById'

function ManualImportDropdownItem({ importerId }) {
  const getImporterById = useMemo(
    () => makeGetImporterById(),
    [],
  )

  const importer = useSelector((state) => getImporterById(
    state,
    { id: importerId },
  ))

  const promisifyDispatch = usePromisifyDispatch()

  const [isLoading, setLoading] = useState(false)
  return (
    <DropdownItem
      small
      className="inline-block"
      disabled={isLoading || (importer && importer.currentlyRunning)}
      onClick={() => {
        setLoading(true)
        promisifyDispatch(runManualImport({ importerId }))
          .finally(() => {
            setLoading(false)
          })
      }}
    >
      Run import
    </DropdownItem>
  )
}

ManualImportDropdownItem.propTypes = {
  importerId: PropTypes.string.isRequired,
}

export default ManualImportDropdownItem

import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Heading } from '@Infowijs-eng/component-library/components'
import { useDispatch, useSelector } from 'react-redux'
import ImporterMigrateImporterSelect from '../components/Importer/Migrate/ImporterMigrateImporterSelect'
import ImporterMigrateMatching from '../components/Importer/Migrate/ImporterMigrateMatching'
import ImporterMigrateOverview from '../components/Importer/Migrate/ImporterMigrateOverview'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import useFetchImporterUsers from '../modules/importerSwitch/useFetchImporterUsers'
import fetchImporters from '../actions/importers/fetchImporters'
import makeGetImportersByCustomerId from '../selectors/importers/makeGetImportersByCustomerId'
import createInitialUserStatusList from '../modules/importerSwitch/createInitialUserStatusList'
import StepIndicator from '../components/StepIndicator'
import getSortedPossibleMatches from '../modules/importerSwitch/getSortedPossiblesMatches'
import clearPossibleMatchesForUser from '../modules/importerSwitch/clearPossibleMatchesForUser'
import usePostSwitchImporterUsers from '../modules/importerSwitch/usePostSwitchImporterUsers'
import getUniqueMatches from '../modules/importerSwitch/getUniqueMatches'
import {
  ImporterUser, MatchStatus, UserRole, UserStatus,
} from '../modules/importerSwitch/types'
import { STATUS_ERROR } from '../components/StatusIcon'
import addNotification from '../actions/notificationCenter/addNotification'
import ImporterMigrateSuccess from '../components/Importer/Migrate/ImporterMigrateSuccess'

export default function ImporterMigrateScreen() {
  const navigate = useNavigate()
  const getImportersByCustomerId = useMemo(makeGetImportersByCustomerId, [])
  const [currentStep, setCurrentStep] = useState(1)
  const totalSteps = 4
  const { customerId } = useParams()
  const fetchImporterUsers = useFetchImporterUsers()
  const postSwitchImporterUsers = usePostSwitchImporterUsers()
  const dispatch = useDispatch()

  const [oldImporterId, setOldImporterId] = useState(null)
  const [newImporterId, setNewImporterId] = useState(null)

  const [oldImporterUsers, setOldImporterUsers] = useState<{ [id: string]: ImporterUser }>({})
  const [newImporterUsers, setNewImporterUsers] = useState<{ [id: string]: ImporterUser }>({})
  const [userRoles, setUserRoles] = useState<{ [id: string]: UserRole }>({})
  const [userStatuses, setUserStatuses] = useState<{ [id: string]: UserStatus }>({})
  const [hasConfirmedChanges, setHasConfirmedChanges] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [loadingMessage, setLoadingMessage] = useState('')
  const [userListProgress, setUserListProgress] = useState(0)

  let isNextAvailable = false
  if (currentStep === 1) {
    isNextAvailable = !!oldImporterId && !!newImporterId && !isLoading
  } else if (currentStep === 2) {
    isNextAvailable = Object.values(userStatuses).every((user) => user.status !== MatchStatus.ImplicitUnmatch)
  } else if (currentStep === 3) {
    isNextAvailable = hasConfirmedChanges && !isLoading
  }

  const onRemoveUser = (fromUser: ImporterUser, toUser: ImporterUser) => {
    const fromUserStatus = userStatuses[fromUser.id]
    if (!fromUserStatus) {
      throw Error("Couldn't find user status")
    }
    const toUserStatus = userStatuses[toUser.id]
    if (!toUserStatus) {
      throw Error("Couldn't find user status")
    }
    fromUserStatus.status = MatchStatus.ImplicitUnmatch
    toUserStatus.status = MatchStatus.ImplicitUnmatch
    fromUserStatus.to = undefined
    toUserStatus.from = undefined

    fromUserStatus.possibleMatches = getSortedPossibleMatches(userStatuses, fromUserStatus, userRoles, newImporterUsers)
    toUserStatus.possibleMatches = getSortedPossibleMatches(userStatuses, toUserStatus, userRoles, oldImporterUsers)

    // Make sure that react reloads state
    setUserStatuses(
      {
        ...userStatuses,
      },
    )
  }

  const onUnmatchedExplicit = (user: ImporterUser) => {
    const userStatus = userStatuses[user?.id]
    if (userStatus) {
      if (userStatus.status !== MatchStatus.ImplicitUnmatch) {
        throw Error('Only unmatched users may be marked explicitly unmatched')
      }
      userStatus.status = MatchStatus.ExplicitUnmatch
      clearPossibleMatchesForUser(userStatuses, user)
    }

    setUserStatuses(
      {
        ...userStatuses,
      },
    )
  }

  const onAddUser = (fromUser: ImporterUser, toUser: ImporterUser) => {
    const fromUserStatus = userStatuses[fromUser.id]
    if (!fromUserStatus) {
      throw Error("Couldn't find user status")
    }
    const toUserStatus = userStatuses[toUser.id]
    if (!toUserStatus) {
      throw Error("Couldn't find user status")
    }

    clearPossibleMatchesForUser(userStatuses, fromUser)
    clearPossibleMatchesForUser(userStatuses, toUser)

    fromUserStatus.status = MatchStatus.ExplicitMatch
    fromUserStatus.to = toUser

    toUserStatus.status = MatchStatus.ExplicitMatch
    toUserStatus.from = fromUser

    // Make sure that react reloads state
    setUserStatuses(
      {
        ...userStatuses,
      },
    )
  }

  async function checkMatchesAndProceed() {
    setIsLoading(true)
    const userIdsToMigrate = []
    const userIdsToIgnore = []

    const totalUsers = Object.keys(oldImporterUsers).length + Object.keys(newImporterUsers).length

    const uniqueMatches = getUniqueMatches(userStatuses)
    uniqueMatches.forEach((uniqueMatch) => {
      if (uniqueMatch?.to?.id === uniqueMatch?.from?.id) {
        dispatch(addNotification({
          title: 'Received userStatus with either a duplicate users, or no users',
          status: STATUS_ERROR,
        }))

        setIsLoading(false)
        throw new Error('Received userStatus with either a duplicate users, or no users')
      }

      if (uniqueMatch.to && uniqueMatch.from) {
        userIdsToMigrate.push({
          fromUserId: uniqueMatch.from.id,
          toUserId: uniqueMatch.to.id,
        })
        return
      }

      userIdsToIgnore.push(uniqueMatch?.to?.id ?? uniqueMatch?.from?.id)
    })

    const idsToConsider = (userIdsToMigrate.length * 2) + userIdsToIgnore.length

    if (totalUsers !== idsToConsider) {
      dispatch(addNotification({
        title: 'Amount of users does not match the ids to consider',
        status: STATUS_ERROR,
      }))
      setIsLoading(false)
      throw Error('Amount of users does not match the ids to consider')
    }

    setCurrentStep(4)
    setIsLoading(false)

    try {
      await postSwitchImporterUsers(oldImporterId, newImporterId, userIdsToMigrate, userIdsToIgnore)
    } catch (e) {
      setIsLoading(false)
      throw Error('Something went wrong on the backend. Please contact a developer.')
    }
  }

  const onNext = async () => {
    if (currentStep === 1) {
      setIsLoading(true)
      setLoadingMessage('Preparing the user matching data. '
        + 'This may take a few minutes to process.')
      fetchImporterUsers(customerId, oldImporterId, newImporterId).then((res) => {
        setOldImporterUsers(res.oldImporterUsers)
        setNewImporterUsers(res.newImporterUsers)
        setUserRoles(res.userRoles)
        createInitialUserStatusList(
          res.userRoles,
          res.oldImporterUsers,
          res.newImporterUsers,
          (progress) => {
            setUserListProgress(progress)
          },
        ).then((userStatusValues) => {
          setUserStatuses(userStatusValues)
          setCurrentStep(2)
          setIsLoading(false)
        })
      })
      // fetch remote importer data and pre-process the user list
    } else if (currentStep === 2) {
      setCurrentStep(3)
    } else if (currentStep === 3) {
      setLoadingMessage('Validating the data locally...')
      checkMatchesAndProceed()
    }
  }

  const importers = useSelector((state) => getImportersByCustomerId(
    state,
    { customerId },
  ))
  useEffect(() => {
    // @ts-expect-error Method does not support typescript typing
    dispatch(fetchImporters({ filter: { customerId: `eq:${customerId}` } }))
  }, [customerId])

  let importerSwitchStep
  let headerTitle = 'Importer Switch'
  switch (currentStep) {
    case 1:
      importerSwitchStep = (
        <ImporterMigrateImporterSelect
          onOldImporterIdChange={(id) => {
            setOldImporterId(id)
          }}
          onNewImporterIdChange={(id) => {
            setNewImporterId(id)
          }}
          importers={importers}
          oldImporterId={oldImporterId}
          newImporterId={newImporterId}
        />
      )
      headerTitle = 'Select Importers'
      break
    case 2:
      importerSwitchStep = (
        <ImporterMigrateMatching
          oldImporterUsers={oldImporterUsers}
          newImporterUsers={newImporterUsers}
          userStatuses={userStatuses}
          onAddUser={onAddUser}
          onRemoveUser={onRemoveUser}
          onUnmatchedExplicit={onUnmatchedExplicit}
          userRoles={userRoles}
        />
      )
      headerTitle = 'Match Users'
      break
    case 3:
      importerSwitchStep = (
        <ImporterMigrateOverview
          userRoles={userRoles}
          userStatuses={userStatuses}
          onConfirmChanges={setHasConfirmedChanges}
          hasConfirmedChanges={hasConfirmedChanges}
        />
      )
      headerTitle = 'Overview'
      break
    case 4:
      importerSwitchStep = (
        <ImporterMigrateSuccess
          customerId={customerId}
        />
      )
      headerTitle = 'Migration in Progress'
      break
    default:
      importerSwitchStep = null
  }

  return (
    <Layout contentClassName="h-full">
      <PageHeader>
        <div className="flex flex-row justify-between">
          <Heading fontSize={Heading.size.H3}>{headerTitle}</Heading>
          {
            currentStep !== totalSteps && (
              <div className="flex flex-row gap-2 items-center">
                <Button
                  onClick={() => {
                    // eslint-disable-next-line no-alert
                    if (window.confirm('Are you sure you want to leave this page? All changes made will be lost.')) {
                      navigate(`/customers/${customerId}`, { replace: true })
                    }
                  }}
                  small
                  neutral
                >
                  Cancel
                </Button>
                <Button
                  disabled={currentStep === 1 || isLoading}
                  onClick={() => {
                    if (currentStep === 2
                      // eslint-disable-next-line no-alert
                      && !window.confirm('Are you sure you want to go back? All changes made will be lost.')) {
                      return
                    }
                    setCurrentStep(() => Math.max(1, currentStep - 1))
                  }}
                >
                  Previous
                </Button>
                <Button
                  disabled={!isNextAvailable}
                  onClick={onNext}
                  primary={totalSteps === currentStep}
                >
                  {currentStep === 3 ? 'Confirm' : 'Next'}
                </Button>
              </div>
            )
          }
        </div>
        <div className="mt-4">
          <StepIndicator total={totalSteps} current={currentStep} />
        </div>
      </PageHeader>
      {
        isLoading && (
          <>
            <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
              <div
                className="bg-blue-600 h-2.5 rounded-full"
                style={{ width: `${Math.floor(userListProgress * 100)}%` }}
              />
            </div>
            <div>{loadingMessage}</div>

          </>

        )
      }
      {
        !isLoading && (
          importerSwitchStep
        )
      }
    </Layout>
  )
}

import {
  Button,
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { Ellipsis } from '@Infowijs-eng/component-library/icons'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import React from 'react'
import actionPropTypes from '@Infowijs-eng/component-library/prop-types/actionsPropTypes'

function ListItemActions({ actions }) {
  let singleAction = null

  if (actions.length === 1) {
    const {
      key,
      permissions,
      permissionsWithRegex,
      children: _,
      ...actionButtonProps
    } = actions[0]
    singleAction = {
      key,
      permissions,
      permissionsWithRegex,
      actionButtonProps,
    }
  }

  return (
    <>
      {/* Desktop Actions */}
      <div className="hidden xl:(flex)">
        <div className="space-x-2 flex opacity-0 w-0 group-hover:(opacity-100 w-full)">
          {actions.length > 0 && actions.map(({
            key, permissions, permissionsWithRegex, children: _, ...action
          }) => (
            <Visibility
              key={key}
              permissions={permissions}
              permissionsWithRegex={permissionsWithRegex}
            >
              <Button
                small
                neutral
                {...action /* eslint-disable-line react/jsx-props-no-spreading */}
                extraCss={['hover:(bg-gray-100 text-black)', action.extraCss]}
              />
            </Visibility>
          ))}
        </div>
      </div>
      {/* Mobile actions */}
      <div className="xl:(hidden)">
        {actions.length > 1 && (
          <Dropdown
            icon={<Ellipsis />}
            buttonProps={{
              small: true,
              neutral: true,
              extraCss: 'hover:(bg-gray-100 text-black)',
            }}
            alignRight
          >
            {actions.map(({
              permissions, permissionsWithRegex, key, ...action
            }) => (
              <Visibility key={key} permissions={permissions} permissionsWithRegex={permissionsWithRegex}>
                <DropdownItem
                  {...action /* eslint-disable-line react/jsx-props-no-spreading */}
                />
              </Visibility>
            ))}
          </Dropdown>
        )}
        {singleAction && (
          <Visibility
            key={singleAction.key}
            permissions={singleAction.permissions}
            permissionsWithRegex={singleAction.permissionsWithRegex}
          >
            <Button
              small
              neutral
              {...singleAction.actionButtonProps /* eslint-disable-line react/jsx-props-no-spreading */}
              extraCss={['hover:(bg-gray-100 text-black)', singleAction.actionButtonProps.extraCss]}
            />
          </Visibility>
        )}
      </div>
    </>
  )
}

ListItemActions.propTypes = {
  actions: actionPropTypes.isRequired,
}

export default ListItemActions

import { Button } from '@Infowijs-eng/component-library/components'
import { XMarkCircle } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function Modal({
  children,
  onClose,
  className,
}) {
  return (
    <div className="absolute top-0 left-0">
      <div
        className="z-20 fixed top-0 left-0 w-screen h-screen flex justify-center items-center overflow-hidden"
      >
        <div
          className={cn(
            'p-8 z-20 relative bg-white shadow border-b border-gray-200 w-screen h-5/6',
            'sm:rounded-lg sm:mx-12 md:mx-24',
            className,
          )}
        >
          {onClose && (
            <div className="absolute -top-12 right-4 sm:-right-2 md:-top-9 md:-right-12">
              <Button
                neutral
                leadingAddon={<XMarkCircle />}
                className="text-white hover:text-gray-100 hover:bg-transparent"
                onClick={onClose}
              />
            </div>
          )}
          <div className="h-full flex flex-col overflow-y-auto">
            {children}
          </div>
        </div>
        <div className="absolute top-0 right-0 bottom-0 left-0 bg-black opacity-50" />
      </div>
    </div>
  )
}

Modal.propTypes = {
  className: cn.propType,
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
}

Modal.defaultProps = {
  className: null,
  onClose: null,
}

export default Modal

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import fetchImporter from '../../actions/importers/fetchImporter'
import runManualImport from '../../actions/importers/runManualImport'
import addNotification from '../../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../../components/StatusIcon'
import awaitAccessToken from '../../modules/awaitAccessToken'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'

function readFile(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = reject
    fileReader.readAsText(file)
  })
}

const onManualImport = function* onManualImport() {
  yield takeLatest(actionTypes.IMPORTERS.MANUAL_IMPORT, function* onManualImportSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const { payload } = action

    let files
    if (payload.files) {
      files = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < payload.files.length; i++) {
        files.push(yield call(readFile, payload.files.item(i)))
      }
    }

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.importers}/${payload.importerId}/manual`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          files,
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reCreateImporter() {
          yield put(runManualImport(payload))
        })
      }

      yield put(fetchImporter(payload.importerId))
      yield put(addNotification({
        title: 'Trying to manually import failed',
        status: STATUS_ERROR,
        errorMessages: e.response.data && e.response.data.errors,
      }, NOTIFICATION_TIMEOUT_LONG))

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(fetchImporter(payload.importerId))
    yield put(addNotification({
      title: 'The importer finished successfully and the users are imported.',
    }))

    acceptAction(action, response.data.data)
  })
}

export default onManualImport

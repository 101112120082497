export default function parseBadgeLabelFromMetadataObject(key, value) {
  switch (typeof value) {
    case 'boolean':
      return value ? key : null
    case 'object':
      if (Array.isArray(value)) {
        return `${value.filter(Boolean).sort().join(', ')}`
      }

      return Object.entries(value)
        .map(([k, v]) => parseBadgeLabelFromMetadataObject(k, v))
        .filter(Boolean)
        .sort()
        .join(', ')
    default:
      return `${key}: ${value}`
  }
}

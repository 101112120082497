import PropTypes from 'prop-types'
import { Button } from '@Infowijs-eng/component-library/components'
import {
  ChevronLeft,
  ChevronRight,
  ChevronVerticalLineLeft,
  ChevronVerticalLineRight,
} from '@Infowijs-eng/component-library/icons'
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import mergeQueryParams from '../modules/mergeQueryParams'

function Pagination({
  current,
  total,
}) {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  return (
    <div className="flex justify-center">
      <div className="flex items-center space-x-1 p-2">
        <Button
          small
          leadingAddon={<ChevronVerticalLineLeft />}
          disabled={current <= 1}
          to={{
            search: mergeQueryParams(searchParams, 'page', 1).toString(),
          }}
        />
        <Button
          small
          leadingAddon={<ChevronLeft />}
          disabled={current <= 1}
          to={{
            search: mergeQueryParams(searchParams, 'page', current - 1).toString(),
          }}
        />
        <div className="px-2 text-sm">
          <input
            size={3}
            value={current}
            className="outline-none text-right bg-transparent"
            onChange={(e) => {
              e.preventDefault()
              e.stopPropagation()
              let value = parseInt(e.target.value.replace(/[^\d]+/g, ''), 10)
              if (value < 1 || !value) {
                value = 1
              }
              if (value > total) {
                value = total
              }

              navigate({
                search: mergeQueryParams(searchParams, 'page', value).toString(),
              })
            }}
          />
          <span className="text-gray-400 mr-6">{` of ${total}`}</span>
        </div>
        <Button
          small
          leadingAddon={<ChevronRight />}
          disabled={current >= total}
          to={{
            search: mergeQueryParams(searchParams, 'page', current + 1).toString(),
          }}
        />
        <Button
          small
          leadingAddon={<ChevronVerticalLineRight />}
          disabled={current >= total}
          to={{
            search: mergeQueryParams(searchParams, 'page', total).toString(),
          }}
        />
      </div>
    </div>
  )
}

Pagination.propTypes = {
  current: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default Pagination

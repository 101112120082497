import { v4 } from 'uuid'
import actionTypes from '../../action-types'
import { NOTIFICATION_TIMEOUT_SHORT } from '../../modules/notificationCenter'

/**
 * Add a notification to the state, so it can be picked up by the Notification Center component
 *
 * @param payload See payloadProps below
 * @param timeout A timeout in microsecconds. Can be any integer,
 *   or use constants NOTIFICATION_TIMEOUT_SHORT or NOTIFICATION_TIMEOUT_LONG
 * @returns a Redux Action
 */
export default (payload, timeout = NOTIFICATION_TIMEOUT_SHORT) => ({
  type: actionTypes.NOTIFICATION_CENTER.ADD,
  payload: {
    ...payload,
    timeout,
    id: payload.id || v4(),
  },
})

/**
 * ## Payload props
 *
 * PropTypes.shape({
 *   // Optional, but will contain a UUID when not given, when an ID is reused, the notification that's already in the
 *   // state will be overwritten.
 *   id: PropTypes.string,
 *   // The main text shown in the notification
 *   title: PropTypes.string.isRequired,
 *   // Optionally, but will be STATUS_SUCCESS from StatusIcon when not given. Status will affect the icon and color of
 *   // the notification.
 *   status: PropTypes.oneOf([STATUS_WARNING, STATUS_ERROR, STATUS_SUCCESS]),
 *   // Every action will render a Button component
 *   actions: PropTypes.arrayOf(PropTypes.shape({
 *     // Pass an unique key
 *     key: PropTypes.string.isRequired,
 *     // The text shown in the action's button
 *     children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]).isRequired,
 *     // Any value that is allowed on React Router Dom's Link[to]. Use either to or dispatchOnClick,
 *     // however both may work
 *     to: PropTypes.string,
 *     // If given, dispatchOnClick should contain an action that will be dispatched to the Redux state onClick of
 *     // the action's Button. Use either to or dispatchOnClick, however both may work
 *     dispatchOnClick: PropTypes.shape({
 *       type: PropTypes.string.isRequired,
 *       // If needed
 *       payload: PropTypes.any,
 *       // If needed
 *       meta: PropTypes.any,
 *     }),
 *   })),
 *   errorMessages: PropTypes.arrayOf(PropTypes.shape({
 *     // The title of the error message
 *     title: PropTypes.string.isRequired,
 *     // The error code that can contain a hidden hint for developers
 *     status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
 *   })),
 * })
 */

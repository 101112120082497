import {
  Heading,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function SectionTitle({
  fontSize,
  children,
  trailingAddon,
  className,
}) {
  return (
    <div className={cn('flex justify-between items-center', className)}>
      <div className="py-2">
        <Heading fontSize={fontSize}>{children}</Heading>
      </div>
      {trailingAddon}
    </div>
  )
}

SectionTitle.propTypes = {
  fontSize: Heading.propTypes.fontSize,
  children: PropTypes.node,
  trailingAddon: WithAddon.propTypes.trailingAddon,
  className: cn.propType,
}

SectionTitle.defaultProps = {
  fontSize: Heading.size.H6,
  children: null,
  trailingAddon: null,
  className: null,
}

export default SectionTitle

import actionTypes from '../../action-types'

export default (userId, payload, meta, included) => ({
  type: actionTypes.USER.USER_GROUP_RELATIONS_SET,
  payload,
  meta: {
    ...meta,
    userId,
    included,
  },
})

import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import fetchSomtodayInstitutes from '../actions/importers/fetchSomtodayInstitutes'
import addNotification from '../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../components/StatusIcon'
import {
  NOTIFICATION_TIMEOUT_LONG,
} from './notificationCenter'

export default () => {
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [institutes, setInstitutes] = useState([])
  const [results, setResults] = useState([])

  useEffect(() => {
    setLoading(true)
    promisifyDispatch(fetchSomtodayInstitutes())
      .then(setInstitutes)
      .catch(() => {
        addNotification({
          title: 'Something went wrong fetching Somtoday institutes',
          status: STATUS_ERROR,
        }, NOTIFICATION_TIMEOUT_LONG)
      })
      .finally(() => setLoading(false))
  }, [])

  function onSearch(value) {
    setResults([
      ...institutes.filter((school) => (
        school.naam.toLowerCase().includes(value.toLowerCase())
        || school.brins.find((brin) => brin.toLowerCase().includes(value.toLowerCase()))
      )).map((result) => ({
        key: result.uuid,
        meta: result.brins.join(', '),
        label: result.naam,
      })),
    ])
  }

  return [loading, results, onSearch]
}

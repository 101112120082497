import PropTypes from 'prop-types'
import {
  ArrowDown,
  ArrowUp,
  ThreeLinesDecrease,
} from '@Infowijs-eng/component-library/icons'
import {
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { useSearchParams } from 'react-router-dom'

function SortingDropdown({ options }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentSorting = (searchParams.get('sort') || '').split(':')
  const currentSortingKey = currentSorting[0] || (options && options[0] && options[0].key) || ''
  const currentSortingLabel = options && options.length > 0
    && options.find((optionsOption) => optionsOption.key === currentSortingKey).label
  const currentSortingOrder = currentSorting[1] || (options && options[0] && options[0].defaultOrder) || 'asc'

  return (
    <Dropdown
      label={`Sort by: ${currentSortingLabel}`}
      buttonProps={{
        small: true,
        neutral: true,
        trailingAddon: <ThreeLinesDecrease />,
      }}
    >
      {options && options.map(({
        key,
        label,
        defaultOrder,
      }) => (currentSortingKey === key ? (
        <DropdownItem
          small
          key={key}
          onClick={() => {
            if (searchParams.has('sort')) {
              searchParams.delete('sort')
            }
            searchParams.set('sort', `${key}:${currentSortingOrder === 'asc' ? 'desc' : 'asc'}`)
            setSearchParams(searchParams)
          }}
          trailingAddon={currentSortingOrder === 'asc' ? <ArrowDown /> : <ArrowUp />}
          leadingAddonClassName="text-[0.75rem]"
        >
          {label}
        </DropdownItem>
      ) : (
        <DropdownItem
          small
          key={key}
          onClick={() => {
            if (searchParams.has('sort')) {
              searchParams.delete('sort')
            }
            searchParams.set('sort', `${key}:${defaultOrder || 'asc'}`)
            setSearchParams(searchParams)
          }}
        >
          {label}
        </DropdownItem>
      )))}
    </Dropdown>
  )
}

SortingDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
}

export default SortingDropdown

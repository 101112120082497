import * as levenshtein from 'damerau-levenshtein'
import determineTopmostUserRoleId from './determineTopmostUserRoleId'
import getNormalizedName from './getNormalizedName'
import {
  ImporterUser, MatchStatus, PossibleMatchUser, UserRole, UserStatus,
} from './types'

export default function getSortedPossibleMatches(
  userStatuses: {[key: string]: UserStatus},
  sourceUserStatus: UserStatus,
  userRoles: { [id: string]: UserRole },
  targetUsers: {[id: string]: ImporterUser},
) {
  const sourceUser = sourceUserStatus.from ?? sourceUserStatus.to
  const sourceUserParentRoleId = determineTopmostUserRoleId(userRoles, sourceUser.userRole.id)

  const possibleMatches = sourceUserStatus.possibleMatches ?? []
  const possibleMatchIds = new Set(possibleMatches.map((match: PossibleMatchUser) => match.user.id) ?? [])

  Object.values(targetUsers).forEach((user) => {
    if (userStatuses[user.id]?.status !== MatchStatus.ImplicitUnmatch) {
      return
    }

    if (possibleMatchIds.has(user.id)) {
      return
    }

    const parentUserRoleId = determineTopmostUserRoleId(userRoles, user.userRole.id)
    if (parentUserRoleId !== sourceUserParentRoleId) {
      return
    }

    const emailScore = !!sourceUser.email && !!user.email
      ? levenshtein(sourceUser.email, user.email).similarity : undefined
    const nameScore = levenshtein(getNormalizedName(sourceUser), getNormalizedName(user)).similarity

    if ((typeof emailScore === 'number' && emailScore > 0.8) || nameScore > 0.8) {
      possibleMatchIds.add(user.id)
      possibleMatches.push({
        user,
        distanceScoreEmail: emailScore,
        distanceScoreName: nameScore,
      })
    }
  })

  return possibleMatches.sort((a, b) => {
    const scoreA = (a.distanceScoreName ?? 0) + (a.distanceScoreEmail ?? 0)
    const scoreB = (b.distanceScoreName ?? 0) + (b.distanceScoreEmail ?? 0)
    return scoreB - scoreA
  })
}

import React from 'react'
import schoolYearOptions from '../../../modules/schoolYearOptions'
import SelectField from './SelectField'

type YearRangeFieldProps = {
  value?: string
  onChange: (change: string) => void
  required?: boolean
  readOnly?: boolean
}

function YearRangeField({
  value = '',
  onChange,
  required = false,
  readOnly = false,
}: YearRangeFieldProps) {
  return (
    <SelectField
      value={value}
      options={
        schoolYearOptions.map((currentValue) => ({
          value: currentValue,
          label: currentValue,
        }))
      }
      onChange={onChange}
      required={required}
      readOnly={readOnly}
    />
  )
}

export default YearRangeField

import { Alert } from '@Infowijs-eng/component-library/components'
import { PlusSquare } from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import fetchCustomerProducts from '../../actions/customerProducts/fetchCustomerProducts'
import makeGetCustomerProductsByCustomerId from '../../selectors/customerProducts/makeGetCustomerProductsByCustomerId'
import ListItem from '../ListItem'
import SectionActions from '../SectionActions'
import CustomerProductListItem from './CustomerProductListItem'
import List from '../List'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'
import CustomerProductListSkeleton from './CustomerProductListSkeleton'

function CustomerProductsSection() {
  const { customerId } = useParams()
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  const getCustomerProductsByCustomerId = makeGetCustomerProductsByCustomerId()
  const customerProducts = useSelector((state) => getCustomerProductsByCustomerId(state, { customerId }))

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchCustomerProducts(customerId))
      .catch(setErrorMessages)
      .finally(() => setLoading(false))
  }, [customerId])

  return (
    <section aria-labelledby="Customer Products">
      <SectionTitle
        trailingAddon={(
          <SectionActions
            actions={[{
              neutral: true,
              children: 'Add product',
              leadingAddon: <PlusSquare />,
              to: `/customers/${customerId}/products/create`,
              permissions: ['customers.customerProducts.create'],
              key: 'add.product',
            }]}
          />
        )}
      >
        Products
      </SectionTitle>
      <SectionBody>
        <List>
          {errorMessages && <Alert errorMessages={errorMessages} />}
          {!!loading && (!customerProducts || !customerProducts.length) && <CustomerProductListSkeleton />}
          {!!customerProducts && !customerProducts.length && <ListItem className="italic">No results</ListItem>}
          {!!customerProducts && !!customerProducts.length && customerProducts.map((product) => (
            <CustomerProductListItem
              key={product.id}
              to={`/customers/products/${product.id}`}
              customerProduct={product}
              customerId={customerId}
            />
          ))}
        </List>

      </SectionBody>
    </section>
  )
}

CustomerProductsSection.propTypes = {}

CustomerProductsSection.defaultProps = {}

export default CustomerProductsSection

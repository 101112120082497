import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import getFirstName from '../../modules/getFirstName'
import DetailCardField from '../DetailCardField'
import DetailCardFieldGroup from '../DetailCardFieldGroup'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'

function UserInformationSection({
  user,
  loading,
}) {
  return (
    <section>
      <SectionTitle>Personal information</SectionTitle>
      <SectionBody className="pt-4">
        <DetailCardFieldGroup>
          <DetailCardField
            loading={loading}
            label="First Name (and/or initials)"
          >
            {getFirstName(user)}
          </DetailCardField>
          {user && user.lastNamePrefix && (
            <DetailCardField
              loading={loading}
              label="Prefix"
            >
              {user && user.lastNamePrefix}
            </DetailCardField>
          )}
          <DetailCardField
            loading={loading}
            label="Last Name"
          >
            {user && user.lastName}
          </DetailCardField>
        </DetailCardFieldGroup>
        <DetailCardFieldGroup>
          <DetailCardField
            loading={loading}
            label="Email"
            className="break-words"
          >
            {user && user.email}
          </DetailCardField>
          {user && user.phone && (
            <DetailCardField
              loading={loading}
              label="Phone"
            >
              {user && user.phone}
            </DetailCardField>
          )}
        </DetailCardFieldGroup>
        <DetailCardFieldGroup>
          <DetailCardField
            loading={loading}
            label="Role"
          >
            {user
              && user.userRole
              && user.userRole.labelI18n
              && getLocalisedLabelString(user.userRole.labelI18n, 1)}
          </DetailCardField>
          {user && user.subtitle && (
            <DetailCardField
              label="Subtitle"
              loading={loading}
            >
              {user && user.subtitle}
            </DetailCardField>
          )}
        </DetailCardFieldGroup>
      </SectionBody>
    </section>
  )
}

UserInformationSection.propTypes = {
  user: PropTypes.shape({
    lastNamePrefix: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    userRole: PropTypes.shape({
      labelI18n: PropTypes.shape({
        one: PropTypes.string,
        other: PropTypes.string,
        locale: PropTypes.string,
      }),
    }),
    subtitle: PropTypes.string,
  }),
  loading: PropTypes.bool,
}

UserInformationSection.defaultProps = {
  user: null,
  loading: false,
}

export default UserInformationSection

import React from 'react'
import TextInputField from './TextInputField'

type NumberFieldProps = {
  value?: number
  textInputPlaceholder?: string
  buttonPlaceholder?: string
  onChange?: (change: number) => void
  required?: boolean
  readOnly?: boolean
}
function NumberField({
  value = null,
  textInputPlaceholder = null,
  buttonPlaceholder = null,
  onChange = null,
  readOnly = false,
  required = false,
}: NumberFieldProps) {
  const transformValue = (valueToTransform : string) => parseInt(valueToTransform, 10)
  const isFieldReadOnly = !onChange || readOnly

  return (
    <TextInputField
      value={value.toString()}
      textInputPlaceholder={textInputPlaceholder}
      buttonPlaceholder={buttonPlaceholder}
      inputType="number"
      onChange={(change : string) => onChange(transformValue(change))}
      required={required}
      readOnly={isFieldReadOnly}
    />
  )
}

export default NumberField

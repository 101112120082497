import {
  Alert,
  Button,
  FormCard,
  FormField,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLoop,
  FloppyDisk,
} from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import {
  Visibility,
  getUserId,
} from '@Infowijs-eng/component-library/modules/authentication'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import fetchAdministrator from '../actions/administrators/fetchAdministrator'
import updateAdministrator from '../actions/administrators/updateAdministrator'
import AdministratorPermissionsFormCard from '../components/Administrator/AdministratorPermissionsFormCard'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import getFullName from '../modules/getFullName'
import getAdministrator from '../selectors/administrators/getAdministrator'

function AdministratorEditScreen() {
  const { administratorId } = useParams()
  const userId = useSelector(getUserId)
  const administrator = useSelector((state) => getAdministrator(state, { id: administratorId }))

  const promisifyDispatch = usePromisifyDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [account, setAccount] = useState('')
  const [permissions, setPermissions] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchAdministrator(administratorId))
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        setErrorMessages([...errorMessages, e])
      })
  }, [administratorId])

  useEffect(() => {
    if (administrator) {
      setLoading(false)
      setFirstName(administrator.firstName)
      setLastName(administrator.lastName)
      setAccount(administrator.email.split('@')[0])
      setPermissions(administrator.permissions)
    }
  }, [administrator])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/administrators',
            label: 'Administrators',
          },
          {
            to: `/administrators/${administrator && administrator.id}`,
            label: getFullName(administrator),
          },
        ]}
        loading={loading}
      >
        <Heading fontSize={Heading.size.H3}>Edit administrator</Heading>
      </PageHeader>
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault()
          setSubmitting(true)
          promisifyDispatch(updateAdministrator({
            id: administrator.id,
            firstName,
            lastName,
            email: `${account.toLowerCase()
              .trim()}@infowijs.nl`,
            permissions,
          }))
            .then(() => {
              navigate('/administrators')
            })
            .catch((e) => {
              setErrorMessages(e)
            })
            .finally(() => {
              setSubmitting(false)
            })
        }}
      >
        {errorMessages && (<Alert errorMessages={errorMessages} />)}
        <FormCard label="Profile">
          <FormField
            label="First name"
            name="first_name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            disabled={loading}
            className="sm:col-span-3"
          />
          <FormField
            label="Last name"
            name="last_name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            disabled={loading}
            className="sm:col-span-3"
          />
          <FormField
            label="Email address"
            name="account"
            value={account}
            onChange={(event) => setAccount(event.target.value)}
            disabled={loading}
            className="sm:col-span-3 xl:col-span-2"
            inputProps={{
              trailingAddon: '@infowijs.nl',
              className: 'pr-24',
            }}
          />
        </FormCard>
        {!loading && (
          <Visibility permission="administrators.permissions.edit">
            <AdministratorPermissionsFormCard
              onChange={(value) => setPermissions(value)}
              value={permissions}
              administrator={administrator}
              userId={userId}
            />
          </Visibility>
        )}

        <div className="flex justify-end space-x-3">
          <Button
            type="submit"
            primary
            disabled={loading || submitting}
            leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
          >
            Save this administrator
          </Button>
        </div>
      </form>
    </Layout>
  )
}

export default AdministratorEditScreen

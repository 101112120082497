import { createSelector } from 'reselect'
import getUserGroupsValues from './getUserGroupsValues'

export default createSelector(
  getUserGroupsValues,
  (userGroups) => {
    const rootTree = {}
    const treeNodeCache = {}
    userGroups.forEach((userGroup) => {
      if (userGroup.userGroupBadge && userGroup.userGroupBadge.id && userGroup.userGroupBadge.id in rootTree) {
        rootTree[userGroup.userGroupBadge.id].push(userGroup)
      } else if (userGroup.userGroupBadge && userGroup.userGroupBadge.id) {
        rootTree[userGroup.userGroupBadge.id] = [userGroup]
      } else if (
        userGroup.parentUserGroup
        && userGroup.parentUserGroup.id
        && userGroup.parentUserGroup.id in treeNodeCache
      ) {
        treeNodeCache[userGroup.parentUserGroup.id].push(userGroup)
      } else if (userGroup.parentUserGroup && userGroup.parentUserGroup.id) {
        treeNodeCache[userGroup.parentUserGroup.id] = [userGroup]
      }
    })

    function addChildren(userGroup) {
      const userGroupCopy = {
        ...userGroup,
        childrenIds: new Set(),
      }
      if (userGroup.id in treeNodeCache) {
        userGroupCopy.children = treeNodeCache[userGroup.id].map((ug) => addChildren(ug))
      } else {
        userGroupCopy.children = []
      }

      userGroupCopy.childrenIds.add(userGroup.id)
      userGroupCopy.children.forEach((child) => {
        child.childrenIds.forEach((childrenId) => {
          userGroupCopy.childrenIds.add(childrenId)
        })
      })

      return userGroupCopy
    }

    return Object.fromEntries(
      Object.entries(rootTree).map(([key, rootUserGroups]) => ([key, rootUserGroups.map((ug) => addChildren(ug))])),
    )
  },
)

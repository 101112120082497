import PropTypes from 'prop-types'
import { cn } from '@Infowijs-eng/component-library/modules'

function SectionBody({ children, className }) {
  return children && (
    <div className={cn('border-t border-gray-200', className)}>
      {children}
    </div>
  )
}

SectionBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: cn.propType,
}

SectionBody.defaultProps = {
  className: null,
}

export default SectionBody

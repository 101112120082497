import {
  call,
  takeLatest,
} from 'redux-saga/effects'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onCreateUser = function* onCreateUser() {
  yield takeLatest(actionTypes.USER.CREATE, function* onCreateUserSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const { payload } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: payload,
      })
    } catch (e) {
      rejectAction(action, e.response.data.errors)
      return
    }

    acceptAction(action, response.data.data)
  })
}

export default onCreateUser

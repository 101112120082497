import mergeQueryParams from './mergeQueryParams'

export default (queryString, key, newValue, oldValues) => {
  let currentValue = (queryString.get(key) || '').split(',').filter(Boolean)

  if (oldValues) {
    currentValue = currentValue.filter((id) => !oldValues.has(id))
  }

  if (newValue) {
    currentValue.push(newValue)
  }

  return mergeQueryParams(queryString, key, currentValue.filter(Boolean).join(','))
}

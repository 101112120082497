import {
  useEffect,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  FormCard,
  FormField,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLoop,
  FloppyDisk,
} from '@Infowijs-eng/component-library/icons'
import {
  getCountry,
  validateMetadataStructure,
  validatePostalCode,
} from '@Infowijs-eng/component-library/modules'
import { countries } from '@Infowijs-eng/component-library/modules/getCountry'
import SelectItem from '@Infowijs-eng/component-library/components/SelectItem'
import customerMetadataStructure from '../../modules/customerMetadataStructure'
import MetadataEditor from '../MetadataEditor/MetadataEditor'
import filterMetadata from '../../modules/filterMetadata'

function CustomerForm({
  customer,
  onSubmit,
  submitting,
  onError,
  loading,
}) {
  const defaultCountry = 'NL'
  const defaultMetadata = {}

  const [title, setTitle] = useState((customer && customer.title) || '')
  const [address, setAddress] = useState((customer && customer.address) || '')
  const [address2, setAddress2] = useState((customer && customer.address2) || '')
  const [postalCode, setPostalCode] = useState((customer && customer.postalCode) || '')
  const [city, setCity] = useState((customer && customer.city) || '')
  const [country, setCountry] = useState((customer && customer.country.toUpperCase()) || defaultCountry)
  const [metadata, setMetadata] = useState((customer && customer.metadata) || defaultMetadata)

  useEffect(() => {
    if (!customer) {
      return
    }

    setTitle(customer.title || '')
    setAddress(customer.address || '')
    setAddress2(customer.address2 || '')
    setPostalCode(customer.postalCode || '')
    setCity(customer.city || '')
    setCountry(customer.country.toUpperCase() || defaultCountry)
    setMetadata(customer.metadata || defaultMetadata)
  }, [customer])

  return (
    <form
      className="space-y-6"
      onSubmit={(e) => {
        e.preventDefault()

        const errors = []
        if (!title || title.length < 2) {
          errors.push({ title: 'Please provide the customer\'s name' })
        }
        if (postalCode && postalCode.length > 0) {
          try {
            validatePostalCode(country, postalCode)
          } catch (event) {
            errors.push({ title: event.message })
          }
        }
        if (!city || city.length < 1) {
          errors.push({ title: 'Please provide the customer\'s city' })
        }
        errors.push(...validateMetadataStructure(customerMetadataStructure, metadata))
        onError(errors)

        if (errors.length === 0) {
          onSubmit({
            title,
            address: address || null,
            address2: address2 || null,
            postalCode: postalCode || null,
            city,
            country,
            metadata: filterMetadata(customerMetadataStructure, metadata, 1),
          })
        }
      }}
    >
      <FormCard
        label="General Information"
        hint="We want to know all of these things about our customers"
      >
        <FormField
          required
          label="Name"
          name="title"
          value={title}
          onChange={(event) => setTitle(event.target.value)}
        />
        <FormField
          label="Address"
          name="address"
          value={address}
          onChange={(event) => setAddress(event.target.value)}
        />
        <FormField
          name="address2"
          value={address2}
          onChange={(event) => setAddress2(event.target.value)}
        />
        <div className="col-span-4 grid grid-cols-4 gap-6">
          <FormField
            label="Postal Code"
            name="postalCode"
            value={postalCode}
            onChange={(event) => setPostalCode(event.target.value)}
            className="sm:col-span-2"
          />
          <FormField
            required
            label="City"
            name="city"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            className="sm:col-span-2"
          />
        </div>
        <FormField
          required
          label="Country"
          name="country"
          type="select"
          value={country}
          inputProps={{
            placeholder: getCountry(country.toUpperCase()) || ' - Select - ',
            onChange: (countryCode) => setCountry(countryCode.toUpperCase()),
          }}
        >
          {Object.entries(countries).map(([countryCode, countryLabel]) => (
            <SelectItem
              key={countryCode}
              label={countryLabel}
              value={countryCode}
            />
          ))}
        </FormField>
      </FormCard>

      <FormCard
        label="Metadata"
        hint="Fields with * are required"
      >
        <div className="col-span-6">
          <MetadataEditor
            metadata={metadata}
            structure={customerMetadataStructure}
            onChange={(newMetadata) => setMetadata(newMetadata)}
          />
        </div>
      </FormCard>

      <div className="flex justify-end space-x-3">
        <Button
          type="submit"
          primary
          disabled={submitting || loading}
          leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <FloppyDisk />}
        >
          Save this Customer
        </Button>
      </div>
    </form>
  )
}

CustomerForm.propTypes = {
  customer: PropTypes.shape({
    title: PropTypes.string,
    address: PropTypes.string,
    address2: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
    metadata: PropTypes.shape(),
  }),
  onSubmit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  loading: PropTypes.bool,
  onError: PropTypes.func.isRequired,
}

CustomerForm.defaultProps = {
  customer: null,
  submitting: false,
  loading: false,
}

export default CustomerForm

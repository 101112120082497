export default ({
  label, options, value,
}) => {
  if (options) {
    return options[value.split(':')[1]]
  }

  const comparator = value.indexOf(':') > -1 && value.split(':')[0]
  switch (comparator) {
    case 'eq':
      return `${label}: ${value.split(':')[1]}`
    case 'ne':
      return `${label} is not ${value.split(':')[1]}`
    case 'lt':
      return `${label} < ${value.split(':')[1]}`
    case 'le':
      return `${label} ≤ ${value.split(':')[1]}`
    case 'gt':
      return `${label} > ${value.split(':')[1]}`
    case 'ge':
      return `${label} ≥ ${value.split(':')[1]}`
    case false:
      return `${label}: ${value}`
    default:
      return `${label} ${comparator} ${value.split(':')[1]}`
  }
}

import {
  Button,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { ArrowsLoop } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useState,
} from 'react'
import Modal from '../Modal'
import QRCode from '../../modules/qr/QRCode'

function UserCustomerProductKeyAccessModal({
  transferToken,
  onClose,
  onRefresh,
}) {
  const [seconds, setSeconds] = useState(29)
  const [refreshing, setRefreshing] = useState(false)
  useEffect(() => {
    let timeout
    if (seconds > 0) {
      timeout = setTimeout(() => {
        setSeconds((prev) => prev - 1)
      }, 1000)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [seconds])

  useEffect(() => {
    setSeconds(29)
    setRefreshing(false)
  }, [transferToken])

  return (
    <div className="whitespace-normal text-black text-base leading-normal">
      <Modal
        onClose={onClose}
        className="w-auto h-auto"
      >
        <div className="space-y-4">
          <Heading fontSize={Heading.size.H4}>Login as user in Hoy app</Heading>
          <div className="mx-auto relative">
            <QRCode content={`hoy_scan://v1/impersonate/${transferToken}`} backgroundColor="transparent" />
            {seconds === 0 && (
            <div
              // eslint-disable-next-line max-len
              className="absolute inset-0 backdrop-filter backdrop-blur-sm bg-gray-50 bg-opacity-50 items-center flex justify-center"
            >
              <Button
                disabled={refreshing}
                leadingAddon={<ArrowsLoop className={[refreshing && 'animate-spin']} />}
                onClick={() => {
                  setRefreshing(true)
                  onRefresh()
                }}
              >
                Refresh QR-code
              </Button>
            </div>
            )}
          </div>
          <div className="space-y-4">
            <p>
              <strong>When you are signed into a Hoy app:</strong>
              <br />
              Go to the last tab and press Hoy Scan
            </p>
            <p>
              <strong>When you are not signed into a Hoy app:</strong>
              <br />
              Tap 10 times on the title (Welcome [App Name]) in the login screen.
            </p>
          </div>
          {seconds > 0 && (
          <div className={cn(seconds <= 10 && 'animate-bounce text-red-600 font-extrabold')}>
            {`The QR-code will expire in ${seconds} seconds`}
          </div>
          )}
        </div>
      </Modal>
    </div>
  )
}

UserCustomerProductKeyAccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  transferToken: PropTypes.string.isRequired,
}

UserCustomerProductKeyAccessModal.defaultProps = {}

export default UserCustomerProductKeyAccessModal

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import setAdministrators from '../../actions/administrators/setAdministrators'
import fetchAdministrators from '../../actions/administrators/fetchAdministrators'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchAdministrators = function* onFetchAdministrators() {
  yield takeLatest(actionTypes.ADMINISTRATORS.FETCH_ALL, function* onfetchAdministrators(action) {
    const accessToken = yield call(awaitAccessToken)

    let response

    const { meta: { page, filter } } = action

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.administrators}`,
        params: {
          page,
          ...filter,
          limit: 20,
        },
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchAdministrators() {
          yield put(fetchAdministrators(page, filter))
        })
      }

      rejectAction(action, e.response.data.errors)
    }

    yield put(setAdministrators(response.data.data, response.data.meta))

    acceptAction(action, response.data.data)
  })
}

export default onFetchAdministrators

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { setSessionRequest } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import signOut from '../../actions/authentication/signOut'
import setSessionRequestAA from '../../actions/authentication/setSessionRequest'

const onSignIn = function* onSignIn() {
  yield takeLatest(actionTypes.AUTHENTICATION.SIGN_IN, function* signIn(action) {
    const { payload } = action

    let response

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.sessions}`,
        method: 'POST',
        headers: {
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          username: payload,
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield put(signOut())
      }

      rejectAction(action, e.response.data.errors)

      return
    }

    if (!response || !response.data) {
      return
    }

    yield put(setSessionRequest({
      ...response.data.data,
      emailAddress: action.payload,
    }))
    yield put(setSessionRequestAA(response.data.data))

    acceptAction(action, response.data.data)
  })
}

export default onSignIn

import actionTypes from '../../action-types'

export default ({
  page, filter, limit, sort,
}) => ({
  type: actionTypes.CUSTOMERS.FETCH_ALL,
  meta: {
    page,
    filter,
    limit,
    sort,
  },
})

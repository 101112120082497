import { Fragment } from 'react'
import { PropTypes } from 'prop-types'
import {
  ArrowDownSmall,
} from '@Infowijs-eng/component-library/icons'
import { WithAddon } from '@Infowijs-eng/component-library/components'
import { Menu } from '@headlessui/react'
import MetadataEditorButton from './MetadataEditorButton.tsx'

function MetadataEditorDropdown({ label, children }) {
  return (
    <Menu as="div" className="relative h-10 inline-block">
      <Menu.Button as={Fragment}>
        <MetadataEditorButton
          className="space-x-0.5 select-none"
        >
          <WithAddon wrapperClassName="space-x-0" trailingAddon={<ArrowDownSmall />}>
            {label}
          </WithAddon>
        </MetadataEditorButton>
      </Menu.Button>
      <Menu.Items className="absolute z-10 bg-white shadow-md rounded-md
      whitespace-nowrap overflow-hidden focus:outline-0"
      >
        {children}
      </Menu.Items>
    </Menu>
  )
}

MetadataEditorDropdown.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
}

export default MetadataEditorDropdown

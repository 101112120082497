import {
  Badge,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { PropTypes } from 'prop-types'
import ListItem from '../ListItem'
import UserCustomerProductKeyAccessOptions from '../User/UserCustomerProductKeyAccessOptions'

function ProductAccessListItem({
  userCustomerProductKey,
  customerProduct,
}) {
  return (
    <ListItem
      trailingAddon={(
        <UserCustomerProductKeyAccessOptions
          key="access-options"
          userCustomerProductKey={userCustomerProductKey}
        />
      )}
    >
      <WithAddon
        className="w-full justify-start"
        trailingAddon={(
          <>
            <Badge
              className={[
                customerProduct.type === 'hoy' && 'bg-green-500',
                customerProduct.type === 'schoolwiki' && 'bg-blue-500',
                customerProduct.type === 'brugger' && 'bg-red-500',
              ]}
            >
              {customerProduct.type}
            </Badge>
            {(!!customerProduct.deletedAt) && (
              <Badge className="bg-red-500">Deleted</Badge>
            )}
            {(!customerProduct.deployedAt) && (
              <Badge className="bg-red-500">Not Deployed</Badge>
            )}
          </>
        )}
      >
        <span className="text-black font-bold font-display">{customerProduct.title}</span>
      </WithAddon>
    </ListItem>
  )
}

ProductAccessListItem.propTypes = {
  userCustomerProductKey: PropTypes.shape({
    id: PropTypes.string.isRequired,
    customerProductId: PropTypes.string.isRequired,
  }).isRequired,
  customerProduct: PropTypes.shape({
    type: PropTypes.string,
    deletedAt: PropTypes.string,
    deployedAt: PropTypes.string,
    title: PropTypes.string,
  }),
}

ProductAccessListItem.defaultProps = {
  customerProduct: null,
}

export default ProductAccessListItem

import { Badge } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import parseBadgeLabelFromMetadataObject from '../modules/getBadgeLabelOfMetadataObject'

function MetadataBadges({
  metadata,
  className,
}) {
  if (!metadata || !Object.keys(metadata).length) {
    return null
  }

  return Object.entries(metadata)
    .map(([key, value]) => {
      const badgeLabel = parseBadgeLabelFromMetadataObject(key, value)
      if (!badgeLabel) {
        return null
      }

      return (
        <Badge key={key} className={cn('bg-orange-500', className)}>{badgeLabel}</Badge>
      )
    })
}

MetadataBadges.propTypes = {
  metadata: PropTypes.shape(),
  className: cn.propType,
}

MetadataBadges.defaultProps = {
  metadata: null,
  className: null,
}

export default MetadataBadges

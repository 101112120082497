import { cn } from '@Infowijs-eng/component-library/modules'
import { forwardRef } from 'react'
import PropTypes from 'prop-types'

const TextInput = forwardRef(({
  name,
  id,
  inline,
  small,
  leadingAddon,
  trailingAddon,
  inputOptions,
  inputMenu,
  className,
  disabled,
  ...props
}, ref) => (
  <div className="relative w-full">
    <div
      className={cn(
        'flex px-1 items-center w-full rounded-full border-gray-200 border-[1px]',
        'focus-within:border-theme-500 focus-within:ring-0',
        inline && 'rounded-none border-0 border-b-2 focus:border-0 focus:border-b-2 focus:ring-0',
        inline && 'focus:border-theme-500 flex-1 block w-full border-gray-200',
        !small && 'py-1',
        className,
      )}
    >
      {leadingAddon && (
        <div className="pl-2 flex items-center pointer-events-none">
          <div className="text-gray-500 text-sm">
            {leadingAddon}
          </div>
        </div>
      )}
      {inputOptions && (
        <div className="pr-3 flex items-center">
          {inputOptions}
        </div>
      )}
      <div className="relative w-full">
        <input
          ref={ref}
          type="text"
          id={id || name}
          name={name}
          className={cn(
            'h-8 px-3 block w-full bg-transparent ring-0 focus:ring-0 border-0 focus:border-0',
            disabled && 'opacity-50 pointer-events-none',
            (leadingAddon || inputOptions) && 'pl-0',
            trailingAddon && 'pr-0',
          )}
          disabled={disabled}
          {...props /* eslint-disable-line react/jsx-props-no-spreading */}
        />
        {inputMenu && (
          <div
            className={cn(
              'hidden md:block w-full absolute z-10 mt-1.5',
              !small && 'mt-2.5',
            )}
          >
            {inputMenu}
          </div>
        )}
      </div>
      {trailingAddon && (
        <div className="pr-1 flex items-center text-black text-sm">
            {trailingAddon}
        </div>
      )}
    </div>
    {inputMenu && (
      <div
        className="block md:hidden w-full absolute z-10 mt-1"
      >
        {inputMenu}
      </div>
    )}
  </div>
))

TextInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  inline: PropTypes.bool,
  small: PropTypes.bool,
  disabled: PropTypes.bool,
  leadingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  trailingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  inputOptions: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  inputMenu: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: cn.propType,
}

TextInput.defaultProps = {
  id: null,
  inline: false,
  small: false,
  disabled: false,
  leadingAddon: null,
  trailingAddon: null,
  inputOptions: null,
  inputMenu: null,
  className: null,
}

export default TextInput

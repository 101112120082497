import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import administratorPermissions from './administratorPermissions'
import administrators from './administrators'
import customerProducts from './customerProducts'
import customers from './customers'
import importers from './importers'
import notificationCenter from './notificationCenter'
import sessions from './sessions'
import user from './user'
import userGroups from './userGroups'
import userRoles from './userRoles'
import users from './users'
import webAuthN from './webAuthN'

const usersPersistConfig = {
  key: 'infowijs-admin-client-users',
  storage,
  whitelist: ['meta'],
}
const notificationCenterPersistConfig = {
  key: 'notification-center',
  storage,
  whitelist: [],
}

export default {
  administratorPermissions,
  administrators,
  customerProducts,
  customers,
  importers,
  notificationCenter: persistReducer(notificationCenterPersistConfig, notificationCenter),
  sessions,
  user,
  userGroups,
  userRoles,
  users: persistReducer(usersPersistConfig, users),
  webAuthN,
}

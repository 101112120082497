import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import updateAdministrator from '../../actions/administrators/updateAdministrator'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onUpdateAdministrator = function* onUpdateAdministrator() {
  yield takeLatest(actionTypes.ADMINISTRATORS.UPDATE, function* onUpdateAdministratorSaga(action) {
    const { payload } = action

    const accessToken = yield call(awaitAccessToken)

    let response

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.administrators}/${payload.id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: payload,
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reUpdateAdministrator() {
          yield put(updateAdministrator(payload))
        })
      }

      rejectAction(action, e.response.data.errors || 'An unknown error occurred')

      return
    }

    if (!response || !response.data) {
      return
    }

    acceptAction(action, response.data.data)
  })
}

export default onUpdateAdministrator

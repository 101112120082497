import { connect } from 'react-redux'
import removeNotification from '../../actions/notificationCenter/removeNotification'
import NotificationCenterNotification from './NotificationCenterNotification'

const dispatchToProps = (dispatch) => ({
  onRemove: (id) => dispatch(removeNotification(id)),
  dispatchAction: (arg) => dispatch(arg),
})

export default connect(null, dispatchToProps)(NotificationCenterNotification)

export default {
  type: 'object',
  optional: {
    admin: {
      type: 'object',
      labelI18n: {
        other: 'Administrators',
        locale: 'en_US',
      },
      optional: {
        comment: {
          labelI18n: {
            other: 'Internal Note',
            locale: 'en_US',
          },
          type: 'string',
        },
      },
    },
    brin: {
      labelI18n: {
        other: 'BRIN',
        locale: 'en_US',
      },
      type: 'string',
    },
    flags: {
      labelI18n: {
        other: 'Flags',
        locale: 'en_US',
      },
      type: 'object',
      optional: {
      },
    },
    locations: {
      labelI18n: {
        other: 'Locations',
        locale: 'en_US',
      },
      type: 'dynamicObject',
      data: {
        type: 'string',
      },
    },
  },
}

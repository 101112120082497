import { Fragment } from 'react'
import PropTypes from 'prop-types'
import StackedTableRecord from '@Infowijs-eng/component-library/components/StackedTableRecord'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import {
  Button,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLeftAndRight,
  CheckCircle,
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import getUserGroupsByParentUserGroupId from '../../selectors/userGroups/getUserGroupsByParentUserGroupId'
import getUserGroupRelations from '../../selectors/user/getUserGroupRelations'

function UserGroups({
  badgeId,
  parentId,
  selectedUserGroupId,
  setSelectedUserGroupId,
  bidirectional,
  setBidirectional,
  onClick,
}) {
  const { customerProductId } = useParams()

  const userGroups = useSelector((state) => (
    getUserGroupsByParentUserGroupId(state, {
      customerProductId,
      badgeId,
      parentId,
    })
  ))
  const userGroupRelations = useSelector(getUserGroupRelations)

  return userGroups.map(((userGroup) => {
    const selected = userGroup.id === selectedUserGroupId
    const existingRelation = !!userGroupRelations.find((relation) => relation.userGroup.id === userGroup.id)
    return (
      <StackedTableRecord
        key={userGroup.id}
        onClick={userGroup.hasChildren && !selectedUserGroupId
          ? () => onClick(userGroup)
          : null}
        trailingAddon={(
          <Fragment key="action">
            {selected && (
              <Button
                onClick={() => setBidirectional((prev) => !prev)}
                className={cn(
                  'mr-2 bg-gray-100 text-gray-400 hover:bg-gray-200 hover:text-gray-500',
                  bidirectional && 'bg-orange-500 text-white hover:bg-orange-600 hover:text-white',
                )}
                leadingAddon={<ArrowsLeftAndRight />}
                small
              >
                Two-way
              </Button>
            )}
            <Button
              small
              neutral
              disabled={(selectedUserGroupId && !selected) || existingRelation}
              className={[
                'text-gray-400',
                existingRelation && 'text-gray-400',
                selected && 'text-green-500 hover:bg-gray-50 hover:text-green-500',
              ]}
              leadingAddon={[
                (!existingRelation && !selected) && (<PlusCircle />),
                (existingRelation || selected) && (<CheckCircle />),
              ]}
              onClick={() => {
                if (selected) {
                  setSelectedUserGroupId(null)
                  setBidirectional(false)
                  return
                }
                setSelectedUserGroupId(userGroup.id)
              }}
            />
            {(selectedUserGroupId || !userGroup.hasChildren) && (
              <div className="block mr-2 w-[33px]" />
            )}
          </Fragment>
        )}
      >
        <div className="h-8 flex justify-center">
          <div
            className="w-full flex flex-wrap justify-start items-center text-black font-bold font-display select-none"
          >
            {getLocalisedLabelString(userGroup.labelI18n)}
          </div>
        </div>
      </StackedTableRecord>
    )
  }))
}

UserGroups.propTypes = {
  badgeId: PropTypes.string,
  parentId: PropTypes.string,
  setSelectedUserGroupId: PropTypes.func.isRequired,
  selectedUserGroupId: PropTypes.string,
  setBidirectional: PropTypes.func.isRequired,
  bidirectional: PropTypes.bool,
}

UserGroups.defaultProps = {
  badgeId: null,
  parentId: null,
  selectedUserGroupId: null,
  bidirectional: false,
}

export default UserGroups

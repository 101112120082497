import {
  Alert,
  Button,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowLeft,
  ArrowRight,
} from '@Infowijs-eng/component-library/icons'
import PropTypes from 'prop-types'

import Modal from './Modal'
import StepIndicator from './StepIndicator'

function ModalWithSteps({
  title,
  children,
  onClose,
  alert,
  totalSteps,
  currentStep,
  nextButtonProps,
  previousButtonProps,
}) {
  return (
    <Modal
      onClose={onClose}
      className="max-w-screen-sm"
    >
      <div className="h-full flex flex-col justify-between space-y-4">
        <div>
          {totalSteps && <div className="mb-6"><StepIndicator total={totalSteps} current={currentStep} /></div>}
          <Heading fontSize={Heading.size.H3} className="mb-2">{title}</Heading>
          <div className="space-y-4">
            {children}
          </div>
        </div>

        <div className="space-y-4">
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          {alert && <Alert {...alert} />}
          <div className="flex justify-between">
            <div>
              {previousButtonProps && (
                <Button
                  /* eslint-disable-next-line react/no-children-prop */
                  children="Previous"
                  leadingAddon={<ArrowLeft />}
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...previousButtonProps}
                />
              )}
            </div>
            <div>
              {nextButtonProps && (
                <Button
                  /* eslint-disable-next-line react/no-children-prop */
                  children="Next"
                  trailingAddon={<ArrowRight />}
                  primary
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...nextButtonProps}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

ModalWithSteps.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  alert: PropTypes.shape({
    title: PropTypes.string,
    errorMessages: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  onClose: PropTypes.func,
  totalSteps: PropTypes.number,
  currentStep: PropTypes.number,
  nextButtonProps: PropTypes.shape(Button.propTypes),
  previousButtonProps: PropTypes.shape(Button.propTypes),
}

ModalWithSteps.defaultProps = {
  nextButtonProps: null,
  previousButtonProps: null,
  alert: null,
  onClose: null,
  totalSteps: null,
  currentStep: null,
}

export default ModalWithSteps

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import setUserRoles from '../../actions/userRoles/setUserRoles'

const onFetchUserRoles = function* onFetchUserRoles() {
  yield takeLatest(actionTypes.USER_ROLES.FETCH_ALL, function* onFetchUserRolesSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const {
      payload: { customerId },
      meta,
    } = action

    const params = {
      page: meta && meta.page ? meta.page : 1,
      limit: meta && meta.limit ? meta.limit : 9999,
      sort: meta && meta.sort ? meta.sort : 'createdAt:desc',
      archivedAt: 'null:null',
    }

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/roles/customers/${customerId}`,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setUserRoles(response.data.data || [], response.data.meta, response.data.included || []))

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchUserRoles

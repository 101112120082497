import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import axios from 'axios'
import dayjs from 'dayjs'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import fetchUser from '../../actions/user/fetchUser'
import archiveUserAction from '../../actions/user/archiveUser'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'
import addNotification from '../../actions/notificationCenter/addNotification'
import removeNotification from '../../actions/notificationCenter/removeNotification'
import getUser from '../../selectors/user/getUser'
import getFullName from '../../modules/getFullName'

const onArchiveUser = function* onArchiveUser() {
  yield takeLatest(actionTypes.USER.ARCHIVE, function* onArchiveUserSaga(action) {
    const {
      payload,
      meta,
    } = action
    const accessToken = yield call(awaitAccessToken)

    try {
      yield call(axios, {
        url: `${serviceEndpoints.users}/${meta.id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          archivedAt: payload ? dayjs().toISOString() : null,
        },
      })
    } catch (e) {
      return
    }

    const user = yield select(getUser, { userId: meta.id })

    yield put(removeNotification(`user-${meta.id}-archive`))
    yield put(removeNotification(`user-${meta.id}-unarchive`))
    if (payload) {
      yield put(addNotification({
        id: `user-${meta.id}-archive`,
        title: `User ${getFullName(user)} has been archived`,
        actions: [{
          key: 'undo',
          dispatchOnClick: archiveUserAction(false, meta.id),
          children: 'Make active again',
        }],
      }, NOTIFICATION_TIMEOUT_LONG))
    } else {
      yield put(addNotification({
        id: `user-${meta.id}-unarchive`,
        title: `User ${getFullName(user)} has been unarchived`,
        actions: [{
          key: 'undo',
          dispatchOnClick: archiveUserAction(true, meta.id),
          children: 'Archive again',
        }],
      }, NOTIFICATION_TIMEOUT_LONG))
    }

    yield put(fetchUser(meta.id))
  })
}

export default onArchiveUser

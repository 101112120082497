import {
  Button,
  Card,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowLeft,
  ArrowsLoop,
  ExclamationTriangle,
} from '@Infowijs-eng/component-library/icons'
import ArrowsClockwise from '@Infowijs-eng/component-library/icons/ArrowClockwise'
import CheckCircle from '@Infowijs-eng/component-library/icons/CheckCircle'
import {
  getLocalisedLabelString,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import fetchImporterDryRun from '../../actions/importers/dryRunImporter'
import updateImporter from '../../actions/importers/updateImporter'
import fetchUserRoles from '../../actions/userRoles/fetchUserRoles'
import getCustomerMetadataLocations from '../../selectors/customers/getCustomerMetadataLocations'
import getUserRolesData from '../../selectors/userRoles/getUserRolesData'
import ModalWithSteps from '../ModalWithSteps'
import Table from '../Table'

// eslint-disable-next-line max-len
const alertMessage = 'The importer setup is not yet finished and therefore cannot be used. You can edit this importer later to continue.\nAre you sure you wish to close this window?'

function ImporterEditDryRun({ totalSteps, currentStep, type }) {
  const { customerId, importerId } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [totals, setTotals] = useState(null)
  const userRoles = useSelector(getUserRolesData)
  const customerLocations = useSelector((state) => getCustomerMetadataLocations(state, { id: customerId }))

  const userRoleIds = useMemo(() => totals && totals.perRole && userRoles && [
    ...Object.keys(totals.perRole),
  ].sort(
    (a, b) => getLocalisedLabelString(userRoles[a].labelI18n, 1)
      .localeCompare(getLocalisedLabelString(userRoles[b].labelI18n, 1)),
  ), [totals, userRoles])

  const tableHead = useMemo(() => totals && totals.perRole && userRoles && [
    [
      { key: 'blanco' },
      ...userRoleIds.map((roleId) => ({
        children: userRoles[roleId] ? getLocalisedLabelString(userRoles[roleId].labelI18n, 1) : '—',
        key: `label-${roleId}`,
      })),
      {
        children: 'Total', tag: 'th', key: 'head-total', className: 'text-left',
      },
    ],
  ], [totals, userRoles])

  const tableBody = useMemo(() => totals && totals.perRole && [
    ...Object.keys(totals.perLocation).map((locationId) => ([
      {
        key: locationId,
        children: (customerLocations && customerLocations[locationId]) || '–',
      },
      ...userRoleIds.map((roleId) => ({
        children: totals.perLocation[locationId][roleId] || 0,
        key: `value-${locationId}-${roleId}`,
      })),
      {
        key: `totals-${locationId}`,
        children: totals.perLocation[locationId].total || 0,
        tag: 'th',
        className: 'text-left',
      },
    ])),
  ], [totals, userRoleIds, customerLocations])

  const tableFoot = useMemo(() => totals && totals.perRole && [
    [
      {
        key: 'total', children: 'Total', tag: 'th', className: 'text-left',
      },
      ...userRoleIds.map((roleId) => ({
        children: totals.perRole[roleId] || 0,
        key: `value-${roleId}`,
      })),
      {
        children: totals.total || 0, tag: 'th', key: 'foot-total', className: 'text-left',
      },
    ],
  ], [totals])

  function fetch() {
    setTotals(null)
    setErrorMessages(null)
    setLoading(true)
    promisifyDispatch(fetchImporterDryRun(importerId))
      .then((data) => {
        setTotals(data)
        setLoading(false)
      })
      .catch((e) => {
        setLoading(false)
        setErrorMessages(e)
      })
  }

  useEffect(() => {
    dispatch(fetchUserRoles(customerId))
    fetch()
  }, [])

  return (
    <ModalWithSteps
      title="Add Importer"
      onClose={!loading ? () => {
        // eslint-disable-next-line no-alert
        if (window.confirm(alertMessage)) {
          navigate(`/customers/${customerId}`)
        }
      } : null}
      totalSteps={totalSteps}
      currentStep={currentStep}
      nextButtonProps={{
        disabled: loading || submitting,
        trailingAddon: (loading || submitting) ? <ArrowsLoop className="animate-spin" /> : <CheckCircle />,
        children: 'Confirm',
        onClick: () => {
          setSubmitting(true)
          promisifyDispatch(updateImporter({ id: importerId, metadata: { config: { done: true } } }))
            .then(() => {
              setSubmitting(false)
              navigate(`/customers/${customerId}`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        },
      }}
      previousButtonProps={{
        disabled: loading,
        onClick: () => navigate(`/customers/${customerId}/importers/create/${type}/${importerId}/settings`),
        leadingAddon: <ArrowLeft />,
      }}
      alert={errorMessages ? {
        title: 'Something went wrong while fetching user totals for this importer',
        errorMessages,
      } : null}
    >
      <span>
        <div className="font-bold">Confirm the user totals</div>
        <p className="text-sm leading-normal">
          You&apos;re about to finalise this importer, which would import users of the locations below when activated.
          Please check if the totals match your estimation.
        </p>
      </span>
      <Card className="py-2 px-5 text-sm">
        {loading && (
          <div className="text-center py-10">
            <ArrowsClockwise className="animate-spin w-12 h-12 text-gray-400 mx-auto mb-6" />
            <p className="leading-normal">
              User totals are being retrieved...
              <br />
              This could take a while, please don&apos;t close this window.
            </p>
          </div>
        )}
        {!loading && !totals && (
          <div className="text-center py-10 space-y-4">
            <div className="rounded-full inline-block mx-auto bg-orange-50 p-4">
              <ExclamationTriangle className="w-9 h-9 text-orange-500" />
            </div>
            <p>The user totals couldn&apos;t be retrieved.</p>
            <Button
              className="mx-auto"
              onClick={() => {
                fetch()
              }}
            >
              Retry
            </Button>
          </div>
        )}
        {!loading && totals && (
          <Table
            head={tableHead}
            body={tableBody}
            foot={tableFoot}
          />
        )}
      </Card>
    </ModalWithSteps>
  )
}

ImporterEditDryRun.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
}

export default ImporterEditDryRun

import administratorPermissions from './administratorPermissions'
import administrators from './administrators'
import authentication from './authentication'
import customerProducts from './customerProducts'
import customers from './customers'
import importers from './importers'
import notificationCenter from './notificationCenter'
import sessions from './sessions'
import user from './user'
import userGroups from './userGroups'
import userRoles from './userRoles'
import users from './users'
import webAuthN from './webAuthN'

export default {
  ADMINISTRATORS: administrators,
  ADMINISTRATOR_PERMISSIONS: administratorPermissions,
  AUTHENTICATION: authentication,
  CUSTOMERS: customers,
  CUSTOMER_PRODUCTS: customerProducts,
  IMPORTERS: importers,
  NOTIFICATION_CENTER: notificationCenter,
  SESSIONS: sessions,
  USER: user,
  USERS: users,
  USER_GROUPS: userGroups,
  USER_ROLES: userRoles,
  WEB_AUTH_N: webAuthN,
}

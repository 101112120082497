import { cn } from '@Infowijs-eng/component-library/modules'
import { createElement } from 'react'
import PropTypes from 'prop-types'

export default function TableRows({ rows, cellCss }) {
  return rows.map(({ tag, css, ...props }) => (
    createElement(tag || 'td', { className: cn('p-4', cellCss, css), ...props })
  ))
}

TableRows.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string,
  })).isRequired,
  cellCss: PropTypes.shape({}),
}

TableRows.defaultProps = {
  cellCss: null,
}

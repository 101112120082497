import {
  Avatar,
  Badge,
} from '@Infowijs-eng/component-library/components'
import { TYPE_CUSTOMER_PRODUCT } from '@Infowijs-eng/component-library/constants/avatar'
import {
  TwoVerticalLines,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  getCountry,
} from '@Infowijs-eng/component-library/modules'
import { makeHasPermission } from '@Infowijs-eng/component-library/modules/authentication'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import ListItem from '../ListItem'

function CustomerListItem({
  customer,
}) {
  const hasPermission = makeHasPermission()
  const userCanView = useSelector((state) => hasPermission(state, {
    permissions: 'customers.view',
  }))

  const customerProductTypes = useMemo(() => [...new Set(
    (
      customer
      && customer.customerProducts
      && customer.customerProducts.map((product) => product.type)
    )
    || [],
  )], [customer && customer.customerProducts])

  return (
    <ListItem
      leadingAddon={(
        <Avatar
          id={(
            customer.customerProducts
            && customer.customerProducts.length
            && customer.customerProducts[0].id
          ) || customer.id} // Prevent loading state on customers without any product
          type={TYPE_CUSTOMER_PRODUCT}
          alt={customer.title}
        />
      )}
      trailingAddon={(
        <span key="badges" className="flex sm:justify-end space-x-1">
          {customerProductTypes.map((productType) => productType && (
            <Badge
              key={productType}
              className={cn(
                productType === 'hoy' && 'bg-green-500',
                productType === 'schoolwiki' && 'bg-blue-500',
                productType === 'brugger' && 'bg-red-500',
              )}
            >
              {productType}
            </Badge>
          ))}
          {customer.suspendedAt && (
            <Badge leadingAddon={<TwoVerticalLines />}>
              {`${dayjs(customer.suspendedAt)
                .format('DD-MM-YYYY')}`}
            </Badge>
          )}
        </span>
      )}
      to={userCanView && `/customers/${customer.id}`}
    >
      <span className="flex flex-col">
        <span className="font-medium font-display">
          {customer.title}
        </span>
        <span className="text-gray-500">
          {[
            (customer.city && customer.city.length > 0) && customer.city,
            (customer.country && customer.country.toUpperCase() !== 'NL')
                  && getCountry(customer.country.toUpperCase()),
          ].filter(Boolean)
            .join(', ')}
        </span>
      </span>
    </ListItem>
  )
}

CustomerListItem.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.shape(),
    title: PropTypes.string,
    metadata: PropTypes.shape(),
    customerProducts: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
    })),
    suspendedAt: PropTypes.bool,
    city: PropTypes.string,
    country: PropTypes.string,
  }).isRequired,
}

CustomerListItem.defaultProps = {}

export default CustomerListItem

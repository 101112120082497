import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import CustomerProductForm from '../components/CustomerProduct/CustomerProductForm'
import getCustomerProduct from '../selectors/customerProducts/getCustomerProduct'
import fetchCustomerProduct from '../actions/customerProducts/fetchCustomerProduct'
import updateCustomerProduct from '../actions/customerProducts/updateCustomerProduct'
import PageHeader from '../components/PageHeader'

function CustomerProductEditScreen() {
  const { customerProductId } = useParams()
  const customerProduct = useSelector((state) => getCustomerProduct(state, { customerProductId }))
  const [loading, setLoading] = useState(!customerProduct)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState('')
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()

  useEffect(() => {
    setErrorMessages(null)
    promisifyDispatch(fetchCustomerProduct(customerProductId))
      .then(() => (
        setLoading(false)
      ))
      .catch((e) => {
        setErrorMessages(e)
      })
  }, [customerProductId])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[{
          to: '/customers',
          label: 'Customers',
        }, {
          to: customerProduct && customerProduct.customerId && `/customers/${customerProduct.customerId}`,
          label: customerProduct && customerProduct.customerTitle,
        }, {
          to: `/customers/products/${customerProductId}`,
          label: customerProduct && customerProduct.title,
        }]}
      >
        <Heading fontSize={Heading.size.H3}>Edit Customer Product</Heading>
      </PageHeader>
      <div className="sticky top-0 z-50">
        {errorMessages && !!errorMessages.length && (<Alert title="You made a booboo" errorMessages={errorMessages} />)}
      </div>
      <CustomerProductForm
        customerProduct={customerProduct}
        loading={loading}
        submitting={submitting}
        onError={(e) => setErrorMessages(e)}
        onSubmit={(payload) => {
          setSubmitting(true)
          promisifyDispatch(updateCustomerProduct(payload))
            .then(() => {
              setSubmitting(false)
              navigate(`/customers/products/${customerProductId}`)
            })
            .catch((error) => {
              setSubmitting(false)
              setErrorMessages(error)
            })
        }}
      />

    </Layout>
  )
}

export default CustomerProductEditScreen

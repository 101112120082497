import { cn } from '@Infowijs-eng/component-library/modules'
import { MouseEventHandler, ReactNode, forwardRef } from 'react'

export interface MetadataEditorButtonProps {
  // eslint-disable-next-line react/require-default-props
  children?: ReactNode
  // eslint-disable-next-line react/require-default-props
  onClick?: MouseEventHandler<HTMLButtonElement>
  // eslint-disable-next-line react/require-default-props
  className?: string|[]
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean
}

const MetadataEditorButton = forwardRef<HTMLButtonElement, MetadataEditorButtonProps>(({
  children = null, disabled = false, onClick = null, className = null,
} : MetadataEditorButtonProps, ref) => (
  <div className="min-w-[1.875rem] min-h-[2.5rem] flex items-center">
    <button
      ref={ref}
      disabled={disabled}
      type="button"
      className={cn(
        'self-center flex items-center text-black font-medium rounded-md relative text-left text-sm p-2',
        !disabled && 'hover:bg-gray-50 focus:outline-none text-theme-700',
        disabled && 'select-text cursor-text ',
        className,
      )}
      onClick={onClick}
    >
      {children}
    </button>
  </div>
))
export default MetadataEditorButton

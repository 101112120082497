import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import {
  Button,
  FormCard,
  FormField,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowsLoop,
  PersonPlus,
} from '@Infowijs-eng/component-library/icons'
import inviteAdministrator from '../actions/administrators/inviteAdministrator'
import Layout from '../components/Layout'
import AdministratorPermissionsFormCard from '../components/Administrator/AdministratorPermissionsFormCard'
import PageHeader from '../components/PageHeader'

function AdministratorInviteScreen({
  loadingAccessToken,
}) {
  const [permissions, setPermissions] = useState(['administrators.list', 'customers.list'])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [account, setAccount] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/administrators',
            label: 'Administrators',
          },
        ]}
      >
        <Heading fontSize={Heading.size.H3}>Invite a new Administrator</Heading>
      </PageHeader>
      <form
        className="space-y-6"
        onSubmit={(event) => {
          event.preventDefault()
          setSubmitting(true)
          promisifyDispatch(inviteAdministrator({
            firstName,
            lastName,
            email: `${account.toLowerCase()
              .trim()}@infowijs.nl`,
            permissions,
          }))
            .then(() => {
              navigate('/administrators')
              setSubmitting(false)
            })
            .catch(() => {
              setSubmitting(false)
            })
        }}
      >
        <FormCard
          label="Profile"
          hint="We want to know all of these things about our new colleague."
        >
          <FormField
            label="First name"
            name="first_name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            className="sm:col-span-3"
          />
          <FormField
            label="Last name"
            name="last_name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            className="sm:col-span-3"
          />
          <div className="col-span-6 grid grid-cols-6">
            <FormField
              label="Email address"
              name="account"
              value={account}
              onChange={(event) => setAccount(event.target.value)}
              className="sm:col-span-3 xl:col-span-2"
              inputProps={{
                trailingAddon: '@infowijs.nl',
                className: 'pr-24',
              }}
            />
          </div>
        </FormCard>
        <AdministratorPermissionsFormCard
          loadingAccessToken={loadingAccessToken}
          onChange={(value) => setPermissions(value)}
          value={permissions}
        />
        <div className="flex justify-end space-x-3">
          <Button
            type="submit"
            primary
            leadingAddon={submitting ? <ArrowsLoop className="animate-spin" /> : <PersonPlus />}
            disabled={submitting}
          >
            Invite this Administrator
          </Button>
        </div>
      </form>
    </Layout>
  )
}

AdministratorInviteScreen.propTypes = {
  loadingAccessToken: PropTypes.bool,
}

AdministratorInviteScreen.defaultProps = {
  loadingAccessToken: false,
}

export default AdministratorInviteScreen

import { createSelector } from 'reselect'
import getRecursiveAdministratorPermissions from './getRecursiveAdministratorPermissions'

export default createSelector(
  getRecursiveAdministratorPermissions,
  (administratorPermissions) => administratorPermissions.reduce((acc, permissionGroup) => {
    permissionGroup.permissions.forEach((permission) => (
      permission.permissionDependencies.forEach((dependency) => {
        if (!acc[dependency]) {
          acc[dependency] = []
        }
        acc[dependency].push(permission.id)
      })
    ))
    return acc
  }, {}),
)

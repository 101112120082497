import getLanguage from './getLanguage'

const customerProductMetadataBase = {
  type: 'object',
  required: {
    language: {
      type: 'enum',
      labelI18n: {
        other: 'Language',
        locale: 'en_US',
      },
      values: ['nl_NL', 'en_US'],
      getValueLabel: (key) => getLanguage(key),
    },
  },
  optional: {
    admin: {
      type: 'object',
      labelI18n: {
        other: 'Administrators',
        locale: 'en_US',
      },
      optional: {
        comment: {
          labelI18n: {
            other: 'Internal Note',
            locale: 'en_US',
          },
          type: 'string',
        },
      },
    },
  },
}

const hoyMetadataStructure = {
  ...customerProductMetadataBase,
  optional: {
    ...customerProductMetadataBase.optional,
    database: {
      type: 'string',
      readOnly: true,
      labelI18n: {
        other: 'Database',
        locale: 'en_US',
      },
    },
    premium: {
      type: 'object',
      labelI18n: {
        other: 'Premium ✨',
        locale: 'en_US',
      },
      required: {
        bundleId: {
          type: 'string',
          labelI18n: {
            other: 'Bundle ID',
            locale: 'en_US',
          },
        },
        appleAppId: {
          type: 'integer',
          labelI18n: {
            other: 'Apple App ID',
            locale: 'en_US',
          },
        },
      },
      optional: {
        appName: {
          type: 'string',
          labelI18n: {
            other: 'App Name',
            locale: 'en_US',
          },
        },
        slogan: {
          type: 'string',
          labelI18n: {
            other: 'Slogan',
            locale: 'en_US',
          },
        },
        emailLogo: {
          type: 'string',
          labelI18n: {
            other: 'Email Logo',
            locale: 'en_US',
          },
        },
      },
    },
    includable: {
      type: 'object',
      labelI18n: {
        other: 'Includable internals',
        locale: 'en_US',
      },
      optional: {
        workerId: {
          labelI18n: {
            other: 'Worker ID which can process cron jobs',
            locale: 'en_US',
          },
          type: 'string',
        },
      },
    },
    rulesetConfig: {
      type: 'object',
      labelI18n: {
        other: 'Ruleset Config',
        locale: 'en_US',
      },
      optional: {
        teachers: {
          type: 'object',
          labelI18n: {
            other: 'Teachers',
            locale: 'en_US',
          },
          optional: {
            studentMentorGroups: {
              type: 'object',
              labelI18n: {
                other: 'Student mentor groups for current school year',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to mentor groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: true,
                },
              },
            },
            parentMentorGroups: {
              type: 'object',
              labelI18n: {
                other: 'Parent mentor groups for current school year',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to mentor groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: true,
                },
              },
            },
            studentMentorGroupsNextYear: {
              type: 'object',
              labelI18n: {
                other: 'Student mentor groups for next school year',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to mentor groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: false,
                },
              },
            },
            parentMentorGroupsNextYear: {
              type: 'object',
              labelI18n: {
                other: 'Parent mentor groups for next school year',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to mentor groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: false,
                },
              },
            },
            studentCourses: {
              type: 'object',
              labelI18n: {
                other: 'Student courses',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to course groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: false,
                },
              },
            },
            parentCourses: {
              type: 'object',
              labelI18n: {
                other: 'Parent courses',
                locale: 'en_US',
              },
              required: {
                coupled: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Teacher has relation to course groups',
                    locale: 'en_US',
                  },
                  default: true,
                },
                bidirectional: {
                  type: 'boolean',
                  labelI18n: {
                    other: 'Relation is bidirectional',
                    locale: 'en_US',
                  },
                  default: false,
                },
              },
            },
          },
        },
        schoolYearSwitchDate: {
          type: 'string',
          labelI18n: {
            other: 'School year switch date',
            locale: 'en_US',
          },
          default: '01-08',
        },
      },
    },
    flags: {
      type: 'object',
      labelI18n: {
        other: 'Flags',
        locale: 'en_US',
      },
      optional: {
        disableInvites: {
          type: 'boolean',
          labelI18n: {
            other: 'Disable Invites',
            locale: 'en_US',
          },
        },
      },
    },
  },
}

const schoolwikiMetadataStructure = {
  ...customerProductMetadataBase,
}

const bruggerMetadataStructure = {
  ...customerProductMetadataBase,
  optional: {
    ...customerProductMetadataBase.optional,
    las: {
      type: 'enum',
      labelI18n: {
        other: 'Preferred LAS',
        locale: 'en_US',
      },
      values: ['somtoday', 'magister'],
      getValueLabel: (key) => {
        switch (key) {
          case 'somtoday':
            return 'Somtoday'
          case 'magister':
            return 'Magister'
          default:
            return ''
        }
      },
    },
  },
}

export default (type) => {
  switch (type) {
    case 'schoolwiki':
      return schoolwikiMetadataStructure
    case 'hoy':
      return hoyMetadataStructure
    case 'brugger':
      return bruggerMetadataStructure
    default:
      return customerProductMetadataBase
  }
}

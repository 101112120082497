import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import addNotification from '../../actions/notificationCenter/addNotification'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'
import fetchUserGroupRelations from '../../actions/user/fetchUserGroupRelations'

const onCreateUserGroupRelation = function* onCreateUserGroupRelation() {
  yield takeLatest(actionTypes.USER.USER_GROUP_RELATIONS_CREATE, function* onCreateUserGroupRelationSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const { payload } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/${payload.userId}/user-groups/relations`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: payload,
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(fetchUserGroupRelations(payload.userId))

    yield put(addNotification({
      id: `user-${payload.userGroupId}-archive`,
      title: 'User group relation was succesfully created',
    }, NOTIFICATION_TIMEOUT_LONG))
    acceptAction(action, response.data.data || [])
  })
}

export default onCreateUserGroupRelation

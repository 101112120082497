import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { useCallback } from 'react'

export default function usePostSwitchImporterUsers() {
  const authenticatedRequest = useAuthenticatedRequest()

  return useCallback(async (
    fromImporterId: string,
    toImporterId: string,
    userIdsToMigrate: {
      fromUserId: string
      toUserId: string
    }[],
    userIdsToIgnore: string[],
  ) => {
    await authenticatedRequest(`${serviceEndpoints.importers}/migrate`, {
      method: 'post',
      data: {
        fromImporterId,
        toImporterId,
        userIdsToMigrate,
        userIdsToIgnore,
      },
    })
  }, [])
}

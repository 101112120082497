import {
  select,
  take,
} from 'redux-saga/effects'
import authenticationActionTypes from '@Infowijs-eng/component-library/modules/authentication/actionTypes'
import { getAccessToken } from '@Infowijs-eng/component-library/modules/authentication'

export default function* awaitAccessToken() {
  let accessToken = yield select(getAccessToken)
  if (!accessToken) {
    yield take(authenticationActionTypes.SET_ACCESS_TOKEN)
    accessToken = yield select(getAccessToken)
  }
  return accessToken
}

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import setUserGroupBadges from '../../actions/userGroups/setUserGroupBadges'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchUserGroupBadges = function* onFetchUserGroupBadges() {
  yield takeLatest(actionTypes.USER_GROUPS.FETCH_ALL_BADGES, function* onFetchUserGroupBadgesSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const {
      payload: { customerProductId },
    } = action

    const params = {
      page: 1,
      limit: 999999999,
      deletedAt: 'null:null',
      customerProductId: `eq:${customerProductId}`,
    }

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/user-group-badges`,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setUserGroupBadges(
      customerProductId,
      response.data.data || [],
      response.data.meta,
      response.data.included || [],
    ))

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchUserGroupBadges

import actionTypes from '../action-types'
import createUserGroupPath from '../modules/createUserGroupPath'

const initialState = {
  data: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USER.FETCH:
      return {
        data: {},
      }
    case actionTypes.USER.SET:
      return {
        ...state,
        data: {
          ...action.payload,
          relations: state.data.relations,
          userGroupRelations: state.data.userGroupRelations,
          customer: {
            ...(action.included && action.included.customer
              ? action.included.customer[action.payload.customer.id]
              : {}),
          },
          lastUpdatedBy: {
            ...(action.included
            && action.included.lastUpdatedBy
            && action.payload.lastUpdatedBy
            && action.payload.lastUpdatedBy.id
            && action.payload.lastUpdatedBy.id.split(':')[2]
              ? action.included.lastUpdatedBy[action.payload.lastUpdatedBy.id.split(':')[2]]
              : {}),
          },
          userRole: {
            ...(action.included && action.included.userRole
              ? action.included.userRole[action.payload.userRole.id]
              : {}),
          },
          importer: {
            ...(action.included && action.included.importer
              ? action.included.importer[action.payload.importer.id]
              : {}),
          },
          userCustomerProductKey: {
            ...((action.included && action.included.userCustomerProductKey) || {}),
          },
        },
      }
    case actionTypes.USER.RELATIONS_SET:
      return {
        ...state,
        data: {
          ...state.data,
          relations: [
            ...(action.payload || []).map((relation) => ({
              ...relation,
              user: {
                ...relation.user,
                userRole: action.included
                  && action.included.userRole
                  && relation.user.userRole
                  && action.included.userRole[relation.user.userRole.id],
              },
            })),
          ],
        },
      }
    case actionTypes.USER.RELATION_DELETE_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          relations: [
            ...state.data.relations.filter((relation) => relation.user.id !== action.payload.toUserId),
          ],
        },
      }

    case actionTypes.USER.USER_GROUP_RELATIONS_SET:
      return {
        ...state,
        data: {
          ...state.data,
          userGroupRelations: action.payload.map((userGroupRelation) => {
            const pathToGroup = createUserGroupPath(userGroupRelation.userGroup.id, action.meta.included.userGroup)
            return ({
              ...userGroupRelation,
              userGroupBadge: pathToGroup[0],
              userGroup: {
                ...userGroupRelation.userGroup,
                label: action.meta.included.userGroup[userGroupRelation.userGroup.id].labelI18n,
                pathToUserGroup: pathToGroup.map((item) => item.labelI18n),
              },
            })
          }),
        },
      }
    default:
      return state
  }
}

import {
  useEffect,
  useState,
} from 'react'
import {
  Route,
  Routes,
  useParams,
} from 'react-router-dom'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  Badge,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import {
  getLocalisedLabelString,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import {
  ArrowsLeftAndRight,
  PersonPlus,
  TrashBin,
} from '@Infowijs-eng/component-library/icons'
import { useMediaQuery } from 'react-responsive'
import Layout from '../components/Layout'
import List from '../components/List'
import ListItem from '../components/ListItem'
import ListItemActions from '../components/ListItemActions'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import getUser from '../selectors/user/getUser'
import UserHeader from '../components/User/UserHeader'
import fetchUserGroupRelations from '../actions/user/fetchUserGroupRelations'
import fetchUser from '../actions/user/fetchUser'
import getFullName from '../modules/getFullName'
import UserGroupRelationsCreateModal from '../components/User/UserGroupRelationsCreateModal'
import deleteUserGroupRelation from '../actions/user/deleteUserGroupRelation'

function UserGroupRelationsScreen() {
  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const {
    customerId,
    userId,
  } = useParams()

  const user = useSelector(getUser)

  useEffect(() => {
    let promised = true
    setLoading(true)

    Promise.all([
      promisifyDispatch(fetchUser(userId)),
      promisifyDispatch(fetchUserGroupRelations(userId)),
    ]).finally(() => {
      if (promised) {
        setLoading(false)
      }
    })

    return () => {
      promised = false
    }
  }, [userId])

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' })

  return (
    <>
      <Routes>
        <Route
          path="/new"
          element={<UserGroupRelationsCreateModal />}
        />
      </Routes>
      <Layout>
        <UserHeader
          breadcrumbs={[{
            to: '/customers',
            label: 'Customers',
          }, {
            to: `/customers/${customerId}`,
            label: user && user.customer && user.customer.title,
          }, {
            to: `/customers/${customerId}/users`,
            label: 'Users',
          }]}
          loading={loading}
          user={{
            id: userId,
            ...user,
          }}
          customerId={customerId}
          actions={user && user.importerType === 'manual' && [
            {
              key: 'addUserGroupRelation',
              permissions: 'users.edit.manual',
              children: 'Add user group relation',
              leadingAddon: <PersonPlus />,
              to: `/customers/${customerId}/users/${userId}/user-groups/new`,
            },
          ]}
        />
        <section className="pt-4" aria-labelledby="User can reach these groups">
          <SectionTitle>User can reach these groups</SectionTitle>
          <SectionBody>
            <List>
              {user
                && user.userGroupRelations
                && user.userGroupRelations.map((relation) => (
                  <div
                    key={relation.id}
                    title={relation.bidirectional
                      ? `Users in this group can reach ${getFullName(user)} as well`
                      : undefined}
                  >
                    <ListItem
                      to={{
                        pathname: `/customers/${customerId}/user-groups`,
                        // eslint-disable-next-line max-len
                        search: `?userGroupBadgeIds=${relation.userGroupBadge.id}&userGroupIds=${relation.userGroup.id}`,
                      }}
                      trailingAddon={(
                        <ListItemActions
                          actions={user && user.importerType === 'manual' && [
                            {
                              key: 'delete',
                              children: 'Delete',
                              title: 'Delete',
                              permissions: 'users.edit.manual',
                              leadingAddon: <TrashBin />,
                              className: 'hover:bg-red-50 hover:text-red-900',
                              onClick: () => {
                                dispatch(deleteUserGroupRelation(userId, relation.id))
                              },
                            },
                          ]}
                        />
                      )}
                    >
                      <div
                        className="w-full flex flex-wrap justify-start items-center text-black font-bold font-display"
                      >
                        {getLocalisedLabelString(relation.userGroup.label)}
                        {relation.bidirectional && (
                          <Badge
                            leadingAddon={<ArrowsLeftAndRight />}
                            className="ml-2 bg-orange-500"
                          >
                            {!isMobile && 'Two-way'}
                          </Badge>
                        )}
                      </div>
                      <div className="w-full flex flex-wrap justify-start items-center mt-1 flex space-x-1">
                        {relation.userGroup.pathToUserGroup.map((labelI18n, i) => {
                          const label = getLocalisedLabelString(labelI18n)
                          return (
                            <span key={label}>
                              <WithAddon
                                leadingAddon={i !== 0 && <span className="text-sm text-gray-200 mr-1">/</span>}
                                className="p-0"
                              >
                                {label}
                              </WithAddon>
                            </span>
                          )
                        })}
                      </div>
                    </ListItem>
                  </div>
                ))}
            </List>
          </SectionBody>
        </section>
      </Layout>
    </>
  )
}

export default UserGroupRelationsScreen

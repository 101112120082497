import {
  Button,
  Clickable,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { ArrowRight } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function ListItem({
  children,
  leadingAddon,
  trailingAddon,
  onClick,
  to,
  href,
  className,
  title,
}) {
  const clickableProps = {
    onClick,
    to,
    href,
  }

  if (to || onClick || href) {
    return (
      <li className="py-4 text-sm" title={title}>
        <WithAddon
          leadingAddon={leadingAddon}
          trailingAddon={(
            <>
              {trailingAddon}
              {(to || onClick || href) && (
                <Button
                  key={to || href}
                  onClick={onClick}
                  to={to}
                  href={href}
                  small
                  neutral
                  leadingAddon={<ArrowRight />}
                  className="ml-2 text-gray-500 hover:bg-gray-100 group-hover:text-black"
                />
              )}
            </>
          )}
          wrapperClassName="group space-x-2"
          className="flex-grow justify-start"
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <Clickable className={cn('w-full', className)} {...clickableProps}>
            {children}
          </Clickable>
        </WithAddon>
      </li>
    )
  }
  return (
    <li className="py-4 text-sm">
      <WithAddon
        leadingAddon={leadingAddon}
        trailingAddon={[
          trailingAddon,
          (to || onClick || href) && (
            <Button
              key={to || href}
              onClick={onClick}
              to={to}
              href={href}
              small
              neutral
              leadingAddon={<ArrowRight />}
              className="ml-2 text-gray-500 hover:bg-gray-100 group-hover:text-black"
            />
          ),
        ]}
        wrapperClassName="group space-x-2"
        className="flex-grow justify-start"
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <div className={cn('w-full', className)} {...clickableProps}>
          {children}
        </div>
      </WithAddon>
    </li>
  )
}

ListItem.propTypes = {
  children: PropTypes.node,
  leadingAddon: PropTypes.node,
  trailingAddon: PropTypes.node,
  onClick: PropTypes.func,
  to: PropTypes.string,
  href: PropTypes.string,
  className: cn.propType,
  title: PropTypes.string,
}

ListItem.defaultProps = {
  children: null,
  leadingAddon: null,
  trailingAddon: null,
  onClick: null,
  to: null,
  href: null,
  className: null,
  title: null,
}

export default ListItem

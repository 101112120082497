import { createSelector } from 'reselect'
import getUserGroupsValues from './getUserGroupsValues'

export default createSelector(
  getUserGroupsValues,
  (_, props) => props.badgeId,
  (_, props) => props.parentId,
  (userGroups, badgeId, parentId) => userGroups
    .filter((userGroup) => (parentId
      ? userGroup.parentUserGroup && userGroup.parentUserGroup.id === parentId
      : !userGroup.parentUserGroup && userGroup.userGroupBadge.id === badgeId))
    .map((userGroup) => ({
      ...userGroup,
      hasChildren: !!userGroups.find((group) => group.parentUserGroup && group.parentUserGroup.id === userGroup.id),
    })),
)

import { Card } from '@Infowijs-eng/component-library/components'
import {
  ArrowRight,
  ArrowsLoop,
} from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import fetchCustomer from '../../actions/customers/fetchCustomer'
import updateCustomerMetadataLocations from '../../actions/customers/updateCustomerMetadataLocations'
import fetchImporterLocations from '../../actions/importers/fetchImporterLocations'
import updateImporter from '../../actions/importers/updateImporter'
import useEditImporterLocations from '../../modules/useEditImporterLocations'
import getCustomerMetadataLocations from '../../selectors/customers/getCustomerMetadataLocations'
import getCustomerTitle from '../../selectors/customers/getCustomerTitle'
import makeGetImporterById from '../../selectors/importers/makeGetImporterById'

import ModalWithSteps from '../ModalWithSteps'
import ImporterLocationEntry from './ImporterLocationEntry'

// eslint-disable-next-line max-len
const alertMessage = 'The importer setup is not yet finished and therefore cannot be used. You can edit this importer later to continue.\nAre you sure you wish to close this window?'

function ImporterEditLocations({ totalSteps, currentStep }) {
  const { customerId, importerId } = useParams()
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()
  const customerTitle = useSelector((state) => getCustomerTitle(state, { id: customerId }))
  const customerLocations = useSelector((state) => getCustomerMetadataLocations(state, { id: customerId }))
  const getImporterById = useMemo(
    () => makeGetImporterById(),
    [],
  )
  const importer = useSelector((state) => getImporterById(
    state,
    { id: importerId },
  ))
  const [selectedLocations, editLocation, toggleLocation, setSelectedLocations] = useEditImporterLocations()
  const [loading, setLoading] = useState(false)
  const [preventSubmit, setPreventSubmit] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [locations, setLocations] = useState([])
  const [errorMessages, setErrorMessages] = useState(null)

  useEffect(() => {
    setLoading(true)
    promisifyDispatch(fetchImporterLocations(importerId))
      .then((data) => {
        setLocations(data)
      })
      .catch((e) => {
        setErrorMessages(e)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [importerId])

  useEffect(() => {
    if (importer && importer.metadata && importer.metadata.config && importer.metadata.config.locations) {
      setSelectedLocations(importer.metadata.config.locations.reduce((acc, locationId) => {
        acc[locationId] = (customerLocations && customerLocations[locationId])
          || (locations && (locations.find(({ id }) => id === locationId) || {}).name)
          || null
        return acc
      }, {}))
    }
  }, [importer, locations, customerLocations])

  useEffect(() => {
    promisifyDispatch(fetchCustomer(customerId))
  }, [customerId])

  return (
    <ModalWithSteps
      title="Add Importer"
      currentStep={currentStep}
      // eslint-disable-next-line no-alert
      onClose={() => window.confirm(alertMessage) && navigate(`/customers/${customerId}`)}
      totalSteps={totalSteps}
      nextButtonProps={{
        disabled: submitting || preventSubmit || Object.values(selectedLocations || {}).length < 1,
        trailingAddon: submitting ? <ArrowsLoop className="animate-spin" /> : <ArrowRight />,
        onClick: () => {
          setSubmitting(true)
          setErrorMessages(null)
          Promise.all([
            promisifyDispatch(updateImporter({
              id: importerId,
              metadata: { config: { locations: [...Object.keys(selectedLocations)] } },
            })),
            promisifyDispatch(updateCustomerMetadataLocations(customerId, { locations: selectedLocations })),
          ])
            .then(() => {
              setSubmitting(false)
              navigate(`/customers/${customerId}/importers/create/${importer.type}/${importerId}/settings`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        },
      }}
      alert={errorMessages ? {
        title: 'Something went wrong while storing the importer\'s locations',
        errorMessages,
      } : null}
    >

      <div className="font-bold">Select locations to include in import</div>
      <Card className="px-4 py-2 divide-y divide-gray-50">
        {loading && (
          <div className="flex justify-center p-4">
            <ArrowsLoop className="animate-spin" />
          </div>
        )}
        {!loading && (!locations || locations.length < 1) && (
          <div className="py-2 italic">
            {/* eslint-disable-next-line max-len */}
            {importer && importer.type === 'somtoday' && (
              `No locations could be found. Are your sure ${customerTitle} has activated the`
              + ' Somtoday Connect for Infowijs?'
            )}
            {importer && importer.type !== 'somtoday' && (
              'No locations could be found.'
            )}
          </div>
        )}
        {!loading && locations && locations.map((location) => {
          const keyAlreadyExists = !!customerLocations && !!customerLocations[location.id] && (
            customerLocations[location.id] !== location.name
            || (!!selectedLocations[location.id] && customerLocations[location.id] !== selectedLocations[location.id])
          )
          const nameAlreadyExists = (!!customerLocations && !customerLocations[location.id]
            && Object.values(customerLocations)
              .find((locationName) => (
                (!selectedLocations[location.id] && location.name === locationName)
                || (!!selectedLocations[location.id] && selectedLocations[location.id] === locationName)
              )))

          const showDefaultName = (
            !!selectedLocations[location.id] && location.name !== selectedLocations[location.id]
          ) || (!selectedLocations[location.id] && keyAlreadyExists)

          return (
            <ImporterLocationEntry
              key={location.id}
              id={location.id}
              name={selectedLocations[location.id]
                || (!!customerLocations && customerLocations[location.id])
                || location.name}
              defaultName={location.name}
              showDefaultName={showDefaultName}
              brins={location.brins}
              selected={!!selectedLocations[location.id]}
              onToggle={toggleLocation}
              onSubmit={editLocation}
              onPreventSubmit={setPreventSubmit}
              keyAlreadyExists={!!keyAlreadyExists}
              nameAlreadyExists={!!nameAlreadyExists}
            />
          )
        })}
      </Card>
    </ModalWithSteps>
  )
}

ImporterEditLocations.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditLocations

import {
  useEffect,
  useState,
} from 'react'

export default function useFocus(ref) {
  const [focus, setFocus] = useState(false)

  const handleFocus = () => setFocus(true)
  const handleBlur = () => setTimeout(() => setFocus(false), 150)

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('focus', handleFocus)
      ref.current.addEventListener('blur', handleBlur)
    }

    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('focus', handleFocus)
        ref.current.removeEventListener('blur', handleBlur)
      }
    }
  }, [ref])

  return [focus, setFocus]
}

import { useState } from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { useNavigate } from 'react-router-dom'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import CustomerForm from '../components/Customer/CustomerForm'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import createCustomer from '../actions/customers/createCustomer'

function CustomerCreateScreen() {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/customers',
            label: 'Customers',
          },
        ]}
      >
        <Heading fontSize={Heading.size.H3}>Create a new Customer</Heading>
      </PageHeader>

      <div className="sticky top-0 z-50">
        {errorMessages && !!errorMessages.length && (<Alert title="You made a booboo" errorMessages={errorMessages} />)}
      </div>

      <CustomerForm
        submitting={submitting}
        onError={(errors) => setErrorMessages(errors)}
        onSubmit={({
          title,
          address,
          address2,
          postalCode,
          city,
          country,
          metadata,
        }) => {
          setSubmitting(true)
          promisifyDispatch(createCustomer({
            title,
            address: address || undefined,
            address2: address2 || undefined,
            postalCode: postalCode || undefined,
            city,
            country,
            metadata,
          }))
            .then((customer) => {
              navigate(`/customers/${customer.id}`)
            }).catch((e) => {
              setErrorMessages(e)
              setSubmitting(false)
            })
        }}
      />
    </Layout>
  )
}

export default CustomerCreateScreen

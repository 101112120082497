import actionTypes from '../../action-types'

export default (id, payload, included) => ({
  type: actionTypes.USER.RELATIONS_SET,
  payload,
  meta: {
    id,
  },
  included,
})

import { Skeleton } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import ListItem from '../ListItem'

function ImporterListItemSkeleton({ className }) {
  return (
    <div>
      <ListItem className={cn('animate-skeleton-1', className)}>
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-3/4" />
          <div className="flex gap-1">
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
          </div>
        </div>
      </ListItem>
      <ListItem className="animate-skeleton-2">
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-3/4" />
          <div className="flex gap-1">
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
          </div>
        </div>
      </ListItem>
      <ListItem className="animate-skeleton-3">
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-3/4" />
          <div className="flex gap-1">
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
            <Skeleton className="animate-none block w-1/6" />
          </div>
        </div>
      </ListItem>
    </div>
  )
}

ImporterListItemSkeleton.propTypes = {
  className: cn.propType,
}

ImporterListItemSkeleton.defaultProps = {
  className: null,
}

export default ImporterListItemSkeleton

import { Toggle } from '@Infowijs-eng/component-library/components'
import React from 'react'

type ToggleFieldProps = {
  value?: boolean
  onChange?: (change: boolean) => void
  label?: string
  readOnly?: boolean
}

function ToggleField({
  value = false,
  onChange = null,
  label = 'toggle',
  readOnly = false,
}: ToggleFieldProps) {
  return (
    <Toggle
      label={label}
      showLabel={false}
      enabled={value}
      onChange={(enabled: boolean) => onChange && onChange(enabled)}
      disabled={!onChange || readOnly}
    />
  )
}

export default ToggleField

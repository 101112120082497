import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  debounceAction,
} from '@Infowijs-eng/component-library/modules/authentication'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import actionTypes from '../../action-types'
import createCustomerProduct from '../../actions/customerProducts/createCustomerProduct'
import setCustomerProduct from '../../actions/customerProducts/setCustomerProduct'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onCreateCustomerProduct = function* onCreateCustomerProduct() {
  yield takeLatest(actionTypes.CUSTOMER_PRODUCTS.CREATE, function* onCreateCustomerProductSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const { payload, meta } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/${meta.customerId}/products`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: { ...payload },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reCreateCustomerProduct() {
          yield put(createCustomerProduct(meta.customerId, payload))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    if (response.status !== 204) {
      yield put(setCustomerProduct(response.data.data))
    }

    acceptAction(action, response.data.data)
  })
}

export default onCreateCustomerProduct

export default {
  FETCH_ALL: 'CUSTOMERS/FETCH_ALL',
  SET_ALL: 'CUSTOMERS/SET_ALL',
  FETCH: 'CUSTOMERS/FETCH',
  SET: 'CUSTOMERS/SET',
  UPDATE: 'CUSTOMERS/UPDATE',
  CREATE: 'CUSTOMERS/CREATE',
  SORT: 'CUSTOMERS/SORT',
  FILTER: 'CUSTOMER/FILTER',
  UPDATE_METADATA_LOCATIONS: 'CUSTOMER/UPDATE_METADATA_LOCATIONS',
}

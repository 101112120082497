import {
  Button,
  Heading,
} from '@Infowijs-eng/component-library/components'
import { Key } from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { getUserId } from '@Infowijs-eng/component-library/modules/authentication'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import fetchAdministratorPermissions from '../actions/administratorPermissions/fetchAdministratorPermissions'
import fetchAdministrator from '../actions/administrators/fetchAdministrator'
import startWebAuthNRegister from '../actions/webAuthN/startWebAuthNRegister'
import CopyToClipboard from '../components/CopyToClipboard'
import DetailField from '../components/DetailField'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import SectionTitle from '../components/SectionTitle'
import getFullName from '../modules/getFullName'
import getRecursiveAdministratorPermissions
  from '../selectors/administratorPermissions/getRecursiveAdministratorPermissions'
import getAdministrator from '../selectors/administrators/getAdministrator'
import isWebAuthNSupported from '../selectors/webAuthN/isWebAuthNSupported'

export default function AdministratorDetailScreen() {
  const supportsWebAuthn = useSelector(isWebAuthNSupported)
  const promisifyDispatch = usePromisifyDispatch()

  const [loading, setLoading] = useState(false)
  const [permissionGroups, setPermissionGroups] = useState({})
  const { administratorId } = useParams()
  const userId = useSelector(getUserId)
  const administrator = useSelector((state) => getAdministrator(state, { id: administratorId }))
  const isMe = userId === (administrator && administrator.id)

  const administratorPermissions = useSelector(getRecursiveAdministratorPermissions)

  useEffect(() => {
    if ((!administratorPermissions || administratorPermissions.length === 0)) {
      promisifyDispatch(fetchAdministratorPermissions())
    }
  }, [])

  useEffect(() => {
    if (!administratorId) {
      return
    }
    setLoading(true)
    promisifyDispatch(fetchAdministrator(administratorId))
      .then(() => {
        setLoading(false)
      })
  }, [administratorId])

  useEffect(() => {
    if (!administrator || !administratorPermissions) {
      return
    }

    const newPermissionGroups = {}
    administratorPermissions.forEach((administratorPermissionGroup) => {
      administratorPermissionGroup.permissions.forEach((administratorPermission) => {
        if (administrator.permissions.indexOf(administratorPermission.id) === -1) {
          return
        }
        if (!newPermissionGroups[administratorPermissionGroup.label]) {
          newPermissionGroups[administratorPermissionGroup.label] = []
        }
        newPermissionGroups[administratorPermissionGroup.label].push(administratorPermission.label)
      })
    })
    setPermissionGroups(newPermissionGroups)
  }, [administrator, administratorPermissions])

  return (
    <Layout>
      <PageHeader
        loading={loading}
        className="mb-5"
        actions={[
          {
            key: 'edit',
            children: 'Edit this administrator',
            primary: true,
            to: 'edit',
          },
        ]}
        breadcrumbs={[
          {
            to: '/administrators',
            label: 'Administrators',
          }]}
      >
        <Heading fontSize={Heading.size.H3}>{getFullName(administrator)}</Heading>
      </PageHeader>

      <section>
        <SectionTitle className="border-b border-gray-200 mb-2">Personal information</SectionTitle>
        <DetailField label="ID" loading={!administrator}>
          {administrator && (
            <div className="flex items-center">
              {administrator.id}
              <CopyToClipboard>{administrator.id}</CopyToClipboard>
            </div>
          )}
        </DetailField>
        <DetailField label="E-mail" loading={!administrator}>
          {administrator && (
            <div className="flex items-center">
              <a
                href={`mailto:${administrator.email}`}
                className="text-theme-600 hover:text-theme-700"
              >
                {administrator.email}
              </a>
              <CopyToClipboard>{administrator.email}</CopyToClipboard>
            </div>
          )}
        </DetailField>
        <DetailField
          label="Permissions"
          loading={!administrator || !administratorPermissions}
          className="grid grid-cols-2 gap-4"
        >
          {Object.keys(permissionGroups).length === 0 && (
            <div><i className="text-gray-300">No permissions assigned</i></div>
          )}
          {Object.keys(permissionGroups)
            .map((permissionGroup) => (
              <div>
                <Heading fontSize={Heading.size.H6}>{permissionGroup}</Heading>
                <ul className="list-disc list-inside">
                  {permissionGroups[permissionGroup].map((administratorPermission) => (
                    <li>{administratorPermission}</li>
                  ))}
                </ul>
              </div>
            ))}
        </DetailField>
      </section>
      {isMe && supportsWebAuthn && (
        <section>
          <SectionTitle className="border-b border-gray-200 mb-2">Secure Authentication</SectionTitle>
          <DetailField
            label="Passkey authentication"
            hint={(
              <a
                href="https://webauthn.guide/"
                className="hover:text-gray-500 transition-colors"
                target="_blank"
                rel="noreferrer"
              >
                What
                is a Passkey?
              </a>
            )}
          >
            <Button
              primary
              type="button"
              onClick={() => promisifyDispatch(startWebAuthNRegister())}
              leadingAddon={<Key />}
            >
              Register your Passkey
            </Button>
          </DetailField>
        </section>
      )}
    </Layout>
  )
}

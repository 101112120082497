import PropTypes from 'prop-types'

import NotificationCenterNotification from '../NotificationCenterNotification'
import StatusIcon from '../StatusIcon'

function NotificationCenter({
  notifications,
  setNotificationCenterHovered,
}) {
  return notifications && notifications.length > 0 && (
    <div
      className="group absolute z-30 left-0 sm:left-auto right-0 bottom-0 p-6 space-y-4"
      onMouseEnter={() => setNotificationCenterHovered(true)}
      onMouseLeave={() => setNotificationCenterHovered(false)}
    >
      {notifications.map(({
        id,
        title,
        actions,
        errorMessages,
        status,
      }, index) => {
        let opacity = 10
        if (index === notifications.length - 3) {
          opacity = 60
        }
        if (index === notifications.length - 2) {
          opacity = 80
        }
        if (index === notifications.length - 1) {
          opacity = 100
        }
        return (
          <NotificationCenterNotification
            key={id}
            id={id}
            title={title}
            actions={actions}
            errorMessages={errorMessages}
            icon={<StatusIcon status={status} />}
            opacity={opacity}
          />
        )
      })}
    </div>
  )
}

NotificationCenter.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
      permissions: PropTypes.arrayOf(PropTypes.string.isRequired),
      children: PropTypes.string,
      trailingAddon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
      to: PropTypes.string,
      neutral: PropTypes.bool,
    })),
    errorMessages: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string.isRequired,
      status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })),
    status: StatusIcon.propTypes.status,
  })),
  setNotificationCenterHovered: PropTypes.func.isRequired,
}

NotificationCenter.defaultProps = {
  notifications: [],
}

export default NotificationCenter

import actionTypes from '../action-types'

const initialState = {
  data: {},
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USER_GROUPS.ADD_ALL:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.customerProductId]: {
            ...state.data[action.meta.customerProductId],
            userGroups: action.payload.reduce((acc, userGroup) => {
              acc[userGroup.id] = {
                ...userGroup,
              }
              return acc
            }, state.data.userGroups || {}),
          },
        },
      }
    case actionTypes.USER_GROUPS.SET_ALL_BADGES:
      return {
        ...state,
        data: {
          ...state.data,
          [action.meta.customerProductId]: {
            ...state.data[action.meta.customerProductId],
            userGroupBadges: action.payload,
          },
        },
      }

    default:
      return state
  }
}

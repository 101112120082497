import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import fetchCustomer from '../../actions/customers/fetchCustomer'
import addNotification from '../../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../../components/StatusIcon'
import awaitAccessToken from '../../modules/awaitAccessToken'
import { NOTIFICATION_TIMEOUT_SHORT } from '../../modules/notificationCenter'
import getCustomer from '../../selectors/customers/getCustomer'

const onUpdateCustomerMetadataLocations = function* onUpdateCustomerMetadataLocations() {
  yield takeLatest(
    actionTypes.CUSTOMERS.UPDATE_METADATA_LOCATIONS,
    function* onUpdateCustomerMetadataLocationsSaga(action) {
      const { payload: { locations }, meta: { customerId } } = action

      const accessToken = yield call(awaitAccessToken)
      const customer = yield select((state) => getCustomer(state, { id: customerId }))

      let response

      try {
        response = yield call(axios, {
          url: `${serviceEndpoints.customers}/${customerId}`,
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'X-Infowijs-Client': 'nl.infowijs.admin',
            'Content-Type': 'application/vnd.infowijs.v1+json',
            Accept: 'application/vnd.infowijs.v1+json',
          },
          data: {
            customerGroupId: customer.customerGroupId,
            metadata: {
              locations,
            },
          },
        })
      } catch (e) {
        if (e.response.status === 401) {
          yield debounceAction(function* reUpdateCustomerMetadataLocations() {
            yield put(onUpdateCustomerMetadataLocationsSaga(customerId, locations))
          })
        }

        yield put(addNotification({
          title: 'Encountered an error while updating locations for this customer',
          status: STATUS_ERROR,
        }, NOTIFICATION_TIMEOUT_SHORT))

        rejectAction(action, e.response.data.errors || 'An unknown error occurred')
        return
      }

      if (!response || !response.data) {
        return
      }

      yield put(fetchCustomer(customerId))
      acceptAction(action, response.data.data)
    },
  )
}

export default onUpdateCustomerMetadataLocations

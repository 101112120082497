import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import addNotification from '../../actions/notificationCenter/addNotification'
import fetchUserRelations from '../../actions/user/fetchUserRelations'
import awaitAccessToken from '../../modules/awaitAccessToken'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'

const onCreateUserRelations = function* onCreateUserRelations() {
  yield takeLatest(actionTypes.USER.RELATIONS_CREATE, function* onCreateUserRelationsSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const { payload: { fromUserId, ...data } } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/${fromUserId}/relations`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
        data,
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(fetchUserRelations(fromUserId))
    yield put(addNotification({
      id: `user-${fromUserId}-relation-create`,
      title: 'User relation has been created',
    }, NOTIFICATION_TIMEOUT_LONG))
    acceptAction(action, response.data.data)
  })
}

export default onCreateUserRelations

import { Button } from '@Infowijs-eng/component-library/components'
import { ExclamationCircle } from '@Infowijs-eng/component-library/icons'
import CheckCircle from '@Infowijs-eng/component-library/icons/CheckCircle'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useRef,
  useState,
} from 'react'
import runManualImport from '../../actions/importers/runManualImport'

function ImporterManualUpload({
  importerId,
}) {
  const manualImportFileRef = useRef()
  const [selectedFiles, setSelectedFiles] = useState(null)
  const [failed, setFailed] = useState(false)
  const [successful, setSuccessful] = useState(false)
  const promisifyDispatch = usePromisifyDispatch()

  return (
    <div className="flex-col border-gray-50 border-2 rounded-lg px-4 py-2">
      <form onSubmit={(e) => e.preventDefault()}>
        <input
          name="file"
          type="file"
          ref={manualImportFileRef}
          onChange={(ev) => {
            setFailed(false)
            setSuccessful(false)
            const files = []
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < ev.target.files.length; i++) {
              files.push(ev.target.files.item(i).name)
            }
            setSelectedFiles(files)
          }}
          accept="text/csv"
          className="inline-block hidden"
          multiple
        />
        <div className="space-x-2 sm:whitespace-nowrap">
          <Button
            type="button"
            className="inline-block"
            onClick={() => {
              setFailed(false)
              setSuccessful(false)
              manualImportFileRef.current.click()
            }}
            small
            neutral={selectedFiles && selectedFiles.length > 0}
          >
            {(!selectedFiles || selectedFiles.length === 0) && 'Select CSV file'}
            <div className="truncate 2xl:max-w-xs max-w-[200px]">
              {(selectedFiles && selectedFiles.length > 0) && selectedFiles.join(', ')}
            </div>
          </Button>
          <Button
            className="inline-block"
            type="submit"
            small
            disabled={!selectedFiles || selectedFiles.length === 0}
            neutral={!selectedFiles || selectedFiles.length === 0}
            onClick={() => {
              if (selectedFiles.length) {
                promisifyDispatch(runManualImport({
                  importerId,
                  files: manualImportFileRef.current.files,
                }))
                  .then(() => {
                    setSuccessful(true)
                    setSelectedFiles(null)
                  })
                  .catch(() => {
                    setFailed(true)
                  })
              }
            }}
          >
            Import CSV
          </Button>
          {successful && <CheckCircle className="inline-block h-5 w-5 text-green-600" />}
          {failed && <ExclamationCircle className="inline-block h-5 w-5 text-orange-600" />}
        </div>
      </form>
    </div>
  )
}

ImporterManualUpload.propTypes = {
  importerId: PropTypes.string.isRequired,
}

ImporterManualUpload.defaultProps = {}

export default ImporterManualUpload

import {
  all,
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import addNotification from '../../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../../components/StatusIcon'

const onInitiateImpersonation = function* onInitiateImpersonation() {
  yield takeLatest(actionTypes.USER.INITIATE_IMPERSONATION, function* onInitiateImpersonationSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const {
      payload: { userCustomerProductKeyId },
    } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.sessions}/transfer/impersonate`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          userCustomerProductKeyId,
        },
      })
    } catch (e) {
      if (e.response.data && e.response.data.errors && Array.isArray(e.response.data.errors)) {
        yield all(e.response.data.errors.map((error) => {
          let notificationPayload
          switch (error.status) {
            case 1303:
              notificationPayload = {
                title: 'Login as user is only possible for Customer Products'
                  + ' where the users are migrated to Infowijs Platform.',
                status: STATUS_ERROR,
              }
              break
            case 1304:
              notificationPayload = {
                title: 'Login as this user is not allowed, because this user cannot login themselves.',
                status: STATUS_ERROR,
              }
              break
            default:
              notificationPayload = {
                title: 'An unknown error occurred',
                status: STATUS_ERROR,
              }
              break
          }
          return put(addNotification(notificationPayload))
        }))
      }

      rejectAction(action, e.response.data.errors)
      return
    }

    acceptAction(action, response.data.data)
  })
}

export default onInitiateImpersonation

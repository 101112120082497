import {
  Avatar,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import ListItem from '../ListItem'

function CustomerListSkeleton() {
  return (
    <div>
      <ListItem leadingAddon={<Avatar className="animate-none" />} className="animate-skeleton-1">
        <div className="w-full space-y-2">
          <Skeleton className="block w-64 animate-none" />
          <Skeleton className="block w-48 animate-none" />
        </div>
      </ListItem>
      <ListItem leadingAddon={<Avatar className="animate-none" />} className="animate-skeleton-2">
        <div className="w-full space-y-2">
          <Skeleton className="block w-64 animate-none" />
          <Skeleton className="block w-48 animate-none" />
        </div>
      </ListItem>
      <ListItem leadingAddon={<Avatar className="animate-none" />} className="animate-skeleton-3">
        <div className="w-full space-y-2">
          <Skeleton className="block w-64 animate-none" />
          <Skeleton className="block w-48 animate-none" />
        </div>
      </ListItem>
    </div>
  )
}

export default CustomerListSkeleton

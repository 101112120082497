import { createSelector } from 'reselect'
import getUser from './getUser'

export default function makeGetUserRelationsGroupedByUserId() {
  return createSelector(
    getUser,
    (user) => {
      if (!user || !user.relations || user.relations.length === 0) {
        return null
      }

      return user.relations.reduce((acc, relation) => {
        acc[relation.user.id] = { ...relation }
        return acc
      }, {})
    },
  )
}

import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Badge,
  Heading,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import {
  Lock,
  Pencil,
  TrashBin,
} from '@Infowijs-eng/component-library/icons'
import getFullName from '../../modules/getFullName'
import PageHeader from '../PageHeader'

function UserHeader({
  breadcrumbs,
  actions,
  customerId,
  user,
  loading,
}) {
  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      subNavigation={[
        {
          children: 'Overview',
          key: 'userDetail',
          end: true,
          to: `/customers/${customerId}/users/${user.id}`,
        },
        {
          key: 'userRelations',
          children: 'Relations',
          to: `/customers/${customerId}/users/${user.id}/relations`,
          permissions: 'users.view',
        },
        {
          key: 'userGroups',
          children: 'User Groups',
          to: `/customers/${customerId}/users/${user.id}/user-groups`,
          permissions: 'users.view',
        },
        {
          key: 'userNotifications',
          children: 'Notifications',
          to: `/customers/${customerId}/users/${user.id}/notifications`,
          permissions: ['notifications.view', 'users.view'],
        },
      ]}
      actions={[
        {
          key: 'userEdit',
          permissionsWithRegex: /users\.edit\.(manual|imported)/,
          children: 'Edit this user',
          leadingAddon: <Pencil />,
          primary: true,
          to: `/customers/${customerId}/users/${user.id}/edit`,
        },
        ...(actions || []),
      ]}
      loading={loading}
    >
      <Heading fontSize={Heading.size.H3}>
        {getFullName(user) || <Skeleton className="h-12 w-64 m-0" />}
      </Heading>
      <div className="flex space-x-2">
        {(!loading && (user
            && user.archivedAt
            && dayjs(user.archivedAt).isBefore(dayjs())
        )) && <Badge className="bg-red-500" leadingAddon={<TrashBin />}>User is archived</Badge>}
        {(!loading && (user
              && user.importerLockedUntil
              && dayjs(user.importerLockedUntil).isAfter(dayjs()))
        ) && (
        <Badge leadingAddon={<Lock />}>
          Locked from importer
        </Badge>
        )}
      </div>
    </PageHeader>
  )
}

UserHeader.propTypes = {
  breadcrumbs: PageHeader.propTypes.breadcrumbs,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    archivedAt: PropTypes.string,
    importerLockedUntil: PropTypes.string,
  }).isRequired,
  customerId: PropTypes.string.isRequired,
  actions: PageHeader.propTypes.actions,
  loading: PropTypes.bool,
}

UserHeader.defaultProps = {
  breadcrumbs: null,
  actions: null,
  loading: false,
}

export default UserHeader

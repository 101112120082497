import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import CustomerForm from '../components/Customer/CustomerForm'
import getCustomer from '../selectors/customers/getCustomer'
import fetchCustomer from '../actions/customers/fetchCustomer'
import updateCustomer from '../actions/customers/updateCustomer'
import PageHeader from '../components/PageHeader'

function CustomerEditScreen() {
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const { customerId } = useParams()
  const navigate = useNavigate()
  const customer = useSelector((state) => getCustomer(state, { id: customerId }))
  const promisifyDispatch = usePromisifyDispatch()

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchCustomer(customerId))
      .then(() => {
        setLoading(false)
      })
      .catch((e) => {
        setErrorMessages(e)
      })
  }, [customerId])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[
          {
            to: '/customers',
            label: 'Customers',
          },
          {
            to: `/customers/${customerId}`,
            label: customer && customer.title,
          },
        ]}
      >
        <Heading fontSize={Heading.size.H3}>Edit Customer</Heading>
      </PageHeader>

      <div className="sticky top-0 z-50">
        {errorMessages && !!errorMessages.length && (<Alert title="You made a booboo" errorMessages={errorMessages} />)}
      </div>

      <CustomerForm
        customer={customer}
        submitting={submitting}
        loading={loading}
        onError={(e) => {
          setErrorMessages(e)
        }}
        onSubmit={(payload) => {
          setSubmitting(true)
          promisifyDispatch(updateCustomer({
            id: customer.id,
            customerGroupId: customer.customerGroupId,
            ...payload,
          }))
            .then(() => {
              navigate('/customers')
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        }}
      />
    </Layout>
  )
}

export default CustomerEditScreen

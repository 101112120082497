import actionTypes from '../../action-types'

export default ({
  page, filter, limit, sort,
} = {}) => ({
  type: actionTypes.IMPORTERS.FETCH_ALL,
  meta: {
    filter,
    page,
    limit,
    sort,
  },
})

import dayjs from 'dayjs'

const now = dayjs()
const summerVacation = now
  .month(8)
  .date(1)
  .hour(0)
  .minute(0)
  .second(0)
  .millisecond(0)

const currentSchoolyearRange = now.isBefore(summerVacation)
  ? [now.year() - 1, now.year()]
  : [now.year(), now.year() + 1]

const previousSchoolyearRange = [
  currentSchoolyearRange[0] - 1,
  currentSchoolyearRange[1] - 1,
]

const nextSchoolyearRange = [
  currentSchoolyearRange[0] + 1,
  currentSchoolyearRange[1] + 1,
]

export const currentSchoolyear = `${currentSchoolyearRange[0]}-${currentSchoolyearRange[1]}`

export default [
  `${previousSchoolyearRange[0]}-${previousSchoolyearRange[1]}`,
  `${currentSchoolyearRange[0]}-${currentSchoolyearRange[1]}`,
  `${nextSchoolyearRange[0]}-${nextSchoolyearRange[1]}`,
]

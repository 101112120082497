import CheckCircle from '@Infowijs-eng/component-library/icons/CheckCircle'

import {
  ExclamationCircle,
  MinusCircle,
} from '@Infowijs-eng/component-library/icons'
import PropTypes from 'prop-types'

export const STATUS_WARNING = 'warning'
export const STATUS_ERROR = 'error'
export const STATUS_SUCCESS = 'success'

function StatusIcon({ status }) {
  switch (status) {
    case STATUS_WARNING:
      return (<ExclamationCircle className="h-5 w-5 text-orange-600" />)
    case STATUS_ERROR:
      return (<MinusCircle className="h-5 w-5 text-red-600" />)
    default:
      return (<CheckCircle className="h-5 w-5 text-green-600" />)
  }
}

StatusIcon.propTypes = {
  status: PropTypes.oneOf([STATUS_WARNING, STATUS_ERROR, STATUS_SUCCESS]),
}

StatusIcon.defaultProps = {
  status: STATUS_SUCCESS,
}

export default StatusIcon

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import setUsers from '../../actions/users/setUsers'

const onFetchUsers = function* onFetchUsers() {
  yield takeLatest(actionTypes.USERS.FETCH_ALL, function* onFetchUsersSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    const {
      meta: {
        page,
        sort,
        filter,
        limit,
      },
    } = action

    const params = {
      page: page || 1,
      limit,
      sort: sort || 'lastUpdatedAt:desc',
      include: 'userRole',
      ...filter,
    }

    let response
    try {
      response = yield call(axios, {
        url: serviceEndpoints.users,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
      })
    } catch (e) {
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    yield put(setUsers(response.data.data || [], response.data.meta, response.data.included || []))

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchUsers

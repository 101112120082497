import React, { useMemo } from 'react'
import { Dropdown, DropdownItem } from '@Infowijs-eng/component-library/components'
import dayjs from 'dayjs'

interface Importer {
  id: string
  type: string
  name?: string
  createdAt?: string
}

interface ImporterMigrateImporterSelectProps {
  onOldImporterIdChange: (id: string) => void
  onNewImporterIdChange: (id: string) => void
  importers: Importer[]
  oldImporterId: string
  newImporterId: string
}

export default function ImporterMigrateImporterSelect({
  onOldImporterIdChange,
  onNewImporterIdChange,
  importers,
  oldImporterId,
  newImporterId,
}: ImporterMigrateImporterSelectProps) {
  const localImporters = useMemo(() => importers.sort((a, b) => (
    dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix())).map((importer) => {
    let importerName = importer.name

    if (!importerName) {
      const importersOfType = importers.filter((filter) => filter.type === importer.type)
      if (importersOfType.length > 1) {
        const index = importersOfType.indexOf(importer)
        importerName = index > 0 ? `${importer.type} ${index + 1}` : importer.type
      } else {
        importerName = importer.type
      }
    }

    return ({
      id: importer.id,
      type: importer.type,
      name: importerName,
    } as Importer)
  }), [importers])

  const oldImporter = useMemo(
    () => localImporters.find((importer) => importer.id === oldImporterId),
    [oldImporterId, localImporters],
  )
  const newImporter = useMemo(
    () => localImporters.find((importer) => importer.id === newImporterId),
    [newImporterId, localImporters],
  )

  return (
    <div className="flex flex-col gap-4 mt-4">
      <div className="flex flex-row gap-4">
        <span className="font-medium w-36">From</span>
        <Dropdown
          label={oldImporter ? oldImporter.name : 'Select'}
          buttonProps={{
            small: true,
            neutral: true,
            className: 'hover:bg-gray-100 hover:text-black',
          }}
        >
          {localImporters.map((option) => {
            if (option.id === newImporterId) {
              return null
            }
            return (
              <DropdownItem small title={option.id} key={option.id} onClick={() => onOldImporterIdChange(option.id)}>
                {option.name}
              </DropdownItem>
            )
          })}
        </Dropdown>
      </div>
      <div className="bg-gray-100 h-[1px]" />
      <div className="flex flex-row gap-4">
        <span className="font-medium w-36">To</span>
        <Dropdown
          label={newImporter ? newImporter.name : 'Select'}
          buttonProps={{
            small: true,
            neutral: true,
            className: 'hover:bg-gray-100 hover:text-black',
          }}
        >
          {localImporters.map((option) => {
            if (option.id === oldImporterId) {
              return null
            }
            return (
              <DropdownItem small title={option.id} key={option.id} onClick={() => onNewImporterIdChange(option.id)}>
                {option.name}
              </DropdownItem>
            )
          })}
        </Dropdown>
      </div>
    </div>

  )
}

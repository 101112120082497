import actionTypes from '../../action-types'

export default (customerProductId, payload, meta, included) => ({
  type: actionTypes.USER_GROUPS.ADD_ALL,
  payload,
  meta: {
    ...meta,
    customerProductId,
  },
  included,
})

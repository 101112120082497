export default {
  type: 'object',
  optional: {
    logoUrl: {
      type: 'string',
      readOnly: true,
      labelI18n: {
        other: 'Logo',
        locale: 'en_US',
      },
    },
    color: {
      type: 'string',
      readOnly: true,
      labelI18n: {
        other: 'Color - deprecated, please use colors',
        locale: 'en_US',
      },
    },
    colors: {
      type: 'object',
      labelI18n: {
        other: 'Colors',
        locale: 'en_US',
      },
      optional: {
        news: {
          type: 'string',
          labelI18n: {
            other: 'News',
            locale: 'en_US',
          },
        },
        forms: {
          type: 'string',
          labelI18n: {
            other: 'Forms',
            locale: 'en_US',
          },
        },
        messages: {
          type: 'string',
          labelI18n: {
            other: 'Messages',
            locale: 'en_US',
          },
        },
        calendar: {
          type: 'string',
          labelI18n: {
            other: 'Calendar',
            locale: 'en_US',
          },
        },
        brand: {
          type: 'string',
          labelI18n: {
            other: 'Brand',
            locale: 'en_US',
          },
        },
      },
    },
  },
}

import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { useSelector } from 'react-redux'
import fetchCustomer from '../actions/customers/fetchCustomer'
import createUser from '../actions/user/createUser'
import Layout from '../components/Layout'
import UserForm from '../components/User/UserForm'
import getCustomerTitle from '../selectors/customers/getCustomerTitle'
import PageHeader from '../components/PageHeader'

export default function UserCreateScreen() {
  const navigate = useNavigate()
  const { customerId } = useParams()
  const customerTitle = useSelector((state) => getCustomerTitle(state, { id: customerId }))
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const promisifyDispatch = usePromisifyDispatch()

  useEffect(() => {
    if (!customerTitle) {
      promisifyDispatch(fetchCustomer(customerId))
        .catch((e) => {
          setErrorMessages(e)
        })
    }
  }, [fetchCustomer, customerId])

  return (
    <Layout>
      <PageHeader
        breadcrumbs={[{
          to: '/customers',
          label: 'Customers',
        }, {
          to: `/customers/${customerId}`,
          label: customerTitle,
        }, {
          to: `/customers/${customerId}/users`,
          label: 'Users',
        }]}
      >
        <Heading fontSize={Heading.size.H3}>Add a new User</Heading>
      </PageHeader>

      {!!errorMessages.length && (
        <div className="sticky top-0 z-50">
          <Alert title="You made a booboo" errorMessages={errorMessages} />
        </div>
      )}

      <UserForm
        submitting={submitting}
        onError={(e) => setErrorMessages(e)}
        onSubmit={({
          firstName,
          initials,
          lastNamePrefix,
          lastName,
          email,
          phone,
          userRoleId,
          metadata,
        }) => {
          setSubmitting(true)
          promisifyDispatch(createUser({
            customerId,
            firstName: firstName === null ? undefined : firstName,
            initials: initials === null ? undefined : initials,
            lastNamePrefix: lastNamePrefix === null ? undefined : lastNamePrefix,
            lastName,
            email,
            phone: (phone === null || !phone) ? undefined : phone,
            userRoleId,
            metadata: metadata === null ? undefined : metadata,
          }))
            .then(({ id }) => {
              navigate(`/customers/${customerId}/users/${id}`)
            })
            .catch((error) => {
              setSubmitting(false)
              setErrorMessages(error)
            })
        }}
      />
    </Layout>
  )
}

import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import fetchImporters from '../../actions/importers/fetchImporters'
import setImporters from '../../actions/importers/setImporters'
import awaitAccessToken from '../../modules/awaitAccessToken'

const onFetchImporters = function* onFetchImporters() {
  yield takeLatest(actionTypes.IMPORTERS.FETCH_ALL, function* onFetchImportersSaga(action) {
    const accessToken = yield call(awaitAccessToken)

    let response
    const {
      meta: {
        page,
        filter,
        sort,
        limit,
        customerId,
      },
    } = action

    const params = {
      include: 'administrator,customer',
      deletedAt: 'null:null',
      page: page || 1,
      sort: sort || 'title:asc',
      limit,
      ...filter,
    }

    try {
      response = yield call(axios, {
        url: serviceEndpoints.importers,
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reFetchImporters() {
          yield put(fetchImporters({ filter: { customerId: `eq:${customerId}` } }))
        })
      }

      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    if (response.status !== 204) {
      yield put(setImporters(response.data.data || [], response.data.meta, response.data.included || []))
    } else {
      yield put(setImporters([]))
    }

    acceptAction(action, response.data.data || [])
  })
}

export default onFetchImporters

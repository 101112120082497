import { createSelector } from 'reselect'
import getAdministratorPermissions from './getAdministratorPermissions'

const findPermissionDependencies = (permissions, permission) => {
  let { permissionDependencies } = permission

  if (!permissionDependencies || permissionDependencies.length === 0) {
    return permissionDependencies
  }

  permission.permissionDependencies.forEach((permissionDependency) => {
    permissionDependencies = permissionDependencies
      .concat(
        permissions[permissionDependency].permissionDependencies,
        findPermissionDependencies(permissions, permissions[permissionDependency]),
      )
  })

  return permissionDependencies
}

export default createSelector(
  getAdministratorPermissions,
  (administratorPermissionGroups) => {
    const permissions = administratorPermissionGroups.reduce((acc, group) => {
      group.permissions.forEach((permission) => {
        acc[permission.id] = permission
      })
      return acc
    }, {})

    const updatedPermissions = Object
      .values(permissions)
      .map((permission) => ({
        ...permission,
        permissionDependencies: findPermissionDependencies(permissions, permission),
      }))
      .reduce((acc, permission) => {
        acc[permission.id] = permission
        return acc
      }, {})

    return administratorPermissionGroups.map((group) => {
      const groupPermissions = group.permissions.map((permission) => updatedPermissions[permission.id])

      return {
        ...group,
        permissions: groupPermissions,
      }
    })
  },
)

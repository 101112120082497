import PropTypes from 'prop-types'
import { forwardRef } from 'react'
import Clickable from '@Infowijs-eng/component-library/components/Clickable'
import WithAddon from '@Infowijs-eng/component-library/components/WithAddon'
import cn from '@Infowijs-eng/component-library/modules/cn'

const Wrapper = forwardRef((props, ref) => {
  const { onClick, to, href } = props
  if (onClick || to || href) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Clickable {...props} ref={ref} />
  }

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <div {...props} />
})

Wrapper.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  })]),
  href: PropTypes.string,
}

Wrapper.defaultProps = {
  onClick: null,
  to: null,
  href: null,
}

function TextInputWithAutoCompleteResult({
  title,
  children,
  leadingAddon,
  trailingAddon,
  onClick,
  to,
  href,
  small,
  className,
  addonProps,
  active,
  ...wrapperProps
}) {
  return (
    <Wrapper
      className={cn(
        'cursor-pointer w-full flex items-center p-3 text-sm text-gray-900',
        'hover:bg-gray-50 focus:outline-none hover:bg-theme-50 hover:text-theme-600',
        small && 'text-xs px-1.5 py-2.5',
        active && 'bg-theme-50 text-theme-600',
        className,
      )}
          // eslint-disable-next-line react/jsx-props-no-spreading
      {...wrapperProps}
      onClick={onClick}
      to={to}
      href={href}
    >
      <WithAddon
        title={title}
        leadingAddon={leadingAddon}
        trailingAddon={trailingAddon}
        className="justify-start"
        fullWidth
            /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...addonProps}
      >
        {children}
      </WithAddon>
    </Wrapper>
  )
}

TextInputWithAutoCompleteResult.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    hash: PropTypes.string,
  })]),
  href: PropTypes.string,
  small: PropTypes.bool,
  leadingAddon: PropTypes.node,
  trailingAddon: PropTypes.node,
  addonProps: PropTypes.shape(WithAddon.PropTypes),
  className: cn.propType,
  active: PropTypes.bool,
}

TextInputWithAutoCompleteResult.defaultProps = {
  title: null,
  onClick: null,
  to: null,
  href: null,
  small: false,
  leadingAddon: null,
  trailingAddon: null,
  addonProps: null,
  className: null,
  active: false,
}

export default TextInputWithAutoCompleteResult

import { createSelector } from 'reselect'
import getUsers from './getUsers'

export default function makeGetUsersByCustomer() {
  return createSelector(
    getUsers,
    (_, props) => props.customerId,
    (users, customerId) => users.filter((user) => user.customer.id === customerId),
  )
}

import { UserStatus } from './types'

export default function getUniqueMatches(userStatuses: {[id: string]: UserStatus}): UserStatus[] {
  return Object.entries(userStatuses).flatMap(([userId, userStatus]) => {
    if (userStatus.from?.id !== userId && userStatus.from !== undefined) {
      return []
    }
    return [userStatus]
  })
}

import { createSelector } from 'reselect'
import getUser from './getUser'

export default function makeGetUserRelationsGroupedByParentRole() {
  return createSelector(
    getUser,
    (user) => {
      if (!user || !user.relations || user.relations.length === 0) {
        return null
      }

      return user.relations.sort((a, b) => a.rank - b.rank).reduce((acc, userRelation) => {
        let parentUserRole = userRelation.user.userRole
        if (userRelation.user.userRole.parentUserRole) {
          parentUserRole = {
            labelI18n: userRelation.user.userRole.parentLabelI18n,
            id: userRelation.user.userRole.parentUserRole.id,
          }
        }

        if (!acc[parentUserRole.id]) {
          acc[parentUserRole.id] = {
            ...parentUserRole,
          }
        }

        if (!acc[parentUserRole.id].userRelations) {
          acc[parentUserRole.id].userRelations = []
        }

        acc[parentUserRole.id].userRelations.push(userRelation)
        return acc
      }, {})
    },
  )
}

export const languages = {
  nl_NL: 'Dutch',
  en_US: 'English',
  ar_MA: 'Arabic (Morocco)',
  tr_TR: 'Turkish',
}

export const defaultLanguage = 'nl_NL'

export default (code) => languages[code] || 'N/A'

export default ({
  firstName,
  lastName,
  lastNamePrefix,
  initials,
}) => {
  let formattedLastName = `${lastNamePrefix || ''} ${lastName}`.trim()
  if (firstName || initials) {
    formattedLastName += ','
  }

  if (!lastName && !lastNamePrefix) {
    formattedLastName = ''
  }

  const fullName = [
    formattedLastName,
  ]

  if (firstName) {
    fullName.push(firstName)
  }

  if (initials) {
    fullName.push(firstName ? `(${initials})` : initials)
  }

  return fullName.join(' ')
}

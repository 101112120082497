import { WithAddon } from '@Infowijs-eng/component-library/components'
import {
  getLocalisedLabelString,
  useDefaultPageLimit,
} from '@Infowijs-eng/component-library/modules'
import dayjs from 'dayjs'

import { parse } from 'qs'
import { useEffect } from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useLocation,
  useParams,
} from 'react-router-dom'
import fetchUserRoles from '../actions/userRoles/fetchUserRoles'
import FilterBar from '../components/FilterBar'
import List from '../components/List'
import ListItem from '../components/ListItem'
import Pagination from '../components/Pagination'
import getDefaultPageLimits from '../modules/getDefaultPageLimits'
import usePagination from '../modules/usePagination'
import getUserRolesData from '../selectors/userRoles/getUserRoles'
import getUserRolesMeta from '../selectors/userRoles/getUserRolesMeta'
import CustomerContextScreen from './CustomerContextScreen'

export default function CustomerUserRolesScreen() {
  const { customerId } = useParams()
  const { search } = useLocation()
  const dispatch = useDispatch()
  const userRolesData = useSelector(getUserRolesData)

  const meta = useSelector(getUserRolesMeta)
  const [page, pages, total] = usePagination(meta)
  const limits = getDefaultPageLimits()
  const defaultPageLimit = limits && useDefaultPageLimit(limits)
  const {
    sort,
    limit,
  } = parse(search, { ignoreQueryPrefix: true })

  useEffect(() => {
    if (customerId) {
      dispatch(fetchUserRoles(customerId, {
        page,
        sort,
        limit: limit || defaultPageLimit,
      }))
    }
  }, [customerId, search])

  return (
    <CustomerContextScreen customerId={customerId}>
      <FilterBar
        sort={[
          {
            key: 'createdAt',
            label: 'Created at',
            defaultOrder: 'desc',
          },
          {
            key: 'legacyRole',
            label: 'Name',
          },
        ]}
        limits={limits}
      />
      <List>
        {userRolesData.length > 0 && userRolesData.map((userRole) => (
          <ListItem
            key={userRole.id}
            className="space-y-1"
            to={`/customers/${customerId}/users?userRoleId=eq:${userRole.id}`}
          >
            <div className="text-black font-bold font-display">
              {getLocalisedLabelString(userRole.labelI18n)}
            </div>
            <WithAddon
              className="w-full justify-start"
              trailingAddon={(
                <div className="w-full flex flex-wrap justify-start items-center" title={userRole.createdAt}>
                  {`Created at: ${dayjs(userRole.createdAt)
                    .format('MMM DD, YYYY [at] HH:mm')}`}
                </div>
              )}
            >
              {`${userRole.activeUserCount} ${getLocalisedLabelString(
                {
                  locale: 'en_US',
                  one: 'User',
                  other: 'Users',
                },
                userRole.activeUserCount,
              )}`}
            </WithAddon>
          </ListItem>
        ))}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && (<Pagination current={page || 1} total={pages} />)}
    </CustomerContextScreen>
  )
}

import { ImporterUser, UserStatus } from './types'

export default function clearPossibleMatchesForUser(
  userStatuses: {[key: string]: UserStatus},
  sourceUser: ImporterUser,
) {
  const sourceUserStatus = userStatuses[sourceUser.id]
  sourceUserStatus.possibleMatches?.forEach((possibleMatch) => {
    const possibleMatchStatus = userStatuses[possibleMatch.user.id]
    if (!possibleMatchStatus) {
      return
    }
    possibleMatchStatus.possibleMatches = possibleMatchStatus.possibleMatches?.filter(
      (matchesMatch) => matchesMatch.user.id !== sourceUser.id,
    ) ?? []
  })

  sourceUserStatus.possibleMatches = []
}

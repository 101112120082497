import PropTypes from 'prop-types'

import {
  Link,
  useSearchParams,
} from 'react-router-dom'
import createSearchParams from '../../modules/createSearchParams'

export default function UserGroupWrapper({ userGroup, isSelected, ...props }) {
  const [searchParams] = useSearchParams()

  if (!userGroup) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <div {...props} />
  }

  return (
    <Link
      to={{
        search: createSearchParams(
          searchParams,
          'userGroupIds',
          !isSelected && userGroup && userGroup.id,
          isSelected ? userGroup.childrenIds : new Set([userGroup.id]),
        ).toString(),
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  )
}

UserGroupWrapper.propTypes = {
  userGroup: PropTypes.shape({
    id: PropTypes.string,
    childrenIds: PropTypes.arrayOf(PropTypes.string),
  }),
  isSelected: PropTypes.bool,
}

UserGroupWrapper.defaultProps = {
  userGroup: null,
  isSelected: false,
}

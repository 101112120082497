import { Button, Heading } from '@Infowijs-eng/component-library/components'
import { useNavigate } from 'react-router-dom'

interface ImporterMigrateSuccessProps {
  customerId: string
}

export default function ImporterMigrateSuccess({ customerId }: ImporterMigrateSuccessProps) {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col items-center mt-4 gap-4">
      <div className="text-[3rem]">🕐</div>
      <Heading fontSize={Heading.size.H3}>Your work here is done!</Heading>
      <div className="flex flex-col items-center whitespace-pre">
        <span>The backend is currently attempting to migrate the users for you.</span>
        <span>
          You can close this screen and check the customer product later to see if the migration was successful.
        </span>
      </div>
      <Button onClick={() => {
        navigate(
          `/customers/${customerId}`,
          { replace: true },
        )
      }}
      >
        Back to Customer
      </Button>

    </div>
  )
}

import {
  Avatar,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import ListItem from '../ListItem'

function UserListSkeleton({ className }) {
  return (
    <div>
      <ListItem className={cn('animate-skeleton-1', className)} leadingAddon={<Avatar className="animate-none" />}>
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-64" />
          <Skeleton className="animate-none block w-48" />
        </div>
      </ListItem>
      <ListItem className={cn('animate-skeleton-2', className)} leadingAddon={<Avatar className="animate-none" />}>
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-64" />
          <Skeleton className="animate-none block w-48" />
        </div>
      </ListItem>
      <ListItem className={cn('animate-skeleton-3', className)} leadingAddon={<Avatar className="animate-none" />}>
        <div className="w-full space-y-2">
          <Skeleton className="animate-none block w-64" />
          <Skeleton className="animate-none block w-48" />
        </div>
      </ListItem>
    </div>
  )
}

UserListSkeleton.propTypes = {
  className: cn.propType,
}

UserListSkeleton.defaultProps = {
  className: null,
}

export default UserListSkeleton

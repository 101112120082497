import { Skeleton } from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function DetailCardField({
  loading,
  label,
  children,
  className,
  title,
}) {
  return (loading || children) && (
    <div>
      {label && (
        <div className="text-sm font-medium text-gray-500">
          {label}
        </div>
      )}
      {loading && <Skeleton sm />}
      {(!loading && children) && (
        <div className={cn('mt-1 text-sm text-gray-900', className)} title={title}>{children}</div>
      )}
    </div>
  )
}

DetailCardField.propTypes = {
  loading: PropTypes.bool,
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  className: cn.propType,
  title: PropTypes.string,
}

DetailCardField.defaultProps = {
  label: null,
  loading: false,
  children: null,
  className: null,
  title: null,
}

export default DetailCardField

import { TextInput } from '@Infowijs-eng/component-library/components'
import { XMark } from '@Infowijs-eng/component-library/icons'
import React, { useState } from 'react'
import MetadataEditorButton from '../MetadataEditorButton'

type TextInputFieldProps = {
  value: string
  inputType?: string
  textInputPlaceholder?: string
  buttonPlaceholder?: string
  onChange?: (change: string) => void
  readOnly?: boolean
  required?: boolean
}

function TextInputField({
  value,
  inputType = null,
  textInputPlaceholder = 'This field is required',
  buttonPlaceholder = 'Click to add value',
  onChange = null,
  readOnly = false,
  required = false,
}: TextInputFieldProps) {
  const [isEditing, setIsEditing] = useState(false)
  const [isFieldDirty, setFieldDirty] = useState(false)

  const revertChanges = (e: Event) => {
    let confirm = true
    if (isFieldDirty) {
      // eslint-disable-next-line no-alert
      confirm = window.confirm('You\'ve made changes, do you want to revert your changes?')
    }
    if (confirm) {
      setIsEditing(false)
      return
    }
    setTimeout(() => {
      if (!e || !e.target) {
        return
      }
      (e.target as HTMLInputElement).focus()
    }, 100)
  }

  if (isEditing) {
    return (
      <TextInput
        type={inputType}
        small
        name=""
        defaultValue={value}
        className="rounded-none focus:ring-0 focus:border-0 p-2 text-sm"
        placeholder={textInputPlaceholder}
        autoFocus
        onBlur={(e: FocusEvent) => {
          revertChanges(e)
        }}
        onKeyDown={(e: KeyboardEvent) => {
          if (!onChange) {
            return
          }

          if ([13, 27].indexOf(e.keyCode || e.which) > -1) {
            e.preventDefault()
            e.stopPropagation()

            // ESC
            if ((e.keyCode || e.which) === 27) {
              revertChanges(e)
              return
            }

            // ENTER
            if ((e.keyCode || e.which) === 13) {
              onChange(((e.target as HTMLInputElement).value))
            }

            setIsEditing(false)
            setFieldDirty(false)
            return
          }

          setFieldDirty(true)
        }}
      />
    )
  }

  return (
    <div className="flex flex-1 justify-between">
      <MetadataEditorButton
        onClick={() => onChange && setIsEditing(true)}
        disabled={!onChange || (readOnly)}
      >
        {value && (
          <span className="truncate max-w-xs" title={value}>{value}</span>
        )}
        {!value && buttonPlaceholder}
      </MetadataEditorButton>
      {!!onChange && !required && !readOnly && (
        <MetadataEditorButton
          className="group-hover:block hidden"
          onClick={() => onChange(null)}
        >
          <XMark />
        </MetadataEditorButton>
      )}
    </div>
  )
}

export default TextInputField

import {
  XMarkCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  useEffect,
  useRef,
  useState,
} from 'react'
import PropTypes from 'prop-types'
import useFocus from '../modules/useFocus'
import TextInput from './TextInput'
import TextInputWithAutoCompleteResult from './TextInputWithAutoCompleteResult'

function TextInputWithAutoComplete({
  value, autoCompleteResults, onAutoCompleteSelect, onReset, trailingAddon, ...props
}) {
  const ref = useRef(null)
  const [focus] = useFocus(ref)
  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (value) {
      setSearchValue(value)
    }
  }, [value])

  let inputMenu = null

  if (focus) {
    if (searchValue.length > 0) {
      inputMenu = (
        <div className="bg-white shadow-md rounded-md">
          <TextInputWithAutoCompleteResult disabled>
            Geen resultaten gevonden
          </TextInputWithAutoCompleteResult>
        </div>
      )
    }

    if (autoCompleteResults && autoCompleteResults.length > 0) {
      inputMenu = (
        <div className="overflow-y-scroll max-h-96 bg-white shadow-md rounded-md">
          {autoCompleteResults.map((result) => (
            <TextInputWithAutoCompleteResult
              key={result.key}
              onClick={() => {
                onAutoCompleteSelect(result.key)
                setSearchValue('')
              }}
            >
              <div className="w-full truncate text-left">
                {result.meta && <div className="text-gray-400 text-xs">{result.meta}</div>}
                <div className="text-black font-extrabold">{result.label}</div>
              </div>
            </TextInputWithAutoCompleteResult>
          ))}
        </div>
      )
    }
  }

  return (

    <TextInput
      ref={ref}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      onChange={(e) => {
        setSearchValue(e.target.value)
        props.onChange(e)
      }}
      value={searchValue}
      trailingAddon={(onReset && searchValue.length > 0) ? (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          type="button"
          onClick={() => {
            onReset()
            setSearchValue('')
          }}
        >
          <XMarkCircle className="px-1 text-[24px]" />
        </button>
      ) : trailingAddon}
      inputMenu={inputMenu}
    />
  )
}

TextInputWithAutoComplete.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onReset: PropTypes.func,
  onAutoCompleteSelect: PropTypes.func.isRequired,
  autoCompleteResults: PropTypes.arrayOf(PropTypes.shape()),
  trailingAddon: TextInput.propTypes.trailingAddon,
}

TextInputWithAutoComplete.defaultProps = {
  value: undefined,
  autoCompleteResults: null,
  onReset: null,
  trailingAddon: null,
}

export default TextInputWithAutoComplete

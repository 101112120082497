import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import axios from 'axios'
import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects'
import actionTypes from '../../action-types'
import addNotification from '../../actions/notificationCenter/addNotification'
import createUserRelation from '../../actions/user/createUserRelation'
import deleteUserRelationSuccessful from '../../actions/user/deleteUserRelationSuccessful'
import { STATUS_ERROR } from '../../components/StatusIcon'
import awaitAccessToken from '../../modules/awaitAccessToken'
import { NOTIFICATION_TIMEOUT_LONG } from '../../modules/notificationCenter'
import makeGetUserRelationByToUserId from '../../selectors/user/makeGetUserRelationByToUserId'

const onDeleteUserRelation = function* onDeleteUserRelation() {
  yield takeLatest(actionTypes.USER.RELATION_DELETE, function* onDeleteUserRelationSaga(action) {
    const accessToken = yield call(awaitAccessToken)
    const { payload: { fromUserId, toUserId } } = action

    let response
    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.users}/${fromUserId}/relations/${toUserId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
          'X-Infowijs-Client': 'nl.infowijs.admin',
        },
      })
    } catch (e) {
      yield put(addNotification({
        title: 'An error occurred while deleting this user relation',
        status: STATUS_ERROR,
        errorMessages: e.response.data && e.response.data.errors,
      }, NOTIFICATION_TIMEOUT_LONG))
      rejectAction(action, e.response.data && e.response.data.errors)
      return
    }

    const getUserRelationByToUserId = makeGetUserRelationByToUserId()

    const userRelation = yield select(getUserRelationByToUserId, { toUserId })

    yield put(deleteUserRelationSuccessful(toUserId))
    yield put(addNotification({
      id: `user-${fromUserId}-relation-deleted`,
      title: 'User relation has been deleted',
      actions: [
        {
          key: 'undo',
          children: 'Undo',
          dispatchOnClick: createUserRelation(
            fromUserId,
            toUserId,
            userRelation && userRelation.metadata ? userRelation.metadata : undefined,
            userRelation && userRelation.metadata ? userRelation.metadata : undefined,
          ),
        },
      ],

    }, NOTIFICATION_TIMEOUT_LONG))
    acceptAction(action, response.data.data)
  })
}

export default onDeleteUserRelation

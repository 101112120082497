import {
  call,
  delay,
  put,
  race,
  select,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  getSessionRequest,
  verifySessionRequest,
} from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import setRefreshToken from '../../actions/authentication/setRefreshToken'

const onSetSessionRequest = function* onSetSessionRequest() {
  yield takeLatest([actionTypes.AUTHENTICATION.SET_SESSION_REQUEST], function* setSessionRequest() {
    while (true) {
      // TODO resume interval after page reload when there is still a SessionRequest in memory
      yield race({
        interval: delay(1000), // Check every 1s
      })

      const sessionRequest = yield select(getSessionRequest)

      if (!sessionRequest) {
        break
      }

      let response

      try {
        response = yield call(axios, {
          // eslint-disable-next-line max-len
          url: `${serviceEndpoints.sessions}/admin/${sessionRequest.id}`,
          method: 'POST',
          headers: {
            'X-Infowijs-Client': 'nl.infowijs.admin',
            'Content-Type': 'application/vnd.infowijs.v1+json',
            Accept: 'application/vnd.infowijs.v1+json',
          },
        })
      } catch (e) {
        return
      }

      if (response.status === 204) {
        yield put(verifySessionRequest())
        return // Return to cancel the race
      }

      if (response && response.data && !response.data.data.status) {
        yield put(setRefreshToken(response.data.data))
        break
      }
    }
  })
}

export default onSetSessionRequest

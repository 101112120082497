import actionTypes from '../../action-types'

export default (userId, customerProductId) => ({
  type: actionTypes.USER.ACCESS_INVITE,
  payload: {
    customerProductId,
  },
  meta: {
    userId,
  },
})

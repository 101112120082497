import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Alert,
  BackgroundBubble,
} from '@Infowijs-eng/component-library/components'
import { useDispatch } from 'react-redux'
import { signOut } from '@Infowijs-eng/component-library/modules/authentication'

function SignOutScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(signOut())

    const timer = setTimeout(() => {
      navigate('/sign-in')
    }, 2000)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <div>
      <BackgroundBubble />
      <div className="min-h-screen flex flex-col md:px-5 items-center justify-center">
        <div className="mb-5">
          <Alert success title="Bye bye now." />
        </div>
      </div>
    </div>
  )
}

export default SignOutScreen

import {
  call,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import actionTypes from '../../action-types'

const onFetchSomtodayInstitute = function* onFetchSomtodayInstitute() {
  yield takeLatest(actionTypes.IMPORTERS.FETCH_SOMTODAY_INSTITUTES, function* onFetchSomtodayInstituteSaga(action) {
    let response
    try {
      response = yield call(axios, {
        url: 'https://api.somtoday.nl/rest/v1/connect/instelling',
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
    } catch (e) {
      rejectAction(action)
      return
    }

    acceptAction(action, response.data.instellingen || [])
  })
}

export default onFetchSomtodayInstitute

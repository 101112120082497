import { Alert } from '@Infowijs-eng/component-library/components'
import { PlusCircle } from '@Infowijs-eng/component-library/icons'
import {
  useDefaultPageLimit,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import { parse } from 'qs'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useParams,
  useSearchParams,
} from 'react-router-dom'
import fetchUserRoles from '../actions/userRoles/fetchUserRoles'
import fetchUsers from '../actions/users/fetchUsers'
import FilterBar from '../components/FilterBar'
import List from '../components/List'
import ListItem from '../components/ListItem'
import Pagination from '../components/Pagination'
import UserListItem from '../components/User/UserListItem'
import UserListSkeleton from '../components/User/UserListSkeleton'
import filters from '../modules/filterBarFilters'
import getDefaultPageLimits from '../modules/getDefaultPageLimits'
import usePagination from '../modules/usePagination'
import getUserRolesForAutoComplete from '../selectors/userRoles/getUserRolesForAutoComplete'
import makeGetFilteredUserRolesForAutoComplete from '../selectors/userRoles/makeGetFilteredUserRolesForAutoComplete'
import getUsersMeta from '../selectors/users/getUsersMeta'
import makeGetUsersByCustomer from '../selectors/users/makeGetUsersByCustomer'
import CustomerContextScreen from './CustomerContextScreen'

function CustomerUsersScreen() {
  const { customerId } = useParams()
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()

  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [userRoleSearchValue, setUserRoleSearchValue] = useState('')

  const getUsersByCustomer = useMemo(makeGetUsersByCustomer, [customerId])
  const getFilteredUserRoles = useMemo(makeGetFilteredUserRolesForAutoComplete, [userRoleSearchValue])
  const users = useSelector((state) => getUsersByCustomer(state, { customerId }))
  const userRolesForAutoComplete = useSelector(getUserRolesForAutoComplete)
  const filteredUseRolesForAutocomplete = useSelector((state) => getFilteredUserRoles(state, { userRoleSearchValue }))

  const [searchParams] = useSearchParams()
  const {
    sort,
    limit,
    ...filter
  } = parse(searchParams.toString(), { ignoreQueryPrefix: true })
  const meta = useSelector(getUsersMeta)
  const [page, pages, total] = usePagination(meta)
  const limits = getDefaultPageLimits()
  const defaultPageLimit = limits && useDefaultPageLimit(limits)

  const userRoleBadgeValue = filter.userRoleId
    && userRolesForAutoComplete
    && userRolesForAutoComplete.find(({ key }) => key === filter.userRoleId.split(':')[1])

  useEffect(() => {
    if (dispatch) {
      dispatch(fetchUserRoles(customerId))
    }
  }, [customerId])

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchUsers({
      page,
      sort,
      limit: limit || defaultPageLimit,
      filter: {
        customerId: `eq:${customerId}`,
        ...filter,
      },
    }))
      .then(() => setLoading(false))
      .catch(setErrorMessages)
      .finally(() => setLoading(false))
  }, [searchParams])

  return (
    <CustomerContextScreen
      loading={loading}
      customerId={customerId}
      secondaryActions={[
        {
          key: 'user.add',
          children: 'Add new User',
          permissions: ['users.create'],
          leadingAddon: <PlusCircle />,
          to: `/customers/${customerId}/users/new`,
        },
      ]}
    >
      <FilterBar
        filters={[
          {
            key: 'fullName',
            label: 'Full Name',
          },
          {
            key: 'email',
            label: 'Email Address',
          },
          {
            ...filters.userRoleId,
            value: userRoleBadgeValue && userRoleBadgeValue.label,
            onChange: (e) => setUserRoleSearchValue(e.target.value.trim()
              .toLowerCase()),
            autoCompleteResults: filteredUseRolesForAutocomplete,
            debounce: 150,
          },
        ]}
        sort={[
          {
            key: 'lastUpdatedAt',
            label: 'Last updated',
            defaultOrder: 'desc',
          },
          {
            key: 'lastName',
            label: 'Last name',
          },
          {
            key: 'importerLockedUntil',
            label: 'Importer locking',
          },
        ]}
        limits={limits}
      />
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List>
        {loading && !users.length && <UserListSkeleton />}
        {!loading && !users.length && <ListItem className="italic">No results</ListItem>}
        {!loading && !!users.length && users.map((user) => <UserListItem key={user.id} user={user} />)}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && <Pagination current={page || 1} total={pages} />}
    </CustomerContextScreen>
  )
}

export default CustomerUsersScreen

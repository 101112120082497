import React, { useMemo } from 'react'
import { Checkbox } from '@Infowijs-eng/component-library/components'
import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import { UserRole, UserStatus } from '../../../modules/importerSwitch/types'
import getUniqueMatches from '../../../modules/importerSwitch/getUniqueMatches'
import Table from '../../Table'

interface ImporterMigrateOverviewProps {
  userRoles: { [id: string]: UserRole }
  userStatuses: { [id: string]: UserStatus }
  onConfirmChanges: (e: boolean) => void
  hasConfirmedChanges: boolean
}

export default function ImporterMigrateOverview({
  userRoles, userStatuses, onConfirmChanges, hasConfirmedChanges,
}: ImporterMigrateOverviewProps) {
  const topMostRoles = useMemo(() => Object.values(userRoles).filter((role) => !role.parentUserRoleId), [userRoles])
  const rows = { matched: 'Matched', added: 'Added', removed: 'Removed' }

  const countPerTopMostRole = useMemo(() => {
    const countPerRole = {}
    const uniqueMatches = getUniqueMatches(userStatuses)

    topMostRoles.forEach((role) => {
      if (!countPerRole[role.id]) {
        countPerRole[role.id] = {
          matched: 0,
          added: 0,
          removed: 0,
        }
      }

      uniqueMatches.forEach((userStatus) => {
        const userRole = userStatus?.to?.userRole?.id ?? userStatus?.from?.userRole?.id
        if (!userRole || role.id !== userRole) {
          return
        }

        if (userStatus.from && userStatus.to) {
          countPerRole[role.id].matched += 1
          return
        }
        if (userStatus.to && !userStatus.from) {
          countPerRole[role.id].added += 1
        }
        if (userStatus.from && !userStatus.to) {
          countPerRole[role.id].removed += 1
        }
      })
    })

    return countPerRole
  }, [topMostRoles, userStatuses])

  const tableHead = useMemo(() => [
    [
      { key: 'blanco', tag: 'th' },
      ...topMostRoles.map((role) => ({
        children: getLocalisedLabelString(role.labelI18n),
        key: `label-${role.id}`,
        tag: 'th',
      })),
      { key: 'total', children: 'Total', tag: 'th' },
    ],
  ], [topMostRoles])

  const tableBody = useMemo(() => (
    Object.entries(rows).map(([key, name]) => [{
      key,
      children: name,
      tag: 'th',
    },
    ...Object.entries(countPerTopMostRole).map(([id, role]) => ({
      key: `${key}-${id}`,
      children: role[key],
    })),
    {
      key: 'total',
      children: Object.values(
        (countPerTopMostRole),
      ).reduce((accumulator, currentValue) => accumulator + currentValue[key], 0),
    },
    ])), [])

  return (
    <div className="flex flex-col gap-4">
      <div className="bg-gray-100 h-[1px]" />
      <Table head={tableHead} body={tableBody} />
      <div className="">
        {'The changes listed above are permanent and cannot be undone.'
          + ' Please review them carefully to ensure they match your expectations based on your previous actions.'}
      </div>
      <Checkbox
        checked={hasConfirmedChanges}
        onChange={onConfirmChanges}
        label="I have reviewed the changes and confirm that they are correct."
      />
    </div>
  )
}

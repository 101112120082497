import { useState } from 'react'
import PropTypes from 'prop-types'
import { FormField } from '@Infowijs-eng/component-library/components'
import { ArrowsLoop } from '@Infowijs-eng/component-library/icons'
import {
  usePromisifyDispatch,
  validateEmail,
} from '@Infowijs-eng/component-library/modules'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import createImporter from '../../actions/importers/createImporter'
import ModalWithSteps from '../ModalWithSteps'

function ImporterEditMagisterAdminCredentials({ totalSteps, currentStep }) {
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()
  const { customerId } = useParams()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  return (
    <ModalWithSteps
      totalSteps={totalSteps}
      currentStep={currentStep}
      title="Add Importer"
      onClose={() => navigate(`/customers/${customerId}`)}
      nextButtonProps={{
        children: 'Send invite',
        disabled: !!(
          !!name && name.length < 2
          && !!email && email.length < 2
          && !!errorMessages && errorMessages.length < 1
        ),
        trailingAddon: submitting ? <ArrowsLoop className="animate-spin" /> : null,
        onClick: () => {
          const errors = []
          try {
            validateEmail(email)
          } catch (error) {
            errors.push({ title: error.message })
          }

          if (errors.length !== 0) {
            setErrorMessages(errors)
            return
          }

          setErrorMessages(null)
          setSubmitting(true)
          promisifyDispatch(createImporter({
            customerId,
            type: 'magister',
            metadata: {
              config: { credentialsSetup: { name, email } },
            },
          }))
            .then((data) => {
              setSubmitting(false)
              // eslint-disable-next-line max-len
              navigate(`/customers/${customerId}/importers/create/magister/${data.id}`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        },
      }}
      previousButtonProps={{
        to: `/customers/${customerId}/importers/create`,
      }}
      alert={errorMessages ? {
        title: 'Something went wrong while creating the importer',
        errorMessages,
      } : null}
    >
      <div className="font-bold">
        Invite the Magister admin to setup the credentials to gain access to the Magister environment.
      </div>
      <div className="w-full grid grid-cols-8 gap-6">
        <FormField
          required
          name="name"
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          inputProps={{
            className: 'bg-transparent',
          }}
        />
        <FormField
          required
          name="email"
          label="Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          inputProps={{
            className: 'bg-transparent',
          }}
        />
      </div>
    </ModalWithSteps>
  )
}

ImporterEditMagisterAdminCredentials.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditMagisterAdminCredentials

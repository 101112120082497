import { Badge } from '@Infowijs-eng/component-library/components'
import {
  cn,
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

import { useSearchParams } from 'react-router-dom'
import UserGroupWrapper from './UserGroupWrapper'

function UserGroups({ userGroups }) {
  const [searchParams] = useSearchParams()
  const userGroupIds = (searchParams.get('userGroupIds') || '').split(',').filter(Boolean)

  return userGroups && userGroups.map((userGroup) => {
    const isSelected = !!userGroupIds.find((id) => userGroup.childrenIds.has(id))
    const hasChildren = userGroup.children && userGroup.children.length > 0

    return (
      <div key={userGroup.id} className="group space-y-1 pl-4">
        <div
          className={cn(
            'p-1.5 pr-2.5 rounded flex items-center',
            isSelected && 'bg-gray-50',
            hasChildren && 'hover:bg-gray-50',
          )}
        >
          <UserGroupWrapper
            userGroup={hasChildren && userGroup}
            isSelected={isSelected}
            className="flex-1"
          >
            <div
              className={cn(
                'bg-green-100 h-2 w-2 rounded-full mx-2 inline-block relative',
                isSelected && 'bg-green-500',
              )}
            />
            {getLocalisedLabelString(userGroup.labelI18n)}
          </UserGroupWrapper>
          <div className="flex-initial text-right">
            <Badge
              to={userGroup.activeUserCount > 0 ? userGroup.id : null}
            >
              {`${userGroup.activeUserCount} users`}
            </Badge>
          </div>
        </div>
        {isSelected && <UserGroups userGroups={userGroup.children} />}
      </div>
    )
  })
}

UserGroups.propTypes = {
  userGroups: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    labelI18n: PropTypes.shape({
      one: PropTypes.string,
      other: PropTypes.string,
      locale: PropTypes.string,
    }).isRequired,
    createdAt: PropTypes.string.isRequired,
    deletedAt: PropTypes.string,
    activeUserCount: PropTypes.number.isRequired,
  })),
}

UserGroups.defaultProps = {
  userGroups: [],
}

export default UserGroups

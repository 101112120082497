import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import { XMark } from '@Infowijs-eng/component-library/icons'
import { Button, Heading } from '@Infowijs-eng/component-library/components'

function NotificationCenterNotification({
  title,
  errorMessages,
  opacity,
  children,
  icon,
  actions,
  id,
  onRemove,
  dispatchAction,
}) {
  return (
    <div
      className={cn(
        'bg-white p-1 flex text-gray-800 sm:w-screen rounded-full group-hover:opacity-100',
        'ring-1 ring-black ring-opacity-5 duration-500 hover:transition-opacity sm:max-w-[28rem]',
        (children || errorMessages || actions) && 'rounded-3xl',
        (opacity && opacity < 100) && `opacity-[${opacity / 100}]`,
      )}
    >
      <div className="p-2">
        {icon}
      </div>
      <div className="flex flex-1 flex-col justify-center py-2">
        <Heading
          className="block text-base font-medium font-display"
          tag={Heading.size.H3}
          fontSize={Heading.size.H6}
          title={id}
        >
          {title}
        </Heading>
        {(children || errorMessages || actions) && (
        <div
          className="mt-2"
        >
          {children && (<p>{children}</p>)}
          {errorMessages && errorMessages.length > 0 && (
          <ul className="list-disc pl-5 space-y-1">
            {errorMessages.map((errorMessage) => (
              <li
                key={errorMessage.title}
                title={errorMessage.status && `Error Code for developers: ${errorMessage.status}`}
              >
                {errorMessage.title}
              </li>
            ))}
          </ul>
          )}
          {actions && actions.length > 0 && (
          <div className="space-x-3">
            {actions.map(({
              key,
              dispatchOnClick,
              ...action
            }) => (
              <Button
                key={key}
                small
                neutral
                fullWidth={false}
                className="underline p-0 m-0 h-auto inline-block"
                onClick={dispatchOnClick ? () => {
                  dispatchAction(dispatchOnClick)
                } : null}
                {...action /* eslint-disable-line react/jsx-props-no-spreading */}
              />
            ))}
          </div>
          )}
        </div>
        )}
      </div>
      <div>
        <Button
          onClick={() => onRemove(id)}
          neutral
          small
          leadingAddon={<XMark />}
        />
      </div>
    </div>
  )
}

NotificationCenterNotification.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  actions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    dispatchOnClick: PropTypes.shape({
      type: PropTypes.string,
    }),
    neutral: PropTypes.bool,
    inline: PropTypes.bool,
    disabled: PropTypes.bool,
    leadingAddon: PropTypes.node,
    trailingAddon: PropTypes.node,
    className: cn.propType,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  })),
  children: PropTypes.node,
  opacity: PropTypes.oneOf([5, 10, 20, 25, 30, 40, 50, 60, 70, 75, 80, 90, 95, 100]),
  onRemove: PropTypes.func.isRequired,
  dispatchAction: PropTypes.func.isRequired,
}

NotificationCenterNotification.defaultProps = {
  errorMessages: null,
  children: null,
  opacity: 100,
  actions: null,
}

export default NotificationCenterNotification

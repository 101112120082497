import {
  PersonPlus,
  TrashBin,
} from '@Infowijs-eng/component-library/icons'
import {
  getLocalisedLabelString,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  Route,
  Routes,
  useParams,
} from 'react-router-dom'
import deleteUserRelation from '../actions/user/deleteUserRelation'
import fetchUser from '../actions/user/fetchUser'
import fetchUserRelations from '../actions/user/fetchUserRelations'
import Layout from '../components/Layout'
import List from '../components/List'
import ListItem from '../components/ListItem'
import ListItemActions from '../components/ListItemActions'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import UserListItem, {
  USER_DETAIL_FIELD_EMAIL,
  USER_DETAIL_FIELD_SUBTITLE,
} from '../components/User/UserListItem'
import UserHeader from '../components/User/UserHeader'
import UserRelationsCreateModal from '../components/User/UserRelationsCreateModal'
import getUser from '../selectors/user/getUser'
import makeGetUserRelationsGroupedByParentRole from '../selectors/user/makeGetUserRelationsGroupedByParentRole'

function UserRelationsScreen() {
  const [loading, setLoading] = useState(false)
  const {
    customerId,
    userId,
  } = useParams()

  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const userRelationsByParentRole = useSelector((state) => makeGetUserRelationsGroupedByParentRole()(state, { userId }))

  useEffect(() => {
    let promised = true
    setLoading(true)

    Promise.all([
      promisifyDispatch(fetchUser(userId)),
      promisifyDispatch(fetchUserRelations(userId)),
    ]).finally(() => {
      if (promised) {
        setLoading(false)
      }
    })

    return () => {
      promised = false
    }
  }, [userId])

  return (
    <>
      <Routes>
        <Route
          path="/new"
          element={<UserRelationsCreateModal />}
        />
      </Routes>
      <Layout>
        <UserHeader
          breadcrumbs={[{
            to: '/customers',
            label: 'Customers',
          }, {
            to: `/customers/${customerId}`,
            label: user && user.customer && user.customer.title,
          }, {
            to: `/customers/${customerId}/users`,
            label: 'Users',
          }]}
          loading={loading}
          user={{
            id: userId,
            ...user,
          }}
          customerId={customerId}
          actions={(user && user.importerType === 'manual') ? [
            {
              key: 'addRelation',
              permissionsWithRegex: /users\.edit\.(manual|imported)/,
              children: 'Add Relation',
              leadingAddon: <PersonPlus />,
              to: `/customers/${customerId}/users/${user.id}/relations/new`,
            },
          ] : null}
        />

        <div className="pt-4 space-y-4 xl:space-y-4">
          {(!userRelationsByParentRole
            || !Object.values(userRelationsByParentRole).length)
          && (
            <List>
              <ListItem inactive>
                No user relations found
              </ListItem>
            </List>
          )}
          {userRelationsByParentRole
            && Object.values(userRelationsByParentRole)
              // Keeping sorting consistent (it's not perfect, but at least consistent)
              .sort((a, b) => getLocalisedLabelString(a.labelI18n).localeCompare(getLocalisedLabelString(b.labelI18n)))
              .map((userRole) => (
                <section aria-labelledby={getLocalisedLabelString(userRole.labelI18n)} key={userRole.id}>
                  <SectionTitle>{getLocalisedLabelString(userRole.labelI18n)}</SectionTitle>
                  <SectionBody>
                    <List
                      isLoading={loading}
                      loadingMessage="Loading users..."
                      placeholder="No users found"
                    >
                      {userRole.userRelations && userRole.userRelations.map((userRelation) => (
                        <UserListItem
                          key={userRelation.user.id}
                          user={userRelation.user}
                          metadata={userRelation.metadata}
                          detailFields={[USER_DETAIL_FIELD_SUBTITLE, USER_DETAIL_FIELD_EMAIL]}
                          trailingAddon={(
                            <ListItemActions
                              key="actions"
                              actions={user && user.importerType === 'manual' ? [{
                                key: 'Delete',
                                children: 'Delete',
                                title: 'Delete',
                                leadingAddon: <TrashBin />,
                                className: 'hover:bg-red-50 hover:text-red-700',
                                onClick: () => dispatch(deleteUserRelation(userId, userRelation.user.id)),
                              }] : []}
                            />
                          )}
                        />
                      ))}
                    </List>
                  </SectionBody>
                </section>
              ))}
        </div>
      </Layout>
    </>
  )
}

UserRelationsScreen.propTypes = {}

UserRelationsScreen.defaultProps = {}

export default UserRelationsScreen

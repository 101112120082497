import PropTypes from 'prop-types'

import {
  Header,
  HeaderActions,
  SubNavigation,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { cn } from '@Infowijs-eng/component-library/modules'
import actionsPropTypes from '@Infowijs-eng/component-library/prop-types/actionsPropTypes'
import Breadcrumbs from './Breadcrumbs'

function PageHeader({
  breadcrumbs, children, leadingAddon, actions, subNavigation, className, loading,
}) {
  return (
    <div className={className}>
      <Breadcrumbs items={breadcrumbs} className="pb-8" />
      <Header
        leadingAddon={leadingAddon}
        trailingAddon={actions && (
          <HeaderActions
            actions={actions.map((action) => ({
              neutral: !action.primary,
              ...action,
            }))}
          />
        )}
      >
        {children}
      </Header>
      {subNavigation && <SubNavigation navigationItems={subNavigation} />}
      <div
        className={cn(
          'h-px rounded-full w-full animate-gradient-x',
          loading
            ? 'bg-gradient-to-r from-theme-600 via-gray-200 to-theme-600 '
            : 'bg-gray-200',
        )}
      />
    </div>
  )
}

PageHeader.propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    label: PropTypes.string,
  })),
  children: PropTypes.node.isRequired,
  leadingAddon: WithAddon.propTypes.leadingAddon,
  actions: PropTypes.oneOfType([PropTypes.bool, actionsPropTypes]),
  // eslint-disable-next-line react/forbid-prop-types
  subNavigation: PropTypes.any,
  className: cn.propType,
  loading: PropTypes.bool,
}

PageHeader.defaultProps = {
  breadcrumbs: [],
  leadingAddon: null,
  actions: null,
  subNavigation: null,
  className: null,
  loading: false,
}

export default PageHeader

import actionTypes from '../../action-types'

export default ({
  page, filter, sort, limit,
}) => ({
  type: actionTypes.USERS.FETCH_ALL,
  meta: {
    page,
    sort,
    limit,
    filter,
  },
})

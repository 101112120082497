import getFullName from './getFullName'

export default (user, combineWithInitials = true) => {
  if (!user) {
    return ''
  }

  if (user.firstName && user.initials && combineWithInitials) {
    return `${user.firstName} (${user.initials})`
  }

  return user.firstName || (combineWithInitials && user.initials) || getFullName(user) || ''
}

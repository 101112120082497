import actionTypes from '../action-types'

const initialState = {
  data: {},
  meta: {
    sort: {
      key: 'lastUpdatedAt',
      order: 'desc',
    },
    limit: null,
    total: 0,
    pages: 1,
  },
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.USERS.SET_ALL:
      return {
        ...state,
        data: (action.payload || []).reduce((acc, user) => {
          acc[user.id] = { ...state.data[user.id], ...user }
          acc[user.id].userRole = {
            ...action.included.userRole[user.userRole.id],
          } || user.userRole
          return acc
        }, {}),
        meta: {
          ...state.meta,
          limit: (action.meta && action.meta.limit) || initialState.meta.limit,
          total: (action.meta && action.meta.total) || initialState.meta.total,
          pages: Math.ceil(((action.meta && action.meta.total) || 0) / ((action.meta && action.meta.limit) || 50)),
        },
      }
    case actionTypes.USERS.LIMIT:
      return {
        ...state,
        meta: {
          ...state.meta,
          limit: Number(action.payload),
        },
      }

    default:
      return state
  }
}

import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { useMemo } from 'react'
import dayjs from 'dayjs'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import determineTopmostUserRoleId from './determineTopmostUserRoleId'
import { ImporterUser, UserRole } from './types'

type FetchImporterUsers = (customerId: string, oldImporter: string, newImporter: string) => Promise<{
  oldImporterUsers: { [id: string]: ImporterUser }
  newImporterUsers: { [id: string]: ImporterUser }
  userRoles: { [id: string]: UserRole }
}>

export default function useFetchImporterUsers(): FetchImporterUsers {
  const authenticatedRequest = useAuthenticatedRequest()
  return useMemo(() => (async (customerId: string, oldImporterId: string, newImporterId: string) => {
    const timestamp = dayjs().unix()

    const params = {
      customerId: `eq:${customerId}`,
      archivedAt: `gtOrNull:${timestamp}`,
      include: 'userRole',
      page: 0,
      limit: '99999999',
    }

    const [
      oldImporterResponse,
      newImporterResponse,
    ] = await Promise.all([
      authenticatedRequest(
        `${serviceEndpoints.users}`,
        {
          method: 'get',
          params: {
            ...params,
            importerId: `eq:${oldImporterId}`,
          },
        },
      ),
      authenticatedRequest(
        `${serviceEndpoints.users}`,
        {
          method: 'get',
          params: {
            ...params,
            importerId: `eq:${newImporterId}`,
          },
        },
      ),
    ])

    const userRoles = {
      ...(oldImporterResponse?.included?.userRole ?? {}),
      ...(newImporterResponse?.included?.userRole ?? {}),
    }

    const oldImporterUsers = Object.fromEntries((oldImporterResponse.data ?? []).map((user) => {
      const updatedUser = user
      const parentRoleId = determineTopmostUserRoleId(userRoles, updatedUser.userRole.id)
      updatedUser.userRole = userRoles[parentRoleId]
      return [user.id, updatedUser]
    }))
    const newImporterUsers = Object.fromEntries((newImporterResponse.data ?? []).map((user) => {
      const updatedUser = user
      const parentRoleId = determineTopmostUserRoleId(userRoles, updatedUser.userRole.id)
      updatedUser.userRole = userRoles[parentRoleId]
      return [user.id, updatedUser]
    }))

    return {
      oldImporterUsers,
      newImporterUsers,
      userRoles,
    }
  }), [])
}

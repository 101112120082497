import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import updateCustomer from '../../actions/customers/updateCustomer'
import awaitAccessToken from '../../modules/awaitAccessToken'
import addNotification from '../../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../../components/StatusIcon'
import {
  NOTIFICATION_TIMEOUT_LONG,
  NOTIFICATION_TIMEOUT_SHORT,
} from '../../modules/notificationCenter'

const onUpdateCustomer = function* onUpdateCustomer() {
  yield takeLatest(actionTypes.CUSTOMERS.UPDATE, function* onUpdateCustomerSaga(action) {
    const { payload } = action

    const accessToken = yield call(awaitAccessToken)

    let response

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/${payload.id}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: payload,
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reUpdateCustomer() {
          yield put(updateCustomer(payload))
        })
      }

      yield put(addNotification({
        title: 'Customer has encountered an error',
        status: STATUS_ERROR,
      }, NOTIFICATION_TIMEOUT_SHORT))

      rejectAction(action, e.response.data.errors || 'An unknown error occurred')

      return
    }

    if (!response || !response.data) {
      return
    }

    yield put(addNotification({
      id: `customer-${payload.id}-update`,
      title: `Customer ${response.data.data.title} has been updated`,
      actions: [{
        key: 'customers-detail',
        to: `/customers/${payload.id}`,
        children: 'View customer',
      }],
    }, NOTIFICATION_TIMEOUT_LONG))

    acceptAction(action, response.data.data)
  })
}

export default onUpdateCustomer

import {
  BOOLEAN,
  DATETIME,
  ENUM,
  INTEGER,
  LIST,
  OBJECT,
  OBJECT_DYNAMIC,
  OBJECT_FORMATTED_KEY,
  OBJECT_YEAR_RANGE,
  STRING,
  STRING_FORMAT_LOCALE,
  YEAR_RANGE,
} from '@Infowijs-eng/component-library/modules/metadataStructureTypes'

export const STRUCTURE_OBJECT = {
  type: OBJECT,
}

export const STRUCTURE_LIST = {
  type: LIST,
}

export const STRUCTURE_ENUM = {
  type: ENUM,
}

export const STRUCTURE_DYNAMIC_OBJECT = {
  type: OBJECT_DYNAMIC,
}

export const STRUCTURE_YEAR_RANGE = {
  type: OBJECT_YEAR_RANGE,
}

export const STRUCTURE_FORMATTED_KEY = {
  type: OBJECT_FORMATTED_KEY,
}

export const STRUCTURE_LOCALE_STRING = {
  type: STRING,
  format: STRING_FORMAT_LOCALE,
}

export const STRUCTURE_YEAR_RANGE_STRING = {
  type: STRING,
  format: YEAR_RANGE,
}

export const STRUCTURE_DATETIME = {
  type: DATETIME,
}

export const STRUCTURE_BOOLEAN = {
  type: BOOLEAN,
}

export const STRUCTURE_INTEGER = {
  type: INTEGER,
}

const isMetadataStructureType = (type, structure) => {
  if (!structure || !type || !(typeof structure === 'object')) {
    return false
  }

  if (Array.isArray(type)) {
    return !!type.find((typeObj) => isMetadataStructureType(typeObj, structure))
  }

  if (!(typeof type === 'object')) {
    return false
  }

  return !((type.type && structure.type !== type.type)
    || (type.format && !structure.format)
    || (type.format !== structure.format))
}

export default isMetadataStructureType

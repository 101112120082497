import React from 'react'
import { Clickable } from '@Infowijs-eng/component-library/components'
import { MagnifyingGlass } from '@Infowijs-eng/component-library/icons'
import { cn } from '@Infowijs-eng/component-library/modules'

interface ImporterMigrateExplicitNoMatchProps {
  onSearch: () => void
}

export default function ImporterMigrateExplicitNoMatch({ onSearch }: ImporterMigrateExplicitNoMatchProps) {
  return (
    <div className={cn(
      'flex h-full flex-row w-full border border-gray-300 bg-gray-50',
      'p-4 gap-2 rounded-lg items-center justify-between',
    )}
    >
      <span className="text-sm ml-6">Removed by you</span>
      <Clickable
        className="p-1 rounded-full bg-gray-100 hover:bg-gray-200 h-fit"
        onClick={onSearch}
      >
        <MagnifyingGlass />
      </Clickable>
    </div>
  )
}

import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import {
  Badge,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import {
  TwoVerticalLines,
  VerticalLineTriangleCircle,
} from '@Infowijs-eng/component-library/icons'
import { promisifyAction } from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { useMemo } from 'react'
import fetchImporters from '../../actions/importers/fetchImporters'
import Duration from '../Duration'
import ListItem from '../ListItem'

function ImporterListItem({
  label,
  type,
  active,
  configured,
  currentlyRunning,
  lastRunStartAt,
  lastRunEndAt,
  lastRunError,
  lastUpdatedBy,
  nextRunAt,
  customer,
  onError,
  trailingAddon,
  to,
  className,
}) {
  const lastUpdatedByUserLabel = useMemo(() => {
    if (!lastUpdatedBy) {
      return null
    }

    if (lastUpdatedBy === 'system') {
      return 'System'
    }

    const [role] = lastUpdatedBy.split(':')

    if (role === 'admin') {
      return 'Infowijs'
    }

    if (role === 'user') {
      return 'School Admin'
    }

    return lastUpdatedBy
  }, [lastUpdatedBy])

  return (
    <ListItem className={className} trailingAddon={trailingAddon} to={to}>
      <div className="w-full flex flex-col text-left">
        <WithAddon
          className="font-display font-medium w-full justify-start capitalize"
          trailingAddon={[
            (!active && configured) && (
            <div key="inactive" className="flex">
              <Badge className="bg-red-500" leadingAddon={<TwoVerticalLines />}>
                Not scheduled
              </Badge>
            </div>
            ),
            !configured && (
            <div key="inactive" className="flex">
              <Badge className="bg-orange-500" leadingAddon={<VerticalLineTriangleCircle />}>
                Config not complete
              </Badge>
            </div>
            ),
            currentlyRunning && (
            <div key="active" className="flex">
              <Badge className="bg-blue-500">
                Is currently running
              </Badge>
            </div>
            ),
          ]}
        >
          {label}
        </WithAddon>
        {(type || lastRunStartAt) && (
          <div className="flex flex-wrap gap-x-4 text-gray-700">
            {type && (
              <span className="w-auto flex-nowrap whitespace-nowrap">
                <span className="font-medium mr-1">
                  Type:
                </span>
                <span className="capitalize">{type}</span>
              </span>
            )}
            {lastRunStartAt && (
              <span className="w-auto flex-nowrap whitespace-nowrap">
                <span className="font-medium mr-1">
                  Last sync started at:
                </span>
                {lastRunStartAt && dayjs(lastRunStartAt).format('DD MMM YYYY [at] HH:mm:ss')}
              </span>
            )}
            {lastRunStartAt && (
              <span
                className="w-auto flex-nowrap whitespace-nowrap"
                title={lastRunEndAt && `Last sync ended at: ${dayjs(lastRunEndAt)
                  .format('DD MMM YYYY [at] HH:mm:ss')}`}
              >
                <span className="font-medium mr-1">
                  Elapsed time:
                </span>
                <Duration
                  since={lastRunStartAt}
                  until={lastRunEndAt}
                  onUpdate={(diff) => {
                    const seconds = Math.round(diff.asSeconds())
                    // Refresh importers every 5 seconds, while in progress
                    if (seconds % 5 === 0) {
                      promisifyAction(fetchImporters({ filter: { customerId: `eq:${customer.id}` } }))
                        .catch(onError)
                    }
                  }}
                />
              </span>
            )}
          </div>
        )}
        {(lastRunError || (nextRunAt && active)) && (
          <div className="text-gray-500">
            {lastRunError && lastRunError.message && (
              <div title={lastRunError.message} className="w-auto text-red-500 whitespace-normal">
                <span className="font-medium mr-2">Last run error:</span>
                <span>{lastRunError.message}</span>
              </div>
            )}
            {nextRunAt && active && (
              <div className="w-auto whitespace-normal">
                <span className="font-medium mr-2">Scheduled for:</span>
                <span className="whitespace-normal">
                  {nextRunAt && dayjs(nextRunAt).format('DD MMM YYYY [at] HH:mm')}
                </span>
              </div>
            )}
          </div>
        )}
        {lastUpdatedBy && (
          <div className="text-gray-500">
            {`Last updated by: ${lastUpdatedByUserLabel}`}
          </div>
        )}
      </div>
    </ListItem>
  )
}

ImporterListItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  to: PropTypes.string,
  active: PropTypes.bool,
  configured: PropTypes.bool,
  currentlyRunning: PropTypes.bool,
  lastRunStartAt: PropTypes.string,
  lastRunEndAt: PropTypes.string,
  lastRunError: PropTypes.shape({
    message: PropTypes.string,
  }),
  lastUpdatedBy: PropTypes.string,
  nextRunAt: PropTypes.string,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
  }),
  onError: PropTypes.func,
  trailingAddon: PropTypes.node,
  className: cn.propType,
}

ImporterListItem.defaultProps = {
  type: null,
  to: null,
  active: false,
  configured: false,
  currentlyRunning: false,
  lastRunStartAt: null,
  lastRunEndAt: null,
  lastRunError: null,
  lastUpdatedBy: null,
  nextRunAt: null,
  customer: { title: null },
  onError: null,
  trailingAddon: null,
  className: null,
}

export default ImporterListItem

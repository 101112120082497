import PropTypes from 'prop-types'

function StepIndicator({ total, current = 1 }) {
  const steps = []
  for (let i = 0; i < total; i += 1) {
    const key = i + 1
    if (key < current) {
      steps.push({ key, className: 'bg-green-300 rounded-md h-1 w-full' })
    } else if (key === current) {
      steps.push({ key, className: 'bg-green-500 rounded-md h-1 w-full' })
    } else {
      steps.push({ key, className: 'bg-gray-300 rounded-md h-1 w-full' })
    }
  }

  return (
    <div className="flex gap-2">
      {steps.map(({ key, className }) => <div key={key} className={className} />)}
    </div>
  )
}

StepIndicator.propTypes = {
  total: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
}

export default StepIndicator

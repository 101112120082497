import PropTypes from 'prop-types'
import {
  Dropdown,
  DropdownItem,
} from '@Infowijs-eng/component-library/components'
import { useDefaultPageLimit } from '@Infowijs-eng/component-library/modules'
import { useSearchParams } from 'react-router-dom'
import { parse } from 'qs'

function LimitDropdown({ options }) {
  const [searchParams, setSearchParams] = useSearchParams()
  const { limit: requestedLimit } = parse(searchParams.toString(), { ignoreQueryPrefix: true })
  const defaultPageLimit = options && useDefaultPageLimit(options)
  const limit = Number(requestedLimit) || defaultPageLimit

  return (
    <Dropdown
      label={`Show: ${limit}`}
      buttonProps={{
        small: true,
        neutral: true,
      }}
    >
      {options && options.map((limitOption) => (
        <DropdownItem
          small
          key={`show-${limitOption}-items`}
          onClick={() => {
            if (searchParams.has('limit')) {
              searchParams.delete('limit')
            }
            searchParams.set('limit', limitOption)

            setSearchParams(searchParams)
          }}
          trailingAddon={limitOption === limit ? '👈' : ''}
        >
          {`${limitOption} items`}
        </DropdownItem>
      ))}
    </Dropdown>
  )
}

LimitDropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.number).isRequired,
}

export default LimitDropdown

import { Route, Routes, useParams } from 'react-router-dom'
import { serviceEndpoints, usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'
import useAuthenticatedRequest from '@Infowijs-eng/component-library/hooks/useAuthenticatedRequest'
import { Skeleton } from '@Infowijs-eng/component-library/components/'
import UserHeader from '../components/User/UserHeader'
import UserGroupRelationsCreateModal from '../components/User/UserGroupRelationsCreateModal'
import Layout from '../components/Layout'
import getUser from '../selectors/user/getUser'
import fetchUser from '../actions/user/fetchUser'
import SectionTitle from '../components/SectionTitle'
import SectionBody from '../components/SectionBody'
import addNotification from '../actions/notificationCenter/addNotification'
import { STATUS_ERROR } from '../components/StatusIcon'
import { NOTIFICATION_TIMEOUT_SHORT } from '../modules/notificationCenter'

enum NotificationsScheduleType {
  Always = 'always',
  Custom = 'custom',
  Never = 'never'
}

type NotificationScheduleDay = {
  to?: string
  from: string
}
type NotificationScheduleWeek = {
  monday?: NotificationScheduleDay | null
  tuesday?: NotificationScheduleDay | null
  wednesday?: NotificationScheduleDay | null
  thursday?: NotificationScheduleDay | null
  friday?: NotificationScheduleDay | null
  saturday?: NotificationScheduleDay | null
  sunday?: NotificationScheduleDay | null
}

function getNotificationScheduleTypeForSchedule(schedule: unknown): NotificationsScheduleType {
  let notificationScheduleType = NotificationsScheduleType.Never

  const notificationScheduleIsEmpty = schedule === null
    || schedule === undefined
    || typeof schedule !== 'object'

  const notificationScheduleIsAlways = notificationScheduleIsEmpty || (
    Object.keys(schedule as object).length === 7
    && Object.values(schedule as object).every((item) => item.from === '00:00' && !('to' in item))
  )

  if (notificationScheduleIsAlways) {
    notificationScheduleType = NotificationsScheduleType.Always
  } else if (Object.keys(schedule).length > 0) {
    notificationScheduleType = NotificationsScheduleType.Custom
  }

  return notificationScheduleType
}

function convertDailyScheduleToLabel(dailySchedule: {
  from?: string
  to?: string
}) {
  if (!dailySchedule?.from) {
    return 'Notifications turned off'
  }
  if (dailySchedule.from === '00:00' && !dailySchedule.to) {
    return 'Entire day'
  }
  if (dailySchedule.from && !dailySchedule.to) {
    return `${dailySchedule.from} until midnight`
  }

  return `${dailySchedule.from} until ${dailySchedule.to}`
}

export default function UserNotificationsScreen() {
  const authenticatedRequest = useAuthenticatedRequest()
  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()

  const [hasError, setHasError] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)
  const [schedule, setSchedule] = useState<NotificationScheduleWeek | null>(null)
  const [scheduleType, setScheduleType] = useState<NotificationsScheduleType | null>(null)
  const { customerId, userId } = useParams()

  const user = useSelector(getUser)

  async function fetchSchedule() {
    let scheduleResponse = null
    setHasError(false)

    try {
      scheduleResponse = await authenticatedRequest(
        `${serviceEndpoints.notifications}/settings/push/user/${userId}`,
        { method: 'get' },
      )
    } catch (e) {
      dispatch(addNotification({
        title: 'Could not retrieve notification schedule',
        status: STATUS_ERROR,
      }, NOTIFICATION_TIMEOUT_SHORT))
      setHasError(true)
      return
    }

    const daysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']
    const scheduleData = scheduleResponse?.data?.pushNotificationSchedule?.weeklySchedule ?? null

    const notificationScheduleType = getNotificationScheduleTypeForSchedule(scheduleData)
    setScheduleType(notificationScheduleType)

    if (notificationScheduleType === NotificationsScheduleType.Always
      || notificationScheduleType === NotificationsScheduleType.Never) {
      return
    }

    const scheduleDataKeys = Object.keys(scheduleData ?? {})
    const sortedScheduleData = scheduleDataKeys
      .sort((a, b) => daysOrder.indexOf(a.toLowerCase()) - daysOrder.indexOf(b.toLowerCase()))
      .reduce((acc, key) => {
        acc[key] = scheduleData[key]
        return acc
      }, {})

    setSchedule(sortedScheduleData)
  }

  async function fetchData() {
    setLoading(true)
    await promisifyDispatch(fetchUser(userId))
    await fetchSchedule()
    setLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [userId])

  return (
    <>
      <Routes>
        <Route path="/new" element={<UserGroupRelationsCreateModal />} />
      </Routes>
      <Layout>
        <UserHeader
          breadcrumbs={[
            {
              to: '/customers',
              label: 'Customers',
            },
            {
              to: `/customers/${customerId}`,
              label: user && user.customer && user.customer.title,
            },
            {
              to: `/customers/${customerId}/users`,
              label: 'Users',
            },
          ]}
          loading={loading}
          user={{
            id: userId,
            ...user,
          }}
          customerId={customerId}
        />
        <section className="pt-4" aria-labelledby="Schedule">
          <SectionTitle>Schedule</SectionTitle>
          <SectionBody className="flex flex-col pt-4 gap-2">
            {loading && (<Skeleton className="w-48" />)}
            {!loading && hasError && (
              <span className="text-red-400 text-sm mt-1 font-medium">
                Could not retrieve notifications settings for user
              </span>
            )}
            { scheduleType === NotificationsScheduleType.Always && (
              <span className="text-gray-950 text-sm mt-1 font-medium">Always</span>
            )}
            { scheduleType === NotificationsScheduleType.Never && (
              <span className="text-gray-950 text-sm mt-1 font-medium">Never</span>
            )}
            { scheduleType === NotificationsScheduleType.Custom && (
              Object.entries(schedule).map(([day, dailySchedule]) => {
                const formattedSchedule = convertDailyScheduleToLabel(dailySchedule)

                return (
                  <div className="flex flex-col" key={`${day}-${formattedSchedule}`}>
                    <span className="text-gray-500 text-sm capitalize font-medium">{day}</span>
                    <span className="text-gray-950 text-sm mt-1 font-medium">{formattedSchedule}</span>
                  </div>
                )
              })
            )}
          </SectionBody>
        </section>
      </Layout>
    </>
  )
}

import determineTopmostUserRoleId from './determineTopmostUserRoleId'
import getNormalizedName from './getNormalizedName'
import {
  ImporterUser, MatchStatus, UserRole, UserStatus,
} from './types'
import getSortedPossibleMatches from './getSortedPossiblesMatches'
import clearPossibleMatchesForUser from './clearPossibleMatchesForUser'

export default async function createInitialUserStatusList(
  userRoles: { [id: string]: UserRole },
  oldImporterUsers: { [id: string]: ImporterUser },
  newImporterUsers: { [id: string]: ImporterUser },
  onProgress?: (percentage: number) => void,
): Promise<{ [id: string]: UserStatus }> {
  const allUserIdsSet = new Set([...Object.keys(oldImporterUsers), ...Object.keys(newImporterUsers)])
  const userStatuses: {[id:string]: UserStatus} = {}

  allUserIdsSet.forEach((userId) => {
    userStatuses[userId] = {
      status: MatchStatus.ImplicitUnmatch,
      from: oldImporterUsers[userId],
      to: newImporterUsers[userId],
      possibleMatches: [],
    }
  })

  const fromEmailMap = Object.values(oldImporterUsers).reduce((map, user) => {
    const localMap = { ...map }
    if (user.email in map) {
      localMap[user.email].push(user)
    } else {
      localMap[user.email] = [user]
    }
    return localMap
  }, {})

  const toEmailMap = Object.values(newImporterUsers).reduce((map, user) => {
    const localMap = { ...map }
    if (user.email in map) {
      localMap[user.email].push(user)
    } else {
      localMap[user.email] = [user]
    }
    return localMap
  }, {})

  Object.values(userStatuses)
    .forEach((userStatus: UserStatus) => {
      if (userStatus.status !== MatchStatus.ImplicitUnmatch) {
        return
      }

      const sourceUser = userStatus.from ?? userStatus.to
      const sourceUserParentRoleId = determineTopmostUserRoleId(userRoles, sourceUser.userRole.id)

      if (!sourceUser?.email) {
        // Could not find email for user - cannot match
        return
      }

      const sourceUserEmailMap = userStatus.from ? fromEmailMap : toEmailMap
      const targetUserEmailMap = userStatus.from ? toEmailMap : fromEmailMap

      const matchingSourceUsersOnMail = sourceUserEmailMap[sourceUser.email]?.filter((user) => {
        const parentRoleId = determineTopmostUserRoleId(userRoles, user.userRole.id)
        return sourceUserParentRoleId === parentRoleId
      })
      const matchingTargetUsersOnMail = targetUserEmailMap[sourceUser.email]?.filter((user) => {
        const parentRoleId = determineTopmostUserRoleId(userRoles, user.userRole.id)
        return sourceUserParentRoleId === parentRoleId
      })

      const hasExactEmailMatch = matchingSourceUsersOnMail?.length === 1 && matchingTargetUsersOnMail?.length === 1
      const targetUser = matchingTargetUsersOnMail?.[0]

      let hasExactNameMatch = false
      if (hasExactEmailMatch) {
        const sourceUserNameString = getNormalizedName(sourceUser)
        const targetNameString = getNormalizedName(targetUser)
        hasExactNameMatch = sourceUserNameString === targetNameString
      }

      if (hasExactNameMatch && hasExactEmailMatch) {
        if (userStatus.from) {
          // eslint-disable-next-line no-param-reassign
          userStatus.to = targetUser
        } else {
          // eslint-disable-next-line no-param-reassign
          userStatus.from = targetUser
        }
        // eslint-disable-next-line no-param-reassign
        userStatus.status = MatchStatus.ImplicitMatch
      }
    })

  const userStatusesValues = Object.values(userStatuses)

  // First calculate all possible matches (progress 0 - 0.5)
  for (let i = 0; i < userStatusesValues.length; i += 1) {
    const userStatus = userStatusesValues[i]

    if (userStatus.status !== MatchStatus.ImplicitUnmatch) {
      // eslint-disable-next-line no-continue
      continue
    }
    const targetUsers = userStatus.from ? newImporterUsers : oldImporterUsers

    // eslint-disable-next-line no-param-reassign
    userStatus.possibleMatches = getSortedPossibleMatches(userStatuses, userStatus, userRoles, targetUsers)

    if (i % 10 === 0) {
      // eslint-disable-next-line no-await-in-loop
      await new Promise((resolve) => {
        setTimeout(resolve, 1)
      })

      onProgress?.((i / userStatusesValues.length) / 2)
    }
  }

  // Now check for some common casus for which we can already determine that they should be removed (progress 0.5 - 1.0)
  for (let i = 0; i < userStatusesValues.length; i += 1) {
    const userStatus = userStatusesValues[i]

    if (userStatus.status !== MatchStatus.ImplicitUnmatch) {
      // eslint-disable-next-line no-continue
      continue
    }

    if (!userStatus.from) {
      // We only determine this in 1 direction
      // eslint-disable-next-line no-continue
      continue
    }

    if (!userStatus.possibleMatches.length && (userStatus.from.archivedAt || userStatus.from.deletedAt)) {
      // If the user was archived already and no matches are available, we just assume it won't have to be matched
      // eslint-disable-next-line no-param-reassign
      userStatus.status = MatchStatus.ExplicitUnmatch
      clearPossibleMatchesForUser(userStatuses, userStatus.from)
    } else if (!userStatus.from.email) {
      // If the user has no email, we check if we have a single match with exact name, in which case we mark it a match
      // If it does not match, or has multiple matches, we will explicitly not link them since users without email can't
      // sign in
      if (userStatus.possibleMatches.length === 1
          && getNormalizedName(userStatus.possibleMatches[0].user) === getNormalizedName(userStatus.from)
      ) {
        const toUserStatus = userStatuses[userStatus.possibleMatches[0].user.id]
        toUserStatus.from = userStatus.from
        userStatus.to = toUserStatus.to

        userStatus.status = MatchStatus.ExplicitMatch
        toUserStatus.status = MatchStatus.ExplicitMatch

        clearPossibleMatchesForUser(userStatuses, userStatus.from)
        clearPossibleMatchesForUser(userStatuses, userStatus.to)
      } else {
        userStatus.status = MatchStatus.ExplicitUnmatch
        clearPossibleMatchesForUser(userStatuses, userStatus.from)
      }
    }
    onProgress?.(((i / userStatusesValues.length) / 2) + 0.5)
  }

  onProgress?.(1)

  return userStatuses
}

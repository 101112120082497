import {
  Avatar,
  Button,
  WithAddon,
} from '@Infowijs-eng/component-library/components'
import { TYPE_USER } from '@Infowijs-eng/component-library/constants/avatar'
import {
  CheckCircle,
  Envelope,
  Person,
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import {
  cn,
  getLocalisedLabelString,
} from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

import getFullName from '../../modules/getFullName'
import ListItem from '../ListItem'

function UserRelationUserListItem({
  hasRelation,
  selected,
  user,
  onSelect,
}) {
  const fullName = getFullName(user)

  return (
    <ListItem
      key={user.id}
      title={hasRelation ? `${fullName} already has a relation to this user` : null}
      leadingAddon={(
        <Avatar
          alt={fullName}
          id={user.id}
          type={TYPE_USER}
        />
        )}
      trailingAddon={(
        <div key="actions" className="flex flex-row items-center mt-2 sm:mt-0 sm:justify-start">
          <Button
            small
            neutral
            disabled={hasRelation}
            className={[
              'text-gray-400',
              selected && 'hover:bg-gray-50 hover:text-green-500 text-green-500',
              hasRelation && 'text-green-200',
            ]}
            trailingAddon={(hasRelation || selected) ? <CheckCircle /> : <PlusCircle />}
            onClick={() => {
              onSelect(!selected)
            }}
          />
        </div>
        )}
      row
    >
      <div className="flex flex-col flex-grow items-start mt-1 sm:mt-0 sm:w-full sm:justify-center">
        <div
          className="flex flex-col justify-start space-y-1 sm:space-y-0 sm:flex-row sm:justify-between not-last:mb-1"
        >
          <div className="w-full flex flex-wrap justify-start items-center text-black font-bold font-display">
            {fullName}
          </div>
        </div>
        <div
          className={cn(
            'flex flex-col justify-start space-y-1',
            'sm:space-x-2 sm:space-y-0 sm:flex-row sm:justify-between not-last:mb-1',
          )}
        >
          <div className="w-full flex flex-wrap justify-start items-center">
            <WithAddon leadingAddon={<Person />}>
              {user.userRole && getLocalisedLabelString(user.userRole.labelI18n, 1)}
            </WithAddon>
          </div>
          <div className="w-full flex flex-wrap justify-start items-center">
            <WithAddon leadingAddon={<Envelope />}>
              {user.email}
            </WithAddon>
          </div>
        </div>
      </div>
    </ListItem>
  )
}

UserRelationUserListItem.propTypes = {
  hasRelation: PropTypes.bool,
  selected: PropTypes.bool,
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    avatar: PropTypes.string,
    userRole: PropTypes.shape({
      labelI18n: PropTypes.shape(),
    }),
    email: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
}

UserRelationUserListItem.defaultProps = {
  hasRelation: false,
  selected: false,
}

export default UserRelationUserListItem

import {
  useEffect,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { parse } from 'qs'
import {
  useDefaultPageLimit,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import fetchCustomer from '../actions/customers/fetchCustomer'
import fetchCustomers from '../actions/customers/fetchCustomers'
import setCustomers from '../actions/customers/setCustomers'
import FilterBar from '../components/FilterBar'
import ImporterListItem from '../components/Importer/ImporterListItem'
import ImporterListSkeleton from '../components/Importer/ImporterListSkeleton'
import List from '../components/List'
import ListItem from '../components/ListItem'
import Pagination from '../components/Pagination'
import filters from '../modules/filterBarFilters'
import getDefaultPageLimits from '../modules/getDefaultPageLimits'
import usePagination from '../modules/usePagination'
import getCustomer from '../selectors/customers/getCustomer'
import getCustomersDataForAutoComplete from '../selectors/customers/getCustomersDataForAutoComplete'
import getImporters from '../selectors/importers/getImporters'
import fetchImporters from '../actions/importers/fetchImporters'
import Layout from '../components/Layout'
import PageHeader from '../components/PageHeader'
import getImportersMeta from '../selectors/importers/getImportersMeta'

function importerOverviewScreen() {
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()
  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)

  const importersMeta = useSelector(getImportersMeta)
  const [page, pages, total] = usePagination(importersMeta)
  const [searchParams] = useSearchParams()
  const limits = getDefaultPageLimits()
  const defaultPageLimit = limits && useDefaultPageLimit(limits)
  const {
    sort,
    limit,
    ...filter
  } = parse(searchParams.toString(), { ignoreQueryPrefix: true })

  const importers = useSelector(getImporters)
  const customer = useSelector((state) => (
    getCustomer(state, { id: filter && filter.customerId && filter.customerId.split(':')[1] })
  ))
  const customersForAutoComplete = useSelector(getCustomersDataForAutoComplete)

  useEffect(() => {
    if (filter.customerId && !customer) {
      dispatch(fetchCustomer(filter.customerId.split(':')[1]))
    }
  }, [dispatch, fetchCustomer])

  useEffect(() => {
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchImporters({
      page,
      sort,
      limit: limit || defaultPageLimit,
      filter,
    }))
      .catch((e) => setErrorMessages(e))
      .finally(() => setLoading(false))
  }, [page, searchParams])

  return (
    <Layout>
      <PageHeader loading={loading}>
        <Heading fontSize={Heading.size.H3}>Importers</Heading>
      </PageHeader>
      <FilterBar
        filters={[
          {
            ...filters.customerId,
            value: customer && customer.title,
            onChange: (e) => {
              const value = e.target.value.trim()
              if (value.length === 0) {
                dispatch(setCustomers([]))
                return
              }
              dispatch(fetchCustomers({ filter: { title: `contains:${value}` } }))
            },
            autoCompleteResults: customersForAutoComplete,
          },
          filters.importerType,
          filters.importerScheduled,
        ]}
        limits={limits}
      />
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List>
        {loading && (!importers || !importers.length) && <ImporterListSkeleton />}
        {!loading && !importers.length && <ListItem>No importers found</ListItem>}
        {!!importers.length && importers.sort((a, b) => {
          if (a.lastRunError && !b.lastRunError) {
            return -1
          }
          if (!a.lastRunError && b.lastRunError) {
            return 1
          }
          return dayjs(a.lastRunStartAt).unix() - dayjs(b.lastRunStartAt).unix()
        }).map((importer) => (
          <ImporterListItem
            key={importer.id}
            id={importer.id}
            label={importer.customer && importer.customer.title}
            configured={!!(importer.metadata && importer.metadata.config && importer.metadata.config.done)}
            type={importer.type}
            active={importer.active}
            currentlyRunning={importer.currentlyRunning}
            lastRunStartAt={importer.lastRunStartAt}
            lastRunEndAt={importer.lastRunEndAt}
            lastRunError={importer.lastRunError}
            lastUpdatedBy={importer.lastUpdatedBy}
            nextRunAt={importer.nextRunAt}
            customer={importer.customer}
            to={importer.customer?.id ? `/customers/${importer.customer.id}` : undefined}
            onError={(e) => setErrorMessages(e)}
          />
        ))}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && (<Pagination current={page || 1} total={pages} />)}
    </Layout>
  )
}

export default importerOverviewScreen

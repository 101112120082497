import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  useLocation,
} from 'react-router-dom'
import { parse } from 'qs'
import {
  Alert,
  Heading,
} from '@Infowijs-eng/component-library/components'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import {
  useDefaultPageLimit,
  usePromisifyDispatch,
} from '@Infowijs-eng/component-library/modules'
import Layout from '../components/Layout'
import FilterBar from '../components/FilterBar'
import List from '../components/List'
import ListItem from '../components/ListItem'
import Pagination from '../components/Pagination'
import UserListItem from '../components/User/UserListItem'
import UserListSkeleton from '../components/User/UserListSkeleton'
import getDefaultPageLimits from '../modules/getDefaultPageLimits'
import filters from '../modules/filterBarFilters'
import fetchUserRoles from '../actions/userRoles/fetchUserRoles'
import usePagination from '../modules/usePagination'
import getUserRolesForAutoComplete from '../selectors/userRoles/getUserRolesForAutoComplete'
import makeGetFilteredUserRolesForAutoComplete from '../selectors/userRoles/makeGetFilteredUserRolesForAutoComplete'
import getUsers from '../selectors/users/getUsers'
import getCustomersDataForAutoComplete from '../selectors/customers/getCustomersDataForAutoComplete'
import getCustomer from '../selectors/customers/getCustomer'
import setUsers from '../actions/users/setUsers'
import setCustomers from '../actions/customers/setCustomers'
import fetchCustomer from '../actions/customers/fetchCustomer'
import fetchCustomers from '../actions/customers/fetchCustomers'
import fetchUsers from '../actions/users/fetchUsers'
import PageHeader from '../components/PageHeader'
import getUsersMeta from '../selectors/users/getUsersMeta'

export default function UsersOverviewScreen() {
  const dispatch = useDispatch()
  const promisifyDispatch = usePromisifyDispatch()

  const limits = getDefaultPageLimits()
  const defaultPageLimit = limits && useDefaultPageLimit(limits)

  const [loading, setLoading] = useState(false)
  const [errorMessages, setErrorMessages] = useState(null)
  const [userRoleSearchValue, setUserRoleSearchValue] = useState([])

  const usersMeta = useSelector(getUsersMeta)
  const [page, pages, total] = usePagination(usersMeta)
  const { search } = useLocation()
  const {
    sort,
    limit,
    ...filter
  } = parse(search, { ignoreQueryPrefix: true })

  const getFilteredUserRoles = useMemo(makeGetFilteredUserRolesForAutoComplete, [userRoleSearchValue])

  const users = useSelector(getUsers)
  const customer = useSelector((state) => (
    getCustomer(state, { id: filter && filter.customerId && filter.customerId.split(':')[1] })
  ))
  const customersForAutoComplete = useSelector(getCustomersDataForAutoComplete)
  const userRolesForAutoComplete = useSelector(getUserRolesForAutoComplete)
  const filteredUseRolesForAutocomplete = useSelector((state) => getFilteredUserRoles(state, { userRoleSearchValue }))

  const userRoleBadgeValue = filter.userRoleId
    && userRolesForAutoComplete
    && userRolesForAutoComplete.find(({ key }) => key === filter.userRoleId.split(':')[1])

  useEffect(() => {
    if (filter.customerId && !customer) {
      dispatch(fetchCustomer(filter.customerId.split(':')[1]))
    }
  }, [search, dispatch, fetchCustomer])

  useEffect(() => {
    if (filter.customerId) {
      dispatch(fetchUserRoles(filter.customerId.split(':')[1]))
    }
  }, [search])

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      dispatch(setCustomers([]))
      dispatch(setUsers([], {
        total: 0,
      }))
      return
    }
    setLoading(true)
    setErrorMessages(null)
    promisifyDispatch(fetchUsers({
      page: Number(page),
      sort,
      limit: limit || defaultPageLimit,
      filter,
    }))
      .catch(setErrorMessages)
      .finally(() => (
        setLoading(false)
      ))
  }, [search, fetchUsers])

  return (
    <Layout>
      <PageHeader loading={loading}>
        <Heading fontSize={Heading.size.H3}>Users</Heading>
      </PageHeader>
      <FilterBar
        filters={[
          filters.fullName,
          filters.email,
          {
            ...filters.customerId,
            value: customer && customer.title,
            onChange: (e) => {
              const value = e.target.value.trim()
              if (value.length === 0) {
                dispatch(setCustomers([]))
                return
              }
              dispatch(fetchCustomers({ filter: { title: `contains:${value}` } }))
            },
            autoCompleteResults: customersForAutoComplete,
            dependencies: [filters.userRoleId.key],
          },
          {
            ...filters.userRoleId,
            value: userRoleBadgeValue && userRoleBadgeValue.label,
            disabled: !(filter && filter.customerId),
            onChange: (e) => setUserRoleSearchValue(e.target.value.trim().toLowerCase()),
            autoCompleteResults: filteredUseRolesForAutocomplete,
          },
        ]}
        sort={[
          {
            key: 'lastUpdatedAt',
            label: 'Last updated',
            defaultOrder: 'desc',
          },
          {
            key: 'lastName',
            label: 'Last name',
          },
          {
            key: 'importerLockedUntil',
            label: 'Importer locking',
          },
        ]}
        limits={limits}
      />
      {!!errorMessages && <Alert errorMessages={errorMessages} />}
      <List>
        {loading && !users.length && <UserListSkeleton />}
        {!loading && !users.length && <ListItem className="italic">No results</ListItem>}
        {!loading && !!users.length && users.map((user) => <UserListItem key={user.id} user={user} />)}
      </List>
      {total > 0 && (
        <div className="text-sm text-gray-300 italic flex justify-end mx-2">
          <div>{`There are ${total} results`}</div>
        </div>
      )}
      {pages > 1 && <Pagination current={page || 1} total={pages} />}
    </Layout>
  )
}

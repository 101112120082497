import { cn } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'

function List({
  children,
  className,
}) {
  return (
    <ul className={cn('divide-y-2 divide-gray-50', className)}>
      {children}
    </ul>
  )
}

List.propTypes = {
  children: PropTypes.node,
  className: cn.propType,
}

List.defaultProps = {
  children: null,
  className: null,
}

export default List

import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import {
  Dropdown,
  DropdownItem,
  Skeleton,
} from '@Infowijs-eng/component-library/components'
import StackedTable from '@Infowijs-eng/component-library/components/StackedTable'
import {
  useDispatch,
  useSelector,
} from 'react-redux'
import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import {
  ArrowsLoop,
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import { useParams } from 'react-router-dom'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import getFirstName from '../../modules/getFirstName'
import getUser from '../../selectors/user/getUser'
import sortCustomerProducts from '../../modules/sortCustomerProducts'
import inviteUser from '../../actions/user/inviteUser'
import addNotification from '../../actions/notificationCenter/addNotification'
import getFullName from '../../modules/getFullName'
import getCustomerProductTypeLabel from '../../modules/getCustomerProductTypeLabel'
import getCustomerProductsData from '../../selectors/customerProducts/getCustomerProductsData'
import ProductAccessListItem from '../CustomerProduct/ProductAccessListItem'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'
import makeGetCustomerProductsByCustomerId from '../../selectors/customerProducts/makeGetCustomerProductsByCustomerId'

function UserCustomerProductKeyAccessSection({ loadUser }) {
  const {
    customerId,
    userId,
  } = useParams()

  const promisifyDispatch = usePromisifyDispatch()
  const dispatch = useDispatch()
  const getCustomerProductsByCustomerId = useMemo(makeGetCustomerProductsByCustomerId, [])

  const user = useSelector(getUser)
  const [isInvitingUser, setIsInvitingUser] = useState(false)
  const [availableCustomerProducts, setAvailableCustomerProducts] = useState([])

  const allCustomerProducts = useSelector(getCustomerProductsData)
  const customerProducts = customerId && useSelector(
    (state) => getCustomerProductsByCustomerId(state, { customerId }),
  )

  useEffect(() => {
    if (!user || !customerProducts) {
      return
    }

    setAvailableCustomerProducts(
      customerProducts
        .filter((customerProduct) => {
          const accessTo = Object.values(user.userCustomerProductKey || {})
            .map((userCustomerProductKey) => userCustomerProductKey.customerProductId)
          return !accessTo.includes(customerProduct.id)
        }),
    )
  }, [customerProducts, user])

  return (
    <section aria-labelledby="user product access">
      <SectionTitle
        trailingAddon={(
          <Visibility permissions={['users.invite']}>
            {!user && <Skeleton lg className="w-36" />}
            {user && availableCustomerProducts.length > 0 && (
            <Dropdown
              buttonProps={{
                leadingAddon: isInvitingUser ? <ArrowsLoop className="animate-spin" /> : <PlusCircle />,
                neutral: true,
                small: true,
              }}
              label="Invite to"
            >
              {availableCustomerProducts
                .sort(sortCustomerProducts)
                .map((customerProduct) => (
                  <DropdownItem
                    small
                    key={customerProduct.id}
                    className="whitespace-nowrap"
                    disabled={isInvitingUser}
                    onClick={() => {
                      setIsInvitingUser(true)
                      promisifyDispatch(inviteUser(userId, customerProduct.id))
                        .then(() => {
                          loadUser()
                          dispatch(addNotification({
                            // eslint-disable-next-line max-len
                            title: `${getFullName(user)} has been invited to ${getCustomerProductTypeLabel(customerProduct.type)} (${customerProduct.title})`,
                          }))
                        })
                        .finally(() => {
                          setIsInvitingUser(false)
                        })
                    }}
                  >
                    {`${getCustomerProductTypeLabel(customerProduct.type)} (${customerProduct.title})`}
                  </DropdownItem>
                ))}
            </Dropdown>
            )}
          </Visibility>
        )}
      >
        Product Access
      </SectionTitle>
      <SectionBody>
        <StackedTable
          placeholder={user && `${getFirstName(user, false)} has no access to any Products`}
          isLoading={!user || !customerProducts}
          loadingMessage="Loading Product Access..."
        >
          {user && user.userCustomerProductKey && Object
            .values(user.userCustomerProductKey)
            .map((userCustomerProductKey) => {
              const product = allCustomerProducts
              && allCustomerProducts[userCustomerProductKey.customerProductId]

              return (
                product
                  ? (
                    <ProductAccessListItem
                      key={product.id}
                      customerProduct={product}
                      userCustomerProductKey={userCustomerProductKey}
                    />
                  )
                  : null
              )
            })}
        </StackedTable>
      </SectionBody>
    </section>
  )
}

UserCustomerProductKeyAccessSection.propTypes = {
  loadUser: PropTypes.func.isRequired,
}

export default UserCustomerProductKeyAccessSection

import {
  Card,
  Toggle,
} from '@Infowijs-eng/component-library/components'
import {
  ArrowLeft,
  ArrowRight,
  ArrowsLoop,
} from '@Infowijs-eng/component-library/icons'
import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import PropTypes from 'prop-types'
import {
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import updateImporter from '../../actions/importers/updateImporter'
import makeGetImporterById from '../../selectors/importers/makeGetImporterById'
import ModalWithSteps from '../ModalWithSteps'

// eslint-disable-next-line max-len
const alertMessage = 'The importer setup is not yet finished and therefore cannot be used. You can edit this importer later to continue.\nAre you sure you wish to close this window?'

function ImporterEditSettings({
  totalSteps,
  currentStep,
}) {
  const { customerId, importerId } = useParams()
  const navigate = useNavigate()
  const promisifyDispatch = usePromisifyDispatch()
  const [submitting, setSubmitting] = useState(false)
  const [settings, setSettings] = useState({
    includeParentsWithDisabledMail: false,
  })
  const getImporterById = useMemo(
    () => makeGetImporterById(),
    [],
  )
  const importer = useSelector((state) => getImporterById(
    state,
    { id: importerId },
  ))
  const [errorMessages, setErrorMessages] = useState(null)

  useEffect(() => {
    if (importer && importer.metadata && importer.metadata.config) {
      const newSettings = {}
      Object.keys(settings).forEach((key) => {
        if (key in importer.metadata.config) {
          newSettings[key] = importer.metadata.config[key]
        }
      })
      setSettings(newSettings)
    }
  }, [importer])

  return (
    <ModalWithSteps
      title="Add Importer"
      onClose={() => {
        // eslint-disable-next-line no-alert
        if (window.confirm(alertMessage)) {
          navigate(`/customers/${customerId}`)
        }
      }}
      totalSteps={totalSteps}
      currentStep={currentStep}
      nextButtonProps={{
        disabled: submitting,
        trailingAddon: submitting ? <ArrowsLoop className="animate-spin" /> : <ArrowRight />,
        onClick: () => {
          setSubmitting(true)
          setErrorMessages(null)
          promisifyDispatch(updateImporter({
            id: importerId,
            metadata: { config: { ...settings } },
          }))
            .then(() => {
              setSubmitting(false)
              navigate(`/customers/${customerId}/importers/create/${importer.type}/${importerId}/dry-run`)
            })
            .catch((e) => {
              setSubmitting(false)
              setErrorMessages(e)
            })
        },
      }}
      previousButtonProps={{
        onClick: () => {
          if (importer.type === 'magister') {
            navigate(`/customers/${customerId}/importers/create/${importer.type}/${importerId}/locations`)
          } else {
            navigate(`/customers/${customerId}/importers/create/${importer.type}/${importerId}`)
          }
        },
        leadingAddon: <ArrowLeft />,
      }}
      alert={errorMessages ? {
        title: 'Something went wrong while storing the importer\'s locations',
        errorMessages,
      } : null}
    >
      <span className="font-bold">Settings</span>
      <Card className="px-8 py-6 divide-y divide-gray-50 text-sm">
        <div className="flex justify-between items-center">
          <div className="font-bold">Include parents without &apos;Post&apos; checkmark</div>
          <Toggle
            label="Parents with Post field checked will be ignored"
            showLabel={false}
            enabled={settings.includeParentsWithDisabledMail}
            onChange={() => setSettings((prev) => ({
              ...prev,
              includeParentsWithDisabledMail: !prev.includeParentsWithDisabledMail,
            }))}
          />
        </div>
      </Card>
    </ModalWithSteps>
  )
}

ImporterEditSettings.propTypes = {
  totalSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
}

export default ImporterEditSettings

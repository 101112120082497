import actionTypes from '../../action-types'

export default (userId, userCustomerProductKeyId) => ({
  type: actionTypes.USER.ACCESS_REVOKE,
  payload: {
    userCustomerProductKeyId,
  },
  meta: {
    userId,
  },
})

import { usePromisifyDispatch } from '@Infowijs-eng/component-library/modules'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  Alert,
  BackgroundBubble,
  Button,
  Infowijs,
} from '@Infowijs-eng/component-library/components'
import { isIframeReady } from '@Infowijs-eng/component-library/modules/authentication'
import validateMagicLink from '../actions/authentication/validateMagicLink'

export default function MagicLinkScreen() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')

  const [tokenValidated, setTokenValidated] = useState(false)
  const [success, setSuccess] = useState(false)
  const iframeReady = useSelector(isIframeReady)
  const promisifyDispatch = usePromisifyDispatch()
  useEffect(() => {
    if (token && !tokenValidated && iframeReady) {
      promisifyDispatch(validateMagicLink(token))
        .then(() => {
          setTokenValidated(true)
          setSuccess(true)
        }, () => {
          setTokenValidated(true)
        })
    }
  }, [tokenValidated, token, iframeReady])

  return (
    <div>
      <BackgroundBubble />
      <div className="min-h-screen flex flex-col md:px-5 items-center justify-center">
        <div className="mb-5">
          <Infowijs />
        </div>
        <div className="w-full sm:max-w-md">
          {success && (
            <Alert success title="Sign in approved.">
              Please close this tab and go to tab where you tried to sign in.
            </Alert>
          )}
          {!success && (
            <Alert title="Sign in failed.">
              Something went wrong, please try to sign in again.
            </Alert>
          )}
          {!success && (<div className="mt-4 flex justify-center"><Button to="/sign-in">Sign in</Button></div>)}
        </div>
      </div>
    </div>
  )
}

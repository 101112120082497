import actionTypes from '../action-types'

export default (state = { data: {}, meta: {} }, action = {}) => {
  switch (action.type) {
    case actionTypes.IMPORTERS.SET_ALL:
      return {
        ...state,
        data: action.payload.reduce((acc, importer) => {
          acc[importer.id] = importer
          if (action.included && action.included.customer) {
            acc[importer.id].customer = action.included.customer[importer.customer.id]
          }
          if (action.included && action.included.administrator) {
            acc[importer.id].createdBy = action.included.administrator[importer.createdBy.id]
          }
          return acc
        }, {}),
        meta: {
          ...state.meta,
          ...action.meta,
          pages: Math.ceil(((action.meta && action.meta.total) || 0) / ((action.meta && action.meta.limit) || 50)),
        },
      }
    case actionTypes.IMPORTERS.SET:
      if (action.included) {
        return {
          ...state,
          data: {
            ...state.data,
            [action.payload.id]: {
              ...action.payload,
              createdBy: action.included.administrator[action.payload.createdBy.id],
            },
          },
        }
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      }
    default:
      return state
  }
}

import {
  call,
  put,
  takeLatest,
} from 'redux-saga/effects'
import axios from 'axios'
import {
  acceptAction,
  rejectAction,
} from '@Infowijs-eng/component-library/modules/reduxPromiseHelpers'
import { debounceAction } from '@Infowijs-eng/component-library/modules/authentication'
import { serviceEndpoints } from '@Infowijs-eng/component-library/modules'
import actionTypes from '../../action-types'
import awaitAccessToken from '../../modules/awaitAccessToken'
import deployCustomerProduct from '../../actions/customerProducts/deployCustomerProduct'
import patchCustomerProduct from '../../actions/customerProducts/patchCustomerProduct'

const onDeployCustomerProduct = function* onDeployCustomerProduct() {
  yield takeLatest(actionTypes.CUSTOMER_PRODUCTS.DEPLOY, function* onDeployCustomerProductSaga(action) {
    const { payload: { customerProductId, deployedAt } } = action

    const accessToken = yield call(awaitAccessToken)

    let response

    try {
      response = yield call(axios, {
        url: `${serviceEndpoints.customers}/products/${customerProductId}`,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'X-Infowijs-Client': 'nl.infowijs.admin',
          'Content-Type': 'application/vnd.infowijs.v1+json',
          Accept: 'application/vnd.infowijs.v1+json',
        },
        data: {
          deployedAt,
        },
      })
    } catch (e) {
      if (e.response.status === 401) {
        yield debounceAction(function* reDeployCustomerProduct() {
          yield put(deployCustomerProduct(customerProductId, deployedAt))
        })
      }

      rejectAction(action, e.response.data.errors || 'An unknown error occurred')

      return
    }

    if (response.status !== 204) {
      yield put(patchCustomerProduct(response.data.data))
    }

    acceptAction(action, response.data.data)
  })
}

export default onDeployCustomerProduct

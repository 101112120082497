import actionTypes from '../../action-types'

export default (id, additionalInclude = []) => ({
  type: actionTypes.USER.FETCH,
  payload: {
    id,
  },
  meta: {
    additionalInclude,
  },
})

import {
  ArrowsLoop,
  PlusCircle,
  PlusSquare,
  TrashBin,
  TwoVerticalLines,
} from '@Infowijs-eng/component-library/icons'

import { Visibility } from '@Infowijs-eng/component-library/modules/authentication'
import dayjs from 'dayjs'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CustomerInformationSection from '../components/Customer/CustomerInformationSection'
import CustomerProductsSection from '../components/CustomerProduct/CustomerProductsSection'
import ImporterSection from '../components/Importer/ImporterSection'
import MetadataEditor from '../components/MetadataEditor/MetadataEditor'
import SectionBody from '../components/SectionBody'
import SectionTitle from '../components/SectionTitle'
import Timeline from '../components/Timeline'
import TimelineEvent from '../components/TimelineEvent'
import customerMetadataStructure from '../modules/customerMetadataStructure'
import getFullName from '../modules/getFullName'
import getCustomer from '../selectors/customers/getCustomer'
import makeGetImportersByCustomerId from '../selectors/importers/makeGetImportersByCustomerId'
import CustomerContextScreen from './CustomerContextScreen'

function CustomerDetailScreen() {
  const getImportersByCustomerId = makeGetImportersByCustomerId()
  const { customerId } = useParams()

  const customer = useSelector((state) => getCustomer(state, { id: customerId }))
  const importers = useSelector((state) => getImportersByCustomerId(state, { customerId }))

  const timelineEvents = []

  if (customer) {
    timelineEvents.push({
      label: `${customer.createdBy.firstName} ${customer.createdBy.lastName} created ${customer.title}`,
      icon: PlusCircle,
      date: customer.createdAt,
    })

    if (customer.suspendedAt) {
      timelineEvents.push({
        icon: TwoVerticalLines,
        label: 'Customer is suspended',
        date: customer.suspendedAt,
      })
    }
  }

  if (importers) {
    importers.forEach((importer) => {
      timelineEvents.push({
        icon: PlusSquare,
        label: `Importer "${importer.type}" is created by Admin ${getFullName(importer.createdBy)}`,
        date: importer.createdAt,
      })
      if (importer.lastRunAt && !importer.deletedAt) {
        timelineEvents.push({
          icon: ArrowsLoop,
          label: `Last run of importer "${importer.type}"`,
          date: importer.lastRunAt,
        })
      }
      if (importer.deletedAt) {
        timelineEvents.push({
          icon: TrashBin,
          label: `Importer "${importer.type}" is removed`,
          date: importer.deletedAt,
        })
      }
    })
  }

  return (
    <CustomerContextScreen customerId={customerId}>
      <div className="pt-4 space-y-4 xl:space-y-0 xl:gap-4 xl:grid xl:grid-cols-12">
        <section aria-labelledby="General Information" className="xl:col-span-7 space-y-4">
          <CustomerInformationSection customer={customer} loading={!customer} />

          {customer && customer.metadata && Object.keys(customer.metadata).length > 0 && (
            <section aria-labelledby="customer metadata">
              <SectionTitle>Metadata</SectionTitle>
              <SectionBody className="pt-0">
                <MetadataEditor
                  metadata={customer.metadata}
                  structure={customerMetadataStructure}
                />
              </SectionBody>
            </section>
          )}

          <CustomerProductsSection />

          <Visibility permissions={['importers.list']}>
            <ImporterSection customerId={customerId} />
          </Visibility>
        </section>

        <section aria-labelledby="timeline" className="xl:col-span-5">
          <Timeline>
            {timelineEvents
              .sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix())
              .map(({ icon, label, date }, i) => (
                <TimelineEvent
                  Icon={icon}
                  label={label}
                  date={date}
                  key={date}
                  eventLineOff={timelineEvents.length - 1 === i}
                />
              ))}
          </Timeline>
        </section>
      </div>
    </CustomerContextScreen>
  )
}

export default CustomerDetailScreen

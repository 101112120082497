import {
  ArrowDownArchive,
  Key,
  Pencil,
  PlusCircle,
} from '@Infowijs-eng/component-library/icons'
import dayjs from 'dayjs'
import {
  useEffect,
  useState,
} from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Timeline from '../Timeline'
import TimelineEvent from '../TimelineEvent'
import getFullName from '../../modules/getFullName'
import makeGetAdministratorByUserImporterSourceId
  from '../../selectors/administrators/makeGetAdministratorByUserImporterSourceId'
import getCustomerProductsData from '../../selectors/customerProducts/getCustomerProductsData'
import getUser from '../../selectors/user/getUser'

const getAdministratorByUserImporterSourceId = makeGetAdministratorByUserImporterSourceId()

function UserTimelineSection() {
  const {
    userId,
  } = useParams()

  const [timelineEvents, setTimelineEvents] = useState([])
  const user = useSelector(getUser)
  const allCustomerProducts = useSelector(getCustomerProductsData)
  const createdBy = userId && useSelector(
    (state) => getAdministratorByUserImporterSourceId(state, { userId }),
  )
  useEffect(() => {
    if (!user) {
      return
    }

    const events = []
    if (user.archivedAt) {
      events.push({
        label: 'Archived',
        icon: ArrowDownArchive,
        date: user.archivedAt,
      })
    }
    if (user.lastUpdatedAt) {
      events.push({
        label: `Last time changed ${(user.lastUpdatedBy && Object.keys(user.lastUpdatedBy).length > 0)
          ? `by Admin ${getFullName(user.lastUpdatedBy)}`
          : ''}`.trim(),
        icon: Pencil,
        date: user.lastUpdatedAt,
      })
    }
    if (user.createdAt && user.importerType === 'manual') {
      events.push({
        label: user.importerSourceId && user.importerType
          && `Created by ${createdBy ? `Admin ${getFullName(createdBy)}` : user.importerType}`,
        icon: PlusCircle,
        date: user.createdAt,
      })
    }
    if (user.createdAt && user.importerType !== 'manual') {
      events.push({
        label: user.importerType
          && `Imported from ${user.importerType}`,
        icon: PlusCircle,
        date: user.createdAt,
      })
    }

    if (user.userCustomerProductKey) {
      Object.values(user.userCustomerProductKey || {}).forEach((userCustomerProductKey) => {
        const customerProduct = allCustomerProducts
          && allCustomerProducts[userCustomerProductKey.customerProductId]
        events.push({
          label: customerProduct
            ? `Invited to ${customerProduct.type} (${customerProduct.title})`
            : 'Invited to Customer Product',
          icon: Key,
          date: userCustomerProductKey.createdAt,
        })
      })
    }

    setTimelineEvents(events)
  }, [user, allCustomerProducts])

  return (
    <Timeline>
      {timelineEvents.sort((a, b) => dayjs(b.date).unix() - dayjs(a.date).unix()).map(({
        icon,
        label,
        date,
      }, i) => (
        <TimelineEvent
          Icon={icon}
          label={label}
          date={date}
          key={btoa(date + label)}
          eventLineOff={timelineEvents.length - 1 === i}
        />
      ))}
    </Timeline>
  )
}

UserTimelineSection.propTypes = {}

UserTimelineSection.defaultProps = {}

export default UserTimelineSection

export default {
  ACCESS_INVITE: 'USER/ACCESS_INVITE',
  ACCESS_REVOKE: 'USER/ACCESS_REVOKE',
  ARCHIVE: 'USER/ARCHIVE',
  CREATE: 'USER/CREATE',
  FETCH: 'USER/FETCH',
  USER_GROUP_RELATIONS_FETCH: 'USER/USER_GROUP_RELATIONS_FETCH',
  USER_GROUP_RELATIONS_SET: 'USER/USER_GROUP_RELATIONS_SET',
  USER_GROUP_RELATIONS_CREATE: 'USER/USER_GROUP_RELATIONS_CREATE',
  USER_GROUP_RELATION_DELETE: 'USER/USER_GROUP_RELATION_DELETE',
  RELATIONS_CREATE: 'USER/RELATIONS_CREATE',
  RELATIONS_FETCH: 'USER/RELATIONS_FETCH',
  RELATIONS_SET: 'USER/RELATIONS_SET',
  RELATION_DELETE: 'USER/RELATION_DELETE',
  RELATION_DELETE_SUCCESS: 'USER/RELATION_DELETE_SUCCESS',
  SET: 'USER/SET',
  UPDATE: 'USER/UPDATE',
  INITIATE_IMPERSONATION: 'USER/INITIATE_IMPERSONATION',
}

import createSagaMiddleware from 'redux-saga'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import {
  persistReducer,
  persistStore,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import { persistedReducers as authPersistedReducers } from '@Infowijs-eng/component-library/modules/authentication'
import reducers from './reducers'
import sagas from './sagas'

const persistConfig = {
  key: 'infowijs-admin-client',
  storage,
  whitelist: [],
}

const sagaMiddleware = createSagaMiddleware()

const persistedReducers = persistReducer(
  persistConfig,
  combineReducers({
    ...reducers,
    ...authPersistedReducers,
  }),
)

const store = configureStore({
  reducer: persistedReducers,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: () => [sagaMiddleware],
})

const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export {
  store,
  persistor,
}

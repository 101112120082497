import React, { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getLocalisedLabelString } from '@Infowijs-eng/component-library/modules'
import { FixedSizeList as List } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { Toggle } from '@Infowijs-eng/component-library/components'
import {
  ImporterUser, MatchStatus, UserRole, UserStatus,
} from '../../../modules/importerSwitch/types'
import ImporterMigrateUserStatus from './UserStatus/ImporterMigrateUserStatus'
import getUniqueMatches from '../../../modules/importerSwitch/getUniqueMatches'
import ImporterMigrateUserSearchModal from './ImporterMigrateUserSearchModal'

interface ImporterMigrateMatchingProps {
  oldImporterUsers: { [id: string]: ImporterUser }
  newImporterUsers: { [id: string]: ImporterUser }
  userStatuses: { [id: string]: UserStatus }
  onAddUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
  onRemoveUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
  onUnmatchedExplicit: (user: ImporterUser) => void
  userRoles: { [id: string]: UserRole }
}

interface UserSearchProps {
  userStatus: UserStatus
  isLeftEditable: boolean
}

interface ListItemProps {
  data: {
    uniqueMatches: UserStatus[]
    customerId: string
    onAddUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
    onRemoveUser: (fromUser: ImporterUser, toUser: ImporterUser) => void
    onUnmatchedExplicit: (user: ImporterUser) => void
    onSearchUser: (result: UserSearchProps) => void
  }
  index: number
  style: unknown

}

function ListItem({ data, index, style }: ListItemProps) {
  const userStatus = data.uniqueMatches[index]
  return (
    <div style={style}>
      <ImporterMigrateUserStatus
        key={`${userStatus?.from?.id}-${userStatus?.to?.id}`}
        customerId={data.customerId}
        userStatus={userStatus}
        onRemoveUser={data.onRemoveUser}
        onUnmatchedExplicit={data.onUnmatchedExplicit}
        onAddUser={data.onAddUser}
        onSearchUser={() => {
          data.onSearchUser({
            userStatus,
            isLeftEditable: !userStatus.from && !!userStatus.to,
          })
        }}
      />
    </div>

  )
}

export default function ImporterMigrateMatching({
  oldImporterUsers, newImporterUsers, userStatuses, onAddUser, onRemoveUser, onUnmatchedExplicit, userRoles,
}: ImporterMigrateMatchingProps) {
  const { customerId } = useParams()
  const [searchUser, setSearchUser] = useState<UserSearchProps | null>(null)
  const [onlyShowImplicitUnmatched, setOnlyShowImplicitUnmatched] = useState<boolean>(false)

  const uniqueMatches = useMemo(() => {
    const result = getUniqueMatches(userStatuses)
      .filter((user) => !onlyShowImplicitUnmatched || user.status === MatchStatus.ImplicitUnmatch)

    result.sort((a: UserStatus, b: UserStatus) => {
      const comparesBothContainFrom = !!a.from === !!b.from
      if (!comparesBothContainFrom) {
        return a.from ? -1 : 1
      }

      if (a.from) {
        const roleALabel = a?.from?.userRole?.labelI18n ? getLocalisedLabelString(a?.from?.userRole?.labelI18n)
          : undefined
        const roleBLabel = b?.from?.userRole?.labelI18n ? getLocalisedLabelString(b?.from?.userRole?.labelI18n)
          : undefined

        if (roleALabel !== roleBLabel) {
          return roleALabel.localeCompare(roleBLabel)
        }
        return (a?.from?.lastName ?? '').localeCompare((b?.from?.lastName ?? ''))
      }

      if (a.to) {
        const roleALabel = a?.to?.userRole?.labelI18n ? getLocalisedLabelString(a?.to?.userRole?.labelI18n)
          : undefined
        const roleBLabel = b?.to?.userRole?.labelI18n ? getLocalisedLabelString(b?.to?.userRole?.labelI18n)
          : undefined

        if (roleALabel !== roleBLabel) {
          return roleALabel.localeCompare(roleBLabel)
        }
        return (a?.to?.lastName ?? '').localeCompare((b?.to?.lastName ?? ''))
      }

      return 0
    })

    return result
  }, [userStatuses, onlyShowImplicitUnmatched])

  return (

    <>
      {searchUser && (
        <ImporterMigrateUserSearchModal
          customerId={customerId}
          importerUsers={searchUser.isLeftEditable ? oldImporterUsers : newImporterUsers}
          userStatuses={userStatuses}
          onClose={() => {
            setSearchUser(null)
          }}
          onSelectUser={(userToAdd) => {
            if (searchUser.isLeftEditable) {
              onAddUser(userToAdd, searchUser.userStatus.to)
              setSearchUser(null)
            } else {
              onAddUser(searchUser.userStatus.from, userToAdd)
              setSearchUser(null)
            }
          }}
          sourceImporterUser={searchUser.isLeftEditable ? searchUser.userStatus.to : searchUser.userStatus.from}
          userRoles={userRoles}
        />
      )}

      <div className="flex h-full flex-col gap-4 mt-4">
        <div className="flex flex-row justify-between">
          <Toggle
            label="Only show matches where I need to take action"
            enabled={onlyShowImplicitUnmatched}
            onChange={() => { setOnlyShowImplicitUnmatched(!onlyShowImplicitUnmatched) }}
          />
          <p>
            {`Total rows: ${uniqueMatches.length}`}
          </p>
        </div>
        <div className="grid grid-cols-2">
          <div className="flex justify-center">From</div>
          <div className="flex justify-center">To</div>
        </div>
        <div className="grow">
          <AutoSizer>
            {/* eslint-disable-next-line react/no-unused-prop-types */}
            {({ height, width }: { height: number; width: number }) => (
              <List
                height={height}
                width={width}
                itemCount={uniqueMatches.length}
                itemData={{
                  uniqueMatches,
                  customerId,
                  onRemoveUser,
                  onUnmatchedExplicit,
                  onAddUser,
                  onSearchUser: (user: UserSearchProps) => {
                    setSearchUser(user)
                  },
                }}
                itemSize={94}
              >
                {ListItem}
              </List>
            )}
          </AutoSizer>
        </div>
      </div>
    </>

  )
}

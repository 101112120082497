import PropTypes from 'prop-types'
import DetailCardFieldGroup from '../DetailCardFieldGroup'
import DetailCardField from '../DetailCardField'
import SectionBody from '../SectionBody'
import SectionTitle from '../SectionTitle'

function UserInformationSection({ customer }) {
  return (
    <section aria-labelledby="general information">
      <SectionTitle>General information</SectionTitle>
      <SectionBody className="pt-4">
        <DetailCardFieldGroup>
          <div>
            <div className="text-sm font-medium text-gray-500">
              Address
            </div>
            <DetailCardField>
              {customer && customer.address}
            </DetailCardField>
            {customer && customer.address2 && (
            <DetailCardField>
              {customer.address2}
            </DetailCardField>
            )}
            {customer && ((customer.postalCode && customer.postalCode.length > 0)
            || (customer.city && customer.city.length > 0)) && (
            <DetailCardField>
              {`${customer.postalCode ? `${customer.postalCode} ` : ''}${customer.city}`}
            </DetailCardField>
            )}
            {customer && customer.country && customer.country.toUpperCase() !== 'NL' && (
            <DetailCardField>
              {customer.country}
            </DetailCardField>
            )}
          </div>
        </DetailCardFieldGroup>
      </SectionBody>
    </section>
  )
}

UserInformationSection.propTypes = {
  customer: PropTypes.shape({
    address: PropTypes.string.isRequired,
    address2: PropTypes.string,
    postalCode: PropTypes.string,
    city: PropTypes.string,
    country: PropTypes.string,
  }),
}

UserInformationSection.defaultProps = {
  customer: null,
}

export default UserInformationSection

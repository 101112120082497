import { ClipboardThreeLines } from '@Infowijs-eng/component-library/icons'
import PropTypes from 'prop-types'
import React, {
  useEffect,
  useState,
} from 'react'

function CopyToClipboard({ children }) {
  const [copied, setCopied] = useState(false)

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    }
  }, [copied])

  if (!window.navigator.clipboard) {
    // Clipboard is only available on HTTPS/localhost
    return null
  }

  return (
    <button
      className="px-2 py-1"
      onClick={() => {
        setCopied(true)
        window.navigator.clipboard.writeText(children.toString())
      }}
      aria-label="Copy to clipboard"
      type="button"
    >
      {!copied && <ClipboardThreeLines className="text-gray-500 hover:text-gray-700 transition-colors" />}
      {copied && <span className="flex whitespace-nowrap text-sm text-gray-300">Copied to clipboard!</span>}
    </button>
  )
}

CopyToClipboard.propTypes = {
  children: PropTypes.string.isRequired,
}

CopyToClipboard.defaultProps = {}

export default CopyToClipboard

import actionTypes from '../action-types'

export default (state = {
  notifications: {},
  inFocus: false,
}, action = {}) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_CENTER.ADD:
      return {
        ...state,
        notifications: {
          ...state.notifications,
          [action.payload.id]: {
            ...action.payload,
          },
        },
      }
    case actionTypes.NOTIFICATION_CENTER.REMOVE:
      return {
        ...state,
        notifications: {
          ...Object.fromEntries(
            Object.entries(state.notifications).filter(([id]) => id !== action.payload),
          ),
        },
      }
    case actionTypes.NOTIFICATION_CENTER.HOVERED:
      return {
        ...state,
        inFocus: action.payload,
      }
    default:
      return state
  }
}
